import React from 'react';
import IseiForm from './Form';

class ForceUpdateDataForm extends IseiForm {
    render() {
        const { submitButton } = this.props;

        return (
            <form className='-force-update' onSubmit={ this.onSubmit.bind(this)}>
                { this.props.sections && (this.props.error || this.props.success) &&
                <React.Fragment>
                    <div className='section' style={ { margin: 0 }} >
                        <div className='-description'></div>

                        <div className='-fields' style={ { boxShadow: 'none', padding: 0 }}>
                            {this.props.error && <div className='alert -error'>{this.props.error} </div>}
                            {this.props.success && <div className='alert -success'>{this.props.success} </div>}
                        </div>
                    </div>
                </React.Fragment> }

                { !this.props.sections &&
                    <React.Fragment>
                        {this.props.error && <div className='alert -error'>{this.props.error} </div>}
                        {this.props.success && <div className='alert -success'>{this.props.success} </div>}
                </React.Fragment> }

                { this.props.sections &&
                    this.props.sections.map((s, idx) => <div key={ idx } className='section'>
                        <div className='-description'>
                            <h2>{ s.title }</h2>
                            <p>{ s.description }</p>
                        </div>

                        <div className='-fields'>
                            { Object.entries(s.fields).map(([ key, field ]) => this.renderField(key, field)) }
                        </div>
                    </div>) }
                { !this.props.sections && Object.entries(this.props.fields).map(([ key, field ]) => this.renderField(key, field)) }

                <div className={ this.props.buttonsRight ? '-buttons -right' : '-buttons' }>
                    { this.props.hasCancelButton &&
                        <button type='button' className='btn -secondary'>
                            Cancelar
                        </button> }
                        { submitButton &&
                    <button type='submit' className='btn -primary'>
                        { submitButton.icon &&
                            <i translate='no' className='material-icons notranslate'>{ submitButton.icon }</i> }
                        { submitButton.label }
                        </button> }
                </div>
            </form>

        );
    }
}

export default ForceUpdateDataForm;
