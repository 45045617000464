import React from 'react';
import { Link } from 'react-router-dom';

function PaginationComponent({ onChange, getUrl, titleFn, collection }) {
    const { list, page, total, rowsPerPage } = collection;
    const totalPages = Math.ceil(total / rowsPerPage);

    const hasPrev = page > 1;
    const hasNext = page < totalPages;

    const pageNumbers = [];

    const minPage = Math.max(page - 3, 1);
    const maxPage = Math.min(page + 3, totalPages);

    let currentPage = minPage;

    while (currentPage <= maxPage) {
        pageNumbers.push(currentPage);
        currentPage++;
    }

    return (
        <div className='pagination'>
            <p>{ titleFn(list.length, total)}</p>

            { totalPages > 1 &&
            <div className='pages'>
                { hasPrev &&
                <div className='-prev'>
                    <Link onClick={ (ev) => {
                        ev.preventDefault();

                        window.history.replaceState(null, null, onChange(page - 1));
                    } } to={ getUrl(page - 1) }>Anterior</Link>
                </div> }

                <div className='-numbers'>
                    { pageNumbers.map((p) => <React.Fragment>
                        { p === page && <span>{p}</span>}
                        { p !== page && <Link onClick={ (ev) => {
                            ev.preventDefault();
                            window.history.replaceState(null, null, onChange(p));
                        } } to={ getUrl(p)}>{p}</Link>}
                    </React.Fragment>)}
                </div>

                { hasNext &&
                <div className='-next'>
                    <Link onClick={ (ev) => {
                        ev.preventDefault();
                        window.history.replaceState(null, null, onChange(page + 1));
                    } } to={ getUrl(page + 1) }>Próxima</Link>
                </div> }
            </div> }
        </div>
    );
}

export default PaginationComponent;
