import React from 'react';
import { uniq, url, getInnerValueByEvent } from '../../utils';
import { MemoryComponent } from '../../memory';
import ajaxAdapter from '../../ajaxAdapter';
import IseiForm from '../../components/Form';
import BaseView from '../BaseView';
import LoadingMask from '../../components/LoadingMask';
import DOMPurify from 'dompurify';

const _selectDegreeRef = React.createRef();
const _signatureCanvasRef = React.createRef();

class SelectDegreesView extends React.Component {
    state = {
        isModalOpen: this.props.isModalOpen,
        degrees: this.props.degrees
    };

    toggleDegreeChecked(degreeId, ref, checked) {
        ref.current.checked = checked;
        const degrees = [ ...this.state.degrees ];

        if (checked) {
            degrees.push(degreeId);
        } else {
            degrees.splice(degrees.indexOf(degreeId), 1);
        }

        this.setState({ degrees: uniq(degrees) });
    }

    _getCatRef(refs, cat) {
        refs.cat[cat.id] = React.createRef();
        return refs.cat[cat.id];
    }

    _getDegreeRef(refs, c) {
        refs.degree[c.id] = React.createRef();
        return refs.degree[c.id];
    }

    render() {
        const templateCategories = this.props
            .templateSchools
            .reduce((curr, s) => curr.concat(s.categories), []);

        const categories = this.props.school.categories.concat(templateCategories);
        const refs = { cat: {}, degree: {} };

        if (!this.state.isModalOpen) { return null; }

        return (
            <div className='-select-degree'>
                <div className='degrees-wrapper'>
                { categories.filter((c) => c.degrees && c.degrees.length).map((cat) => <div className='-cat'>
                <div onClick={ () =>{
                    const value = !refs.cat[cat.id].current.checked;
                    refs.cat[cat.id].current.checked = value;

                    // disabled for now
                } } className='-header'>
                    <h5>{ cat.title }</h5>
                    <input
                        ref={ this._getCatRef(refs, cat) }
                        type='checkbox'
                        style={ { display: 'none' }}
                        value='1'
                        name='all' />
                    </div>

                    { cat.degrees.filter((c) => c.title).map((c) => <div onClick={ (ev) => {
                        const value = ev.target.type === 'checkbox' ?
                            refs.degree[c.id].current.checked : !refs.degree[c.id].current.checked;

                        this.toggleDegreeChecked(c.id, refs.degree[c.id], value);
                    } } className='-degree'>
                        <p>{ c.title }</p>
                        <input
                            checked={ this.state.degrees.includes(c.id )}
                            ref={ this._getDegreeRef(refs, c) }
                            type='checkbox'
                            value='1'
                            name='all' />
                        </div>)}
                    </div>)}
            </div>
        </div>);
    }

    async _submit() {
        try {
            this.props.onSubmit({ ...this.state });
            this.hide();
        } catch(ex) {
            console.error(ex);
            this.setState({ error: ex.message, justUpdated: false });
        }

    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            degrees: [],
            all_degrees: false,
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class SignatureCanvas extends React.Component {
    render() {
        return (
            <div style={{ width: Math.min(600, window.innerWidth - 50), padding: 5, position: 'relative'}}>
                <p className='-secondary'>Assine abaixo:</p>
                <canvas
                    ref='canvas'
                    width={ Math.min(600, window.innerWidth - 50) }
                    height="150px"
                    id="signatureBox" className="signature-canvas"></canvas>

                <div style={ {
                    width: '98%',
                    position: 'absolute',
                    bottom: 25,
                    left: '1%',
                    borderTop: '2px dotted #D4D2D0'
                }} />

                    <button style={ {
                        position: 'absolute',
                        top: 20,
                        right: 0
                    } }
                    onClick={ (ev) => {
                        ev.preventDefault();
                        this.signaturePad.clear();
                    }}
                    className='btn -secondary'>
                    <i
                        style={ { margin: 0 } }
                        translate='no' className='material-icons'>delete_sweep</i>
                </button>
            </div>
        );
    }

    componentDidMount() {
        this.signaturePad = new global.SignaturePad(this.refs.canvas, {
            penColor: '#2a72ee',
            maxWidth: 1.5
        });
    }

    getBlob() {
        const dataURL = this.signaturePad.toDataURL('image/png');
        const data = atob(dataURL.substring('data:image/png;base64,'.length)),
            asArray = new Uint8Array(data.length);

        for (var i = 0, len = data.length; i < len; ++i) {
            asArray[i] = data.charCodeAt(i);
        }

        return new Blob([asArray], { type: 'image/png' });
    }
}

const configSections = [
    {
        title: 'Assinatura',
        description: 'Assine virtualmente o seu certificado, de rápida e fácil sem burocracia :)',
        fields: {
            signature_name: {
                type: 'text',
                autoFocus: true,
                icon: 'text_format',
                placeholder: 'Por exemplo: Deividy Metheler',
                label: 'Nome da pessoa',
                autoComplete: 'name',
                getValidationError(str) {
                    if (!str || str.length < 4) {
                        return 'Por favor, preencha o nome da pessoa corretamente';
                    }

                    return false;
                }
            },

            signature_value: {
                type: 'custom',
                render(form) {
                    const { values } = form.state;

                    if (values.signature_img) {
                        return (<div style={
                            { position: 'relative', width: 600 }
                        } className='signature-img'>

                        <button style={ {
                            position: 'absolute', top: 20, right: 0 } }
                        onClick={ (ev) => {
                            ev.preventDefault();
                            form.setValue('signature_img', null);
                        }}
                        className='btn -secondary'>
                        <i
                            style={ { margin: 0 } }
                            translate='no' className='material-icons'>delete_sweep</i>
                    </button>
                            <p className='-secondary'>Confira a sua assinatura:</p>
                            <img src={ values.signature_img } alt={ values.signature_name } />
                        </div>);
                    }

                    return <SignatureCanvas ref={ _signatureCanvasRef } />
                }
            }
        }
    },

    {
        title: 'Configuração',
        description: 'Configure para qual curso e quando será gerado o certificado',
        fields: {
            all_degrees: {
                type: 'switch',
                label: 'Certificado para todos os cursos',
                onChange(value) {
                    if (!value) { return _selectDegreeRef.current.show(); }

                    _selectDegreeRef.current.hide();
                }
            },
            degrees: { type: 'custom' }
        }
    }
];

class AdminCertificate extends BaseView {
    state = { certificate: {}, isLoading: false };

    async removeCertificate() {
        const certificateId = this.state.certificate.id;

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).delete(`certificate/${certificateId}/delete`);

            this.props.history.push(url('certificados'));
        } catch(ex) {
            global.error(ex);
        }
    }

    async onSubmit(values) {
        try {
            this.setState({ isLoading: true });

            let signature;
            if (_signatureCanvasRef.current) {
                signature = {
                    isSignature: true,
                    blob: _signatureCanvasRef.current.getBlob(),
                    name: `signature_${values.signature_name}.png`
                };
            }

            let degrees = [];
            if (!values.all_degrees && _selectDegreeRef.current) {
                degrees = _selectDegreeRef.current.state.degrees;
            }

            values.degrees = JSON.stringify(degrees);

            await ajaxAdapter({
                isSchoolRequest: true
            }).postFormData('certificate/save', {
                id: this.state.certificate.id,
                signature,
                ...{
                    degrees: values.degrees,
                    all_degrees: values.all_degrees,
                    is_automatic: values.is_automatic,
                    signature_img: values.signature_img,
                    signature_name: values.signature_name
                }
            });

            this.setState({ success: 'Certificado salvo com sucesso' });
        } catch(ex) {
            global.error(ex);
        } finally {
            this.setState({ isLoading: false});
        }
    }

    render() {
        const values = { signatures: [], ...this.state.certificate };

        configSections[1].fields.degrees.render = () => <SelectDegreesView
            isModalOpen={ !values.all_degrees }
            templateSchools={ this.props.templateSchools }
            degrees={ values.degrees }
            ref={ _selectDegreeRef }
            school={ this.props.school } />;

        values.signature_name = values.signatures[0]?.name || '';
        values.signature_img = values.signatures[0]?.signature;

        return (
            <div className='view-certificate base-view' style={ { position: 'relative' } }>
                <h2
                    onInput={ () => {

                    }}
                    onFocus={ () => {

                    }}
                    onBlur={ (ev) => {
                        const val = getInnerValueByEvent(ev);
                        this.setCertificate({ title: val })
                    }}

                    contentEditable={ true }
                    spellCheck={ false }
                    dangerouslySetInnerHTML={ {
                        __html: DOMPurify.sanitize(this.state.certificate.title || '')
                    }} />
                { values.id &&
                <IseiForm
                    ref='form'
                    success={ this.state.success }
                    error={ this.state.error }
                    onSubmit={ this.onSubmit.bind(this) }
                    sections={ configSections }
                    values={ values }
                    buttonsRight={ true }
                    submitButton={ { label: 'Salvar' } } /> }

                <button className='btn -remove' onClick={ () => {
                    if (global.confirm('Tem certeza?')) {
                        this.removeCertificate();
                    }
                } } style={ {
                    padding: '.3rem',
                    fontSize: '.8rem',
                    right: '7rem',
                    position: 'absolute',
                    bottom: '3rem'
                } }>Apagar certificado</button>

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    setCertificate(values, doSave = true) {
        const cls = { ...this.state.certificate, ...values };

        this.setState({ certificate: cls });
        if (doSave) { this.saveCertificate(cls); }
    }

    async saveCertificate(cls) {
        const certificateId = this.state.certificate.id;

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`certificate/${certificateId}/save-title`, cls);
        } catch(ex) {
            global.error(ex);
        }
    }

    async loadCertificate(page, certificateId = this.props.match.params.certificateId) {
        try {
            page = page || this.props.match.params.page || this.state.page;
            const res = {
                certificate: this.props.certificateList.find((c) => c.id === Number(certificateId))
            };

            this.setState(res)
        } catch(ex) {
            global.error(ex);
        }

    }

    async createCertificate() {
        const res = await ajaxAdapter({
            isSchoolRequest: true
        }).post('certificate/create');

        window.history.replaceState(null, this.props.school.name, url('certificado/' + res.certificate.id));

        res.isLoading = false;
        this.setState(res);
    }

    async componentDidMount() {
        super.componentDidMount();

        try {
            const { certificateId } = this.props.match.params;

            if (!certificateId) {
                return await this.createCertificate();
            }

            await this.loadCertificate();
        } catch(ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(AdminCertificate, 'school', 'certificateList', 'templateSchools');
