import React, { useContext, useEffect, useState } from 'react';
import { updateMemory, getMemory } from './memory';
import { debounce } from './utils';

import ajaxAdapter from './ajaxAdapter';
import IseiContext from './Context';
import IseiQuill from './components/IseiQuill';

import ViewAdminGateway from './views/admin/AdminGateway';
import ViewAdminContent from './views/admin/AdminContent';
import ViewAdminConfig from './views/admin/AdminConfig';
import ViewAdminDegree from './views/admin/AdminDegree';
import ViewAdminDegreeList from './views/admin/AdminDegreeList';
import ViewAdminDesign from './views/admin/AdminDesign';
import ViewAdminGatewayList from './views/admin/AdminGatewayList';
import ViewAdminHome from './views/admin/AdminHome';
import ViewAdminStudent from './views/admin/AdminStudent';
import ViewAdminStudentList from './views/admin/AdminStudentList';
import ViewAdminCommunity from './views/admin/AdminCommunity';
import ViewAdminOtherSchools from './views/admin/AdminOtherSchools';
import ViewAdminSupport from './views/admin/AdminSupport';
import ViewAdminCertificates from './views/admin/AdminCertificates';
import ViewAdminCertificate from './views/admin/AdminCertificate';
import ViewAdminPlans from './views/admin/AdminPlans';
import ViewAdminPoints from './views/admin/AdminPoints';
import ViewAdminBank from './views/admin/AdminBank';
import ViewAdminAddBank from './views/admin/AdminAddBank';
import ViewAdminOffer from './views/admin/AdminOffer';
import ViewAdminSchoolTemplates from './views/admin/AdminSchoolTemplates';
import ViewAdminSchoolTemplatesPlans from './views/admin/AdminSchoolTemplatesPlans';
import ViewAdminApi from './views/admin/AdminApi';
import ViewAdminUsers from './views/admin/AdminUsers';
import ViewAdminLowBalance from './views/admin/AdminLowBalance';

import ViewStudentSchoolContact from './views/student/StudentSchoolContact';
import ViewStudentContent from './views/student/StudentContent';
import ViewStudentDegree from './views/student/StudentDegree';
import ViewStudentDegreeList from './views/student/StudentDegreeList';
import ViewStudentFavorites from './views/student/StudentFavorites';
import ViewStudentSupport from './views/student/StudentSupport';
import ViewStudentCertificates from './views/student/StudentCertificates';
import ViewStudentMyDegrees from './views/student/StudentMyDegrees';

import ViewUserHome from './views/user/UserHome';
import ViewUserMyAccount from './views/user/UserMyAccount';
import ViewUserMyDegrees from './views/user/UserMyDegrees';
import ViewUserSchools from './views/user/UserSchools';
import ViewUserDegreeList from './views/user/UserDegreeList';

import LoadingMask from './components/LoadingMask';
import DOMPurify from 'dompurify';

const savePageHtml = debounce(async (link, html) => {
    try {
        const u = `school/${global.school.id || 0}/html-page/${link}`
        await ajaxAdapter().post(u, { html });
    } catch(ex) {
        global.error(ex)
    }
}, 1000);

function htmlPageComponent(link) {
    let isLoaded = false;

    return function () {
        const [ html, setHtml ] = useState(getMemory(`html_${link}`) || '');
        const context = useContext(IseiContext.type);

        useEffect(() => {
            if (isLoaded && html) { return; }

            const u = `school/${global.school.id || 0}/html/${link}`;
            ajaxAdapter().get(u).then(({ html }) => {
                if (global.school && global.school.name) {
                    html = html
                        .replace(/iSei Plataforma EAD do Brasil Sociedade LTDA/g, global.school.name)
                        .replace(/iSei/g, global.school.name)
                        .replace(/sensei@isei\.app/g, global.school.email);
                }

                isLoaded = true;
                setHtml(html);
                updateMemory({ [`html_${link}`]: html });
            }).catch((ex) => {
                global.error(ex)
            });
        });

        const isAdmin = context.schoolRole === 'admin' ||
            context.userRole === 'admin';

        return <div className='html-page'>
            <div className='-container'>
                { isAdmin &&
                    <IseiQuill
                        value={ html }
                        onChange={ (value) => {
                            setHtml(value);
                            savePageHtml(link, value);
                            updateMemory({ [`html_${link}`]: value });
                        } } /> }

                { !isAdmin &&
                    <div dangerouslySetInnerHTML={ {
                        __html: DOMPurify.sanitize(html) }} /> }

                <LoadingMask show={ !html } fullScreen={ true } />
            </div>
        </div>
    }
}

const routes = [
    { path: 'contato', school: true, components: {
        student: ViewStudentSchoolContact,
        admin: ViewAdminLowBalance
    } },
    { path: 'termos-e-condicoes', component: htmlPageComponent('termos-e-condicoes') },
    { path: 'politica-de-privacidade', component: htmlPageComponent('politica-de-privacidade') },
    { path: 'sobre', component: htmlPageComponent('sobre') },

    { path: 'low-balance', school: true, auth: 'school-admin', component: ViewAdminLowBalance },

    { path: 'curso/:categoryId/novo', auth: 'school-admin', component: ViewAdminDegree },
    { path: 'c/:link/:moduleId/nova-aula', school: true, auth: 'school-admin', component: ViewAdminContent },

    { path: 'c/:link/:classLink', components: {
        student: ViewStudentContent,
        admin: ViewAdminContent
    } },

    { path: 'c/:link', components: {
        student: ViewStudentDegree,
        admin: ViewAdminDegree
    } },

    { path: 'minha-conta', auth: 'student', component: ViewUserMyAccount },

    { path: 'cursos', components: {
        student: ViewStudentMyDegrees,
        admin: ViewAdminDegreeList ,
        isei: ViewUserMyDegrees
    } },

    { path: 'favoritos', auth: 'student', component: ViewStudentFavorites },
    { path: 'suporte', components: {
        student: ViewStudentSupport,
        admin: ViewAdminSupport
    } },
    {
        path: 'certificados',
        components: {
            admin: ViewAdminCertificates,
            student: ViewStudentCertificates
        }
    },

    { path: 'config', school: true, auth: 'school-admin', component: ViewAdminConfig },
    { path: 'design', school: true, auth: 'school-admin', component: ViewAdminDesign },
    { path: 'escolas', components: {
        admin: ViewAdminOtherSchools,
        student: ViewAdminOtherSchools,
        isei: ViewUserSchools
    } },
    { path: 'bank', school: true, auth: 'school-admin', component: ViewAdminBank },
    { path: 'add-saldo/:type', school: true, auth: 'school-admin', component: ViewAdminAddBank },
    { path: 'trocar-plano/:type', school: true, auth: 'school-admin', component: ViewAdminAddBank },
    { path: 'planos', school: true, auth: 'school-admin', component: ViewAdminPlans },
    { path: 'pontos', school: true, auth: 'school-admin', component: ViewAdminPoints },
    { path: 'comunidade', school: true, auth: 'school-admin', component: ViewAdminCommunity },
    { path: 'turmas', school: true, auth: 'school-admin', component: ViewAdminGatewayList },
    { path: 'turma/:gatewayId/p/:page', school: true, auth: 'school-admin', component: ViewAdminGateway },
    { path: 'turma/criar', school: true, auth: 'school-admin', component: ViewAdminGateway },
    { path: 'filiais-planos/criar', school: true, auth: 'school-admin', component: ViewAdminSchoolTemplates },
    { path: 'filiais-planos/:planId', school: true, auth: 'school-admin', component: ViewAdminSchoolTemplates },
    { path: 'filiais-planos/:planId/p/:page', school: true, auth: 'school-admin', component: ViewAdminSchoolTemplates },
    { path: 'filiais-planos', school: true, auth: 'school-admin', component: ViewAdminSchoolTemplatesPlans },

    { path: 'certificado/criar', school: true, auth: 'school-admin', component: ViewAdminCertificate },
    { path: 'certificado/:certificateId', school: true, auth: 'school-admin', component: ViewAdminCertificate },
    { path: 'api', school: true, auth: 'school-admin', component: ViewAdminApi },
    { path: 'usuarios', school: true, auth: 'school-admin', component: ViewAdminUsers },

    { path: 'turma/:gatewayId', school: true, auth: 'school-admin', component: ViewAdminGateway },
    { path: 'aluno/:link', school: true, auth: 'school-admin', component: ViewAdminStudent },
    { path: 'alunos', school: true, auth: 'school-admin', component: ViewAdminStudentList },
    { path: 'offer/:offer', school: true, auth: 'school-admin', component: ViewAdminOffer },
    { path: 'alunos/p/:page', school: true, auth: 'school-admin', component: ViewAdminStudentList },
    { path: 'alunos/:id', school: true, auth: 'school-admin', component: ViewAdminStudent },
    { path: 'galeria', components: {
        isei: ViewUserDegreeList,
        iseiPublic: ViewUserDegreeList
    } },

    { path: '', components: {
        isei: ViewUserHome,
        iseiPublic: ViewUserHome,
        public: ViewStudentDegreeList,
        student: ViewStudentDegreeList,
        admin: ViewAdminHome
    } }
];

export default routes;
