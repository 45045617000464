import React from 'react';
import { Link } from "react-router-dom";

import { url } from '../utils';
import { MemoryComponent } from '../memory';
import IseiContext from '../Context';

const sidebarMenusByContext = {
    school_admin: [
        { icon: 'home', label: 'Home', link: '/' },
        { icon: 'view_module', label: 'Meus cursos', link: '/cursos' },
        { icon: 'people', label: 'Meus alunos', link: '/alunos' },
        { icon: 'groups', label: 'Minhas turmas', link: '/turmas' },
        { icon: 'construction', label: 'Edições da escola', link: '/config' },
        { icon: 'design_services', label: 'Design da escola', link: '/design' },
        { icon: 'chat_bubble', label: 'Comunidade', link: '/comunidade' },
        { icon: 'school', feature: 'has_certificate', label: 'Certificados', link: '/certificados' },
        { icon: 'connect_without_contact', feature: 'has_affiliation', label: 'Afiliados / filiais', link: '/filiais-planos' },
        { icon: 'code', feature: 'has_api', label: 'Interface de acesso', link: '/api' },
        { icon: 'supervised_user_circle', feature: 'has_multiple_users', label: 'Usuários do sistema', link: '/usuarios' },
        { icon: 'corporate_fare', user: 'isAdminOfMoreSchools', label: 'Minhas escolas', link: '/escolas' },
    ],
    school_student: [
        { icon: 'home', label: 'Home', link: '/' },
        { icon: 'view_module', label: 'Meus cursos', link: '/cursos' },
        { icon: 'bookmark', label: 'Meus favoritos', link: '/favoritos' },
        { icon: 'account_box', label: 'Meus perfil', link: '/perfil' },
        { icon: 'corporate_fare', user: 'isAdminOfMoreSchools', label: 'Minhas escolas', link: '/escolas' },
        { icon: 'chat_bubble', feature: 'has_support', label: 'Suporte', link: '/suporte' },
    ],

    none: [
        { icon: 'home', label: 'Home', link: '/login' },
        { icon: 'school', label: 'Criar escola', link: '/cadastro' },
    ],

    user: [
        { icon: 'home', label: 'Home', link: '/' },
        { icon: 'view_module', label: 'Meus cursos', link: '/cursos' },
        { icon: 'school', label: 'Meus perfil', link: '/perfil' },
        { icon: 'corporate_fare', featureOff: 'is_private', label: 'Minhas escolas', link: '/escolas' },
    ]
};


class Sidebar extends React.Component {
    static contextType = IseiContext.type;

    componentDidMount() {
        this.historyUnlisten = this.props.history.listen(() => {
            this.forceUpdate();
        });
    }
    componentWillUnmount() {
        this.historyUnlisten();
    }

    filterFeatures(menu) {
        if ([
            '/perfil',
            '/cursos',
            '/favoritos'
        ].includes(menu.link) && localStorage.contextOverride === 'student') {
            return false;
        }

        if (!this.context.hasSchool && ![ '/login', '/cadastro' ].includes(menu.link)) {
            return false;
        }

        if (menu.user) { return this.props.user[menu.user]; }
        if (!menu.feature && !menu.featureOff) { return true; }
        if (menu.feature) { return this.props.school[menu.feature]; }

        return !this.props.school[menu.featureOff];
    }

    render() {
        const { hasUser, isSchoolAdmin, contextRole, contextName } = this.context;

        const menuState = this.props.sidebar;
        const sidebarMenusContextName = contextName === 'school' ?
            `${contextName}_${contextRole}` : contextName;

        const menus = [
            ...(sidebarMenusByContext[sidebarMenusContextName] || [])
        ].map((m) => ({ ...m })).filter(this.filterFeatures.bind(this))

        const { pathname } = this.props.history.location;

        menus.forEach((m) => {
            if (m.link === '/') {
                m.active = pathname === m.link || pathname === global.baseUiPathname + '/';
                return;
            }

            m.active = pathname.includes(m.link);
        });

        const name = this.props.user.name || this.userName;
        this.userName = name;

        return (<div className={ `sidebar -${menuState}` }>
<nav>
    <header>
        { name &&
        <h3 className='-normal'>
            <span className='-secondary'>Olá,</span> { name.split(' ')[0] }
        </h3> }

        { !name &&
            <React.Fragment>
                <h3 className='-normal'>
                    <span className='-secondary'>Olá!</span>
                </h3>
                { !this.context.hasSchool &&
                <p>
                    <span className='-role -student'>Visitante</span> de iSei :)
                </p> }
            </React.Fragment> }

        { this.props.school.name &&
            <p>
                { !isSchoolAdmin && <span className='-role -student'>{ hasUser ? 'Estudante' : 'Visitante' }</span> }
                { isSchoolAdmin && <span
                    onClick={ (ev) => {
                        localStorage.setItem('contextOverride', contextRole === 'student'  ? 'admin' : 'student');
                        localStorage.setItem('contextOverrideSchoolId', this.props.school.id);

                        IseiContext.update();

                        if (localStorage.getItem('contextOverride') === 'student') {
                            // smart redirects
                            if (pathname.includes(url('cursos'))) {
                                this.props.history.replace(url('/'));
                            }

                            if (pathname.includes(url('escolas'))) {
                                this.props.history.replace(url('/'));
                            }
                        } else {
                            // smart redirects
                            if (pathname === url('') || pathname === url('/')) {
                                this.props.history.replace(url('cursos'));
                            }

                            if (this.props.degree.school_id !== this.props.school.id) {
                                this.props.history.replace(url('cursos'));
                            }
                        }
                    }}
                    className='-role'>{ contextRole === 'student' ? 'Estudante' : 'Administrador' }</span> }
                 &nbsp;de { this.props.school.name }.</p> }
    </header>

    <ul className='-menu'>
        { menus.map((m, idx) => <li key={ idx } className={ m.active ? 'active' : '' }>
            { m.icon &&
                <Link to={ url(m.link === '/perfil' ?
                    this.props.user.link ?
                        `p/${this.props.user.link}` : 'minha-conta' :
                    m.link) }>
                    <i translate='no' className="material-icons notranslate">{ m.icon }</i>
                    <span>{ m.label }</span>
                </Link> }

            { m.component &&
                <Link to={ url(m.link) }>
                    { m.component() }
                </Link> }
            </li>) }

        <li className='active-secondary'>
            <a onClick={ (ev) => {
                ev.preventDefault();

                switch(menuState) {
                    case 'open':
                        return this.props.updateMemory({ sidebar: 'small' });
                    case 'right':
                        return this.props.updateMemory({ sidebar: 'closed-right' });
                    default:
                        return this.props.updateMemory({ sidebar: 'open' });
                }
            } } href='/'>
                <i translate='no' className="material-icons notranslate">
                    { menuState === 'small' ? 'chevron_right' : 'chevron_left' }
                    </i>
                    <span>Recolher Menu</span>
                </a>
            </li>

        </ul>
    </nav>
    <footer>
        <ul>
            <li className='copyright'>
                { this.props.school.name || 'iSei' } &copy; { new Date().getFullYear() }
            </li>
            <li>
                <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
            </li>
        </ul>
    </footer>
</div>);
    }
}

export default MemoryComponent(Sidebar, 'sidebar', 'user', 'school', 'degree');
