import React from 'react';
import { Link } from "react-router-dom";

import BaseView from '../BaseView';
import { Logo } from '../../svgs';
import { capitalize, url, validations } from '../../utils';
import IseiForm from '../../components/Form';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';
import IseiContext from '../../Context';
import { MemoryComponent } from '../../memory';

function addTokenToUrl(url) {
    url += url.includes('?') ? '&' : '?';
    url += `auth=${localStorage.getItem('jwtToken')}`;

    return url;
}

function getAcademyAppName(school, user) {
    const role = user.role === 'academy-admin' ? user.role : [].concat(
        school.role
    )[0];

    return {
        'academy-admin': 'mib',
        'admin': 'skinner',
        'academy': 'skinner',
        'student': 'hero',
        'teacher': 'xavier'
    }[role];
}

const signinSchoolFields = {
    school_name: {
        type: 'text',
        icon: 'school',
        placeholder: 'Nome de sua área de membros',
        autoComplete: 'organization',
        getPrettyValue: capitalize,
        getValidationError(str) {
            if (!str || str.length < 4) {
                return 'Por favor, preencha o seu nome';
            }

            return false;
        }
    },
    user_name: {
        type: 'text',
        icon: 'person',
        placeholder: 'O seu nome completo',
        autoComplete: 'name',
        getPrettyValue: capitalize,
        getValidationError(str) {
            if (!str || str.length < 4) {
                return 'Por favor, preencha o nome de sua área de membros';
            }

            return false;
        }
    },
    email: {
        type: 'text',
        icon: 'alternate_email',
        placeholder: 'E-mail para acessar a plataforma',
        autoComplete: 'email',
        selectOnFocus: true,

        getPrettyValue(value) {
            return (value &&
                value.toLowerCase &&
                value.toLowerCase().replace().replace(/\s/g, '')
            ) || value;
        },

        getValidationError(value) {
            if (!value || !/.+@.+\.[A-Za-z]+$/.test(value)) {
                return 'Preencha o seu e-mail corretamente';
            }

            return null;
        }
    },

    password: {
        type: 'password',
        icon: 'locker',
        placeholder: 'Preencha aqui a sua senha',
        autoComplete: 'current-password',
        selectOnFocus: true,
        getValidationError: validations.password
    },

    disclosure: {
        type: 'switch',
        label: 'Estou de acordo com os',
        linkAtEnd: {
            label: 'Termos e Condições',
            link: 'termos-e-condicoes'
        }
    },
};

const siginStudentFields = {
    name: {
        type: 'text',
        icon: 'person',
        placeholder: 'O seu nome completo',
        autoComplete: 'name',
        getPrettyValue: capitalize,

        getValidationError(str) {
            if (!str || str.length < 4) {
                return 'Por favor, preencha o seu nome';
            }

            return false;
        }
    },
    email: {
        type: 'text',
        icon: 'alternate_email',
        placeholder: 'E-mail para acessar a plataforma',
        autoComplete: 'email',
        selectOnFocus: true,

        getPrettyValue(value) {
            return (value &&
                value.toLowerCase &&
                value.toLowerCase().replace().replace(/\s/g, '')
            ) || value;
        },

        getValidationError(value) {
            if (!value || !/.+@.+\.[A-Za-z]+$/.test(value)) {
                return 'Preencha o seu e-mail corretamente';
            }

            return null;
        }
    },

    password: {
        type: 'password',
        icon: 'locker',
        placeholder: 'Preencha aqui a sua senha',
        autoComplete: 'current-password',
        selectOnFocus: true,
        getValidationError: validations.password
    },

    disclosure: {
        type: 'switch',
        label: 'Estou de acordo com os',
        linkAtEnd: {
            label: 'Termos e condições',
            link: 'termos-e-condicoes'
        }
    },
};

class ViewSignIn extends BaseView {
    state = {
        school_name: '',
        user_name: '',
        email: '',
        password: '',
        disclosure: false,
        isLoading: false,
        timer: 5
    };

    async onSubmit(values) {
        if (!values.disclosure) {
            return alert('Por favor, aceite os termos para continuar');
        }

        try {
            this.setState({
                isLoading: true,
                success: null,
                mustConfirmEmail: false,
                error: null,
                errorByField: {}
            })

            const token = this.props.match.params.token;
            const postData = { ...values, token };

            await ajaxAdapter({
                isSchoolRequest: true
            }).post('create-account/' + token, postData);

            this.setState({ isLoading: false, isCreated: true });

            this.interval = setInterval(() => {
                const timer = --this.state.timer;
                this.setState({ timer });

                if (timer === 0) {
                    clearInterval(this.interval);
                    this.goToMySchool();
                }
            }, 1000);
        } catch (ex) {
            console.error(ex);

            let message = ex.message;


            this.setState({ isLoading: false, success: null, error: message, justUpdated: false });
        }
    }

    renderSchoolSignIn() {
        const { school, user } = this.props;
        const { dark_mode } = user;
        const { logo_wide_url, logo_dark_wide_url } = school;
        const name = school.name || 'isei';

        return (
            <div className='app custom-page -signin'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            { dark_mode && logo_dark_wide_url && <img src={ logo_dark_wide_url } alt={ name } title={ name } /> }
                            { dark_mode && !logo_dark_wide_url && <Logo /> }

                            { !dark_mode && logo_wide_url && <img src={ logo_wide_url } alt={ name } title={ name } /> }
                            { !dark_mode && !logo_wide_url && <Logo /> }
                        </div>
                    </header>

                    { this.state.isCreated &&
                        <main>
                            <h2 style={ { margin: 0 } }>Boas Vindas! ;)</h2>
                            <p className='-secondary'>O seu cadastro foi efetuado com sucesso<br /><br />Por favor, acesse o seu e-mail para ativar a sua conta</p>
                        </main> }

                    { !this.state.isCreated &&
                    <main>
                        <p className='-secondary'>Olá, seja bem-vindo(a) a {name}!</p>
                        <p className='-secondary'>Para completar o seu cadastro preencha os seus dados abaixo:</p>
                            { this.state.success && <div className='alert -success'>{this.state.success}</div>}
                            {this.state.error && !/duplicate/i.test(this.state.error) && <div className='alert -error'>{this.state.error}</div>}
                            {this.state.error && /duplicate/i.test(this.state.error) && <div className='alert -error'>
                                E-mail já cadastrado, <Link to={ url('login') } >clique aqui</Link> para efetuar login.
                            </div>}
                            { this.state.mustConfirmEmail && <small className='text-muted mt-0 mb-3 d-block'>Caso ainda não tenha recebido o e-mail de confirmação, <a href='/' onClick={ (ev) => {
                                ev.preventDefault();

                                this.resendRegisterEmail();
                                this.setState({ error: null, mustConfirmEmail: false, success: 'E-mail enviado com sucess!'})
                            } }>clique aqui</a> para enviar novamente.</small>}
                            { this.state.hasLoaded && <IseiForm
                            values={ this.state.values }
                            onSubmit={ this.onSubmit.bind(this) }
                            fields={ siginStudentFields }
                            submitButton={ { label: `Acessar ${name}` } } /> }
                    </main> }
                </div>

                <footer>
                    <ul>
                        <li className='copyright'>
                            { this.props.school.name || 'iSei' } &copy; { new Date().getFullYear() }
                        </li>
                        <li>
                            <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                        </li>
                    </ul>
                </footer>
                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    render() {
        const { school, user } = this.props;
        const { dark_mode } = user;
        const { logo_wide_url, logo_dark_wide_url } = school;
        const name = school.name || 'isei';

        if (this.state.isCreated) {
            return (
                <div className='app custom-page -signin'>
                    <div className='box -size-2'>
                        <header>
                            <div className='logo'>
                                { dark_mode && logo_dark_wide_url && <img src={ logo_dark_wide_url } alt={ name } title={ name } /> }
                                { dark_mode && !logo_dark_wide_url && <Logo /> }

                                { !dark_mode && logo_wide_url && <img src={ logo_wide_url } alt={ name } title={ name } /> }
                                { !dark_mode && !logo_wide_url && <Logo /> }
                            </div>
                        </header>
                        <main>
                            <h2 style={ { margin: 0 } }>Pronto!</h2>
                            <p className='-secondary'>Sua conta está pronta! (eba!)<br /> Agora é só esperar {this.state.timer} segundo{ this.state.timer > 1 ? 's' : '' } para ser redirecionado ou <a
                                onClick={ (ev) => {
                                    ev.preventDefault();
                                    this.goToMySchool();
                                }}
                            href={ url('/') }>clicar aqui</a></p>
                        </main>
                    </div>

                    <footer>
                        <ul>
                            <li className='copyright'>
                                iSei &copy; 2020
                            </li>
                            <li>
                                <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                            </li>
                        </ul>
                    </footer>
                    <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
                </div>
            );
        }

        if (school.id) {
            return this.renderSchoolSignIn();
        }

        return (
            <div className='app custom-page -signin'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            <Logo />
                        </div>
                    </header>
                    { this.state.isCreated &&
                        <main>
                            <h2 style={ { margin: 0 } }>Boas Vindas! ;)</h2>
                            <p className='-secondary'>O cadastro de sua área de membros foi efetuado com sucesso<br /><br />Por favor, acesse o seu e-mail para ativar a sua conta</p>
                        </main> }

                    { !this.state.isCreated &&
                    <main>
                        <p className='-secondary'>Olá!</p>
                        <p className='-secondary'>Para efetuar o cadastro de sua escola, preencha os dados abaixo:</p>
                            { this.state.success && <div className='alert -success'>{this.state.success}</div> }
                            { this.state.error && <div className='alert -error'>{this.state.error}</div> }
                            { this.state.mustConfirmEmail && <small className='text-muted mt-0 mb-3 d-block'>Caso ainda não tenha recebido o e-mail de confirmação, <a href='/' onClick={ (ev) => {
                                ev.preventDefault();

                                this.resendRegisterEmail();
                                this.setState({ error: null, mustConfirmEmail: false, success: 'E-mail enviado com sucess!'})
                            } }>clique aqui</a> para enviar novamente.</small>}

                            { this.state.hasLoaded && <IseiForm
                            values={ this.state.values }
                            onSubmit={ this.onSubmit.bind(this) }
                            fields={ signinSchoolFields }
                            submitButton={ { label: `Criar a minha área de membros!` } } /> }
                    </main> }

                </div>

                <footer>
                    <ul>
                        <li className='copyright'>
                            iSei &copy; 2020
                        </li>
                        <li>
                            <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                        </li>
                    </ul>
                </footer>
                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    async componentDidMount() {
        try {
            const token = this.props.match.params.token;

            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).get('user-by-token/' + token)

            this.setState({
                hasLoaded: true,
                values: {
                    name: res.user.name,
                    email: res.user.email
                }
            })
        } catch(ex) {
            if (/Not found/.test(ex.data && ex.data.message)) {
                this.props.history.push(url('/'))
                return;
            }

            this.setState({ error: ex.message });
        }
    }

    async goToMySchool() {
        if (this.hasGoneToMySchool) { return; }
        this.hasGoneToMySchool = true;

        await IseiContext.load(null, url('/'));

        if (global.school.is_academy) {
            let url = '';
            if (global.baseUiUrl.includes('localhost')) {
                url = 'http://localhost:4000'
            }

            url += `${global.baseUiPathname}/${getAcademyAppName(
                global.school, global.user
            )}/`;
            url = addTokenToUrl(url);

            return window.location.href = url;
        }

        this.props.history.replace(url('/'));
    }
}

export default MemoryComponent(ViewSignIn, 'school', 'user');
