import React from 'react';
import { ReactSortable } from "react-sortablejs";
import Dropzone from 'react-dropzone'
import BaseView from '../BaseView';

import {
    getBestModuleNumber, getInnerValueByEvent, prettify, url,
    debounce
} from '../../utils';
import { updateMemory, MemoryComponent } from '../../memory';
import { Link } from 'react-router-dom';
import IseiContext from '../../Context';
import ajaxAdapter from '../../ajaxAdapter';
import LoadingMask from '../../components/LoadingMask';
import IseiQuill from '../../components/IseiQuill';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { saveDegree } from '../../utils';
import DOMPurify from 'dompurify';

let lastScrollTopModulesPosition = 0;
let ignoreScrollOnMount = false;
let _openModules = { };

function renderFileIcon({ filename, location }) {
    if (!location) {
        return <div className='-loading'>
            <i translate='no' className='material-icons notranslate'>scatter_plot</i>
        </div>
    }

    const fileParts = filename.split('.');
    const extension = fileParts[fileParts.length - 1];
    const s = defaultStyles[extension];

    if (!s) { return null; }
    return <div onClick={ () => {
        window.open(location);
    } } style={ {
        width: '3rem',
        cursor: 'pointer'
    } }><FileIcon extension={ extension } {...s} /></div>;
}

let idCount = 0;
function getId() { return String(new Date().getTime()) + idCount++; }

function getWideHeightBasedOnWidth(width, ratio = 1.47) {
    var height = ((width) / (Math.sqrt((Math.pow(ratio, 2) + 1))));

    return Math.round(height);
}

function getVimeoId(url) {
    // eslint-disable-next-line
    const m = url.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/);

    return m && m.length >= 4 ? m[3] : null;
}

function getIframeVideoUrl(link) {
    if (/vimeo/.test(link)) {
        let url = `https://player.vimeo.com/video/${getVimeoId(link)}`;

        if (link.includes('?h=')) {
            url += `?h=${link.split('?h=')[1]}`;
        }

        return url;
    }

    if (/youtu/.test(link)) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = link.match(regExp);

        if (match && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}`;
        }
    }

    if (/loom\.com/.test(link)) {
        const linkParts = link.split('/').filter(Boolean);
        const loomId = linkParts[linkParts.length - 1];

        return `https://www.loom.com/embed/${loomId}`;
    }
}

const navbarMenus = {
    loggedIn: [
        {
            icon: 'person',
            cls: '-avatar',
            link: 'minha-conta',
            submenu: [
                { label: 'Minha conta', icon: 'person', link: 'minha-conta' },
                { label: 'Sair', icon: 'exit_to_app', onClick(ev) {
                    ev.preventDefault();

                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('user');
                    localStorage.removeItem('schools');

                    window.location.href = url('');
                } },
            ]
        },
    ],
    none: [
        { icon: 'person', cls: '-avatar', link: 'minha-conta' }
    ]
};

class Header extends React.Component {
    render() {
        const { user, menus, school } = this.props;

        return (
            <header style={ { padding: '.5rem 2rem .5rem 2rem' } } className='main-header'>
                <div className='-title' style={ { display: 'flex', alignItems: 'center' } }>
                    <i onClick={ () => {
                        this.props.history.push(url(`c/${this.props.degree.link}`));
                    } } translate='no' className='material-icons notranslate'>arrow_back</i>

                    <h2
                        onInput={ () => {

                        }}
                        onFocus={ () => {

                        }}
                        onBlur={ (ev) => {
                            const val = getInnerValueByEvent(ev);

                            this.props.onTitleChange(val);
                        }}

                        contentEditable={ true }
                        spellCheck={ false }
                        dangerouslySetInnerHTML={ {
                            __html: DOMPurify.sanitize(this.props.title) }} />
                 </div>
                <ul className='-menu -content'>
                    {menus.filter((m) => {
                        if (m.feature && !school[m.feature]) { return false; }

                        return true;
                    }).map((m, idx) => <li key={idx}>
                        <Link to={url(m.link)}>
                            { m.cls === '-avatar' &&
                                <React.Fragment>
                                    <img
                                        src={ user.profile_img_url || 'https://isei.app/sensei.png'  }
                                    alt={ user.name } className='-avatar' />
                                </React.Fragment> }

                            { m.cls !== '-avatar' &&
                                <i className={`material-icons notranslate ${m.cls || ''}`}>{m.icon}</i> }
                        </Link>
                        {m.submenu && <ul className='-dropdown'>
                            {m.submenu.map((s, idx) => <li key={idx}>
                                <Link onClick={s.onClick} to={url(s.link)}>
                                    <i className={`material-icons notranslate ${s.cls || ''}`}>{s.icon}</i>
                                    {s.label}
                                </Link>
                            </li>)}
                        </ul>}
                    </li>)}
                </ul>
            </header>

        );
    }
}

const HeaderWithMemory = MemoryComponent(Header, 'school', 'user')

class Events {
    constructor() {
        this._listeners = {};
    }

    _ensureEventListenerKey(event) {
        if (this._listeners[event]) { return; }
        this._listeners[event] = [ ];
    }

    _addListener(event, listener, once = false) {
        this._ensureEventListenerKey(event);
        this._listeners[event].push({ once, listener });
    }

    once(event, listener) {
        this._addListener(event, listener, true);
    }

    on(event, listener) {
        this._addListener(event, listener, false);
    }

    off(event, listener) {
        this._ensureEventListenerKey(event);
        this._listeners[event] = this._listeners[event].filter((l) =>
            l.listener !== listener);
    }

    emit(event, ...values) {
        this._ensureEventListenerKey(event);

        let idx = this._listeners[event].length;
        while(idx--) {
            const { listener, once } = this._listeners[event][idx];

            if (once) {
                this._listeners[event].splice(idx, 1);
            }

            listener(...values);
        }
    }
}

class ImageUploader extends Events {
    constructor() {
        super();

        this._queue = [];
        this._isRunning = false;
    }

    add(file) {
        this._queue.push(file);
        this.process();
    }

    async process() {
        if (this._isRunning || this._queue.length === 0) { return; }

        this._isRunning = true;
        const file = this._queue.shift();

        try {
            const res = await ajaxAdapter({ isSchoolRequest: true }).postFormData('content/file', {
                file: file.fileObject,
                fieldName: 'file'
            });

            this.emit('success', { ...file, location: res.file_location })
        } catch(ex) {
global.error(ex);

            this.emit('error', file, ex);
        } finally {
            this._isRunning = false;
            this.process();
        }
    }
}

class AddContent extends BaseView {
    state = {
        isLoading: false,
        isMenuHidden: localStorage.getItem('isModuleMenuHidden') || window.innerWidth < 500,
        degree: { modules: [] },
        content: {},
        openModules: {}
    };

    static contextType = IseiContext.type;

    constructor(props) {
        super(props);

        this.imgUploader = new ImageUploader();
        this.imgUploader.on('error', (file) => {
            const files = [ ...this.props.content.files ];

            for (let idx = 0; idx < files.length; ++idx) {
                if (files[idx].id === file.id) {
                    files.splice(idx, 1);
                    break;
                }
            }

            this.setContent({ files });
            this.saveContentDraft({ files });
        })

        this.imgUploader.on('success', (file) => {
            const files = [ ...this.props.content.files ];

            files.forEach((f, idx) => {
                if (f.id === file.id) {
                    files[idx] = { ...file };
                }
            })

            this.setContent({ files });
            this.saveContentDraft({ files });
        })
    }

    isPointIn(x, y, cls) {
        let el;

        try {
            el = document.elementFromPoint(x, y);
        } catch(ex) {
            console.error(ex);
            return false;
        }

        if (!el) { return false; }

        if (el.className && el.className.includes && el.className.includes(cls)) {
            return true;
        }

        if (
            el.parentNode &&
            el.parentNode.className &&
            el.parentNode.className.includes &&
            el.parentNode.className.includes(cls)) { return true; }

        return false;
    }

    setDegree(values) {
        let degree = { ...this.props.degree };

        const school = { ...this.props.school };
        school.categories = [ ...school.categories ];

        if (degree.isDraft) {
            degree.draft = { ...degree.draft, ...values };
        } else {
            degree = { ...degree, ...values };
        }

        for (const cat of school.categories) {
            if (!cat.degrees) { continue; }

            let idx = 0;
            for (const c of cat.degrees) {
                if (c.link === degree.link) {
                    cat.degrees = [ ...cat.degrees ];
                    cat.degrees[idx] = { ...degree };

                    break;
                }

                idx++;
            }
        }
        updateMemory({ degree, school });
    }

    setContent(values) {
        let content = { ...this.props.content };

        if (content.isDraft) {
            content.draft = { ...content.draft, ...values };
        } else {
            content = { ...content, ...values };
        }

        updateMemory({ content });
    }

    async saveContentDraft(c = {}) {
        clearTimeout(this.saveContentDraftTimeout);
        this.isSaving = true;

        c = { ...this.props.content, ...c, degree_id: this.props.degree.id };

        try {
            delete c.currentValue;

            const res = await ajaxAdapter({ isSchoolRequest: true })
                .post(`/degree/${this.props.degree.link}/content/${this.props.content.link}/save`, c);

            const { moduleId } = this.props.match.params;
            c = { ...c, ...res.content, isDraft: true };

            const mediaUrl = (res.content.draft &&
                res.content.draft.main_media_url) || res.content.main_media_url;

            const currentMediaUrl = (this.props.content.draft &&
                this.props.content.draft.main_media_url) ||
                this.props.content.main_media_url;

            if (mediaUrl !== currentMediaUrl) {
                updateMemory({ content: c });
            }

            const modules = [ ...this.props.degree.modules ];
            for (const mod of modules) {
                if (!mod.content) { continue; }

                mod.content =  [ ...mod.content ];
                mod.content.forEach((_c, idx) => {
                    if (c.id === _c.id || (/new_/.test(_c.id) && moduleId === mod.id)) {
                        mod.content[idx] = c;
                    }
                });

                mod.content = [ ...mod.content ].filter((c) => !/new_/.test(c.id));
            }

            if (!this.props.match.params.classLink) {
                window.history.replaceState(
                    null,
                    null,
                    url('c/' + res.degree.link + '/' + res.content.link)
                );

                updateMemory({ content: { ...this.props.content, id: c.id, link: c.link } });
            }

            this.setDegree({ modules });
            this.isSaving = false;

            return res.content;
        } catch(ex) {
            this.isSaving = false;
            console.error(ex);
            this.setState({ error: ex.message });
        }

    }

    isContentVideo() {
        // HACKy
        return /youtu|vimeo|loom/.test(this.props.content.main_media_url);
    }

    renderContentVideo() {
        const { content } = this.props;

        const iframeWidth = Math.min(700, global.window.innerWidth - 470);
        const iframeHeight = getWideHeightBasedOnWidth(iframeWidth);

        return (
            <iframe
                title={content.title}
                src={getIframeVideoUrl(content.main_media_url)}
                width={iframeWidth}
                height={iframeHeight}
                frameBorder="0"
                allow="autoplay; fullscreen"
                webkitallowfullscreen='true'
                mozallowfullscreen='true'
                allowFullScreen />
        );
    }

    renderExternalFile(ef) {
        if (ef.isAddButton) {
            if (this.state.isDraggingExternalLink) {
            return (<div key='addExternalFile' className='add-external_link -remove'>
                    <i translate='no' className='material-icons notranslate'>delete</i>
                    Arraste aqui para remover
            </div>);

            }

            return (<div
            key='addExternalFile'
            onClick={ () => {
                const external_links = [ ...this.props.content.external_links ];

                external_links.push({
                    id: getId(),
                    title: '',
                    link: ''
                })

                this.setContent({ external_links });
                this.saveContentDraft({ external_links });

            }} className='add-external_link'>
                    <i translate='no' className='material-icons notranslate'>link</i>
                   Adicionar Link Externo
            </div>);
        }

        return (
            <div key={ ef.id } className='external-link -drag'>
                <div className='field-wrapper' onClick={ () => {
                this.refs[`external_f_link_${ef.id}`].focus(); }} >
                    <div className={ this.state.focus === 'external_f_link_' + ef.id ? 'field -active' : 'field' } >
                        <div className='icon'>
                            <i translate='no' className='material-icons notranslate'>notes</i>
                        </div>
                        <div className='input'>
                            <input
                                ref={ `external_f_link_${ef.id}` }
                                onFocus={ (ev) => {
                                    ev.target.select();
                                    this.setState({ focus: 'external_f_link_' + ef.id })
                                } }
                                value={ ef.title || '' }
                                maxLength={ 200 }
                                onChange={ (ev) => {
                                    const external_links = [ ...this.props.content.external_links ];
                                    const d = ev.nativeEvent.target.value;

                                    external_links.forEach((e, idx) => {
                                        if (e.id === ef.id) {
                                            external_links[idx] = { ...e, ...ef, title: d };
                                        }
                                    })

                                    this.setContent({ external_links });
                                } }

                                onBlur={() => {
                                    this.setState({ focus: null });
                                    this.saveContentDraft();
                                }}
                                placeholder='Título de exibição' type='text' />
                        </div>
                    </div>
                </div>
                <div className='field-wrapper' onClick={ () => {
                this.refs[`external_link_${ef.id}`].focus(); }} >
                    <div className={ this.state.focus === 'external_link_' + ef.id ? 'field -active' : 'field' } >
                        <div onClick={ () => {
                            if (ef.link) {
                                if (/^http/.test(ef.link)) {
                                    window.open(ef.link);
                                    return;
                                }
                                window.open('https://' + ef.link);
                            }

                        } } className='icon' style={ { cursor: ef.link ? 'pointer' : 'normal' }}>
                            <i translate='no' className='material-icons notranslate'>link</i>
                        </div>
                        <div className='input'>
                            <input
                                ref={ `external_link_${ef.id}` }
                                onFocus={ (ev) => {
                                    ev.target.select();
                                    this.setState({ focus: 'external_link_' + ef.id })
                                } }
                                value={ ef.link || '' }
                                maxLength={ 200 }
                                onChange={ (ev) => {
                                    const external_links = [ ...this.props.content.external_links ];
                                    const d = ev.nativeEvent.target.value.trim();

                                    let url = d;
                                    if (d) {
                                        const link = prettify.removeDiacritics(d).replace(/\s/g, '-');

                                        url = link
                                    }

                                    external_links.forEach((e, idx) => {
                                        if (e.id === ef.id) {
                                            external_links[idx] = { ...e, ...ef, link: url };
                                        }
                                    })

                                    this.setContent({ external_links });
                                } }

                                onBlur={() => {
                                    this.setState({ focus: null });
                                    this.saveContentDraft();
                                }}
                                placeholder='O seu link externo' type='text' />
                        </div>
                    </div>
                </div>
                <div className='-dragger'>
                    <i translate='no' className='material-icons notranslate'>drag_indicator</i>
                </div>
            </div>
        );
    }

    async addFileToQueue(file) {
        this.imgUploader.add(file);
    }

    renderFile(file, idx) {
        if (file.isAddButton) {
            if (this.state.isDraggingFile) {
                return ( <button key={ idx } className='add-file -remove'>
                            <i translate='no' className='material-icons notranslate'>delete</i>
                            Arraste para remover
                        </button>
                );
            }
        return (
                <Dropzone key={ idx } multiple onDrop={ (filesToUpload) => {
                    const files = [ ...this.props.content.files ];
                    const alertMessage = [ ];

                    for (const file of filesToUpload) {
                        const sizeInMb = (file.size / 1024 / 1024)

                        if (sizeInMb >= 30) {
                            alertMessage.push(`Arquivo: ${file.name} excede o tamanho máximo!`);
                            continue;
                        }

                        const dbFile = {
                            id: getId(),
                            title: file.name.slice(0, 25),
                            filename: file.name,
                            type: file.type,
                            fileObject: file,
                            size: file.size,
                            sizeInMb
                        };

                        files.push(dbFile)
                        this.addFileToQueue(dbFile);
                    }

                    if (alertMessage.length > 0) {
                        alert(alertMessage.join('\n'));
                    }

                    this.setContent({ files });
                    this.saveContentDraft({ files });
                }}>
                    {({ getRootProps, getInputProps }) => (
                        <React.Fragment>
                        <div {...getRootProps()} className='add-file'>
                            <input {...getInputProps()} />
                            <i translate='no' className='material-icons notranslate'>cloud_upload</i>
                            <p>Enviar Arquivo</p>
                        </div>
                        </React.Fragment>
                    )}
                </Dropzone> )
        }

        return (
            <div key={ file.id } className='-file -drag'>
                { renderFileIcon(file) }
                <h5
                    contentEditable={ true }
                    spellCheck={ false }
                    onFocus={ () => {

                    }}

                    onBlur={ (ev) => {
                        const val = getInnerValueByEvent(ev);
                        const files = [ ...this.props.content.files ];

                        files[idx] =  { ...file, title: val };

                        this.setContent({ files });
                        this.saveContentDraft({ files });
                    } }
                    dangerouslySetInnerHTML={ {
                        __html: DOMPurify.sanitize(file.title) } } />
            </div>
        );
    }

    async publishDegree() {
        this.setState({ isLoading: true });
        await this.saveContentDraft();

        this.setState({ isLoading: true });
        await this.saveDegreeDraft();

        this.setState({ isLoading: true });
        try {
            const u = `degree/${this.props.degree.link}/publish`;
            const res = await ajaxAdapter({ isSchoolRequest: true })
                .post(u, { id: this.props.degree.id });

            updateMemory({
                school: res.school, degree: {
                ...res.degree, isDraft: false
            } });

            this.setState({ isLoading: false });
        } catch(ex) {
            console.error(ex);
            this.setState({ isLoading: false, error: ex.message });
        }
    }
    hasPrevContent() {
        return Boolean(this.getPrevContent());
    }

    hasNextContent() {
        return Boolean(this.getNextContent());
    }

    getPrevContent() {
        const { modules } = this.props.degree;
        const contentId = this.props.content.id;

        let hasFoundCurrent = false;
        let idx = modules.length;

        while (idx--) {
            const mod = modules[idx];
            if (!mod.content) { continue; }

            let contentIdx = mod.content.length;

            while (contentIdx--) {
                const content = mod.content[contentIdx];

                if (!content.id) { continue; }

                if (content.id === contentId) {
                    hasFoundCurrent = true;
                    continue;
                }

                if (hasFoundCurrent) { return content; }
            }
        }

        return false;
    }

    getNextContent() {
        const { modules } = this.props.degree;
        const contentId = this.props.content.id;

        let hasFoundCurrent = false;

        for (const mod of modules) {
            if (!mod.content) { continue; }

            for (const content of mod.content) {
                if (!content.id) { continue; }

                if (content.id === contentId) {
                    hasFoundCurrent = true;
                    continue;
                }

                if (hasFoundCurrent) { return content; }
            }
        }

        return false;
    }

    render() {
        const isSchoolAdmin = this.context.contextRole === 'admin';
        const menus = navbarMenus[this.context.hasUser ? 'loggedIn' : 'none'].filter((m) => {
            if (m.onlyStudent && isSchoolAdmin) { return false }
            if (m.onlyAdmin && !isSchoolAdmin) { return false }

            return true;
        });

        const schoolId = this.props.degree.school_id;
        if (schoolId && schoolId !== this.props.school.id) {
            const { classLink } = this.props.match.params;
            const u = url(`c/${this.props.degree.link}/${classLink}?student=1`);

            this.props.history.replace(u);
        }

        return (
            <React.Fragment>
                <div className='view-content'>
                    <HeaderWithMemory
                        onTitleChange={ (title) => {
                            this.setDegree({ title })
                            this.saveDegreeDraft({ title });
                        }}
                        degree={ this.props.degree }
                        title={ this.props.degree.title }
                        history={ this.props.history }
                        menus={ menus } />

                    <div className='content-body'>
                        <div className='-left'>
                            <div className={ this.isContentVideo() ? '-content -video' : '-content' }>
                                <div className='field-wrapper'>
                                    <label>Link de sua aula:</label>
                                    <div className={ this.state.focus === 'main_media_url' ? 'field -active' : 'field' } >
                                        <div className='icon'>
                                            <i translate='no' className='material-icons notranslate'>link</i>
                                        </div>
                                        <div className='input'>
                                            <input
                                                onFocus={ (ev) => {
                                                    ev.target.select();
                                                    this.setState({ focus: 'main_media_url' })
                                                } }
                                                value={ this.props.content.main_media_url || '' }
                                                maxLength={ 200 }
                                                onChange={ (ev) => {
                                                    const d = ev.nativeEvent.target.value.trim();

                                                    if (!d) {
                                                        this.setContent({ main_media_url: '' });
                                                        return;
                                                    }

                                                    const link = prettify.removeDiacritics(d).replace(/\s/g, '-');
                                                    this.setContent({ main_media_url: link });
                                                } }

                                                onBlur={() => {
                                                    this.setState({ focus: null });
                                                    this.saveContentDraft();
                                                }}
                                                placeholder='Link da aula - Ex.: Vimeo ou Youtube' type='text' />
                                        </div>
                                    </div>
                                </div>

                                { this.state.isMenuHidden &&
                                    <i onClick={ () => {
                                        localStorage.removeItem('isModuleMenuHidden');
                                        this.setState({ isMenuHidden: false });
                                    } } className='-open material-icons notranslate'>menu</i> }
                                { this.isContentVideo() && this.renderContentVideo() }
                            </div>
                            <div className='-description'>
                                <div className='-wrapper'>
                                    <div className='title-wrapper'>
                                        <h4
                                            style={ { width: '100%' } }
                                            ref='contentTitle'
                                            spellCheck={ false }
                                            onFocus={ (ev) => {
                                                let val = getInnerValueByEvent(ev)

                                                if (val === 'Nova aula') {
                                                    this.setContent({ title: '' });
                                                }
                                            }}

                                            onInput={ (ev) => {
                                                clearTimeout(this.saveContentDraftTimeout)

                                                let val = getInnerValueByEvent(ev)
                                                this.isSaving = true;

                                                this.saveContentDraftTimeout = setTimeout(() => {
                                                    this.saveContentDraft({ title: val });
                                                }, 350);
                                            }}

                                            onBlur={ (ev) => {
                                                let val = getInnerValueByEvent(ev)
                                                if (!val) { val = 'Nova aula'; }

                                                this.setContent({ title: val });
                                            } }
                                            dangerouslySetInnerHTML={ 
                                                {__html: DOMPurify.sanitize(this.props.content.title)
                                            } }
                                            contentEditable={ true } />
                                        <div className='-buttons'>
                                            { this.hasPrevContent() &&
                                                <button onClick={ () => {
                                                    const content = this.getPrevContent();
                                                    this.props.history.push(url(`/c/${this.props.degree.link}/${content.link}`));
                                                } } type='button' className='-navigate'>
                                                    <i translate='no' className='material-icons notranslate'>navigate_before</i>
                                            </button>  }

                                            { this.hasNextContent() &&
                                                <button onClick={ () => {
                                                    const content = this.getNextContent();
                                                    this.props.history.push(url(`c/${this.props.degree.link}/${content.link}`));
                                                } } type='button' className='-navigate'>
                                                    <i translate='no' className='material-icons notranslate'>navigate_next</i>
                                                </button>  }

                                        </div>
                                    </div>

                            <IseiQuill
                                onFocus={ () => {
                                    if (this.props.content.description === '<p>Insira aqui a descrição para o seu conteúdo</p>' || !this.props.content.description) {
                                        this.setContent({ description: '<p></p>' })
                                    }

                                }}
                                onBlur={ () => {
                                    let val = this.props.content.description.trim();

                                    if (val === '<p><br></p>') {
                                        val = '<p>Insira aqui a descrição para o seu conteúdo</p>';
                                        this.setContent({ description: val })
                                    }

                                    this.saveContentDraft({ description: val });
                                }}
                                value={ this.props.content.description }
                                onChange={ (val) => {
                                    if (val === this.props.content.description) {
                                        return;
                                    }
                                    this.setContent({ description: val })

                                    clearTimeout(this.saveContentDraftTimeout)

                                    this.isSaving = true;
                                    this.saveContentDraftTimeout = setTimeout(() => {
                                        this.saveContentDraft({ description: val });
                                    }, 350);
                                }} />

                                <h4 style={ { marginTop: '2rem' }}>Arquivos</h4>
                                <ReactSortable
                                    className='files-list'
                                    group='files'
                                    easing='cubic-bezier(1, 0, 0, 1)'
                                    animation={200}
                                    ghostClass='-ghost'
                                    dragoverBubble={true}
                                    delayOnTouchStart={true}
                                    delay={2}
                                    list={ this.props.content.files }
                                    draggable='.-drag'
                                    onStart={ () => {
                                        this.setState({ isDraggingFile: true });
                                    }}

                                    onEnd={ async (ev) => {
                                        this.setState({ isDraggingFile: false });

                                        const x = ev.originalEvent.clientX;
                                        const y = ev.originalEvent.clientY;

                                        const isRemove = this.isPointIn(x, y, 'add-file');

                                        if (isRemove && window.confirm('Tem certeza?')) {
                                            const files = [ ...this.props.content.files ].filter((m) => {
                                                return String(m.id) !== String(ev.item.dataset.id);
                                            });

                                            this.setContent({ files })
                                            this.saveContentDraft({ files })
                                            return;
                                        }

                                        this.saveContentDraft();
                                    } }
                                    setList={ (list) => {
                                        if (list.length === 0) { return; }

                                        list = [ ...list ];

                                        let addButtonIdx;
                                        for (let idx = 0; idx < list.length; ++idx) {
                                            if (list[idx].isAddButton) {
                                                addButtonIdx = idx;
                                                break;
                                            }
                                        }

                                        const addButton = list.splice(addButtonIdx, 1)[0];

                                        list.unshift(addButton);

                                        this.setContent({ files: list });
                                    } } >
                                    {this.props.content.files.map(this.renderFile.bind(this))}
                                </ReactSortable>
                                <h4 style={ { marginTop: '2rem' }}>Links externos</h4>
                                <ReactSortable
                                    className='external_links-list'
                                    group='external_links'
                                    easing='cubic-bezier(1, 0, 0, 1)'
                                    animation={200}
                                    ghostClass='-ghost'
                                    dragoverBubble={true}
                                    delayOnTouchStart={true}
                                    delay={2}
                                    list={ this.props.content.external_links }
                                    draggable='.-drag'
                                    handle='.-dragger'

                                onStart={ () => {
                                    this.setState({ isDraggingExternalLink: true });
                                }}

                                onEnd={ async (ev) => {
                                    this.setState({ isDraggingExternalLink: false });

                                    const x = ev.originalEvent.clientX;
                                    const y = ev.originalEvent.clientY;

                                    const isRemove = this.isPointIn(x, y, 'add-external_link');

                                    if (isRemove && window.confirm('Tem certeza?')) {
                                        const external_links = [ ...this.props.content.external_links ].filter((m) => {
                                            return String(m.id) !== String(ev.item.dataset.id);
                                        });

                                        this.setContent({ external_links })
                                        this.saveContentDraft({ external_links })

                                        return;
                                    }

                                    this.saveContentDraft(); } }
                                    setList={ (list) => {
                                        if (list.length === 0) { return; }
                                        this.setContent({ external_links: [ ...list ] });
                                    } } >
                                    {this.props.content.external_links.map(this.renderExternalFile.bind(this))}
                                </ReactSortable>

                                <section className='-buttons'>
                                    { this.props.degree.id && (this.props.degree.isDraft || !this.props.degree.utc_published_on) &&
                                    <button onClick={ debounce(this.publishDegree.bind(this), 100) } type='submit' className='btn -primary'>
                                        <i translate='no' className='material-icons notranslate'>thumb_up</i>
                                        <span>Publicar { this.props.degree.utc_published_on ? 'alterações' : 'curso' }</span>
                                    </button> }
                                </section>
                                </div>
                            </div>
                        </div>

                        { !this.state.isMenuHidden &&
                        <div className='-right'>
                            <div className='-header'>
                                <h4>Conteúdo do curso</h4>
                                <i onClick={ () => {
                                    localStorage.isModuleMenuHidden = true;
                                    this.setState({ isMenuHidden: true });
                                } } translate='no' className='material-icons notranslate'>chevron_right</i>
                            </div>

                            <div className='modules'>
                            <ReactSortable
                                animation={200}
                                easing='cubic-bezier(1, 0, 0, 1)'
                                dragoverBubble={true}
                                delayOnTouchStart={true}
                                delay={2}
                                group='module'
                                ghostClass='-ghost'
                                draggable='.-drag'
                                list={ this.props.degree.modules }

                                onStart={ () => {
                                    this.setState({ isDraggingModule: true });
                                }}

                                onEnd={ async (ev) => {
                                    this.setState({ isDraggingModule: false });

                                    const x = ev.originalEvent.clientX;
                                    const y = ev.originalEvent.clientY;

                                    const isRemove = this.isPointIn(x, y, 'add-module');

                                    let mustRedirect = false;
                                    if (isRemove && window.confirm('Tem certeza?')) {
                                        const modules = [ ...this.props.degree.modules ];

                                        const s = {
                                            modules: modules.filter((m) => {
                                                const isDelete = String(m.id) === String(ev.item.dataset.id);

                                                if (isDelete) {
                                                    for (const content of m.content) {
                                                        if (String(content.id) === String(this.props.content.id)) {
                                                            mustRedirect = true;
                                                            break;
                                                        }
                                                    }
                                                }

                                                return !isDelete;
                                            })
                                        };

                                        this.setDegree(s);
                                        await this.saveDegreeDraft(s);

                                        if (mustRedirect) {
                                            this.props.history.replace(url(`c/${this.props.degree.link}`));
                                        }
                                        return;
                                    }

                                    this.saveDegreeDraft();
                                } }

                                setList={ (list) => {
                                    this.setDegree({ modules: list })
                                } } >
                                    { this.props.degree.modules.map(this.renderModule.bind(this)) }
                            </ReactSortable>
                            </div>
                        </div> }
                    </div>
                </div>

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </React.Fragment>
        )
    }

    async saveDegreeDraft(s = {}) {
        const { categoryId } = this.props.match.params;
        const draft = { ...this.props.degree, ...s, categoryId };

        this.isSaving = true;

        try {
            delete draft.currentValue;
            const res = await saveDegree(draft, this.props.degree);

            updateMemory({ school: res.school })

            if (this._isMounted) {
                this.setDegree({
                    id: res.degree.id, link: res.degree.link,
                    utc_created_on: res.degree.utc_created_on
                });
             }

             this.isSaving = false;

            return res.degree;
        } catch(ex) {
             this.isSaving = false;

            console.error(ex);
            this.setState({ error: ex.message });
        }
    }

    _setStateFromResponse(res) {
        let { classLink } = this.props.match.params;

        if (!classLink) {
            const pathnameParts = window.location.pathname.split('/').filter((s) => s);
            classLink = pathnameParts[pathnameParts.length - 1];
        }

        classLink = res.content.link;

        const c = { ...res };
        const modules = [ ...c.degree.modules, ...(c.degree.draft?.modules || [] ) ];

        for (const m of modules) {
            if (!m.content) { continue; }

            for (const c of m.content) {
                if (c.link === classLink) {
                    this.toggleModuleOpen(m, true);
                }
            }
        }

        updateMemory(c)
        this.setState({ isLoading: false });
    }

    toggleModuleOpen(m, isOpen) {
        const openModules = { ..._openModules };
        isOpen = isOpen !== undefined ? isOpen : !this.isModuleOpen(m);

        if (!isOpen) {
            delete openModules[m.id];
        } else {
            openModules[m.id] = true;
        }

        _openModules = openModules;
        this.forceUpdate();
    }

    isModuleOpen(m) { return _openModules[m.id]; }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.querySelector('.body').style.overflow = '';
        document.body.style.overflow = '';
    }

    async componentDidMount() {
        super.componentDidMount();

        const { link, moduleId, classLink } = this.props.match.params;

        document.querySelector('.body').style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';

        const $mod = document.querySelector('.-right');
        if ($mod) {
            $mod.scrollTop = lastScrollTopModulesPosition;
            $mod.onscroll = () => {
                lastScrollTopModulesPosition = $mod.scrollTop;
            }
        }

        try {

            let content;
            let degree = this.props.degree;

            if (!degree.id) {
                const res = await ajaxAdapter({ isSchoolRequest: true }).get('degree/' + link);

                if (!res.degree) {
                    this.props.history.replace(url('cursos'));
                    return;
                }

                degree = { ...res.degree };
                updateMemory({ degree });
            }

            const school = { ...this.props.school };
            if (classLink) {
                content = this.props.tryFindContent(classLink);
            }

            if (!moduleId && !content) { return; }

            if (!content) {
                for (const mod of degree.modules) {
                    if (mod.id === moduleId) {
                        content = mod.content.find((c) => /new_/.test(c.id));
                        break;
                    }
                }
            }

            if (!content) {
                content = {
                    id: 'new_' + new Date().getTime(),
                    link: 'new_' + new Date().getTime(),
                    degree_id: degree.id,
                    moduleId,
                    isDraft: false,
                    title: 'Nova aula',
                    description: '<p>Insira aqui a descrição para o seu conteúdo</p>',
                    files: [ {isAddButton: true }],
                    external_links: [ {isAddButton: true }],
                    media: {},
                    draft: {},

                    is_private:  false,
                    utc_published_on: null,
                    is_visible: true,

                    error: null,
                    success: null,

                    isLoading: true
                };

                const modules = (degree.draft && degree.draft.modules) || degree.modules;
                for (const mod of modules) {
                    if (mod.id === moduleId) {
                        mod.content = [ ...mod.content ];
                        mod.content.push(content);
                        break;
                    }
                }

                for (const cat of school.categories) {
                    if (!cat.degrees) { continue; }

                    let idx = 0;
                    for (const c of cat.degrees) {
                        if (c.id === degree.id) {
                            cat.degrees = [ ...cat.degrees ];
                            cat.degrees[idx] = degree;
                            break;
                        }

                        idx++;
                    }
                }

                setTimeout(() => {
                    if (this.refs.contentTitle && this.refs.contentTitle.focus) {
                        this.refs.contentTitle.focus();
                    }
                }, 50);
            }

            this._setStateFromResponse({ degree, content, school })

            if (ignoreScrollOnMount) {
                ignoreScrollOnMount = false;
                return;
            }

            setTimeout(() => {
                const node = document.querySelector(`.-content[data-id="${content.id}"]`);
                if (node) {
                    node.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                }
            }, 10);
        } catch(ex) {
            console.error(ex);
            this.setState({ error: ex.message, isLoading: false })
        }
    }

    updateModule(id, values, doSave = false) {
        const modules = [ ...this.props.degree.modules, ];

        for (let idx = 0; idx < modules.length; ++idx) {
            const m = modules[idx]

            if (String(m.id) !== String(id)) { continue; }
            modules[idx] = { ...m, ...values };
        }

        this.setDegree({ modules });

        if (doSave) {
            this.saveDegreeDraft({ modules });
        }
    }

    renderModule(m) {
        if (m.isAddButton) {
            if (this.state.isDraggingModule) {
                return (
                    <div key='addModule' className='add-module-wrapper'>
                        <button className='add-module -remove'>
                            <i translate='no' className='material-icons notranslate'>delete</i>
                            Arraste para remover
                        </button>
                    </div>
                );
            }

            return (
            <div key='addModule' className='add-module-wrapper'>
                <button onClick={ () => {
                    const modules = [ ...this.props.degree.modules ];
                    const id = getId();

                    modules.push({
                        id,
                        title:'Módulo ' + getBestModuleNumber(modules),
                        content: [
                            { isAddButton: true }
                        ]
                    })

                    this.setDegree({ modules })
                    this.saveDegreeDraft({ modules });
                    this.toggleModuleOpen({ id });

                    setTimeout(() => {
                        const node = document.querySelector(`.-module[data-id="${id}"]`);

                        if (node) {
                            node.scrollIntoView({
                                behavior: "smooth", block: "start", inline: "nearest"
                            });
                        }
                    }, 10)
                } } className='add-module'>
                    <i translate='no' className='material-icons notranslate'>add_box</i>
                    Criar Módulo
                </button>
            </div>
            );
        }

        return (
            <div
                data-id={ m.id }
                key={ m.id }
                className={ this.isModuleOpen(m) ? '-open -module -drag' : '-module -drag' }>
                <header>
                    <i
                        onClick={ () => {
                            this.toggleModuleOpen(m)
                        }}
                        translate='no'
                        className='material-icons notranslate -expander'>{ !this.isModuleOpen(m) ? 'expand_more' : 'expand_less' }</i>
                    <span
                        onInput={ () => {

                        }}
                        onFocus={ () => {

                        }}
                        onBlur={ (ev) => {
                            const val = getInnerValueByEvent(ev);

                            this.updateModule(m.id, { title: val }, true)
                        }}

                        contentEditable={ true }
                        spellCheck={ false }
                        dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(m.title) }} />

                    <div onClick={ async () => {
                        let mustRedirect = false;
                        if (window.confirm('Tem certeza?')) {
                            const modules = [ ...this.props.degree.modules ];

                            const s = {
                                modules: modules.filter((_m) => {
                                    const isDelete = m.id === _m.id;

                                    if (isDelete) {
                                        for (const content of m.content) {
                                            if (String(content.id) === String(this.props.content.id)) {
                                                mustRedirect = true;
                                                break;
                                            }
                                        }
                                    }

                                    return !isDelete;
                                })
                            };

                            this.setDegree(s);
                            await this.saveDegreeDraft(s);

                            if (mustRedirect) {
                                this.props.history.replace(url(`c/${this.props.degree.link}`));
                            }
                        }
                    } } className='-remove'>
                        <i translate='no' className='material-icons notranslate'>delete</i>
                    </div>
                    <i translate='no' className='material-icons notranslate -dragger'>drag_indicator</i>
                </header>
                { this.isModuleOpen(m) &&
                <ReactSortable
                    className='content-list'
                    group='content'
                    easing='cubic-bezier(1, 0, 0, 1)'
                    animation={200}
                    dragoverBubble={true}
                    delayOnTouchStart={true}
                    delay={2}
                    list={m.content}
                    draggable='.-drag'
                    handle='.-dragger'
                    ghostClass='-ghost'
                    onStart={ () => {
                        this.setState({ isDraggingClass: true });
                    }}

                    onEnd={ async (ev) => {
                        this.setState({ isDraggingClass: false });

                        const x = ev.originalEvent.clientX;
                        const y = ev.originalEvent.clientY;

                        const isRemove = this.isPointIn(x, y, 'add-content');

                        if (isRemove && window.confirm('Tem certeza?')) {
                            const modules = [ ...this.props.degree.modules ];
                            let { id } = ev.item.dataset;

                            if (!/new_/.test(id)) { id = Number(id); }

                            let mustRedirect = false;
                            for (const mod of modules) {
                                if (!mod.content) { continue; }

                                let idx = mod.content.length;
                                while (idx--) {
                                    const content = mod.content[idx];

                                    if (content.id === id) {
                                        if (content.id === this.props.content.id) {
                                            mustRedirect = true;
                                        }

                                        mod.content.splice(idx, 1);
                                    }
                                }
                            }

                            const s = { ...this.props.degree, modules };

                            this.setDegree(s);
                            await this.saveDegreeDraft(s);

                            if (mustRedirect) {
                                this.props.history.replace(url(`c/${this.props.degree.link}`));
                            }

                            return;
                        }

                        this.saveDegreeDraft();
                    }}
                    setList={(list) => {
                        const modules = [...this.props.degree.modules];

                        modules.forEach((c, idx) => {
                            if (c.id === m.id) {
                                modules[idx] = { ...c };
                                modules[idx].content = list
                            }
                        });

                        this.setDegree({ modules });
                    }} >
                    {m.content.map((c) => this.renderContent(m, c) ) }
                </ReactSortable> }

            </div>
        );
    }

    renderContent(mod, content) {
        content = {
            ...content,
            ...content.draft,
            isDraft: JSON.stringify(content.draft) !== '{}' ||
                (this.props.degree.utc_published_on && !content.utc_published_on)
        };

        if (content.isAddButton) {
            const addId = `addContent-${mod.id}`;

            if (this.state.isDraggingClass) {
                return (
                    <div key={ addId } data-id={ addId } className='add-content-wrapper'>
                        <div className='add-content -remove'>
                            <i translate='no' className='material-icons notranslate'>delete</i>
                            <span >Arraste para remover</span>
                        </div>
                    </div>);
            }
            return (
            <div key={ addId } data-id={ addId } className='add-content-wrapper'>
                <div onClick={ () => {
                    this.goToAddClass(mod);
                } } className='add-content'>
                    <i translate='no' className='material-icons notranslate'>add_box</i>
                    <span>Criar Aula</span>
                </div>
            </div>);
        }

        let { classLink } = this.props.match.params;

        if (!classLink) {
            const pathnameParts = window.location.pathname.split('/').filter((s) => s);
            classLink = pathnameParts[pathnameParts.length - 1];
        }

        if (this.props.content) {
            classLink = this.props.content.link;
        }

        const cls = [ '-content', '-drag' ];

        if (classLink === content.link) {
            cls.push('-active');
        }

        return (
            <div data-id={ content.id } key={ content.id } className={cls.join(' ')}>
                <Link onClick={ () => {
                    ignoreScrollOnMount = true;
                } } to={ url(`c/${this.props.degree.link}/${content.link}`) }>{ content.title }</Link>

                { content.main_media_duration && <span className='-duration'>
                    { (content.main_media_duration / 60).toFixed(2).replace('.', ':') }
                </span> }
                { content.isDraft && <i translate='no' className='material-icons notranslate -draft'>text_snippet</i> }

                <div onClick={ async() => {
                    let mustRedirect = false;

                    if (window.confirm('Tem certeza?')) {
                        const modules = [ ...this.props.degree.modules ];
                        let id = content.id;

                        if (!/new_/.test(id)) { id = Number(id); }

                        for (const mod of modules) {
                            if (!mod.content) { continue; }

                            let idx = mod.content.length;
                            while (idx--) {
                                const content = mod.content[idx];

                                if (content.id === id) {
                                    if (content.id === this.props.content.id) {
                                        mustRedirect = true;
                                    }

                                    mod.content.splice(idx, 1);
                                }
                            }
                        }

                        const s = { ...this.props.degree, modules };

                        this.setDegree(s);
                        await this.saveDegreeDraft(s);

                        if (mustRedirect) {
                            this.props.history.replace(url(`c/${this.props.degree.link}`));
                        }
                    }
                } } className='-remove'>
                    <i translate='no' className='material-icons notranslate'>delete</i>
                </div>
                <i translate='no' className='material-icons notranslate -dragger'>drag_indicator</i>
            </div>
        );
    }

    goToAddClass(mod) {
        if (this.isSaving) {
            clearTimeout(this.goToAddClassTimeout)

            this.setState({ isLoading: true });
            this.goToAddClassTimeout = setTimeout(() => {
                this.goToAddClass(mod);
            }, 100);
            return;
        }

        let { link } = this.props.match.params;

        this.props.history.push(url(`c/${link}/${mod.id}/nova-aula`));
        this.setState({ isLoading: false });
    }
}

export default MemoryComponent(AddContent, 'school', 'user', 'degree', 'content');
