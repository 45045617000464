import React from 'react';
import { uniq } from '../utils';

const Switch = ({ id, isOn, handleToggle }) => {
    return (
        <div className='input-switch'>
            <input
                checked={isOn || false}
                onChange={handleToggle}
                id={`field_switch_${id} `}
                type="checkbox" />

            <label className={isOn ? '-active' : ''} htmlFor={`field_switch_${id} `}>
                <span />
            </label>
        </div>
    );
}

class SelectDegreesModal extends React.Component {
    state = {
        isModalOpen: false,
        degrees: [],
        all_degrees: false
    };

    _getCatRef(refs, cat) {
        refs.cat[cat.id] = React.createRef();
        return refs.cat[cat.id];
    }

    _getDegreeRef(refs, c) {
        refs.degree[c.id] = React.createRef();
        return refs.degree[c.id];
    }

    toggleDegreeChecked(degreeId, ref, checked) {
        ref.current.checked = checked;
        const degrees = [...this.state.degrees];

        if (checked) {
            degrees.push(degreeId);
        } else {
            degrees.splice(degrees.indexOf(degreeId), 1);
        }

        this.setState({ degrees: uniq(degrees) });
    }

    render() {
        if (!this.state.isModalOpen) { return null; }
        const refs = { cat: {}, degree: {} };

        const templateCategories = this.props
            .templateSchools
            .reduce((curr, s) => curr.concat(s.categories), []);

        const categories = this.props.school.categories.concat(templateCategories);

        return (
            <div className={`modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}`}>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}
                        <p className='-description'>Seleciona abaixo os cursos para a {this.props.className}:</p>

                        <div className='all-switchers'>
                            <div key='' className='field-wrapper -switch'>
                                <label>
                                    <Switch
                                        id='all_degrees'
                                        isOn={this.state.all_degrees}
                                        handleToggle={() => {
                                            this.setState({ all_degrees: !this.state.all_degrees });
                                        }} />

                                    <span>Todos os cursos</span>
                                </label>
                            </div>
                        </div>

                        {!this.state.all_degrees && <div className='degrees-wrapper'>
                            {categories.filter((c) => c.degrees && c.degrees.length).map((cat) => <div className='-cat'>
                                <div onClick={() => {
                                    const value = !refs.cat[cat.id].current.checked;
                                    refs.cat[cat.id].current.checked = value;

                                    // disabled for now
                                }} className='-header'>
                                    <h5>{cat.title}</h5>
                                    <input
                                        ref={ this._getCatRef(refs, cat) }
                                        type='checkbox'
                                        style={{ display: 'none' }}
                                        value='1'
                                        name='all' />
                                </div>

                                {cat.degrees.filter((c) => c.title).map((c) => <div onClick={(ev) => {
                                    const value = ev.target.type === 'checkbox' ?
                                        refs.degree[c.id].current.checked : !refs.degree[c.id].current.checked;

                                    this.toggleDegreeChecked(c.id, refs.degree[c.id], value);
                                }} className='-degree'>
                                    <p>{c.title}</p>
                                    <input
                                        checked={this.state.degrees.includes(c.id)}
                                        ref={ this._getDegreeRef(refs, c) }
                                        type='checkbox'
                                        value='1'
                                        name='all' />
                                </div>)}
                            </div>)}
                        </div>}

                        <div className='-footer'>
                            <button type='button' onClick={this._submit.bind(this)} className='btn -primary'>Salvar</button>
                            <button type='button' onClick={() => {
                                this.setState({ isModalOpen: false });
                            }} className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit() {
        try {
            this.props.onSubmit({ ...this.state });
            this.hide();
        } catch (ex) {
            global.error(ex);
            this.setState({ error: ex.message });
        }

    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            degrees: [],
            all_degrees: false,
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

export default SelectDegreesModal;
