import React from 'react';
import config from '../../config';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent } from '../../memory';
import { debounce, url } from '../../utils';
import LoadingMask from '../../components/LoadingMask';
import BaseView from '../BaseView';
import PaginationComponent from '../../components/Pagination';

const Switch = ({ min, id, isOn, handleToggle }) => {
    return (
        <div className={ min ? 'input-switch -min' : 'input-switch' }>
            <input
                checked={isOn || false}
                onChange={handleToggle}
                id={`field_switch_${id} `}
                type="checkbox" />

            <label className={ isOn ? '-active' : '' } htmlFor={`field_switch_${id} `}>
                <span />
            </label>
        </div>
    );
}

class OtherSchools extends BaseView {
    state = {
        page: 1,
        schools: { total: 0, page: 1, list: [] },
        search: '',
        sortBy: 'id',
        negative: ''
    };

    constructor(props) {
        super(props);

        this.onSearchSchoolDebounce = debounce(this.onSearchSchool.bind(this), 200);
    }

    onSearchSchool() {
        this.loadSchools(1);
    }

    renderSchool(school) {
        let url = `${config.baseAppUrl}/escola/${school.id}`

        if (school.namespaces.length && global.user.role !== 'admin') {
            const ns = school.namespaces[0];

            if (ns.domain === 'main' || ns.namespace === 'main') {
                url = '/remote-learning/c/bcd285bf';
            } else if (ns.domain) {
                url = `https://${ns.domain}`;
            } else {
                url = `${config.baseAppUrl}/${ns.namespace}`;
            }
        }

        const id = school.id;
        const name = school.name;
        const cellphone = school.cellphone;
        const email = school.email;
        const date = school.utc_created_on.split('T')[0];
        const total_students = school.total_students;
        const role = school.role;
        const plan_id = school.plan_id;
        const plan_name = school.plan_name;
        const balance = school.balance.replace('.', ',');
        const is_blocked = school.is_blocked;

        return (
            <tr key={ id }>
                <td className='-id'>{ id }</td>
                <td className='-name'>{ name }</td>
                <td className='-cellphone'>{ cellphone }</td>
                <td className='-email'>{ email }</td>
                <td className='-date'>{ date }</td>
                <td className='-students'>
                    { role !== 'student' ? `${ total_students } alunos` : '' }
                </td>
                { this.props.user.role === 'admin' &&
                <td className='-balance'>
                    { plan_id === 'onDemand' ?  `R$ ${ balance }` : '' }

                    { plan_id && plan_id !== 'onDemand' &&
                        <div style={ { width: 100 } } className={ `-label label-${plan_id}` }>{
                            plan_name
                        }</div> }
                </td> }
                { this.props.user.role !== 'admin' &&
                <td className='-role'>
                    <div className={ `-label role-${role}` }>{
                        role === 'student' ? 'estudante' : 'gerente'
                    }</div>
                </td> }
                <td className='-block'>
                    <div key='' className='field-wrapper -switch'>
                        <Switch
                            id={ id }
                            isOn={ is_blocked }
                            handleToggle={(ev) => {
                                this.setBlockedSchool(id, !is_blocked);
                                this.setState(prevState => ({
                                    ...prevState,
                                    schools: {
                                        ...prevState.schools,
                                        list: prevState.schools.list.map(
                                            school => school.id === id?
                                            { ...school, is_blocked: !is_blocked } : school
                                        )
                                    }
                                }));
                            }}
                        />
                    </div>
                </td>
                <td className='-actions'>
                    <a
                        rel='noopener noreferrer'
                        href={ url }
                        target='_blank'>acessar escola</a>
                </td>
            </tr>
        );
    }

    render() {
        return (
            <div className='view-school-list'>
                <h2>Minhas escolas</h2>
                <p className='-description'>
                    Aqui você pode acessar outras escolas que tem acesso (e até mesmo criar novas), sem limites!
                </p>
                {
                    this.hadSchools &&
                    <React.Fragment>
                        {
                            this.props.user.role === 'admin' &&
                            <div className='grid-wrapper'>
                                <div className='grid-filter'>
                                    <form className='schools-list'>
                                        <div className='field-wrapper'>
                                            <div className='field'>
                                                <div className='icon'>
                                                    <i
                                                        translate='no'
                                                        className='material-icons notranslate'
                                                    >
                                                        search
                                                    </i>
                                                </div>
                                                <div className='input'>
                                                    <input
                                                        value={ this.state.search || '' }
                                                        autoFocus={ true }
                                                        maxLength={ 30 }
                                                        onChange={ (ev) => {
                                                            this.setState({
                                                                search: ev.nativeEvent.target.value
                                                            });
                                                            this.onSearchSchoolDebounce();
                                                        } }
                                                        placeholder='Pesquise por uma escola...'
                                                        type='search'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='field-wrapper'>
                                            <div className='field'>
                                                <div className='input'>
                                                    { this.props.user.role === 'admin' &&
                                                        <select
                                                            style={{
                                                                border: '0px',
                                                                padding: '15px',
                                                                width: '100%'
                                                            }}
                                                            onChange={ (ev) => {
                                                                const val = ev.nativeEvent.target.value;
                                                                this.setState({
                                                                    negative: val
                                                                });
                                                                this.loadSchools(null, val);
                                                            } }
                                                        >
                                                            <option value=''>
                                                                Todos
                                                            </option>
                                                            <option value='0'>
                                                                Saldo positivo
                                                            </option>
                                                            <option value='1'>
                                                                Saldo negativo
                                                            </option>
                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='field-wrapper'>
                                            <div className='field'>
                                                <div className='input'>
                                                    { this.props.user.role === 'admin' &&
                                                        <select
                                                            style={{
                                                                border: '0px',
                                                                padding: '15px',
                                                                width: '100%'
                                                            }}
                                                            onChange={ (ev) => {
                                                                const val = ev.nativeEvent.target.value;
                                                                this.setState({
                                                                    sortBy: val
                                                                });
                                                                this.loadSchools(
                                                                    null,
                                                                    this.state.negative,
                                                                    this.state.search,
                                                                    val
                                                                );
                                                            } }
                                                            defaultValue={'id'}
                                                        >
                                                            <option value='id' disabled>
                                                                Ordenar por:
                                                            </option>
                                                            <option value='id'>
                                                                ID
                                                            </option>
                                                            <option value='date'>
                                                                Data de Criação
                                                            </option>
                                                            <option value='total_students'>
                                                                Total de alunos
                                                            </option>
                                                            <option value='name'>
                                                                Nome
                                                            </option>
                                                            <option value='balance'>
                                                                Saldo em conta
                                                            </option>
                                                        </select>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        }
                    <div className='grid-wrapper'>
                        <table className='schols-table'>
                            <thead>
                                <tr>
                                    <th style={ { width: 50 } } className='-id'>#</th>
                                    <th className='-name'>Nome</th>
                                    <th className='-cellphone'>Celular</th>
                                    <th className='-email'>E-mail</th>
                                    <th className='-date'>Data de Criação</th>
                                    {
                                        this.props.user.role === 'admin' &&
                                        <th
                                             className='-students'
                                        >
                                            N° de alunos
                                        </th>
                                    }
                                    <th
                                         className='-balance'
                                    >
                                        Saldo
                                    </th>
                                    {
                                        this.props.user.role !== 'admin' &&
                                        <th style={ { width: 80 } } className='-role'></th>
                                    }
                                    <th className='-block'>Bloquear escola</th>
                                    <th className='-actions'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.schools.list.map(this.renderSchool.bind(this))}
                            </tbody>
                        </table>
                    </div> 
                    </React.Fragment>
                }

                <PaginationComponent
                    onChange={ (p) => {
                        this.loadSchools(p);

                        return this._paginationUrl(p);
                    }}
                    titleFn={ (listTotal, total) => {
                        if (listTotal && listTotal === total) {
                            return `Mostrando ${total} escola${total > 1 ? 's' : ''} cadastrada${total > 1 ? 's' : ''} `;
                        }

                        return `Mostrando ${listTotal} escola${listTotal > 1 ? 's' : ''} de ${total} cadastrada${total > 1 ? 's' : ''}`;
                    }}
                    getUrl={ this._paginationUrl.bind(this) }
                    collection={ this.state.schools } />

                <LoadingMask fullScreen={ true } isLoading={ this.state.isLoading } />
            </div>
        );
    }

    async componentDidMount() {
        await this.loadSchools();
    }

    async loadSchools(page,
        negative = this.state.negative,
        search = this.state.search,
        sortBy = this.state.sortBy) {
        try {
            page = page || this.props.match.params.page || this.state.page;

            let queries = `?page=${page}&search=${search}&sortBy=${sortBy}&negative=${negative}`;

            const schools = (await ajaxAdapter()
                .get('/user/schools'+queries)).schools;

            if (schools.total > 0) {
                this.hadSchools = true;
            }

            this.setState({ schools });
        } catch(ex) {
            global.error(ex);
        }
    }

    async setBlockedSchool(schoolId, isBlocked) {
        try {
            await ajaxAdapter()
                .post(`school/${schoolId}/update`, { is_blocked: isBlocked });

            this.setState({ isLoading: true });
        } catch(ex) {
            global.error(ex);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    _paginationUrl(page) {
        return url(`escolas/p/${page}`)
    }
}

export default MemoryComponent(OtherSchools, 'user');
