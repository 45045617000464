import React from 'react';
import { Link } from 'react-router-dom';
import BaseView from '../BaseView';
import { MemoryComponent } from '../../memory';
import { url } from '../../utils';
import { Sensei } from '../../svgs';
import IseiQuill from '../../components/IseiQuill';
import ajaxAdapter from '../../ajaxAdapter';
import DOMPurify from 'dompurify';

class AdminCommunity extends BaseView {
    state = {
        threads: [ ],
        openAskBoxesByThread: {},
        askBoxesCommentsByThread: {}
    };

    render() {
        return (
            <div className='view-gateway-list'>
                <h2>Comunidade</h2>

                <p className='-description'>
                    Aqui será um lugar onde você irá gerenciar a comunidade (fórum, interações sociais e muito mais!) de <strong>{ this.props.school.name }</strong>
                    <br />Por enquanto, esse lugar contém apenas as perguntas sem resposta para as suas aulas.
                </p>

                <br />
                <h3>Perguntas sem resposta</h3>

                { this.state.threads.length === 0 &&
                    <p>A sua escola não tem nenhuma pergunta sem resposta... boa! ;)</p> }


                { this.state.threads.length > 0 &&
                    <div className='threads-wrapper'>
                        { this.state.threads.map(this.renderThread.bind(this)) }
                    </div> }
            </div>
        );
    }

    renderThread(thread) {
        return <div className='thread'>
            <div className='ask-box-wrapper'>
                <div className='-avatar'>
                    <div className='sensei'>
                        { !thread.profile_img_url &&
                            <div className='sensei'><Sensei /></div> }
                        { thread.profile_img_url &&
                            <img
                                style={ { width: '100%' } }
                                alt={ thread.user_name }
                                title={ thread.user_name }
                                src={ thread.profile_img_url } /> }
                    </div>
                </div>
                <div className='ask-content'>
                    <h5>{ thread.user_name }</h5><span>, perguntou em <Link to ={
                        url(`c/${thread.degree_link}/${thread.content_link}`)
                    }> { thread.content_title }</Link> do curso: <Link to ={
                        url(`c/${thread.degree_link}`)
                    }> { thread.degree_title }</Link>

                </span>
                    <p dangerouslySetInnerHTML={ {
                        __html: DOMPurify.sanitize(thread.message)
                    } } />
                </div>
            </div>

            <div className='-actions'>
                <a onClick={ (ev) => {
                    ev.preventDefault();

                    this.toggleAskBoxForThread(thread);
                } } href='/'>
                    Responder
                </a>
                &nbsp; &nbsp; &nbsp;
                <a onClick={ (ev) => {
                    ev.preventDefault();

                    if (global.confirm('Tem certeza?')) {
                        return this.deleteThread(thread);
                    }
                } } className='text-red' href='/'>
                    Excluir
                </a>
            </div>

            { this.state.openAskBoxesByThread[thread.id] && <React.Fragment>
                <div style={ { padding: '1rem' } }>
                <hr />
                <h5>A sua resposta</h5> <span>:</span>

                <IseiQuill
                    onFocus={ (ev) => {
                    }}
                    onBlur={ (ev) => {

                    }}
                    value={ this.state.askBoxesCommentsByThread[thread.id] }
                    onChange={ (val) => {
                        const askBoxesCommentsByThread = {
                            ...this.state.askBoxesCommentsByThread
                        };
                        askBoxesCommentsByThread[thread.id] = val;

                        this.setState({ askBoxesCommentsByThread });
                    }} />


                { !this.state.askBoxesCommentsByThread[thread.id] &&
                    <button className='btn -secondary' disabled>Enviar resposta</button> }

                { this.state.askBoxesCommentsByThread[thread.id] &&
                    <button onClick={ (ev) => {
                        this.sendAnswearByThread(thread);
                    } } className='btn -primary'>Enviar resposta</button> }
                </div>
            </React.Fragment> }
        </div>
    }

    toggleAskBoxForThread(thread) {
        const boxes = { ...this.state.openAskBoxesByThread };
        boxes[thread.id] = !boxes[thread.id];

        this.setState({ openAskBoxesByThread: boxes });
    }

    async deleteThread(thread) {
        try {
            const link = `content/${thread.content_link}/thread/${thread.id}?admin=1`;

            const { threads } = await ajaxAdapter({
                isSchoolRequest: true
            }).delete(link);

            this.setState({ threads });
        } catch (ex) {
            global.error(ex);
        }
    }

    async sendAnswearByThread(thread) {
        const boxes = { ...this.state.askBoxesCommentsByThread };
        const message = boxes[thread.id];

        try {
            const link = `content/${thread.content_link}/thread/${thread.id}?admin=1`;
            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).post(link, { message });
            boxes[thread.id] = '';

            const openAskBoxesByThread = { ...this.state.openAskBoxesByThread };
            openAskBoxesByThread[thread.id] = false;

            this.setState({
                openAskBoxesByThread,
                askBoxesCommentsByThread: boxes,
                threads: res.threads
            });
        } catch (ex) {
            global.error(ex);
        }
    }

    async componentDidMount() {
        await this.loadThreads();
    }

    async loadThreads() {
        try {
            const link = `threads/waiting-reply`;
            const res = await ajaxAdapter({ isSchoolRequest: true }).get(link);

            this.setState({ threads: res.threads });
        } catch (ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(AdminCommunity, 'school');
