import React from 'react';
import { MemoryComponent } from '../../memory';
import BaseView from '../BaseView';
import { PlanOnDemand, PlanMiddle, PlanSuper } from '../../components/PriceItems';

class AdminPlans extends BaseView {
    async updatePlan(planId) {
        this.props.history.push(`trocar-plano/${planId}`)
    }

    render() {
        return (
            <div className='view-plans base-view'>
                <h2>Planos iSei</h2>
                <p>Confira abaixo os planos disponíveis para fazer um upgrade em seu ensino a distância:</p>
                <div className='plans-wrapper'>
                    <PlanOnDemand
                        active={
                            this.props.resource.plan_id === 'onDemand' ||
                            String(this.props.resource.plan_id) === '1'
                        }
                        onClick={ this.updatePlan.bind(this, 'onDemand') } />

                    <PlanMiddle
                        active={ this.props.resource.plan_id === 'middle'}
                        onClick={ this.updatePlan.bind(this, 'middle') } />

                    <PlanSuper
                        active={ this.props.resource.plan_id === 'super'}
                        onClick={ this.updatePlan.bind(this, 'super') } />
                </div>
            </div>
        );
    }
}

export default MemoryComponent(AdminPlans, 'students', 'school', 'bank', 'resource');
