import React from 'react';
import { MemoryComponent } from '../../memory';
import { prettify, debounce, validations } from '../../utils';
import BaseView from '../BaseView';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';

class AdminPlans extends BaseView {
    state = {
        student: { name: '', email: '', cellphone: '' },
        school_gateways: [],
        degrees: [],
        isLoading: true
    };

    constructor(props) {
        super(props);

        this.debouncedSaveNotes = debounce(this.saveNotes.bind(this), 1000);
    }

    async saveNotes() {
        try {
            const { link } = this.state.student;

            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`student/${link}/update-notes`, {
                notes: this.state.notes
            });
        } catch(ex) {
            global.error(ex);
        }
    }

    async removeStudentFromClass(id) {
        try {
            this.setState({ isLoading: true });

            const school_gateways = [ ...this.state.school_gateways ].filter((c) => c.id !== id);
            this.setState({ school_gateways });

            await ajaxAdapter({
                isSchoolRequest: true
            }).delete(`student/${this.state.student.user_id}/remove/${id}`);
        } catch(ex) {
            global.error(ex);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    renderClass(cls, idx) {
        return (<div key={ cls.id } className='-class'>
            <div className='-name'>
                { cls.title }
            </div>

            <button onClick={ (ev) => {
                if (global.confirm('Tem certeza?')) {
                    this.removeStudentFromClass(cls.id);

                }
            } } className='btn -remove'>
                Remover aluno de turma
            </button>
        </div>);
    }

    renderStudentLinkAndOptions() {
        const { student } = this.state;
        const welcomeUrl = `${global.baseUiUrl}/welcome/${student.token}`;

        return <p>
            Link de acesso: <br /><a
                href={ welcomeUrl }
                rel='noopener noreferrer'
                target='_blank'>{ welcomeUrl }</a>
            <br />
            <br />
            <a onClick={ (ev) => {
                ev.preventDefault();
                this.sendStudentLink();
            } } href='/'>Enviar link para o e-mail do aluno</a>

            { student.cellphone && <React.Fragment>
                <br />
                <a onClick={ (ev) => {
                    ev.preventDefault();
                    this.sendStudentLink('sms');
                } } href='/'>Enviar SMS com o link para o  aluno</a>
            </React.Fragment> }

        </p>;
    }

    render() {
        const { student } = this.state;

        return (
            <div className='base-view'>
                <React.Fragment>
                    <div className='bordered-box'>
                        <header>
                            <h2>{ student.name }</h2>
                        </header>
                        <main>
                            <div style={ { paddingBottom: 0 } } className='-fields'>
                                <div className='-field'>
                                    <span className='-label'>Nome:</span>
                                    <span className='-value'>{ student.name }</span>
                                </div>

                                <div className='-field'>
                                    <span className='-label'>E-mail:</span>
                                    <span className='-value'>{ student.email }</span>
                                </div>

                                { student.cellphone &&
                                <div className='-field'>
                                    <span className='-label'>Celular:</span>
                                    <span className='-value'>{
                                        prettify.phone(student.cellphone)
                                    }</span>
                                </div> }
                            </div>

                            <h5>Dados de acesso</h5>
                            <div className='access-data'>
                                { student.token ?
                                    this.renderStudentLinkAndOptions() :
                                    <a onClick={ (ev) => {
                                        ev.preventDefault();
                                        this.updateStudentToken();
                                    } } href='/'>Gerar link de acesso</a> }

                                { this.state.isUpdatingPassword ? <React.Fragment>
                                    <br />
                                    <div className='field-wrapper'>
                                        <div className='field'>
                                            <div className='icon'>
                                                <i translate='no' className='material-icons notranslate'>locker</i>
                                            </div>
                                            <div className='input'>
                                                <input
                                                    value={ this.state.password || '' }
                                                    autoFocus={ true }
                                                    maxLength={ 30 }
                                                    onChange={ (ev) => {
                                                        this.setState({
                                                            password: ev.nativeEvent.target.value
                                                        });
                                                    } }
                                                    autocomplete='new-password'
                                                    placeholder='A senha nova de seu aluno'
                                                    type='password' />
                                            </div>
                                        </div>
                                    </div>

                                    <button onClick={ () => {
                                        this.updateStudentPassword();
                                    } } className='btn'>
                                        Salvar nova senha
                                    </button>

                                    <button style={ {
                                        marginLeft: '.5rem'
                                    } } onClick={ () => {
                                        this.setState({
                                            isUpdatingPassword: false
                                        });
                                    } } className='btn -secondary'>
                                        Cancelar
                                    </button>

                                </React.Fragment> : <p>
                                    <a href='/' onClick={ (ev) => {
                                        ev.preventDefault();

                                        this.setState({
                                            isUpdatingPassword: true
                                        });
                                    } }>Alterar a senha</a></p> }
                            </div>

                            { this.state.degrees.length > 0 &&
                                <React.Fragment>
                                    <h5>Cursos em progresso</h5>
                                    <div className='gateway-list'>
                                        { this.state.degrees.map(
                                            this.renderDegree.bind(this)) }
                                    </div>
                                </React.Fragment> }

                            <React.Fragment>
                                <h5>Anotações</h5>
                                <div className='gateway-list' style={ {
                                    padding: '.5rem'
                                } }>
                                    <textarea
                                        style={ {
                                            padding: '.5rem',
                                            width: '100%',
                                            height: '50px',
                                            border: '1px solid #F4F2F0'
                                        } }
                                        placeholder='Digite aqui notas para o seu aluno'
                                        value={ this.state.notes }

                                        onChange={ (ev) => {
                                            this.setState({
                                                notes: ev.nativeEvent.target.value
                                            });

                                            this.debouncedSaveNotes();
                                        } }
                                    ></textarea>
                                </div>
                            </React.Fragment>

                            { this.state.school_gateways.length > 0 &&
                            <React.Fragment>
                                <h5>Turmas</h5>
                                <div className='gateway-list'>
                                    { this.state.school_gateways.map(
                                        this.renderClass.bind(this)) }
                                </div>
                            </React.Fragment> }
                        </main>
                    </div>
                </React.Fragment>
                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    renderDegree(degree) {
        const { progress, title } = degree;

        return <div key={ degree.id } className='degree-progress'>
            <div
                style={ { padding: 0, borderTop: 0 } }
                className='progress-wrapper'
            >
                <div className="progress-bar-wrapper">
                    <span
                        className='label'
                        style={ {
                            width: 400,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'no-wrap'
                        } }
                    >
                        <React.Fragment>
                            {progress}% completo de <strong>{title}</strong>
                        </React.Fragment>
                    </span>
                </div>
                    {
                        Number(progress) === 100 &&
                        <button
                            onClick={ (ev) => {
                                ev.preventDefault();
                                this.regenerateCertificate(degree.id);
                            } }
                            className='btn'
                        >
                            Regerar certificado
                        </button>
                    }
            </div>
        </div>
    }

    async regenerateCertificate(degreeId) {
        const { student } = this.state;

        this.setState({ isLoading: true });

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`student/${student.user_id}/update-certificate`, {
                degreeId
            });

            alert(`O certificado do aluno: ${student.name} foi regerado!`);
        } catch(ex) {
            global.error(ex);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    async sendStudentLink(type = 'email') {
        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post('resend-student-link', {
                email: this.state.student.email,
                cellphone: this.state.student.cellphone,
                type
            });

            alert('Link enviado para o e-mail do aluno!');
        } catch(ex) {
            global.error(ex);
        }
    }

    async updateStudentPassword() {
        const { student, password } = this.state;
        const errorMessage = validations.password(password);

        if (errorMessage) { return alert(errorMessage); }

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`student/${student.user_id}/update-password`, {
                password
            });

            alert('Senha alterada com sucesso!');
        } catch (ex) {
            global.error(ex);
        }
    }

    async updateStudentToken() {
        const { student } = this.state;

        try {
            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).post(`student/${student.user_id}/update-token`);

            this.setState({ student: res.student });
        } catch (ex) {
            global.error(ex);
        }
    }

    async componentDidMount() {
        const { link } = this.props.match.params;

        try {
            const { student, degrees, school_gateways } = await ajaxAdapter({
                isSchoolRequest: true
            }).get(`student/${link}`);

            this.setState({
                notes: student.notes || '',
                school_gateways,
                degrees,
                student,
                isLoading: false
            });
        } catch(ex) {
            global.error(ex);
            this.setState({ isLoading: false });
        }
    }
}

export default MemoryComponent(AdminPlans, 'students', 'school', 'bank', 'resource');
