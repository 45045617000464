import React from 'react';
import BaseView from '../BaseView';
import DegreeList from '../../components/DegreeList';

class Degrees extends BaseView {
    render() {
        return <DegreeList history={ this.props.history } />
    }
}

export default Degrees;
