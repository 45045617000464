import React from 'react';
import { ReactSortable } from "react-sortablejs";
import BaseView from '../BaseView';

import { MemoryComponent, updateMemory } from '../../memory';
import { url } from '../../utils';
import ajaxAdapter from '../../ajaxAdapter';
import LoadingMask from '../../components/LoadingMask';
import DOMPurify from 'dompurify';

function getId() { return String(new Date().getTime()); }

function decorateWithPlaceholders(categories) {
    if (!categories[0] || !categories[0].isAddCategory) {
        categories.unshift({ isAddCategory: true });

        if (categories.length === 1) {
            categories.push({
                id: getId(),
                title: 'Nova categoria',
                degrees: [
                    { isAddButton: true },
                ]
            });
        }
    }

    categories = categories.filter((c) => !c.withoutCat || (
        c.withoutCat && c.degrees.length
    ));

    return categories;
}

class Degrees extends BaseView {
    state = { categories: [ ] };

    get isReadOnly() {
        return localStorage.getItem('contextOverride') === 'student';
    }

    async saveCategories(categories) {
        if (this.isSavingCategories) {
            this.needsResave = true;
            return;
        }

        this.isSavingCategories = true;

        try {
            const school = { ...this.props.school };

            categories = categories.map((cat) => {
                if (!cat.degrees) { return cat; }

                delete cat.courses;
                cat.degrees = cat.degrees.map((degree) => {
                    delete degree.modules;

                    if (degree.draft && degree.draft.modules) {
                        delete degree.draft.modules;
                    }

                    delete degree.description;

                    return degree;
                });

                return cat;
            });

            school.categories = categories;
            updateMemory({ school })

            await ajaxAdapter({
                isSchoolRequest: true
            }).post('categories/update', {
                categories: categories.filter((c) => !c.withoutCat)
            });
        } catch(ex) {
            global.error(ex);
        } finally {
            this.isSavingCategories = false;

            if (this.needsResave) {
                this.needsResave = false;
                return this.saveCategories(this.state.categories);
            }
        }
    }

    async setCategories(categories) {
        if (this.isReadOnly) { return; }

        categories = decorateWithPlaceholders(categories);

        this.setState({ categories })
        this.saveCategories(categories);
    }

    renderCategory(category, idx) {
        if (category.isAddCategory) {
            if (this.state.isDraggingCategory) {
                return (<div key='addCategory' className='add-category -remove'>
                    <i translate='no' className='material-icons notranslate'>delete</i>
                    <span >Arraste aqui para remover essa categoria</span>
                </div>);
            }
            return (<div key='addCategory' onClick={ (ev) => {
                const categories = [ ...this.state.categories ];

                categories.splice(1, 0, {
                    id: getId(),
                    title: 'Nova categoria',
                    degrees: [
                        { id: getId(), isAddButton: true },
                    ]
                });

                this.setCategories(categories);
            } } className='add-category'>
                <i translate='no' className='material-icons notranslate'>add_box</i>
                <span>Criar Categoria</span>
            </div>);
        }

        return (
            <section
                key={ category.id }
                className={ `category-degrees ${category.withoutCat ? '-without-cat' : '-drag' } ${this.isReadOnly ? '-readonly' : ''}`}>
                <header>
                    <h2
                        onFocus={ (ev) => {
                            if (category.title === 'Nova categoria' || !category.title) {
                                ev.target.innerHTML = '';
                                ev.target.focus();
                            }
                        }}
                        onBlur={ (ev) => {
                            let title =  ev.target.innerHTML.replace(/<[^>]*>/, '').trim();
                            const categories = [ ...this.state.categories ];

                            if (!title) {
                                ev.target.innerHTML = 'Nova categoria';
                                title = 'Nova categoria';
                            }

                            categories[idx] = { ...category, title };
                            this.setCategories(categories);
                        }}
                        dangerouslySetInnerHTML={ {__html:  DOMPurify.sanitize(category.title) } }
                        spellCheck={ false }
                        contentEditable={ !category.withoutCat } />
                    { !category.withoutCat &&
                    <i translate='no' className='material-icons notranslate -dragger'>drag_indicator</i> }
                </header>

                <div className='degrees-list-wrapper'>
                    <ReactSortable
                        className='degrees-list'
                        group='degrees'
                        easing='cubic-bezier(1, 0, 0, 1)'
                        animation={200}
                        ghostClass='-ghost'
                        dragoverBubble={true}
                        delayOnTouchStart={true}
                        delay={2}
                        list={ category.degrees }
                        draggable='.-drag'
                        onEnd={ () => {
                            this.setCategories(this.state.categories);
                        }}
                        setList={ (list) => {
                            const categories = [ ...this.state.categories ];

                            categories.forEach((c, idx) => {
                                if (c.id === category.id) {
                                    categories[idx] = { ...c };
                                    categories[idx].degrees = list
                                }
                            });

                            this.setState({ categories });
                        } } >
                        {category.degrees.map(this.renderDegree.bind(this, category))}
                    </ReactSortable>
                </div>
            </section>
        );
    }

    render() {
        const templateCategories = this.props
            .templateSchools
            .reduce((curr, s) => curr.concat(s.categories), []);

        return (
            <React.Fragment>
            <div className='view-degrees'>
                <ReactSortable
                    animation={200}
                    easing='cubic-bezier(1, 0, 0, 1)'
                    dragoverBubble={true}
                    delayOnTouchStart={true}
                    delay={2}
                    group='category'
                    ghostClass='-ghost'
                    handle='.-dragger'
                    draggable='.-drag'

                    onStart={ (ev) => {
                        this.setState({ isDraggingCategory: true });
                    }}

                    onEnd={ (ev) => {
                        this.setState({ isDraggingCategory: false });

                        const x = ev.originalEvent.clientX;
                        const y = ev.originalEvent.clientY;

                        const isRemove = this.isPointIn(x, y, 'add-category');

                        if (isRemove && window.confirm('Tem certeza?')) {
                            const categories = [ ...this.state.categories ];
                            const c = categories.find((m) => m.id === ev.item.dataset.id);

                            let withoutCatCategory = categories.find((c) => c.withoutCat);

                            if (!withoutCatCategory) {
                                withoutCatCategory = {
                                    id: '0',
                                    withoutCat: true,
                                    title: 'Cursos sem categoria',
                                    degrees: []
                                };

                                categories.push(withoutCatCategory);
                            }

                            withoutCatCategory.degrees = withoutCatCategory
                                .degrees
                                .concat((c.degrees || []).filter((c) => !c.isAddButton));

                            this.setCategories(categories.filter(({ id }) => id !== c.id));
                            return;
                        }

                        this.setCategories(this.state.categories);
                    }}
                    list={ this.state.categories }
                    setList={ (list) => {
                        if (list.length === 0) { return; }
                        this.setCategories([ ...list ]);
                    } } >
                    {this.state.categories.map(this.renderCategory.bind(this))}
                </ReactSortable>

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
            <div className='view-degrees -student' style={ { opacity: .5 } }>
                {templateCategories.map(this.renderCategoryTemplate.bind(this))}
            </div>

            </React.Fragment>
        );
    }

    renderCategoryTemplate(category, idx) {
        if (!category.degrees || category.degrees.length === 0) { return null; }

        return (
            <section key={ category.id } className={ `category-degrees ${this.isReadOnly ? '-readonly' : ''}`}>
                <header>
                    <h2 dangerouslySetInnerHTML={ {
                        __html:  DOMPurify.sanitize(category.title)
                    } } contentEditable={ false } />
                </header>

                <div className='degrees-list-wrapper'>
                    <div className='degrees-list'>
                        {category.degrees.map(this.renderDegreeTemplate.bind(this, category))}
                    </div>
                </div>
            </section>
        );
    }

    isPointIn(x, y, cls) {
        const el = document.elementFromPoint(x, y);

        if (!el) { return false; }
        if (el.className.includes(cls)) {
            return true;
        }

        if (el.parentNode.className.includes(cls)) {
            return true;
        }

        return false;
    }

    componentDidUpdate(lastProps) {
        super.componentDidUpdate();

        const isDiff = JSON.stringify(lastProps.school.categories) !==
            JSON.stringify(this.props.school.categories);

        if (isDiff) {
            const categories = decorateWithPlaceholders([
                ...this.props.school.categories
            ], this.isReadOnly);

            this.setState({ categories })
        }
    }

    componentDidMount() {
        super.componentDidMount();

        if (!this.props.school.categories) {
            return;
        }
        const categories = decorateWithPlaceholders([
            ...this.props.school.categories
        ], this.isReadOnly);

        this.setState({ categories })
        updateMemory({ degree: { modules: [ ], draft: { }, _base: {} } });
    }

    goToAddDegree(category) {
        if (this.isSavingCategories) {
            this.setState({ isLoading: true });

            return setTimeout(() => {
                this.goToAddDegree(category);
            }, 250);
        }

        this.setState({ isLoading: false });
        this.props.history.push(url(`curso/${category.id}/novo`));
    }

    renderDegree(category, degree) {
        if (degree.isAddButton || !degree.id) {
            return (<div
            key='addDegree'
            onClick={ () => {
                this.goToAddDegree(category);
            }} className='add-degree'>
                    <i translate='no' className='material-icons notranslate'>add_box</i>
                    <p>Criar Curso</p>
            </div>);
        }

        const title = (degree.draft && degree.draft.title) || degree.title || '';

        return (
            <div onClick={ (ev) => {
                this.props.history.push(url(`c/${degree.link}`));
            } } key={ degree.id } className='degree -drag'>
                <h4>{ title }</h4>

                { (!degree.utc_published_on || (degree.draft && JSON.stringify(degree.draft) !== '{}')) ?
                <div className='-badge -draft'>
                    <i translate='no' className='material-icons notranslate'>text_snippet</i>

                </div> : degree.utc_published_on &&
                <div className='-badge -published'>
                    <i translate='no' className='material-icons notranslate'>check_circle</i>
                </div> }
                { degree.img_wide_url && <div className='-background'>
                    <div className='overlay' />
                    <img loading='lazy' alt={ degree.title } src={ degree.img_wide_url } />
                </div>}

                { !degree.is_visible && <div className='-badge -invisible'>
                    <i translate='no' className='material-icons notranslate'>visibility_off</i>
                </div> }

                { !degree.is_private && <div style={ {
                    marginTop: !degree.is_private && !degree.is_visible ? '.5rem' : '0px'
                } } className='-badge -public'>
                    <i translate='no' className='material-icons notranslate'>lock_open</i>
                </div> }
            </div>
        );
    }

    renderDegreeTemplate(category, degree) {
        if (degree.isAddButton) { return null; }

        return (
            <div onClick={ () => {
                this.props.history.push(url(`c/${degree.link}?student=1`));
            } } key={ degree.id } className='degree'>
                <h4>{ degree.title }</h4>

                <div className='-badge -locked'>
                    <i translate='no' className='material-icons notranslate'>lock</i>
                    <span>Franquia</span>
                </div>
            </div>
        );
    }
}

export default MemoryComponent(Degrees, 'school', 'templateSchools');
