import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import version from './version.json';

import App from './App';
import * as serviceWorker from './serviceWorker';
import IseiContext from './Context';
import { store } from './memory';

const isDev = process.env.NODE_ENV === 'development';

let canLoadApp = true;
if (!isDev) {
    const { location } = global;
    if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
        canLoadApp = false;
    }
}


const ignoreSentryErrorRgx = /mas aconteceu um erro inesperado/;

function captureSentryException(error, scope) {
    if (ignoreSentryErrorRgx.test(error)) { return; }

    Sentry.captureException(error, scope);
}

global.error = (ex, doAlert = true) => {
    console.error(ex);

    const cfg = ex.data ? ex : ex.config;
    if (cfg && cfg.data && (cfg.data.message || cfg.data.msg)) {
        const error = new Error(cfg.data.message || cfg.data.msg);
        const scope = new Sentry.Scope();

        scope.setContext('request', {
            data: cfg.config && cfg.config.data
        });

        scope.setContext('response', {
            body: cfg.data,
            status: cfg.status,
            statusTest: cfg.statusText
        });

        captureSentryException(error, scope);
    } else if (!(ex instanceof Error) && ex.message) {
        captureSentryException(new Error(ex.message));
    } else {
        captureSentryException(ex instanceof Error ? ex : new Error(ex));
    }

    if (doAlert && ex.message) {
        alert(ex.message);
    }
};

if (canLoadApp) {
    Sentry.init({
        dsn: isDev ? null : "https://ab2e87de682d4775b737ca6dc3018b05@o465256.ingest.sentry.io/5479956",
        environment: isDev ? 'local' : 'prod',
        tracesSampleRate: 1.0,
        release: `react@${version.version}`
    });

    const fallBackErrorMessage = [
        'Erro interno em nosso sistema.\nSensei foi acordado e já está',
        'resolvendo esse problema, pedimos desculpas.' ].join(' ');

    ReactDOM.render(
        <Sentry.ErrorBoundary fallback={ fallBackErrorMessage }>
            <IseiContext>
                <Provider store={ store }>
                    <App />
                </Provider>
            </IseiContext>
        </Sentry.ErrorBoundary>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
