import React from 'react';
import BaseView from '../BaseView';

class StudentSuppot extends BaseView {
    render() {
        return (
            <div className='view-gateway-list'>
                <h2>Suporte - estudante</h2>
            </div>
        );
    }
}

export default StudentSuppot;