import React from 'react';
import { url } from '../utils';
import { MemoryComponent, updateMemory } from '../memory';
import ajaxAdapter from '../ajaxAdapter';
import DOMPurify from 'dompurify';

class Degrees extends React.Component {
    state = { categories: [ ] };

    renderCategory(category, idx) {
        if (!category.degrees || category.degrees.length === 0) { return null; }
        return (
            <section key={ category.id } className={ `category-degrees ${this.isReadOnly ? '-readonly' : ''}`}>
                <header>
                    <h2 dangerouslySetInnerHTML={ {
                        __html:  DOMPurify.sanitize(category.title)
                    } } contentEditable={ false } />
                </header>

                <div className='degrees-list-wrapper'>
                    <div className='degrees-list'>
                        {category.degrees.map(this.renderDegree.bind(this, category))}
                    </div>
                </div>
            </section>
        );
    }

    render() {
        return (
            <div className='view-degrees -student'>
                {this.state.categories.map(this.renderCategory.bind(this))}
            </div>
        );
    }

    componentDidMount() {
        if (!this.props.school.categories) {
            return;
        }

        const { school_gateways } = this.props.acl;
        const isAdmin = this.props.school.role?.includes('admin');

        const templateCategories = this.props
            .templateSchools
            .reduce((curr, s) => curr.concat(s.categories), []);

        const categories =  [ ...this.props.school.categories ]
            .concat(templateCategories)
            .filter((c) => !c.withoutCat && c.degrees)
            .map((c) => ({ ...c }));

        for (const cat of categories) {
            cat.degrees = cat.degrees.filter((c) => (
                c.is_visible ||
                isAdmin ||
                school_gateways.allDegrees?.includes(c.id) ||
                school_gateways.hasAllAccess
            ));

            if (this.props.onlyWithAccess) {
                cat.degrees = cat.degrees.filter(this.hasAccessToDegree.bind(this));
            }
        }

        this.setState({ categories: categories.filter((c) => c.degrees.length) })
    }

    hasAccessToDegree(degree) {
        const degreeId = degree.id;
        const { school_gateways } = this.props.acl;
        const isAdmin = this.props.school.role?.includes('admin');

        return ((isAdmin || school_gateways.hasPublicAccess) && !degree.is_private) ||
                school_gateways.hasAllAccess ||
                school_gateways.allDegrees?.includes(degreeId);

    }

    isDegreeInitialized(degreeId) {
        return Boolean(this.props.acl.degrees[degreeId]);
    }

    isDegreeDone(degreeId) {
        const acl = this.props.acl.degrees[degreeId];

        return acl && /100/.test(acl.progress);
    }

    isDegreeBookmark(degreeId) {
        return this.props.bookmarks.find((b) => b.degree_id === degreeId && !b.content_id);
    }

    async toggleDegreeBookmark(degreeId, isBookmark) {
        try {
            const res = await ajaxAdapter({ isSchoolRequest: true }).post('bookmark', {
                degreeId,
                isBookmark
            })

            updateMemory({ bookmarks: res.bookmarks });
        } catch(ex) {
            global.error(ex);
        }
    }

    renderDegree(category, degree) {
        if (degree.isAddButton) { return null; }

        const hasAccess = this.hasAccessToDegree(degree);
        const isDegreeInitialized = hasAccess && this.isDegreeInitialized(degree.id);
        const isDegreeDone = hasAccess && this.isDegreeDone(degree.id);

        return (
            <div onClick={ () => {
                if (!hasAccess && degree.landing_page_url) {
                    const url = degree.landing_page_url;
                    window.open(/^http/.test(url) ? url : `https://${url}`);

                    return;
                }
                this.props.history.push(url(`c/${degree.link}`));
            } } key={ degree.id } className='degree'>
                <h4>{ degree.title }</h4>

                { hasAccess &&
                <div onClick={ (ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();

                    this.toggleDegreeBookmark(degree.id, !this.isDegreeBookmark(degree.id));
                } } className={ this.isDegreeBookmark(degree.id) ? '-bookmarked -favorite -badge' : '-favorite -badge' }>
                    <i translate='no' className='material-icons notranslate'>bookmark</i>
                </div> }

                { !hasAccess &&
                <div className='-badge -lock'>
                    <i translate='no' className='material-icons notranslate'>lock</i>
                </div> }

                { degree.img_wide_url && <div className='-background'>
                    <div className='overlay' />
                    <img loading='lazy' alt={ degree.title } src={ degree.img_wide_url } />
                </div>}

                { !hasAccess && <div className='-badge -locked'>
                    <i translate='no' className='material-icons notranslate'>lock_open</i>
                    <span>Desbloquear</span>
                </div> }

                { isDegreeInitialized && !isDegreeDone && <div className='-badge -continue'>
                    <i translate='no' className='material-icons notranslate'>play_circle_filled</i>
                    <span>Continuar</span>
                </div> }

                { isDegreeDone && <div className='-badge -done'>
                    <i translate='no' className='material-icons notranslate'>emoji_events</i>
                    <span>Finalizado</span>
                </div> }

                { hasAccess && !isDegreeInitialized && <div className='-badge -start'>
                    <span>Iniciar</span>
                    <i translate='no' className='material-icons notranslate'>thumb_up</i>
                </div> }
            </div>
        );
    }
}

export default MemoryComponent(Degrees, 'school', 'acl', 'bookmarks', 'templateSchools');
