const config = {};

const isDev = process.env.NODE_ENV === 'development';
const { hostname } = window.location;

if (isDev || hostname === 'localhost') {
    config.baseApiUrl = 'http://localhost:7000';
    config.baseAppUrl = 'http://localhost:3000';
    config.publicStripeToken = 'pk_test_51HcTGcGdWltq8nD6wqEwSLJSUi2BUc7YSGsqnrZAS4ajPUJ6hipHgtYFAzB7KJdtAnlvuiXt1I5uZeJqinlamPc200GxhF1x6b';
} else if (/dev\.isei\.app/.test(hostname) || /dev-domain\.isei\.app/.test(hostname) || hostname.includes('tenx.club')) {
    config.baseApiUrl = 'https://dev-api.isei.app';
    config.baseAppUrl = 'https://dev.isei.app';
    config.publicStripeToken = 'pk_test_51HcTGcGdWltq8nD6wqEwSLJSUi2BUc7YSGsqnrZAS4ajPUJ6hipHgtYFAzB7KJdtAnlvuiXt1I5uZeJqinlamPc200GxhF1x6b';
} else {
    config.baseApiUrl = 'https://api.isei.app';
    config.baseAppUrl = 'https://isei.app';
    config.publicStripeToken = 'pk_live_51HcTGcGdWltq8nD6cnXFFGr0Y9nFb57DfBl4PsPtiulSZz9YKYELx0v9L5no66ndyZenhkREXQuwTqvxzwonuNBo00ygQtouib';
}

config.baseApiSchoolUrl = '';
config.isDev = isDev;

export default config;
