import React from 'react';
import { Link } from "react-router-dom";
import BaseView from '../BaseView';
import moment from 'moment';

import { url } from '../../utils';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent } from '../../memory';
import { Sensei } from '../../svgs';
import IseiQuill from '../../components/IseiQuill';

import DOMPurify from 'dompurify';

class DegreeList extends React.Component {
    render() {
        return (
            <div className='view-degrees -student -awards'>
                <div className='category-degrees'>
                    <div className='degrees-list-wrapper'>
                        <div className='degrees-list'>
                            { this.props.degrees.map(this.renderDegree.bind(this))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDegree(degree, idx) {
        return (
            <div onClick={ () => {
                const certificate = this.props.certificates.find((c) =>
                    c.degree_id === degree.degree_id);

                if (certificate) {
                    window.open(certificate.pdf);
                    return;
                }

                this.props.history.push(url(`c/${degree.degree_link}`));
            } } key={ degree.id || idx } className='degree'>
                <h4>{ degree.title }</h4>

                { degree.img_wide_url && <div className='-background'>
                    <div className='overlay' />
                    <img loading='lazy' alt={ degree.title } src={ degree.img_wide_url } />
                </div>}

                { degree.has_complete &&
                <div className='-badge -lock'>
                    <i translate='no' className='material-icons notranslate'>emoji_events</i>
                </div> }
            </div>
        );
    }
}

class Login extends BaseView {
    state = {
        school_gateways: [],
        progress: {},
        awards: {},
        user: {},
        isLoading: true
    };

    async onSubmit(values) {
    }

    render() {
        const { user } = this.state;
        const itsMe = this.props.user.id === user.user_id;

        return (
            <div className='app custom-page -profile-page'>
                <div style={ {
                    width: '100%',
                    maxWidth: 900,
                    paddingBottom: '1rem'
                } }>
                <div className='box -size-1 -profile'>
                    <main>
                        <div className='user-profile'>
                            <div className='profile-img'>
                                { !user.profile_img_url && user.user_id &&
                                    <div className='sensei'><Sensei /></div> }

                                { user.profile_img_url &&
                                    <img
                                        alt={ user.name }
                                        title={ user.name }
                                        src={ user.profile_img_url } /> }
                            </div>

                            <div className='profile-content'>
                                <h2>{ user.name }</h2>
                                { itsMe && <React.Fragment>
                                    <IseiQuill
                                    onFocus={ (ev) => {

                                    }}
                                    onBlur={ (ev) => {
                                        this.saveUserProfileDescription();
                                    }}
                                    value={ this.state.user.profile_description }
                                    onChange={ (val) => {
                                        const user = { ...this.state.user };
                                        user.profile_description = val;

                                        this.setState({ user });
                                    }} />
                                    </React.Fragment> }
                                { !itsMe && user.profile_description && <React.Fragment>
                                    <p dangerouslySetInnerHTML={ {
                                        __html: DOMPurify.sanitize(user.profile_description)
                                    } } />
                                </React.Fragment> }

                                <p style={ { marginTop: '1rem' } }>
                                    Aluno desde { moment.utc(user.utc_created_on).format('D MMMM, yyyy') }.
                                </p>
                                { this.state.school_gateways.map((c) => <p>
                                    { /turma/i.test(c.title) ? c.title : `Turma: ${c.title}` }
                                </p> )}
                            </div>
                        </div>
                        { this.state.awards.degrees?.length > 0 &&
                        <div className='awards'>
                            <header>
                                <i translate='no' className='material-icons notranslate'>emoji_events</i>
                                <h2>Conquistas</h2>
                            </header>
                            <main>
                                { this.state.awards.degrees &&
                                    <DegreeList
                                        certificates={ this.state.certificates }
                                        user={ this.state.user }
                                        history={ this.props.history }
                                        degrees={ this.state.awards.degrees } /> }
                            </main>
                        </div> }

                        { this.state.progress.degrees?.length > 0 &&
                        <div className='progress'>
                            <header>
                                <i translate='no' className='material-icons notranslate'>school</i>
                                <h2>Em progresso</h2>
                            </header>
                            <main>
                                { this.state.progress.degrees &&
                                    <DegreeList
                                        certificates={ this.state.certificates }
                                        user={ this.state.user }
                                        history={ this.props.history }
                                        degrees={ this.state.progress.degrees } /> }
                            </main>
                        </div> }
                    </main>

                </div>
                <footer>
                    <ul>
                        <li className='copyright'>
                            { this.props.school.name || 'iSei' } &copy; { new Date().getFullYear() }
                        </li>
                        <li>
                            <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                        </li>
                    </ul>
                </footer>
            </div>

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    async componentDidMount() {
        const { link } = this.props.match.params;

        try {
            const { user, awards, progress, school_gateways, certificates } = await ajaxAdapter({
                isSchoolRequest: true
            }).get(`profile/${link}`);

            this.setState({ isLoading: false, user, awards, progress, school_gateways, certificates });
        } catch (ex) {
            this.setState({ isLoading: false });
            global.error(ex);
        }
    }

    async saveUserProfileDescription() {
        const { profile_description } = this.state.user;

        try {
            await ajaxAdapter().post('/user/profile/save', { profile_description });
        } catch (ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(Login, 'school', 'user');
