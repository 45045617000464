import React from 'react';
import { Link } from "react-router-dom";

import BaseView from '../BaseView';
import { Logo } from '../../svgs';
import { url } from '../../utils';
import IseiForm from '../../components/Form';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent } from '../../memory';

const loginFields = {
    email: {
        type: 'text',
        icon: 'alternate_email',
        placeholder: 'Preencha o seu e-mail de cadastro',
        autoComplete: 'email',
        selectOnFocus: true,

        getPrettyValue(value) {
            return (value &&
                value.toLowerCase &&
                value.toLowerCase().replace().replace(/\s/g, '')
            ) || value;
        },

        getValidationError(value) {
            if (!value || !/.+@.+\.[A-Za-z]+$/.test(value)) {
                return 'Preencha o seu e-mail corretamente';
            }

            return null;
        }
    }
};

class RecoverPassword extends BaseView {
    state = { isLoading: false };

    async resendRegisterEmail() {
        try {
            const postData = { email: this.state.email, };
            await ajaxAdapter({
                showLoading: true
            }).post(url('public/resend-signup'), postData);
        } catch (ex) {
            this.setState({ success: null, error: ex.message, justUpdated: false });
            global.error(ex, false);
        }
    }

    async onSubmit(values) {
        try {
            this.setState({
                isLoading: true,
                success: null,
                mustConfirmEmail: false,
                error: null,
                errorByField: {}
            })

            const postData = {
                ...values,
                schoolId: this.props.school.id
            };

            await ajaxAdapter().post('/user/recover-password', postData);
            this.setState({
                isLoading: false,
                success: 'Instruções enviadas com sucesso para o seu e-mail!',
                error: null,
                justUpdated: false
            });
        } catch (ex) {
            if (ex.responseJSON && ex.responseJSON.mustConfirmEmail) {
                this.setState({ isLoading: false, error: 'Por favor, confirme o seu e-mail', mustConfirmEmail: true });
                return;
            }
            console.error(ex);
            this.setState({ isLoading: false, success: null, error: ex.message, justUpdated: false });
            global.error(ex, false);
        }
    }

    render() {
        const { school, user } = this.props;
        const { dark_mode } = user;
        const { logo_wide_url, logo_dark_wide_url } = school;
        const name = school.name || 'isei';

        return (
            <div className='app custom-page -login'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            { dark_mode && logo_dark_wide_url && <img src={ logo_dark_wide_url } alt={ name } title={ name } /> }
                            { dark_mode && !logo_dark_wide_url && <Logo /> }

                            { !dark_mode && logo_wide_url && <img src={ logo_wide_url } alt={ name } title={ name } /> }
                            { !dark_mode && !logo_wide_url && <Logo /> }
                        </div>
                    </header>
                    <main>
                        <h5 style={ { margin: 0, padding: 0, fontWeight: 'normal' }}>Tudo bem, acontece...</h5>
                        <p className='-secondary'><i>Todos já perdemos a senha alguma vez na vida...</i></p>
                        <br />
                        <p className='-secondary'>Preencha o seu e-mail abaixo que iremos te ajudar:</p>
                            { this.state.success && <div className='alert -success'>{this.state.success}</div>}
                            {this.state.error && <div className='alert -error'>{this.state.error}</div>}
                            { this.state.mustConfirmEmail && <small className='text-muted mt-0 mb-3 d-block'>Caso ainda não tenha recebido o e-mail de confirmação, <a href='/' onClick={ (ev) => {
                                ev.preventDefault();

                                this.resendRegisterEmail();
                                this.setState({ error: null, mustConfirmEmail: false, success: 'E-mail enviado com sucess!'})
                            } }>clique aqui</a> para enviar novamente.</small>}
                        <IseiForm
                            onSubmit={ this.onSubmit.bind(this) }
                            fields={ loginFields }
                            submitButton={ { label: 'Recuperar senha' } } /><br />

                        <p>Lembrou a senha? <Link to={ url('login') }>clique aqui</Link></p>
                    </main>

                    <footer>
                        <ul>
                            <li className='copyright'>
                                { this.props.school.name || 'iSei' } &copy; { new Date().getFullYear() }
                            </li>
                            <li>
                                <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                            </li>
                        </ul>
                    </footer>
                </div>

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }
}

export default MemoryComponent(RecoverPassword, 'school', 'user');
