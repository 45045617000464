import React from 'react';
import { MemoryComponent } from '../../memory';
import { url } from '../../utils';
import BaseView from '../BaseView';
import { Link } from 'react-router-dom';

import { PriceBasic, PriceMax, PriceTurbo } from '../../components/PriceItems';
class AdminHome extends BaseView {
    render() {
        return (
            <div className='view-admin-bank base-view'>
                <h2>Adicione saldo para continuar</h2>
                <p>Selecione um valor abaixo para adicionar saldo em sua conta, ou se preferir <Link to={ url('planos') }>assine um plano</Link>.</p>

                <div className='plans-wrapper'>
                    <PriceBasic onClick={ () => { this.props.history.push(url('add-saldo/basic')) } } />
                    <PriceTurbo onClick={ () => { this.props.history.push(url('add-saldo/turbo'))  } } />
                    <PriceMax onClick={ () => { this.props.history.push(url('add-saldo/max'))  } } />
                </div>
            </div>
        );
    }
}

export default MemoryComponent(AdminHome, 'gatewayList', 'students', 'school', 'bank', 'resource');
