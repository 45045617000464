import React, { useState } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import config from '../config';
import { capitalize } from '../utils';
import LoadingMask from './LoadingMask';
import { Switch } from './Form';

const CARD_OPTIONS = {
    hidePostalCode: true,
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#2a72ee',
            color: '#404040',
            fontWeight: 500,
            fontFamily: 'Josefin Sans, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: '14px',
            fontSmoothing: 'antialiased',
            paddingLeft: '5rem',
            ':-webkit-autofill': {
                color: '#404040',
            },
            '::placeholder': {
                color: '#888',
            },
        },
        invalid: {
            iconColor: '#cc5700',
            color: '#cc5700',
        },
    },
};
const ELEMENTS_OPTIONS = {
    fonts: [ { cssSrc: 'https://fonts.googleapis.com/css?family=Josefin+Sans' } ]
};

const CardField = ({ onChange }) => (
    <div style={ { width: '100%', minHeight: 45, paddingTop: 3, paddingLeft: '1rem' } }>
        <CardElement
            options={CARD_OPTIONS}
            onChange={onChange} />
    </div>
);

const CheckoutForm = ({ onSubmit }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [errorCard, setErrorCard] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [saveCard, setSaveCard] = useState(true);

    const [name, setName] = useState('');
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const _inputNameRef = React.createRef();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!name) {
            _inputNameRef.current.focus();
            return setErrorName('Por favor, preencha o nome como está no cartão');
        }

        if (!stripe || !elements) { return; }

        if (errorCard) {
            elements.getElement('card').focus();
            return;
        }
        if (cardComplete) { setProcessing(true); }

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: { name: name }
        });

        if (payload.error) {
            setProcessing(false);
            return setErrorCard(payload.error.message);
        }

        await onSubmit(payload, saveCard);
        setProcessing(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="field-wrapper">
                <label>Nome como está no cartão</label>
                <div className={ errorName ? 'field -invalid' : 'field' }>
                    <div className="icon" style={ { width: 40, paddingLeft: '.4rem' }}>
                        <i translate='no' className="material-icons notranslate">person</i>
                    </div>
                    <div className="input">
                        <input
                            autoFocus={ true }
                            ref={ _inputNameRef }
                            style={ {
                                paddingLeft: 0,
                                paddingTop: 17,
                                paddingBottom: 13
                            } }
                            value={ name }
                            onChange={ (ev) => {
                                setName(capitalize(ev.nativeEvent.target.value));
                                setErrorName(null);
                            } }
                            maxLength="100"
                            type="text"
                            placeholder="Ex. Sensei da iSei" />
                    </div>
                    { errorName && <div className="input-error">{errorName}</div> }
               </div>
           </div>
            <div className="field-wrapper">
                <label>Dados do seu cartão</label>
                <div className={ errorCard ? 'field -invalid' : 'field' }>
                    <CardField
                        onChange={(e) => {
                            setErrorCard(e.error && e.error.message);
                            setCardComplete(e.complete);
                        }}
                    />

                    { errorCard && <div className="input-error">{errorCard}</div> }
                </div>
            </div>

            <div style={ { marginTop: '1rem' } } className='field-wrapper -switch'>
                <label>
                    <Switch
                        id='saveCard'
                        isOn={saveCard}
                        handleToggle={() => {
                            setSaveCard(!saveCard)
                        }} />

                    <span>
                        Salvar cartão para compras futuras
                    </span>
                </label>
            </div>
            <button
                style={ { marginTop: '.5rem' } }
                className='btn -primary' type='submit'>Efetuar Pagamento</button>

            <LoadingMask fullScreen={ true } show={ processing } />
        </form>
    );
};

export default ({ onSubmit }) => {
    const { loadStripe } = require('@stripe/stripe-js');

    return (
    <div className='card-form-wrapper'>
        <Elements stripe={loadStripe(
            config.publicStripeToken, { locale: 'pt-BR' }
            )} options={ELEMENTS_OPTIONS}>
            <CheckoutForm onSubmit={ onSubmit } />
        </Elements>
    </div>);
}
