import React from 'react';
import { setDocumentTitle } from '../utils';

function isIos() {
    try {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) || (
            navigator.userAgent.includes("Mac") &&
            "ontouchend" in document
        );
    } catch (ex) {
        console.error(ex);

        return false;
    }
}


class BaseView extends React.Component {
    _setDocumentTitle() {
        let { school, degree, content } = this.props;
        let defaultTitle = 'iSei - a sua escola online';

        if (!school || !school.name) { school = global.school; }

        if (school && school.name) {
            defaultTitle = school.name;
        }

        if (degree && degree.title) {
            defaultTitle = `${degree.title} @${defaultTitle}`;
        }

        if (content && content.title) {
            defaultTitle = `${content.title} - ${defaultTitle}`;
        }

        setDocumentTitle(this.getViewTitle ? this.getViewTitle() : defaultTitle);
    }

    componentDidMount() {
        this._lastTitle = document.title;
        this._setDocumentTitle();

        const $left = document.querySelector('.content-body .-left');
        const $body = document.querySelector('#app > .body > .-padding');

        for (const $el of [ $left, $body ]) {
            if (!$el) { continue; }
            if (isIos()) { $el.classList.add('-iphone'); }
        }
    }

    componentWillUnmount() {
        document.tile = this._lastTitle;
    }

    componentDidUpdate() {
        this._setDocumentTitle();
    }
}

export default BaseView;
