import React from 'react';

function PriceBasic({ onClick }) {
    return (
    <div className='-plan -ondemand'>
        <header>
            <h3>Básico</h3>
        </header>
        <main>
            <div className='highlight'>
                <h4>R$ 29,90</h4>
                <span>30 em saldo + 30 pontos</span>
            </div>
            <div className='features'>
                <ul>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Pague apenas o que utilizar</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Saldo nunca expira</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Domínio personalizado</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Carga imediata</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Acumule pontos</li>
                </ul>
            </div>
        </main>
        { onClick && <footer>
            <button onClick={ onClick } type='button'>
                adicionar saldo
            </button>
        </footer>  }
    </div>
    );
}

function PriceTurbo({ onClick }) {
    return (
    <div className='-plan -middle'>
        <header>
            <h3>Turbo</h3>
        </header>
        <main>
            <div className='highlight'>
                <h4>R$ 149,00</h4>
                <span>150 em saldo + 150 pontos</span>
            </div>
            <div className='features'>
                <ul>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Pague apenas o que utilizar</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Saldo nunca expira</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Domínio personalizado</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Carga imediata</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Acumule pontos</li>
                </ul>
            </div>
        </main>
        { onClick && <footer>
            <button onClick={ onClick } type='button'>
                adicionar saldo
            </button>
        </footer>  }
    </div>
    );
}

function PriceMax({ onClick }) {
    return (
    <div className='-plan -super'>
    <header>
            <h3>Máximo</h3>
        </header>
        <main>
            <div className='highlight'>
                <h4>R$ 499,00</h4>
                <span>500 em saldo + 500 pontos</span>
            </div>
            <div className='features'>
                <ul>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Pague apenas o que utilizar</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Saldo nunca expira</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Domínio personalizado</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Carga imediata</li>
                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Acumule pontos</li>
                </ul>
            </div>
        </main>
        { onClick && <footer>
            <button onClick={ onClick } type='button'>
                adicionar saldo
            </button>
        </footer>  }
    </div>
    );
}

function PlanOnDemand({ active, onClick }) {
    return (
        <div className='-plan -ondemand'>
            <header>
                <h3>On Demand</h3>
                { active && <div className='active-badge'>ativo</div> }
            </header>
            <main>
                <div className='highlight'>
                    <h4>Gratuíto</h4>
                    <span>15 alunos grátis</span>
                </div>
                <div className='features'>
                    <ul>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Criação ilimitada de cursos</li>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Criação ilimitada de aulas</li>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Envio ilimitado de arquivos</li>
                    </ul>
                </div>
                <div className='description'>
                    <p>
                        Ideal para quem está começando, <strong>você vai pagar somente o que utilizar</strong>.
                    </p>
                </div>

                <div className='additional'>
                    <span>Custos adicionais</span>
                    <ul>
                        <li><i translate='no' className='material-icons notranslate'>
                            check_circle
                        </i> R$ 1,00 por aluno/mês</li>
                        <li><i translate='no' className='material-icons notranslate'>
                            check_circle
                        </i> R$ 0,50 por domínio personalizado/mês</li>

                        <li><i translate='no' className='material-icons notranslate'>
                            check_circle
                        </i> R$ 0,75 por SMS enviado</li>
                    </ul>
                </div>
            </main>
            { !active && onClick &&
            <footer>
                <button onClick={ onClick } type='button'>
                    contratar plano
                    </button>
            </footer> }
        </div>
    );
}

function PlanMiddle({ btnLabel, active, onClick }) {
    return (
        <div className='-plan -middle'>
            <header>
                <h3>Middle</h3>
                { active && <div className='active-badge'>ativo</div> }
            </header>
            <main>
                <div className='highlight'>
                    <h4>R$ 199,00/m</h4>
                    <span>1.000 alunos grátis</span>
                </div>
                <div className='features'>
                    <ul>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Criação ilimitada de cursos</li>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Criação ilimitada de aulas</li>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Envio ilimitado de arquivos</li>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Domínio personalizado</li>
                    </ul>
                </div>
                <div className='description'>
                    <p>
                    Ideal para quem já tem uma base alunos para migrar.
                    </p>
                </div>

                <div className='additional'>
                    <span>Custos adicionais</span>
                    <ul>
                        <li><i translate='no' className='material-icons notranslate'>
                            check_circle
                        </i> R$ 0,80 por aluno/mês</li>
                        <li><i translate='no' className='material-icons notranslate'>
                            check_circle
                        </i> R$ 0,75 por SMS enviado</li>
                    </ul>
                </div>
            </main>
            { !active && onClick &&
            <footer>
                <button onClick={ onClick } type='button'>
                    { btnLabel || 'contratar plano' }
                    </button>
            </footer> }
        </div>
    );
}

function PlanSuper({ btnLabel, active, onClick }) {
    return (
        <div className='-plan -super'>
            <header>
                <h3>Super</h3>
                { active && <div className='active-badge'>ativo</div> }
            </header>
            <main>
                <div className='highlight'>
                    <h4>R$ 599,00/m</h4>
                    <span>10.000 alunos grátis</span>
                </div>
                <div className='features'>
                    <ul>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Criação ilimitada de cursos</li>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Criação ilimitada de aulas</li>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Envio ilimitado de arquivos</li>
                        <li><i translate='no' className='material-icons notranslate'>check_circle</i> Domínio personalizado</li>
                    </ul>
                </div>
                <div className='description'>
                    <p>
                        Ideal para grandes produtores e quem estiver fazendo lançamentos
                    </p>
                </div>

                <div className='additional'>
                    <span>Custos adicionais</span>
                    <ul>
                        <li><i translate='no' className='material-icons notranslate'>
                            check_circle
                        </i> R$ 0,50 por aluno/mês</li>
                        <li><i translate='no' className='material-icons notranslate'>
                            check_circle
                        </i> R$ 0,75 por SMS enviado</li>
                    </ul>
                </div>
            </main>
            { !active && onClick &&
            <footer>
                <button onClick={ onClick } type='button'>
                    { btnLabel || 'contratar plano' }
                    </button>
            </footer> }
        </div>
    );
}

export {
    PriceBasic,
    PriceTurbo,
    PriceMax,
    PlanOnDemand,
    PlanMiddle,
    PlanSuper
};
