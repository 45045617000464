import React from 'react'; import BaseView from '../BaseView';

import { nextTick, url } from '../../utils';
import { updateMemory, MemoryComponent } from '../../memory';
import { Link } from 'react-router-dom';
import IseiContext from '../../Context';
import ajaxAdapter from '../../ajaxAdapter';
import LoadingMask from '../../components/LoadingMask';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Sensei } from '../../svgs';
import IseiQuill from '../../components/IseiQuill';
import moment from 'moment';
import DOMPurify from 'dompurify';
import Player from '@vimeo/player';

let lastScrollTopModulesPosition = 0;
let ignoreScrollOnMount = false;
let _openModules = { };

class CountdownComponent extends React.Component {
    render() {
        const now = moment.utc();
        const mAvailableWhen = moment.utc(this.props.content.availableWhen);
        const countdown = moment.utc(mAvailableWhen - now);

        const days = countdown.format('D');
        const hours = countdown.format('HH');
        const minutes = countdown.format('mm');
        const seconds = countdown.format('ss');

        const isSameDay = mAvailableWhen.isSame(now, 'day') ||
            mAvailableWhen.isSame(moment.utc().add(24, 'hours'), 'day');

        return (
            <div className="countdown-wrapper">
                { !isSameDay &&
                <div className="countdown-item">
                    {days}
                    <span>dias</span>
                </div> }

                { isSameDay &&
                    <React.Fragment>
                        { hours >= 1 &&
                        <div className="countdown-item">
                            {hours}
                            <span>horas</span>
                        </div> }
                        <div className="countdown-item">
                            {minutes}
                            <span>minutos</span>
                        </div>
                        <div className="countdown-item">
                            {seconds}
                            <span>segundos</span>
                        </div>
                    </React.Fragment> }
            </div>
        );
    }

    componentDidMount() {
        this.timer = setInterval(this.forceUpdate.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }
}

function renderFileIcon({ filename, location }) {
    if (!location) {
        return <div className='-loading'>
            <i translate='no' className='material-icons notranslate'>scatter_plot</i>
        </div>
    }

    const fileParts = filename.split('.');
    const extension = fileParts[fileParts.length - 1];
    const s = defaultStyles[extension];

    if (!s) { return null; }
    return <div onClick={ () => {
        window.open(location);
    } } style={ {
        width: '3rem',
        cursor: 'pointer'
    } }><FileIcon extension={ extension } {...s} /></div>;
}

function getWideHeightBasedOnWidth(width, ratio = 1.47) {
    var height = ((width) / (Math.sqrt((Math.pow(ratio, 2) + 1))));

    return Math.round(height);
}

function getVimeoId(url) {
    // eslint-disable-next-line
    const m = url.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/);

    return m && m.length >= 4 ? m[3] : null;
}

function getIframeVideoUrl(link) {
    if (/vimeo/.test(link)) {
        let url = `https://player.vimeo.com/video/${getVimeoId(link)}`;

        if (link.includes('?h=')) {
            url += `?h=${link.split('?h=')[1]}`;
        }

        return url;
    }

    if (/youtu/.test(link)) {
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        var match = link.match(regExp);

        if (match && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}`;
        }
    }

    if (/loom\.com/.test(link)) {
        const linkParts = link.split('/').filter(Boolean);
        const loomId = linkParts[linkParts.length - 1];

        return `https://www.loom.com/embed/${loomId}`;
    }
}

const navbarMenus = {
    loggedIn: [
        {
            icon: 'person',
            cls: '-avatar',
            link: 'minha-conta',
            submenu: [
                { label: 'Minha conta', icon: 'person', link: 'minha-conta' },
                { label: 'Sair', icon: 'exit_to_app', onClick(ev) {
                    ev.preventDefault();

                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('user');
                    localStorage.removeItem('schools');

                    window.location.href = url('');
                } },
            ]
        },
    ],
    none: [
        { icon: 'person', cls: '-avatar', link: 'minha-conta' }
    ]
};

class Header extends React.Component {
    render() {
        const { user, menus, school } = this.props;

        return (
            <header style={ { padding: '.5rem 2rem .5rem 2rem' } } className='main-header'>
                <div className='-title' style={ { display: 'flex', alignItems: 'center' } }>
                    <i onClick={ () => {
                        this.props.history.push(url(`c/${this.props.degree.link}`));
                    } } translate='no' className='material-icons notranslate'>arrow_back</i>

                    <h2 dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(this.props.title) }} />
                 </div>
                <ul className='-menu -content'>
                    {menus.filter((m) => {
                        if (m.feature && !school[m.feature]) { return false; }

                        return true;
                    }).map((m, idx) => <li key={idx}>
                        <Link to={url(m.link)}>
                            { m.cls === '-avatar' &&
                                <React.Fragment>
                                    <img
                                        src={ user.profile_img_url || 'https://isei.app/sensei.png'  }
                                    alt={ user.name } className='-avatar' />
                                </React.Fragment> }

                            { m.cls !== '-avatar' &&
                                <i className={`material-icons notranslate ${m.cls || ''}`}>{m.icon}</i> }
                        </Link>
                        {m.submenu && <ul className='-dropdown'>
                            {m.submenu.map((s, idx) => <li key={idx}>
                                <Link onClick={s.onClick} to={url(s.link)}>
                                    <i className={`material-icons notranslate ${s.cls || ''}`}>{s.icon}</i>
                                    {s.label}
                                </Link>
                            </li>)}
                        </ul>}
                    </li>)}
                </ul>
            </header>

        );
    }
}

const HeaderWithMemory = MemoryComponent(Header, 'school', 'user')

class AddContent extends BaseView {
    state = {
        isLoading: false,
        isMenuHidden: localStorage.getItem('isModuleMenuHidden') || window.innerWidth < 500,
        degree: { modules: [] },
        threads: [],
        content: {},
        comment: '',
        openAskBoxesByThread: {},
        askBoxesCommentsByThread: {}
    };

    static contextType = IseiContext.type;

    isContentMagicPdf() {
        return /genial\.ly/.test(this.props.content.main_media_url);
    }

    isContentVideo() {
        // HACKy
        return /youtu|vimeo|loom/.test(this.props.content.main_media_url);
    }

    renderMagicPdf() {
        const { content } = this.props;

        const iframeWidth = Math.min(800, global.window.innerWidth - 370);
        const iframeHeight = getWideHeightBasedOnWidth(iframeWidth);

        return (
            <iframe
                title={content.title}
                src={content.main_media_url}
                width={iframeWidth}
                height={iframeHeight}
                frameBorder="0"
                allow="autoplay; fullscreen"
                webkitallowfullscreen='true'
                mozallowfullscreen='true'
                allowFullScreen />
        );
    }

    renderContentVideo() {
        const { content } = this.props;

        const iframeWidth = Math.min(700, global.window.innerWidth - 470);
        const iframeHeight = getWideHeightBasedOnWidth(iframeWidth);

        if (/vimeo/.test(content.main_media_url)) {
            clearTimeout(this.lastTimeout);

            this.lastTimeout = setTimeout(() => {
                try {
                    new Player(String(content.id));
                } catch (ex) {
                    console.error(ex);
                }
            }, 10);

            return (<div
                data-vimeo-url={getIframeVideoUrl(content.main_media_url)}
                data-vimeo-width={iframeWidth}
                data-vimeo-height={iframeHeight}
                id={String(content.id)}>
            </div>);
        }
        return (
            <iframe
                title={content.title}
                src={getIframeVideoUrl(content.main_media_url)}
                width={iframeWidth}
                height={iframeHeight}
                frameBorder="0"
                allow="fullscreen"
                webkitallowfullscreen='true'
                mozallowfullscreen='true'
                allowFullScreen />
        );
    }

    renderFile(file) {
        if (file.isAddButton) { return null; }

        return (
            <div onClick={ () => {
                window.open(file.location);
            } } key={ file.id } className='-file'>
                { renderFileIcon(file) }
                <h5 dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(file.title) } } />
            </div>
        );
    }

    hasPrevContent() {
        return Boolean(this.getPrevContent());
    }

    hasNextContent() {
        return Boolean(this.getNextContent());
    }

    getPrevContent() {
        const { modules } = this.props.degree;
        const contentId = this.props.content.id;

        let hasFoundCurrent = false;
        let idx = modules.length;

        while (idx--) {
            const mod = modules[idx];
            if (!mod.content) { continue; }

            let contentIdx = mod.content.length;

            while (contentIdx--) {
                const content = mod.content[contentIdx];

                if (!content.id) { continue; }

                if (content.id === contentId) {
                    hasFoundCurrent = true;
                    continue;
                }

                if (hasFoundCurrent) { return content; }
            }
        }

        return false;
    }

    getNextContent() {
        const { modules } = this.props.degree;
        const contentId = this.props.content.id;

        let hasFoundCurrent = false;

        for (const mod of modules) {
            if (!mod.content) { continue; }

            for (const content of mod.content) {
                if (!content.id) { continue; }

                if (content.id === contentId) {
                    hasFoundCurrent = true;
                    continue;
                }

                if (hasFoundCurrent) { return content; }
            }
        }

        return false;
    }

    async setMetadata(values = {}) {
        const degrees = { ...this.props.acl.degrees }
        const cfg = { ...(degrees[this.props.degree.id] || { metadata: { } }) };
        const metadata = { ...(cfg.metadata || {}) };

        metadata.content = { ...(metadata.content || {}) };
        const contentCfg = { ...(metadata.content[this.props.content.id] || {}) };

        metadata.content[this.props.content.id] = { ...contentCfg, ...values };

        cfg.metadata = metadata;
        degrees[this.props.degree.id] = cfg;

        try {
            const { userDegree } = await ajaxAdapter({
                isSchoolRequest: true
            }).post('student/content/metadata', {
                contentId: this.props.content.id,
                degreeId: this.props.degree.id,
                userDegree: cfg
            });

            degrees[this.props.degree.id] = userDegree;
            updateMemory({ acl: { ...this.props.acl, degrees } });
        } catch(ex) {
            global.error(ex);
        }
    }

    isComplete(contentId = this.props.content.id) {
        const degrees = { ...this.props.acl.degrees }
        const cfg = degrees[this.props.degree.id] || { metadata: { } };
        const contentCfg = (cfg.metadata?.content || {})[contentId] || {};

        return contentCfg.isComplete;
    }

    hasDescription() {
        return this.props.content.description !== '<p>Insira aqui a descrição para o seu conteúdo</p>';
    }

    renderExternalLink(link) {
        if (!link.link) { return null; }
        link.link = link.link.replace(/\u200B/g, '');

        return (
            <div onClick={ (ev) =>{
                ev.preventDefault();
                window.open(link.link.trim())
             } } className='external-link'>
                <i translate='no' className='material-icons notranslate'>link</i>
                <a rel="noopener noreferrer" target='_blank' href={ link.link.trim() }>{ link.title }</a>
            </div>
        )
    }

    isContentBookmarked() {
        return this.props.bookmarks.find((b) =>
            b.degree_id === this.props.degree.id &&
            b.content_id === this.props.content.id);
    }

    async bookmarkToggle() {
        try {
            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).post('bookmark', {
                degreeId: this.props.degree.id,
                contentId: this.props.content.id,
                isBookmark: !this.isContentBookmarked()
            })

            updateMemory({ bookmarks: res.bookmarks });
        } catch(ex) {
            global.error(ex);
        }
    }

    renderAskBox() {
        return (
            <div className='my-ask-box'>
                <div className='ask-box-wrapper'>
                    <div className='-avatar'>
                        <div className='sensei'>
                            { !this.props.user.profile_img_url &&
                                <div className='sensei'><Sensei /></div> }
                            { this.props.user.profile_img_url &&
                                <img
                                    style={ { width: '100%' } }
                                    alt={ this.props.user.name }
                                    title={ this.props.user.name }
                                    src={ this.props.user.profile_img_url } /> }
                        </div>
                    </div>
                    <div className='ask-content'>
                        <h5>{ this.props.user.name }</h5>
                        <span>,</span>

                        <IseiQuill
                            onFocus={ (ev) => {
                            }}
                            onBlur={ (ev) => {

                            }}
                            value={ this.state.comment }
                            onChange={ (val) => {
                                this.setState({ comment: val });
                            }} />

                        { !this.hasComment() &&
                            <button className='btn -secondary' disabled>Enviar pergunta</button> }

                        { this.hasComment() &&
                            <button onClick={ (ev) => {
                                this.sendQuestion();
                            } } className='btn -primary'>Enviar pergunta</button> }
                    </div>
                </div>
            </div>
        );
    }

    renderThread(thread) {
        const { school } = this.props;
        const isSchoolAdmin = school.role.includes('admin');
        const assistantName = school.assistant_name || 'Sensei';

        return <div className='thread'>
            <div className='ask-box-wrapper'>
                <div className='-avatar'>
                    <div className='sensei'>
                        { !thread.profile_img_url &&
                            <div className='sensei'><Sensei /></div> }
                        { thread.profile_img_url &&
                            <img
                                style={ { width: '100%' } }
                                alt={ thread.user_name }
                                title={ thread.user_name }
                                src={ thread.profile_img_url } /> }
                    </div>
                </div>
                <div className='ask-content'>
                    <h5>{ thread.user_name }</h5><span>,</span>
                    <p dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(thread.message) } } />
                </div>
            </div>

            { thread.subthreads.length > 0 && <hr /> }
            { thread.subthreads.map((s) => <React.Fragment>
                <h5>Resposta de { assistantName }</h5>
                <p dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(s.message) } } />
            </React.Fragment>)}

            { thread.subthreads.length === 0 && this.props.user.id === thread.user_id &&
            <div className='-actions'>
                <a onClick={ (ev) => {
                    ev.preventDefault();

                    if (global.confirm('Tem certeza?')) {
                        return this.deleteThread(thread.id);
                    }
                } } className='text-red' href='/'>
                    Excluir
                </a>
            </div> }

            { thread.subthreads.length === 0 && isSchoolAdmin &&
                <div className='-actions'>
                    <a onClick={ (ev) => {
                        ev.preventDefault();

                        this.toggleAskBoxForThread(thread);
                    } } href='/'>
                        Responder
                    </a>
                </div> }

            { this.state.openAskBoxesByThread[thread.id] && <React.Fragment>
                <div style={ { padding: '1rem' } }>
                <hr />
                <h5>A sua resposta</h5> <span>:</span>

                <IseiQuill
                    onFocus={ (ev) => {
                    }}
                    onBlur={ (ev) => {

                    }}
                    value={ this.state.askBoxesCommentsByThread[thread.id] }
                    onChange={ (val) => {
                        const askBoxesCommentsByThread = {
                            ...this.state.askBoxesCommentsByThread
                        };
                        askBoxesCommentsByThread[thread.id] = val;

                        this.setState({ askBoxesCommentsByThread });
                    }} />


                { !this.state.askBoxesCommentsByThread[thread.id] &&
                    <button className='btn -secondary' disabled>Enviar resposta</button> }

                { this.state.askBoxesCommentsByThread[thread.id] &&
                    <button onClick={ (ev) => {
                        this.sendAnswearByThread(thread);
                    } } className='btn -primary'>Enviar resposta</button> }
                </div>
            </React.Fragment> }
        </div>
    }

    async sendAnswearByThread(thread) {
        const boxes = { ...this.state.askBoxesCommentsByThread };
        const message = boxes[thread.id];

        try {
            const { content } = this.props;

            const link = `content/${content.link}/thread/${thread.id}`;
            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).post(link, { message });
            boxes[thread.id] = '';

            const openAskBoxesByThread = { ...this.state.openAskBoxesByThread };
            openAskBoxesByThread[thread.id] = false;

            this.setState({
                openAskBoxesByThread,
                askBoxesCommentsByThread: boxes,
                threads: res.threads
            });
        } catch (ex) {
            global.error(ex);
        }
    }

    toggleAskBoxForThread(thread) {
        const boxes = { ...this.state.openAskBoxesByThread };
        boxes[thread.id] = !boxes[thread.id];

        this.setState({ openAskBoxesByThread: boxes });
    }

    async deleteThread(threadId) {
        try {
            const link = `content/${this.props.content.link}/thread/${threadId}`;

            const { threads } = await ajaxAdapter({
                isSchoolRequest: true
            }).delete(link);

            this.setState({ threads });
        } catch (ex) {
            global.error(ex);
        }
    }

    renderThreads() {
        const isSchoolAdmin = this.props.school.role.includes('admin');

        return (
            <React.Fragment>
                <div className='threads-wrapper'>
                    { this.state.threads.map(this.renderThread.bind(this)) }
                </div>

                { !isSchoolAdmin && this.renderAskBox() }
            </React.Fragment>
        );
    }

    render() {
        const isContextAdmin = this.context.contextRole === 'admin';
        const isSchoolAdmin = this.props.school.role.includes('admin');
        const menus = navbarMenus[this.context.hasUser ? 'loggedIn' : 'none'].filter((m) => {
            if (m.onlyStudent && isContextAdmin) { return false }
            if (m.onlyAdmin && !isContextAdmin) { return false }

            return true;
        });

        return (
            <React.Fragment>
                <div className='view-content -student'>
                    <HeaderWithMemory
                        onTitleChange={ (title) => {
                            this.setDegree({ title })
                        }}
                        degree={ this.props.degree }
                        title={ this.props.degree.title }
                        history={ this.props.history }
                        menus={ menus } />

                    <div className={ this.state.isMenuHidden &&
                            !this.isContentVideo() &&
                            !this.isContentMagicPdf() ?  '-modules-hidden content-body'
                        : 'content-body' }>
                        <div className='-left' style={ { position: 'relative'}}>
                            { this.isContentVideo() ?
                            <div className='-content -video'>
                                { this.state.isMenuHidden &&
                                    <i onClick={ () => {
                                        localStorage.removeItem('isModuleMenuHidden');
                                        this.setState({ isMenuHidden: false });
                                    } } className='-open material-icons notranslate'>menu</i> }
                                { this.renderContentVideo() }
                            </div> : this.isContentMagicPdf() ? <div className='-content -video'>
                                { this.state.isMenuHidden &&
                                    <i onClick={ () => {
                                        localStorage.removeItem('isModuleMenuHidden');
                                        this.setState({ isMenuHidden: false });
                            } } className='-open material-icons notranslate'>menu</i> }
                                { this.renderMagicPdf() }
                            </div> : <React.Fragment><br/></React.Fragment>}

                            { !this.isContentVideo() && this.state.isMenuHidden &&
                                    <i onClick={ () => {
                                        localStorage.removeItem('isModuleMenuHidden');
                                        this.setState({ isMenuHidden: false });
                                    } }  style={ {
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        right: '1rem',
                                        top: '1rem'
                                    } } className='-open material-icons notranslate'>menu</i> }

                            <div className='-description'>
                                <div className='-wrapper'>
                                    <div className='title-wrapper'>
                                        <h4 dangerouslySetInnerHTML={ {
                                            __html: DOMPurify.sanitize(this.props.content.title)
                                        } } />
                                        <div className='-buttons'>
                                            { this.isComplete() && !isSchoolAdmin &&
                                                <button onClick={ () => {
                                                    this.setMetadata({ isComplete: false });
                                                } } type='button' className='-completed'>
                                                    <i translate='no' className='material-icons notranslate'>check</i>
                                                </button>  }
                                            { this.hasPrevContent() &&
                                                <button onClick={ () => {
                                                    const content = this.getPrevContent();
                                                    this.props.history.push(url(`/c/${this.props.degree.link}/${content.link}`));
                                                } } type='button' className='-navigate'>
                                                    <i translate='no' className='material-icons notranslate'>navigate_before</i>
                                            </button>  }

                                            { this.hasNextContent() && (this.isComplete() || isSchoolAdmin) &&
                                                <button onClick={ () => {
                                                    const content = this.getNextContent();
                                                    this.props.history.push(url(`c/${this.props.degree.link}/${content.link}`));
                                                } } type='button' className='-navigate'>
                                                    <i translate='no' className='material-icons notranslate'>navigate_next</i>
                                                </button>  }

                                            { !this.isComplete() && !isSchoolAdmin && !this.props.content.dripped &&
                                                <button onClick={ () => {
                                                    this.setMetadata({ isComplete: true });
                                                } } type='button' className='-complete'>Completar</button>  }

                                            { !isSchoolAdmin &&
                                                <React.Fragment>
                                                    <button
                                                        onClick={ this.bookmarkToggle.bind(this) }
                                                        type='button'
                                                        className={ this.isContentBookmarked() ? '-bookmarked -favorite -badge' : '-favorite -badge' }>
                                                        <i translate='no' className='material-icons notranslate'>bookmark</i></button>
                                                </React.Fragment> }
                                        </div>
                                    </div>
                                    { this.props.content.dripped &&
                                        <React.Fragment>
                                            <p>Essa aula será liberada em:</p>
                                            <CountdownComponent content={ this.props.content } />
                                        </React.Fragment> }

                                    { !this.props.content.dripped &&
                                        <React.Fragment>
                                            { this.hasDescription() &&
                                            <div dangerouslySetInnerHTML={ {
                                                __html: DOMPurify.sanitize(this.props.content.description)
                                                } } /> }

                                            { this.props.content.files?.length > 1 &&
                                            <React.Fragment>
                                            <h4 style={ { marginTop: '2rem' }}>Arquivos</h4>
                                            <div className='files-list'>
                                                {this.props.content.files.map(this.renderFile.bind(this))}
                                            </div> </React.Fragment>}

                                            { this.props.content.external_links?.filter((e) => e.title !== '').length > 1 &&

                                            <React.Fragment>
                                            <h4 style={ { marginTop: '2rem' }}>Links externos</h4>
                                            <div className='external-links-list'>
                                                {this.props.content.external_links.map(this.renderExternalLink.bind(this))}
                                            </div> </React.Fragment>}


                                            { this.props.degree.has_comments &&
                                            <React.Fragment>
                                            <hr style={ { marginTop: '1rem', marginBottom: '1rem' } } />
                                            <h4>Perguntas e respostas</h4>

                                            { !this.state.threads.length ?
                                                <React.Fragment>
                                                    { isSchoolAdmin ?
                                                        <p>
                                                            Esse conteúdo ainda não possuí nenhuma pergunta.
                                                        </p>
                                                    : <React.Fragment>
                                                    <p>
                                                        Esse conteúdo ainda não possuí nenhuma pergunta, fique a vontade para enviar a primeira :)
                                                    </p>
                                                    { this.renderAskBox() }
                                                </React.Fragment>}
                                            </React.Fragment> : this.renderThreads() }
                                            </React.Fragment> }
                                    </React.Fragment> }

                                </div>
                            </div>
                        </div>

                        { !this.state.isMenuHidden &&
                        <div className='-right'>
                            <div className='-header'>
                                <h4>Conteúdo do curso</h4>
                                <i onClick={ () => {
                                    localStorage.isModuleMenuHidden = true;
                                    this.setState({ isMenuHidden: true });
                                } } translate='no' className='material-icons notranslate'>chevron_right</i>
                            </div>

                            <div className='modules'>
                                <div>
                                    { this.props.degree.modules.map(this.renderModule.bind(this)) }
                                </div>
                            </div>
                        </div> }
                    </div>
                </div>

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </React.Fragment>
        )
    }

    async sendQuestion() {
        try {
            const { content } = this.props;

            const link = `content/${content.link}/thread`;
            const res = await ajaxAdapter({ isSchoolRequest: true })
                .post(link, { message: this.state.comment });

            this.setState({ comment: '', threads: res.threads });
        } catch (ex) {
            global.error(ex);
        }
    }

    hasComment() {
        return this.state.comment && this.state.comment !== '<p><br></p>';
    }

    _setStateFromResponse(res) {
        const { classLink } = this.props.match.params;

        if (classLink !== res.content.link) { return; }

        const c = { ...res };
        const modules = [ ...c.degree.modules, ...(c.degree.draft?.modules || [] ) ];

        for (const m of modules) {
            if (!m.content) { continue; }

            for (const c of m.content) {
                if (c.link === classLink) {
                    this.toggleModuleOpen(m, true);
                }
            }
        }

        updateMemory(c)
        nextTick(this.loadThreads.bind(this));
    }

    async loadThreads() {
        try {
            const { degree, content } = this.props;

            const link = `degree/${degree.link}/content/${content.link}/threads`;
            const res = await ajaxAdapter({ isSchoolRequest: true }).get(link);

            this.setState({ threads: res.threads });
        } catch (ex) {
            global.error(ex);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.querySelector('.body').style.overflow = '';
        document.body.style.overflow = '';
    }

    async componentDidMount() {
        super.componentDidMount();

        document.querySelector('.body').style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';

        const $mod = document.querySelector('.-right');
        if ($mod) {
            $mod.scrollTop = lastScrollTopModulesPosition;
            $mod.onscroll = () => {
                lastScrollTopModulesPosition = $mod.scrollTop;
            }
        }

        try {
            const { link, moduleId, classLink } = this.props.match.params;

            const content = this.props.tryFindContent(classLink);
            if (content) {
                const degree = this.props.degree;
                this._setStateFromResponse({ degree, content });

                if (ignoreScrollOnMount) {
                    ignoreScrollOnMount = false;
                    return;
                }

                setTimeout(() => {
                    const node = document.querySelector(`.-content[data-id="${content.id}"]`);
                    if (node) {
                        node.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                    }
                }, 10);
                return;
            }

            //this.setState({ isLoading: true })
            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).post('degree/student/' + link, { classLink, moduleId });

            if (!res.degree) {
                this.props.history.replace(url('cursos'));
                return;
            }

            if (res.content && !classLink) {
                this.props.history.replace(url('c/' + res.degree.link + '/' + res.content.link));
            }

            this._setStateFromResponse(res);

            setTimeout(() => {
                const node = document.querySelector(`.-content[data-id="${res.content.id}"]`);
                if (node) {
                    node.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                }
            }, 10)
        } catch(ex) {
            const { link } = this.props.match.params;
            if (ex.status === 404) {
                return this.props.history.replace(url(`/`));
            }

            console.error(ex);
            this.setState({ error: ex.message, isLoading: false })

            this.props.history.replace(url(`/c/${link}`));
        }
    }

    setDegree(values) {
        const degree = { ...this.props.degree, ...values };

        updateMemory({ degree });
    }

    setContent(values) {
        const content = { ...this.props.content, ...values };

        updateMemory({ content });
    }

    updateModule(id, values) {
        const modules = [ ...this.props.degree.modules, ];

        for (let idx = 0; idx < modules.length; ++idx) {
            const m = modules[idx]

            if (m.id !== Number(id)) { continue; }
            modules[idx] = { ...m, ...values };
        }

        this.setDegree({ modules });
    }

    renderModule(m) {
        if (m.isAddButton) {
            return null;
        }

        return (
            <div
                data-id={ m.id }
                key={ m.id }
                className={ this.isModuleOpen(m) ? '-open -module -drag' : '-module -drag' }>
                <header>
                    <i
                        onClick={ () => {
                            this.toggleModuleOpen(m)
                        }}
                        translate='no' className='material-icons notranslate -expander'>{ !this.isModuleOpen(m) ? 'expand_more' : 'expand_less' }</i>
                    <span dangerouslySetInnerHTML={ {
                        __html: DOMPurify.sanitize(m.title) }} />
                </header>
                { this.isModuleOpen(m) &&
                <div className='content-list'>
                    {m.content.map((c) => this.renderContent(c) ) }
                </div> }

            </div>
        );
    }

    renderContent(content) {
        content = {
            ...content,
            ...content.draft,
            isDraft: JSON.stringify(content.draft) !== '{}' ||
                (this.props.degree.utc_published_on && !content.utc_published_on)
        };

        if (content.isAddButton) {
            return null;
        }

        const { classLink } = this.props.match.params;
        const cls = [ '-content' ];

        if (classLink === content.link) {
            cls.push('-active');
        }

        return (
            <div data-id={ content.id } key={ content.id } className={cls.join(' ')}>
                <Link onClick={ () => {
                    ignoreScrollOnMount = true;
                } } to={ url(`c/${this.props.degree.link}/${content.link}`) }>{ content.title }</Link>

                { !content.dripped &&
                    <React.Fragment>

                    { content.main_media_duration && <span className='-duration'>
                        { (content.main_media_duration / 60).toFixed(2).replace('.', ':') }
                    </span> }

                    <i className={
                        this.isComplete(content.id) ? 'material-icons notranslate -done' : 'material-icons notranslate -not-done'
                    }>check_circle</i>
                    </React.Fragment> }

                { content.dripped &&
                    <React.Fragment>
                        <span className='-duration'>
                            { moment.utc(content.availableWhen).fromNow() }
                        </span>
                        <i translate='no' className='material-icons notranslate'>schedule</i>
                    </React.Fragment> }
            </div>
        );
    }

    toggleModuleOpen(m, isOpen) {
        const openModules = { ..._openModules };
        isOpen = isOpen !== undefined ? isOpen : !this.isModuleOpen(m);

        if (!isOpen) {
            delete openModules[m.id];
        } else {
            openModules[m.id] = true;
        }

        _openModules = openModules;
        this.forceUpdate();
    }

    isModuleOpen(m) { return _openModules[m.id]; }

}

export default MemoryComponent(AddContent, 'school', 'user',
    'degree', 'content', 'acl', 'bookmarks');
