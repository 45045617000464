import React from 'react';
import { Link } from "react-router-dom";

import BaseView from '../BaseView';
import { Logo } from '../../svgs';
import { validations, url, capitalize } from '../../utils';
import IseiForm from '../../components/Form';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent } from '../../memory';

const signinSchoolFields = {
    school_name: {
        type: 'text',
        icon: 'school',
        placeholder: 'Nome de sua área de membros',
        autoComplete: 'organization',
        getValidationError(str) {
            if (!str || str.length < 4) {
                return 'Por favor, preencha o seu nome';
            }

            return false;
        }
    },
    user_name: {
        type: 'text',
        icon: 'person',
        placeholder: 'O seu nome completo',
        autoComplete: 'name',
        getPrettyValue: capitalize,
        getValidationError(str) {
            if (!str || str.length < 4) {
                return 'Por favor, preencha o nome de sua área de membros';
            }

            return false;
        }
    },
    email: {
        type: 'text',
        icon: 'alternate_email',
        placeholder: 'E-mail para acessar a plataforma',
        autoComplete: 'email',
        selectOnFocus: true,

        getPrettyValue(value) {
            return (value &&
                value.toLowerCase &&
                value.toLowerCase().replace().replace(/\s/g, '')
            ) || value;
        },

        getValidationError: validations.email
    },

    password: {
        type: 'password',
        icon: 'locker',
        placeholder: 'Preencha aqui a sua senha',
        autoComplete: 'current-password',
        selectOnFocus: true,

        getValidationError: validations.password
    },

    disclosure: {
        type: 'switch',
        label: 'Estou de acordo com os',
        linkAtEnd: {
            label: 'Termos e Condições',
            link: 'termos-e-condicoes'
        }
    },
};

const siginStudentFields = {
    user_name: {
        type: 'text',
        icon: 'person',
        placeholder: 'O seu nome completo',
        autoComplete: 'name',
        getPrettyValue: capitalize,

        getValidationError(str) {
            if (!str || str.length < 4) {
                return 'Por favor, preencha o seu nome';
            }

            return false;
        }
    },
    email: {
        type: 'text',
        icon: 'alternate_email',
        placeholder: 'E-mail para acessar a plataforma',
        autoComplete: 'email',
        selectOnFocus: true,

        getPrettyValue(value) {
            return (value &&
                value.toLowerCase &&
                value.toLowerCase().replace().replace(/\s/g, '')
            ) || value;
        },

        getValidationError(value) {
            if (!value || !/.+@.+\.[A-Za-z]+$/.test(value)) {
                return 'Preencha o seu e-mail corretamente';
            }

            return null;
        }
    }
};

class ViewSignIn extends BaseView {
    state = {
        school_name: '',
        user_name: '',
        email: '',
        password: '',
        disclosure: false,
        isLoading: false
    };

    async onSubmit(values) {
        if (!values.disclosure && values.school_name) {
            return alert('Por favor, aceite os termos para continuar');
        }

        try {
            this.setState({
                isLoading: true,
                success: null,
                mustConfirmEmail: false,
                error: null,
                errorByField: {}
            })

            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const isAcademy = Number(params.academy) === 1 ? 1 : 0;

            const postData = { ...values, isAcademy };
            await ajaxAdapter({
                isSchoolRequest: Boolean(this.props.school.id)
            }).post('create-account', postData);

            this.setState({ isLoading: false, isCreated: true });
        } catch (ex) {
            console.error(ex);

            let message = ex.message;
            this.setState({
                isLoading: false,
                success: null,
                error: message,
                justUpdated: false
            });
        }
    }

    renderSchoolSignIn() {
        const { school, user } = this.props;
        const { dark_mode } = user;
        const { logo_wide_url, logo_dark_wide_url } = school;
        const name = school.name || 'isei';

        return (
            <div className='app custom-page -signin'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            { dark_mode && logo_dark_wide_url && <img src={ logo_dark_wide_url } alt={ name } title={ name } /> }
                            { dark_mode && !logo_dark_wide_url && <Logo /> }

                            { !dark_mode && logo_wide_url && <img src={ logo_wide_url } alt={ name } title={ name } /> }
                            { !dark_mode && !logo_wide_url && <Logo /> }
                        </div>
                    </header>

                    { this.state.isCreated &&
                        <main>
                            <h2 style={ { margin: 0 } }>Boas Vindas! ;)</h2>
                            <p className='-secondary'>O seu cadastro foi efetuado com sucesso<br /><br />Por favor, acesse o seu e-mail para ativar a sua conta</p>
                        </main> }

                    { !this.state.isCreated &&
                    <main>
                        <p className='-secondary'>Olá, seja bem-vindo(a) a {name}!</p>
                        <p className='-secondary'>Para efetuar o seu cadastro preencha os seus dados abaixo:</p>
                            { this.state.success && <div className='alert -success'>{this.state.success}</div>}
                            {this.state.error && !/duplicate/i.test(this.state.error) && <div className='alert -error'>{this.state.error}</div>}
                            {this.state.error && /duplicate/i.test(this.state.error) && <div className='alert -error'>
                                    E-mail já cadastrado, <Link to={ url('login') } >clique aqui</Link> para efetuar login.
                            </div>}
                            { this.state.mustConfirmEmail && <small className='text-muted mt-0 mb-3 d-block'>Caso ainda não tenha recebido o e-mail de confirmação, <a href='/' onClick={ (ev) => {
                                ev.preventDefault();

                                this.resendRegisterEmail();
                                this.setState({ error: null, mustConfirmEmail: false, success: 'E-mail enviado com sucess!'})
                            } }>clique aqui</a> para enviar novamente.</small>}
                        <IseiForm
                            values={ this.state.values }
                            onSubmit={ this.onSubmit.bind(this) }
                            fields={ siginStudentFields }
                            submitButton={ { label: `Criar a minha conta em ${name}` } } />
                    </main> }
                </div>

                <footer>
                    <ul>
                        <li className='copyright'>
                            { this.props.school.name || 'iSei' } &copy; { new Date().getFullYear() }
                        </li>
                        <li>
                            <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                        </li>
                    </ul>
                </footer>
                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    render() {
        const { school } = this.props;

        if (this.state.isCreated) {
            this.redirectTimeout = setTimeout(() => {
                this.props.history.push(url('login'));
            }, 7 * 1000);
        }

        if (school.id) {
            return this.renderSchoolSignIn();
        }

        return (
            <div className='app custom-page -signin'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            <Logo />
                        </div>
                    </header>
                    { this.state.isCreated &&
                        <main>
                            <h2 style={ { margin: 0 } }>Boas Vindas! ;)</h2>
                            <p className='-secondary'>O cadastro de sua área de membros foi efetuado com sucesso<br /><br />Por favor, acesse o seu e-mail para ativar a sua conta</p>
                        </main> }

                    { !this.state.isCreated &&
                    <main>
                        <p className='-secondary'>Olá!</p>
                        <p className='-secondary'>Para efetuar o cadastro de sua escola, preencha os dados abaixo:</p>
                            { this.state.success && <div className='alert -success'>{this.state.success}</div> }
                            { this.state.error && <div className='alert -error'>{this.state.error}</div> }
                            { this.state.mustConfirmEmail && <small className='text-muted mt-0 mb-3 d-block'>Caso ainda não tenha recebido o e-mail de confirmação, <a href='/' onClick={ (ev) => {
                                ev.preventDefault();

                                this.resendRegisterEmail();
                                this.setState({ error: null, mustConfirmEmail: false, success: 'E-mail enviado com sucess!'})
                            } }>clique aqui</a> para enviar novamente.</small>}
                        <IseiForm
                            values={ this.state.values }
                            onSubmit={ this.onSubmit.bind(this) }
                            fields={ signinSchoolFields }
                            submitButton={ { label: `Criar a minha área de membros!` } } />
                    </main> }

                </div>

                <footer>
                    <ul>
                        <li className='copyright'>
                            iSei &copy; 2020
                        </li>
                        <li>
                            <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                        </li>
                    </ul>
                </footer>
                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }
}

export default MemoryComponent(ViewSignIn, 'school', 'user');
