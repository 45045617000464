import React from 'react';
import { Logo } from '../../svgs';
import config from '../../config';
import ajaxAdapter from '../../ajaxAdapter';
import { updateMemory, MemoryComponent } from '../../memory';
import { prettify } from '../../utils';
import LoadingMask from '../../components/LoadingMask';
import IseiForm from '../../components/Form';
import BaseView from '../BaseView';
import PaginationComponent from '../../components/Pagination';
import { url, getInnerValueByEvent } from '../../utils';
import SelectDegreesModal from '../../components/SelectDegreesModal';
import DOMPurify from 'dompurify';

class AddTemplateModal extends React.Component {
    state = { isModalOpen: false, link: '' };

    render() {
        if (!this.state.isModalOpen) { return null; }

        return (
            <div className={ `modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}` }>
                <div className='-content'>
                    <div className='-body'>

                        <p className='-description'>
                            Aqui você adiciona uma nova filial para a sua escola.<br />
                            Para isso a sua filial precisa ter uma escola cadastrada em <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href='https://isei.app/cadastro'>iSei.app/cadastro</a>.
                        </p>

                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}
                        { !this.state.error && <br /> }

                        <IseiForm
                            ref='form'
                            onSubmit={ this._submit.bind(this) }
                            values={ this.state.values }
                            fields={ {
                                link: {
                                    type: 'text',
                                    icon: 'content_copy',
                                    placeholder: 'exemplo: rl.isei.app',
                                    label: 'Link de sua filial',
                                    maxLength: 40,
                                    getValidationError(str, values) {
                                        if (str && (str.includes('http://isei/') ||
                                            str.includes('http://localhost') ||
                                            str.includes('.'))) {

                                            return false;
                                        }

                                        return 'Por favor, preencha uma escola válida';
                                    },

                                    onButtonClick(value, field) {
                                        field.focus();
                                        field.select();
                                        document.execCommand('copy');
                                    }
                                }
                            } } />

                        <div className='-footer'>
                            <button type='button' onClick={ (ev) => {
                                this.refs.form.onSubmit(ev)
                            }} className='btn -primary'>
                                Afiliar escola
                            </button>
                            <button type='button' onClick={ () => {
                                this.setState({ isModalOpen: false });
                            } } className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit(values) {
        this.props.onSubmit(values);
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            name: '',
            email: '',
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class OtherSchools extends BaseView {
    state = {
        page: 1,
        isLoading: true,
        schools: { total: 0, page: 1, list: [], rowsPerPage: 50 },
        plan: { id: null, title: '', degrees: [], all_degrees: true }
    };

    renderSchool(school) {
        const { dark_mode } = this.props.user;
        const { logo_wide_url, logo_dark_wide_url } = school;

        let url = `${config.baseAppUrl}/escola/${school.id}`

        if (school.namespace || school.domain) {
            const ns = {
                domain: school.domain,
                namespace: school.namespace
            };

            if (ns.domain) {
                url = `https://${ns.domain}`;
            } else {
                url = `${config.baseAppUrl}/${ns.namespace}`;
            }
        }

        const name = school.name;

        return (
            <div key={ school.id } onClick={() => {

            }} className='-school school-template' style={ { cursor: 'default' } }>
                <div className='-logo-wrapper -wide'>
                    <div className='content logo'>
                        { dark_mode && logo_dark_wide_url && <img src={ logo_dark_wide_url } alt={ name } title={ name } /> }
                        { dark_mode && !logo_dark_wide_url && <Logo /> }

                        { !dark_mode && logo_wide_url && <img src={ logo_wide_url } alt={ name } title={ name } /> }
                        { !dark_mode && !logo_wide_url && <Logo /> }
                    </div>
                </div>
                <h4>{ school.name }</h4>
                { !school.utc_approved_on && !school.utc_declined_on &&
                <div className='-label -secondary'>aguardando</div> }

                { school.utc_approved_on && <div className='-label -success'>aprovado</div> }
                { school.utc_declined_on && <div className='-label -error'>recusado</div> }

                { school.cellphone &&
                <p><i translate='no' className='material-icons notranslate'>phone</i> { prettify.phone(school.cellphone) }</p> }

                { school.email &&
                <p><i translate='no' className='material-icons notranslate'>alternate_email</i> { school.email }</p> }

                <p><i translate='no' className='material-icons notranslate'>link</i> { url.replace('https://', '').replace('http://', '')}</p>

                <a href='/' onClick={ (ev) => {
                    ev.preventDefault();
                    if (global.confirm('Tem certeza?')) {
                        this.removeSchool(school);
                    }
                } } className='remove-school-template'>
                    Remover afiliado
                </a>
            </div>
        )
    }

    async removeSchool(school) {
        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post('remove-school-template', {
                school_id: school.id
            });

            this.loadSchools();
        } catch (ex) {
            global.error(ex);
        }
    }

    _findDegree(id) {
        const templateCategories = this.props
            .templateSchools
            .reduce((curr, s) => curr.concat(s.categories), []);

        const categories = this.props.school.categories
            .concat(templateCategories);

        for (const cat of categories) {
            if (!cat.degrees) { continue; }

            for (const degree of cat.degrees) {
                if (degree.id === id) { return degree; }
            }
        }

        throw Error(`Cannot find degree ${id}`);
    }

    degreeExists(id) {
        try {
            this._findDegree(id);
        } catch (ex) {
            return false;
        }

        return true;
    }

    getSelectedDegreesText() {
        const { all_degrees, degrees } = this.state.plan;

        if (all_degrees) {
            return 'Todos os cursos';
        }

        if (!degrees) { return '(nenhum curso)'; }

        if (degrees.length <= 3) {
            const degreeTitles = degrees
                .filter(this.degreeExists.bind(this))
                .map((id) => {
                const degree = this._findDegree(id);

                return degree.title;
            });

            const last = degreeTitles.pop();

            if (degreeTitles.length) {
                return `${degreeTitles.join(', ')} e ${last}`;
            } else {
                return last || '(nenhum curso)';
            }
        }

        return `${degrees.length} cursos`
    }

    _paginationUrl(page) {
        return url(`filiais-planos/${this.state.plan.id}/p/${page}`)
    }

    render() {
        return (
            <div className='view-school-list'>
                <h2
                    onInput={() => {}}
                    onFocus={() => {}}
                    onBlur={(ev) => {
                        const val = getInnerValueByEvent(ev);
                        this.setPlan({ title: val })
                    }}

                    contentEditable={true}
                    spellCheck={false}
                    dangerouslySetInnerHTML={
                        {
                            __html: DOMPurify.sanitize((this.state.plan && this.state.plan.title) || '')
                        }
                    }
                />
                <p className='-description'>
                    <span
                        onClick={() => {
                            this.refs.selectDegreesModal.show({
                                degrees: this.state.plan.degrees,
                                all_degrees: this.state.plan.all_degrees
                            });
                        }}
                        className='-editable'
                    >
                        { this.getSelectedDegreesText() }
                    </span>
                    {(this.state.plan.degrees && this.state.plan.degrees.length > 1) ||
                            this.state.plan.all_degrees ? ' estão disponíveis' : ' está disponível'} para o {this.state.plan.title}.
                </p>

                <div className='school-list-wrapper'>
                    <div onClick={ () => {
                        this.refs.modal.show();
                    } } className='-add'>
                        <i translate='no' className='material-icons notranslate'>add_box</i>
                        <p>Afiliar  Escola</p>
                    </div>

                    { this.state.schools.list.map(this.renderSchool.bind(this)) }

                </div>

                <PaginationComponent
                    onChange={ (p) => {
                        this.loadSchools(p);

                        return this._paginationUrl(p);
                    }}
                    titleFn={ (listTotal, total) => {
                        if (listTotal && listTotal === total) {
                            return `Mostrando ${total} escola${total > 1 ? 's' : ''} afiliada${total > 1 ? 's' : ''} `;
                        }

                        return `Mostrando ${listTotal} escola${listTotal > 1 ? 's' : ''} de ${total} afiliada${total > 1 ? 's' : ''}`;
                    }}
                    getUrl={ this._paginationUrl.bind(this) }
                    collection={ this.state.schools }
                />

                {
                    this.state.plan.id &&
                    this.state.schools.total === 0 &&
                    <div className='link-remove'>
                        <button
                            style={{
                                fontSize: '.8rem',
                                padding: '.4rem',
                                marginTop: '1.5rem'
                            }}
                            className='btn -remove'
                            onClick={(ev) => {
                                ev.preventDefault();

                                if (global.window.confirm('Tem certeza?')) {
                                    this.removePlan();
                                }
                            }}
                        >
                            Apagar plano
                        </button>
                    </div>
                }

                <AddTemplateModal ref='modal' onSubmit={ this.onSubmit.bind(this) } />
                <LoadingMask fullScreen={ true } isLoading={ this.state.isLoading } />
                <SelectDegreesModal
                    templateSchools={this.props.templateSchools}
                    school={this.props.school}
                    className={this.state.plan.title}
                    onSubmit={(values) => {
                        const all_degrees = values.all_degrees;
                        const degrees = all_degrees ? [] : values.degrees;

                        this.setPlan({ degrees, all_degrees })
                    }}
                    ref='selectDegreesModal'
                />
            </div>
        );
    }

    async onSubmit(values) {
        this.setState({ isLoading: true });

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`school-templates/${this.state.plan.id}/save`, values);
            this.refs.modal.hide();

            await this.loadSchools();
        } catch(ex) {
            global.error(ex);
        }
    }

    async loadSchools(page, planId) {
        try {
            page = page || this.props.match.params.page || this.state.page;
            planId = this.props.match.params.planId || this.state.plan.id || planId;

            const { plan } = await ajaxAdapter({
                isSchoolRequest: true
            }).get(`school-templates-plans/${planId}`);

            const { schools } = await ajaxAdapter({
                isSchoolRequest: true
            }).get(`school-templates/${planId}?page=${page}`);

            this.setState({
                page,
                isLoading: false,
                schools,
                plan
            });

        } catch(ex) {
            global.error(ex);
        }
    }

    setPlan(values, doSave = true) {
        const cls = { ...this.state.plan, ...values };

        this.setState({ plan: cls });
        if (doSave) { this.savePlan(cls); }
    }

    async savePlan(cls) {
        const planId = this.state.plan.id;

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`school-templates-plans/${planId}`, cls);

        } catch (ex) {
            global.error(ex);
        }
    }

    async createPlan() {
        const { plan } = await ajaxAdapter({
            isSchoolRequest: true
        }).post('school-templates-plans/create');

        window.history
            .replaceState(null,
                this.props.school.name,
                url('filiais-planos/', plan.id)
            );

        const SchoolTemplatesPlans = [...this.props.schoolTemplatesPlans];
        SchoolTemplatesPlans.push(plan);

        updateMemory({ SchoolTemplatesPlans });

        this.setState({ isLoading: false, plan });
    }

    async removePlan() {
        const planId = this.state.plan.id;

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`school-templates-plans/${planId}/delete`);

            this.props.history.push(url('filiais-planos'));
        } catch (ex) {
            global.error(ex);
        }
    }

    async componentDidMount() {
        super.componentDidMount();

        try {
            const { planId } = this.props.match.params;

            if (!planId) {
                return await this.createPlan();
            }

            await this.loadSchools();
        } catch (ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(OtherSchools,
                                'user',
                                'school',
                                'schoolTemplatesPlans',
                                'templateSchools');
