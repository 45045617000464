import React from 'react';

import { Link } from 'react-router-dom';
import { MemoryComponent } from '../../memory';
import { url } from '../../utils';
import BaseView from '../BaseView';

import { PriceBasic, PriceMax, PriceTurbo } from '../../components/PriceItems';

class AdminLowBalance extends BaseView {

    render() {
        return (
            <div className='view-admin-bank base-view'>
                <h2>Saldo negativo</h2>
                <p className="-description">
                    O seu saldo atual é de: R$ { this.props.bank.balance }.
                    <br />
                    <React.Fragment>{ /contato/.test(window.location.pathname) ?
                    'Sua conta está bloqueada' :
                    'Evite que sua conta seja bloqueada' }</React.Fragment>, por favor,
                    &nbsp;adicione um saldo ou <Link to={ url('planos') }>mude de plano</Link>.
                </p>

                <div className='plans-wrapper'>
                    <PriceBasic onClick={ () => { this.props.history.push(url('add-saldo/basic')) } } />
                    <PriceTurbo onClick={ () => { this.props.history.push(url('add-saldo/turbo'))  } } />
                    <PriceMax onClick={ () => { this.props.history.push(url('add-saldo/max'))  } } />
                </div>
            </div>
        );
    }
}

export default MemoryComponent(AdminLowBalance, 'bank');
