import React from 'react';
import { Link } from "react-router-dom";
import BaseView from '../BaseView';

import { Logo } from '../../svgs';
import { url, validations } from '../../utils';
import IseiForm from '../../components/Form';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent } from '../../memory';
import IseiContext from '../../Context';

const recoverPasswordFields = {
    password: {
        type: 'password',
        icon: 'locker',
        placeholder: 'Preencha aqui a sua nova senha',
        autoComplete: 'new-password',
        selectOnFocus: true,

        getValidationError: validations.password
    },
    repeatPassword: {
        type: 'password',
        icon: 'locker',
        label: 'Repita a sua nova senha',
        placeholder: 'Preencha aqui a sua nova senha',
        autoComplete: 'new-password',
        selectOnFocus: true,

        getValidationError(value, values) {
            if (!value) {
                return 'Sua senha deve conter no mínimo 6 caracteres';
            }

            if (value !== values.password) {
                return 'As senhas devem ser iguais!';
            }

            return null;
        }
    },
};

class ViewSignIn extends BaseView {
    state = {
        school_name: '',
        user_name: '',
        email: '',
        password: '',
        disclosure: false,
        isLoading: false,
        timer: 5
    };

    async onSubmit(values) {
        try {
            const { token } = this.props.match.params;
            this.setState({
                isLoading: true,
                success: null,
                mustConfirmEmail: false,
                error: null,
                errorByField: {}
            })

            const postData = { ...values };
            await ajaxAdapter({
                isSchoolRequest: Boolean(this.props.school.id)
            }).post('set-new-password/' + token, postData);

            this.setState({ isLoading: false, isCreated: true });

            this.interval = setInterval(() => {
                const timer = --this.state.timer;
                this.setState({ timer });

                if (timer === 0) {
                    clearInterval(this.interval);
                    this.goToMySchool();
                }
            }, 1000);
        } catch (ex) {
            if (/Not found/.test(ex.message)) {
                alert('Link expirado!')
                this.setState({ isLoading: false, success: null, error: null, justUpdated: false });
                this.goToMySchool();
                return;
            }

            console.error(ex);

            let message = ex.message;

            this.setState({ isLoading: false, success: null, error: message, justUpdated: false });
        }
    }

    async goToMySchool() {
        await IseiContext.load(null, localStorage.getItem('lastActivateSchoolUrl') || url('/'));
        this.props.history.replace(localStorage.getItem('lastActivateSchoolUrl') || url('/'));
    }

    renderSchoolSignIn() {
        const { school, user } = this.props;
        const { dark_mode } = user;
        const { logo_wide_url, logo_dark_wide_url } = school;
        const name = school.name || 'isei';

        return (
            <div className='app custom-page -signin'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            { dark_mode && logo_dark_wide_url && <img src={ logo_dark_wide_url } alt={ name } title={ name } /> }
                            { dark_mode && !logo_dark_wide_url && <Logo /> }

                            { !dark_mode && logo_wide_url && <img src={ logo_wide_url } alt={ name } title={ name } /> }
                            { !dark_mode && !logo_wide_url && <Logo /> }
                        </div>
                    </header>

                    { this.state.isCreated &&
                        <main>
                            <h2 style={ { margin: 0 } }>Boa! ;)</h2>
                            <p className='-secondary'>A sua senha foi alterada com sucesso!<br /><br />Agora é só esperar {this.state.timer} segundo{ this.state.timer > 1 ? 's' : '' } para ser redirecionado ou <a
                            onClick={ (ev) => {
                                ev.preventDefault();
                                this.goToMySchool();
                            }}
                        href={ url('/') }>clicar aqui</a></p></main> }

                    { !this.state.isCreated &&
                    <main>
                        <p className='-secondary'>Olá, seja bem-vindo(a) a {name}!</p>
                        <p className='-secondary'>Para definir a sua nova senha, basta preencher os dados abaixo:</p>
                            { this.state.success && <div className='alert -success'>{this.state.success}</div>}
                            {this.state.error && !/duplicate/i.test(this.state.error) && <div className='alert -error'>{this.state.error}</div>}
                            {this.state.error && /duplicate/i.test(this.state.error) && <div className='alert -error'>
                                    E-mail já cadastrado, <Link to={ url('login') } >clique aqui</Link> para efetuar login.
                            </div>}
                            { this.state.mustConfirmEmail && <small className='text-muted mt-0 mb-3 d-block'>Caso ainda não tenha recebido o e-mail de confirmação, <a href='/' onClick={ (ev) => {
                                ev.preventDefault();

                                this.resendRegisterEmail();
                                this.setState({ error: null, mustConfirmEmail: false, success: 'E-mail enviado com sucess!'})
                            } }>clique aqui</a> para enviar novamente.</small>}
                        <IseiForm
                            values={ this.state.values }
                            onSubmit={ this.onSubmit.bind(this) }
                            fields={ recoverPasswordFields }
                            submitButton={ { label: `Definir nova senha` } } />
                    </main> }
                </div>

                <footer>
                    <ul>
                        <li className='copyright'>
                            { this.props.school.name || 'iSei' } &copy; { new Date().getFullYear() } 
                        </li>
                        <li>
                            <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                        </li>
                    </ul>
                </footer>
                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    render() {
        const { school } = this.props;

        if (school.id) {
            return this.renderSchoolSignIn();
        }

        return (
            <div className='app custom-page -signin'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            <Logo />
                        </div>
                    </header>
                    { this.state.isCreated &&
                        <main>
                            <h2 style={ { margin: 0 } }>Boa! ;)</h2>
                            <p className='-secondary'>A sua senha foi alterada com sucesso!<br /><br />Agora é só esperar {this.state.timer} segundo{ this.state.timer > 1 ? 's' : '' } para ser redirecionado ou <a
                            onClick={ (ev) => {
                                ev.preventDefault();
                                this.goToMySchool();
                            }}
                        href={ url('/') }>clicar aqui</a></p></main> }

                    { !this.state.isCreated &&
                    <main>
                        <p className='-secondary'>Olá, seja bem-vindo(a)!</p>
                        <p className='-secondary'>Para definir a sua nova senha, basta preencher os dados abaixo:</p>
                            { this.state.success && <div className='alert -success'>{this.state.success}</div> }
                            { this.state.error && <div className='alert -error'>{this.state.error}</div> }
                            { this.state.mustConfirmEmail && <small className='text-muted mt-0 mb-3 d-block'>Caso ainda não tenha recebido o e-mail de confirmação, <a href='/' onClick={ (ev) => {
                                ev.preventDefault();

                                this.resendRegisterEmail();
                                this.setState({ error: null, mustConfirmEmail: false, success: 'E-mail enviado com sucess!'})
                            } }>clique aqui</a> para enviar novamente.</small>}
                        <IseiForm
                            values={ this.state.values }
                            onSubmit={ this.onSubmit.bind(this) }
                            fields={ recoverPasswordFields }
                            submitButton={ { label: `Definir nova senha` } } />
                    </main> }

                </div>

                <footer>
                    <ul>
                        <li className='copyright'>
                            iSei &copy; 2020
                        </li>
                        <li>
                            <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                        </li>
                    </ul>
                </footer>
                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }
}

export default MemoryComponent(ViewSignIn, 'school', 'user');
