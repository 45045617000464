import React from 'react';
import { updateMemory, MemoryComponent } from '../../memory';
import { capitalize, url } from '../../utils';
import BaseView from '../BaseView';
import ajaxAdapter from '../../ajaxAdapter';
import CardWidget from '../../components/CardWidget';

import { PriceBasic, PriceMax, PriceTurbo } from '../../components/PriceItems';
import { PlanOnDemand, PlanMiddle, PlanSuper } from '../../components/PriceItems';
import LoadingMask from '../../components/LoadingMask';

const cardTypeImages = {
    visa: require('../../assets/credit-cards/visa.png'),
    mastercard: require('../../assets/credit-cards/mastercard.png'),
    jcb: require('../../assets/credit-cards/jcb.png'),
    discover: require('../../assets/credit-cards/discover.png'),
    diners_club: require('../../assets/credit-cards/diners_club.png'),
    amex: require('../../assets/credit-cards/amex.png')
}

class AdminAddBank extends BaseView {
    state = { isShowingWidget: false, isLoading: false, paymentMethods: [ ] };

    async onSubmit(paymentMethod, saveCard) {
        try {
            const { type } = this.props.match.params;

            const { resource, bank } = await ajaxAdapter({
                isSchoolRequest: true
            }).post('do-payment', {
                paymentMethod,
                item: type,
                saveCard
            });

            const updateData = { bank };
            if (resource) { updateData.resource = resource; }

            updateMemory(updateData);

            this.setState({ paid: true, isLoading: false });
        } catch(ex) {
            this.setState({ isLoading: false });
            global.error(ex)

            if (!this.isShowingWidget && this.state.paymentMethods.length) {
                await this.loadPaymentMethods();
            }
        }
    }

    async cancelSubscription() {
        try {
            this.setState({ isLoading: true });

            const { resource, bank } = await ajaxAdapter({
                isSchoolRequest: true
            }).post('cancel-subscription');

            const updateData = { bank };
            if (resource) { updateData.resource = resource; }

            updateMemory(updateData);

            this.setState({ canceld: true, isLoading: false });
        } catch(ex) {
            this.setState({ isLoading: false });
            global.error(ex)
        }
    }

    async loadPaymentMethods() {
        try {
            this.setState({ isLoading: true });
            const { paymentMethods } = await ajaxAdapter({
                isSchoolRequest: true
            }).get('payment-methods')

            this.setState({
                isLoading: false,
                paymentMethods: paymentMethods,
                isShowingWidget: paymentMethods.length === 0
            })
        } catch (ex) {
            this.setState({ isLoading: false });
            global.error(ex)
        }

    }

    async componentDidMount() {
        await this.loadPaymentMethods();
    }

    renderPaymentMethod(paymentMethod) {
        return (<div key={ paymentMethod.id } className='payment-method'>
            <div className='-brand'>
                <img 
                    src={ cardTypeImages[paymentMethod.card_type]}
                    alt={ capitalize(paymentMethod.card_type) } />
            </div>

            <div className='-number'>
                <p>XXXX-XXXX-XXXX-{paymentMethod.last_4_digits}</p>
            </div>
            <button onClick={ async () => {
                this.setState({ isLoading: true })
                await this.onSubmit(paymentMethod);
            } } className='btn -secondary'>
                Usar cartão
            </button>
        </div>);
    }

    renderPaid() {
        return <div className='checkout-wrapper base-view'>
            <div className='paid-wrapper'>
                <header>
                    <i translate='no' className='material-icons notranslate'>check_circle</i>
                    <h2>Pagamento efetuado com sucesso!</h2>
                </header>
                <main>
                    <p>
                        <strong>Seu pagamento foi efetuado com sucesso!</strong><br />
                        Os seus créditos e bônus já estão disponíveis.
                    </p>
                    <button onClick={ () => {
                        this.props.history.push(url('/'));
                    } } className='btn -primary'>
                        Voltar para home
                    </button>
                </main>
            </div>
        </div>;
    }

    renderCanceled() {
        return <div className='checkout-wrapper base-view'>
            <div className='paid-wrapper'>
                <header>
                    <i translate='no' className='material-icons notranslate'>check_circle</i>
                    <h2>Assinatura cancelada</h2>
                </header>
                <main>
                    <p>
                        <strong>A sua assinatura mensal foi cancelada com sucesso.</strong><br />
                    </p>
                    <button onClick={ () => {
                        this.props.history.push(url('/'));
                    } } className='btn -primary'>
                        Voltar para home
                    </button>
                </main>
            </div>
        </div>;
    }

    render() {
        if (this.state.paid) { return this.renderPaid(); }
        if (this.state.canceld) { return this.renderCanceled(); }

        const { type } = this.props.match.params;

        const onClickKeepPlan = () => {
            this.props.history.push(url('/'));
        };

        return <div className='checkout-wrapper base-view'>
            <div className='card-widget-wrapper'>
                { type === 'onDemand' && <React.Fragment>
                <header>
                    <h2 style={ { borderBottom: 0 } }>Mudar para o plano onDemand</h2>
                </header>
                </React.Fragment> }
                { type !== 'onDemand' && <React.Fragment>
                <header>
                    <h2 style={ { borderBottom: 0 } }>Adicionar saldo</h2>
                    <img
                        alt='Visa e Mastercard'
                        src={ require('../../assets/accepted-cards.png')} />
                </header>
                <p>Informe o seu cartão de crédito abaixo para finalizar a compra:</p>
                </React.Fragment> }

                <main>
                    { type !== 'onDemand' && <React.Fragment>
                        { this.state.isShowingWidget &&
                        <CardWidget onSubmit={ ({ paymentMethod }, doSaveCard) => {
                            return this.onSubmit(paymentMethod, doSaveCard);
                        }} /> }
                        { !this.state.isShowingWidget && <div className='payment-methods-wrapper'>
                            <button onClick={ () => {
                                this.setState({ isShowingWidget: true }); }
                            } className='btn -secondary add-card'>
                                Novo cartão
                            </button>
                            { this.state.paymentMethods.map(this.renderPaymentMethod.bind(this))}
                        </div>}
                    </React.Fragment> }
                    { type === 'onDemand' && <div className='on-demand-wrapper' >
                        <p className='-red'>
                            Você está prestes a <strong>cancelar</strong> o seu plano { this.props.resource.plan_name } para voltar ao plano on demand (pagar apenas o que usar).
                            <br />
                            <strong>Tem certeza?</strong>
                         </p>

                        <button onClick={ () => {
                            this.cancelSubscription()
                        } } className='btn -danger'>Cancelar plano</button>
                        <button onClick={ onClickKeepPlan } className='btn -secondary'>Continuar com { this.props.resource.plan_name }</button>
                    </div> }

                    { type === 'onDemand' && 
                        <div className='plans-wrapper'>
                            { this.props.resource.plan_name === 'Middle' &&
                                <PlanMiddle btnLabel='continuar com o plano' onClick={ onClickKeepPlan } /> }
                            { this.props.resource.plan_name === 'Super' &&
                                <PlanSuper btnLabel='continuar com o plano' onClick={ onClickKeepPlan } /> }
                        </div> }

                    { type !== 'onDemand' && 
                    <div className='plans-wrapper'>
                        { type === 'basic' && <PriceBasic /> }
                        { type === 'turbo' && <PriceTurbo /> }
                        { type === 'max' && <PriceMax /> }
                        { type === 'onDemand' && <PlanOnDemand /> }
                        { type === 'middle' && <PlanMiddle /> }
                        { type === 'super' && <PlanSuper /> }
                    </div>}
                </main>
            </div>

            <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
        </div>
    }
}

export default MemoryComponent(AdminAddBank, 'gatewayList', 'students', 'school', 'bank', 'resource');
