import React from 'react';
import { Logo, LoadingCircles } from '../svgs';
import { MemoryComponent } from '../memory';

class LoadingMask extends React.Component {
    render() {
        const { show, fullScreen } = this.props;
        const gatewayList = [ 'loading-mask' ];

        gatewayList.push(show ? '-loading' : '-hide');
        if (fullScreen) { gatewayList.push('-fullscreen'); }

        if (window.isFromSchoolLoad ||
            Number(this.props.bank.balance) > 0 ||
                (this.props.resource.plan_id &&
                    this.props.resource.plan_id !== 'onDemand')
        ) {
            return (
                <div className={ gatewayList.join(' ') }>
                    <div className='logo -loading' style={ { textAlign: 'center' } }>
                        <LoadingCircles />
                    </div>
                </div>);
        }

        return (
            <div className={ gatewayList.join(' ') }>
                <div className='logo -loading'>
                    <Logo />
                </div>
            </div>
        );
    }
}

export default MemoryComponent(LoadingMask, 'bank', 'resource');
