import React from 'react';
import { Logo } from '../../svgs';
import config from '../../config';
import ajaxAdapter from '../../ajaxAdapter';
import { updateMemory, MemoryComponent } from '../../memory';
import { copyToClipboard, prettify } from '../../utils';
import LoadingMask from '../../components/LoadingMask';
import BaseView from '../BaseView';

class InstructionsModal extends React.Component {
    state = {
        isModalOpen: false,
        school_gateway_id: null
    };

    render() {
        if (!this.state.isModalOpen) { return null; }
        return (
            <div className={`modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}`}>
                <div className='-content'>
                    <div className='-body'>
                        <p>Agora você tem tudo em mãos e falta poucos passos para a integração!</p>
                        <p>
                            <br />
                            <strong>Primeiro</strong> você precisa gerar um <a
                            href='https://www.devmedia.com.br/como-o-jwt-funciona/40265'
                            rel="noopener noreferrer"
                            target='_blank'>jwt token</a> usando a sua <strong>KEY</strong> como valor do objeto e usar o seu <strong>
                            SECRET</strong> como senha desse token (<a
                                href='https://gist.github.com/Deividy/e5db04ef0bcc7cfb6374b1c68a43495c#file-01_jwt-token-node-js'
                                rel="noopener noreferrer" target='_blank'>veja aqui um exemplo</a>)
                            <br />
                            Com esse token, você tem acesso a nossa api e para começar a usar você precisa pedir um hash de autenticação que tem válidade de 1 hora.
                            <br /><br />
                            Esse hash é pedido enviando um request para o nosso endpoint: <a
                            onClick={ (ev) => {
                                ev.preventDefault();

                                const link = `https://api.isei.app/integration/${ this.props.school.id }/hash`;
                                copyToClipboard(link);
                            } }
                            href={ `https://api.isei.app/integration/${ this.props.school.id }/hash` }>https://api.isei.app/integration/{ this.props.school.id }/hash</a> passando o header de Authorization com o valor do JWT gerado anteriormente&nbsp;
                            (<a href='https://gist.github.com/Deividy/e5db04ef0bcc7cfb6374b1c68a43495c#file-02_get-token-sh' target='_blank' rel="noopener noreferrer">veja aqui um exemplo</a>)
                            <br /><br />
                            Agora, basta enviar um request POST para o endpoint: <a
                            href={ `https://api.isei.app/integration/${ this.props.school.id }/${ this.state.hash }`} onClick={ (ev) => {
                                ev.preventDefault();

                                const link = `https://api.isei.app/integration/${ this.props.school.id }/${ this.state.hash }`;
                                copyToClipboard(link);
                            } } >https://api.isei.app/integration/{ this.props.school.id }/{ this.state.hash }</a> usando o header Authorization
                            com o hash recebido no último passo e de dados do POST um objeto JSON contendo os atributos <strong>name</strong> e <strong>email</strong>. (<a
                                href='https://gist.github.com/Deividy/e5db04ef0bcc7cfb6374b1c68a43495c#file-03_student-api-sh'
                                rel="noopener noreferrer" target='_blank'>veja aqui um exemplo</a>)

                            <br /><br />
                            Caso esse request seja bem sucedido iremos adicionar esse aluno na turma <strong>{ this.state.integration_title }</strong> (caso ele ainda não esteja) e retornaremos uma resposta no formato JSON
                            com o atributo token, esse token pode ser usado para efetuar um login para o usuário, para isso basta redirecionar o seu aluno para a seguinte url:
                            <br />
                            <a onClick={ (ev) => {
                                ev.preventDefault();

                                const link = `${ global.baseUiUrl }/login/`;
                                copyToClipboard(link);
                            } } href='/'>{ global.baseUiUrl }/login/o_token_do_aluno_aqui</a>
                            <br /> <br />
                            Tokens de acesso para alunos são expirados apenas quando for pedido um novo token para o aluno.
                        </p>
                        <div className='-footer'>
                            <button type='button' onClick={() => {
                                this.setState({ isModalOpen: false });
                            }} className='btn -secondary'>Fechar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            school_gateway_id: null,
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class SelectClassModal extends React.Component {
    state = {
        isModalOpen: false,
        school_gateway_id: null
    };

    render() {
        if (!this.state.isModalOpen) { return null; }
        return (
            <div className={`modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}`}>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}
                        <p>Selecione a turma para criar a sua integração:</p>
                        <select value={ this.state.school_gateway_id } onChange={ (ev) => {
                            this.setState({ school_gateway_id: Number(ev.nativeEvent.target.value) });
                         } } >
                            <option></option>
                            { this.props.gatewayList.map((c) => <option
                                value={ c.id }>{ c.title }</option> )}
                        </select>
                        <div className='-footer'>
                            { this.state.school_gateway_id &&
                                <button type='button' onClick={this._submit.bind(this)} className='btn -primary'>Salvar</button>
                            }
                            <button type='button' onClick={() => {
                                this.setState({ isModalOpen: false });
                            }} className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit() {
        try {
            this.props.onSubmit({ ...this.state });
            this.hide();
        } catch (ex) {
            console.error(ex);
            this.setState({ error: ex.message, justUpdated: false });
        }

    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            school_gateway_id: null,
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class SchoolApi extends BaseView {
    state = { isLoading: true, integrations: [ ] };

    renderSchool(school) {
        const { dark_mode } = this.props.user;
        const { logo_wide_url, logo_dark_wide_url } = school;

        let url = `${config.baseAppUrl}/escola/${school.id}`

        if (school.namespaces.length) {
            const ns = school.namespaces[0];

            if (ns.domain) {
                url = `https://${ns.domain}`;
            } else {
                url = `${config.baseAppUrl}/${ns.namespace}`;
            }
        }

        const name = school.name;

        return (
            <div key={ school.id } onClick={() => { window.open(url); }} className='-school'>
                <div className='-logo-wrapper -wide'>
                    <div className='content logo'>
                        { dark_mode && logo_dark_wide_url && <img src={ logo_dark_wide_url } alt={ name } title={ name } /> }
                        { dark_mode && !logo_dark_wide_url && <Logo /> }

                        { !dark_mode && logo_wide_url && <img src={ logo_wide_url } alt={ name } title={ name } /> }
                        { !dark_mode && !logo_wide_url && <Logo /> }
                    </div>
                </div>
                <h4>{ school.name }</h4>
                { school.roles.map((r) => <div className={ `-label role-${r}` }>{
                    r === 'student' ? 'estudante' : 'gerente'
                }</div>)}
                { school.cellphone &&
                <p><i translate='no' className='material-icons notranslate'>phone</i> { prettify.phone(school.cellphone) }</p> }

                { school.email &&
                <p><i translate='no' className='material-icons notranslate'>alternate_email</i> { school.email }</p> }

                <p><i translate='no' className='material-icons notranslate'>link</i> { url.replace('https://', '').replace('http://', '')}</p>
            </div>
        )
    }

    render() {
        return (
            <div className='view-school-list'>
                <h2>Interface de acesso (API)</h2>
                <p className='-description'>
                    Aqui você pode configurar a sua interface de acesso e integrações <i>one click</i> com a sua plataforma atual
                </p>

                { !this.props.school.api_key &&
                    <React.Fragment>
                        <p className='-description'>
                            Para gerar a sua chave de acesso, clique no botão abaixo:
                        </p>

                        <button onClick={ () => {
                            this.genApiKey();
                        } } className='btn btn-primary'>Criar chave de acesso</button>
                    </React.Fragment> }

                { this.props.school.api_key &&
                    <React.Fragment>
                        <p className='-description'>
                            Anote a sua chave e senha de acesso abaixo:
                        </p>
                        <p>
                            <strong>KEY</strong><br />
                            { this.props.school.api_key }

                            <br />
                            <strong>SECRET</strong><br />
                            { this.props.school.api_secret }

                        </p>

                        <button onClick={ () => {
                            if (global.confirm('Tem certeza?\nEssa ação invalidará a sua chave anterior.')) {
                                this.genApiKey();
                            }
                        } } className='btn -secondary'>Criar nova chave de acesso</button>

                        <br /><br />
                        { this.state.integrations.length === 0 &&
                            <React.Fragment>
                                <p>Agora basta criar uma integração para uma turma clicando no botão abaixo e selecionando a turma:</p>

                            <button onClick={ () => {
                                this.refs.selectClassModal.show();
                            } } className='btn -primary'>Criar integração</button>
                            </React.Fragment>
                        }

                        { this.state.integrations.length >  0 &&
                            <React.Fragment>
                                <p>Confira as suas integrações abaixo e clique no botão instruções para os detalhes finais de cada integração:</p>

                                <button onClick={ () => {
                                    this.refs.selectClassModal.show();
                                } } className='btn -secondary'>Criar nova integração</button>

                                <div className='grid-wrapper' style={ { marginTop: '1rem' } }>
                                    <table className='students-table'>
                                        <thead>
                                            <tr>
                                                <th style={ { width: 100 } } className='-hash'>Hash</th>
                                                <th className='-name'>Turma</th>
                                                <th className='-actions'>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.integrations.map((s) =>
                                                <tr key={ s.id }>
                                                    <td className='-hash'>{s.hash}</td>
                                                    <td className='-name'>{s.integration_title}</td>
                                                    <td className='-actions' style={ { textAlign: 'right' } }>
                                                        <button onClick={ () => {
                                                            this.refs.InstructionsModal.show(s);
                                                        } } className='btn -secondary'>
                                                            Instruções
                                                        </button>

                                                        &nbsp; &nbsp;

                                                        <button onClick={ () => {
                                                            if (global.confirm('Tem certeza?')) {
                                                                this.deleteIntegration(s);
                                                            }
                                                        } } className='btn -secondary'>
                                                            <i style={ { margin: 0, fontSize: '1.2rem' } } translate='no' className='material-icons notranslate'>delete</i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </React.Fragment> }

                    </React.Fragment> }

                <SelectClassModal
                    gatewayList={ this.props.gatewayList }
                    ref='selectClassModal'
                    onSubmit={ this.onSubmit.bind(this) } />

                <InstructionsModal school={ this.props.school } ref='InstructionsModal' />
                <LoadingMask fullScreen={ true } isLoading={ this.state.isLoading } />
            </div>
        );
    }

    async deleteIntegration(integration) {
        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).delete(`api/integrations/${integration.id}/delete`);

            this.loadIntegrations();
        } catch (ex) {
            global.error(ex);
        }
    }

    async genApiKey() {
        super.componentDidMount();
        try {
            const { key, secret } = await ajaxAdapter({
                isSchoolRequest: true
            }).post('api/key/gen');

            const school = { ...this.props.school };

            school.api_key = key;
            school.api_secret = secret;

            updateMemory({ school });
        } catch(ex) {
            global.error(ex);
        }

    }

    async onSubmit({ school_gateway_id }) {
        try {
            const { integrations } = await ajaxAdapter({
                isSchoolRequest: true
            }).post('api/integrations/save', { school_gateway_id });

            this.setState({ integrations });
        } catch (ex) {
            global.error(ex);
        }
    }

    async loadIntegrations() {
        try {
            const { integrations } = await ajaxAdapter({
                isSchoolRequest: true
            }).get('api/integrations');

            this.setState({ integrations });
        } catch(ex) {
            global.error(ex);
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.loadIntegrations();
    }
}

export default MemoryComponent(SchoolApi, 'school', 'gatewayList');
