import React from 'react';
import moment from 'moment';
import Dropzone from 'react-dropzone'

import BaseView from '../BaseView';
import ajaxAdapter from '../../ajaxAdapter';

import { Link } from 'react-router-dom';
import { ReactSortable } from "react-sortablejs";
import IseiQuill from '../../components/IseiQuill';
import {
    debounce, getBestModuleNumber, prettify, url, getInnerValueByEvent, saveDegree
} from '../../utils';

import { MemoryComponent, updateMemory } from '../../memory';
import LoadingMask from '../../components/LoadingMask';
import CropImageModal from '../../components/CropImageModal';
import DOMPurify from 'dompurify';

function getId() { return String(new Date().getTime()); }

function getHeight(length, ratio) {
    let height = ((length) / (Math.sqrt((Math.pow(ratio, 2) + 1))));
    height = Math.round(height);

    return isFinite(height) ? height : 0;
}

const Switch = ({ min, id, isOn, handleToggle }) => {
    return (
        <div className={ min ? 'input-switch -min' : 'input-switch' }>
            <input
                checked={isOn || false}
                onChange={handleToggle}
                id={`field_switch_${id} `}
                type="checkbox" />

            <label className={ isOn ? '-active' : '' } htmlFor={`field_switch_${id} `}>
                <span />
            </label>
        </div>
    );
}

function formatInputHours (d) {
    d = d.replace('meia', '30 minutos');

    let hr = 0;

    if (/hr|hora|hrs|horas|h/.test(d)) {
        const hparts = d.split(/hr|hora|hrs|horas|h/);

        hr =  Number(hparts[0]);
    }

    const mparts = d.split(/\D+/).filter((n) =>
        n && !isNaN(Number(n))).map((n) => Number(n));

    let min;

    if (mparts.length === 2) {
        min = mparts[1];

        if (!hr) {
            hr = mparts[0];
        }
    } else if (!hr) {
        min = mparts[0];
    }

    if (min >= 60) {
        const hours = min / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);

        hr += rhours;
        min = rminutes;
    }

    if (hr && min) {
        return `${hr} hora${hr > 1 ? 's' :'' } e ${min} minuto${min > 1 ? 's' :''}`;
    }

    if (!hr && min && !/minutos|min|m/.test(d)) {
        hr = min;
        min = null;
    }

    if (min) {
        return `${min} minuto${min > 1 ? 's' :''}`;
    }

    if (hr) {
        return `${hr} hora${hr > 1 ? 's' :'' }`;
    }

    return '';
}

function getMinutesFromInputHours (str) {
    const parts = str.split(/\D+/).filter((n) => n && !isNaN(Number(n)));

    let hr = 0;
    let minute = 0;

    if (parts.length === 1) {
        if (/hora/.test(str)) {
            hr = Number(parts[0]);
        } else {
            minute = Number(parts[0]);
        }
    } else {
        hr = Number(parts[0]);
        minute = Number(parts[1]);
    }

    const minutes = minute + (60 * hr);

    return minutes;
}

class AddDegree extends BaseView {
    state = {
        collapsedModules: {},
        id: null,
        isDraft: false,
        title: 'Insira aqui o nome do curso',
        workload_hours: '',
        description_title: 'Descrição do curso',
        description: '<p>Insira aqui a descrição para o seu curso</p>',
        link: '',
        no_dripping: true,

        modules: [
            { isAddButton: true },
            {
                id: getId(),
                title:'Módulo 1',
                content: [
                    { isAddButton: true }
                ]
            }
        ],

        is_private:  false,
        utc_published_on: null,
        is_visible: true,
        hide_to_affiliates: false,

        error: null,
        success: null,

        isLoading: false
    };

    async componentDidMount() {
        super.componentDidMount();

        const { link } = this.props.match.params;

        if (!link) {
            const degree = {
                id: null,
                isDraft: false,
                title: 'Insira aqui o nome do curso',
                workload_hours: '',
                description_title: 'Descrição do curso',
                description: '<p>Insira aqui a descrição para o seu curso</p>',
                link: '',
                no_dripping: true,

                modules: [
                    { isAddButton: true },
                    {
                        id: getId(),
                        title:'Módulo 1',
                        content: [
                            { isAddButton: true }
                        ]
                    }
                ],

                is_private:  true,
                utc_published_on: null,
                is_visible: true,
                hide_to_affiliates: false,

                error: null,
                success: null,

                isLoading: false

            };

            updateMemory({ degree: degree })
            return;
        }

        const degree = this.props.tryFindDegree(link);
        if (degree && degree.modules) {
            updateMemory({ degree });
            return;
        }

        if (this.props.degree && this.props.degree.id) { return; }

        try {
            this.setState({ isLoading: true })
            const res = await ajaxAdapter({ isSchoolRequest: true }).get('degree/' + link);

            if (!res.degree) {
                this.props.history.replace(url('cursos'));
                return;
            }

            const c = { ...res.degree };

            if (!c.modules[0] || !c.modules[0].isAddButton) {
                c.modules.unshift({ isAddButton: true });
                this.saveDraft(c);
            }

            updateMemory({ degree: c });
            this.setState({
                isLoading: false
            })
        } catch(ex) {
            console.error(ex);
            this.setState({ error: ex.message, isLoading: false })
        }
    }

    isPointIn(x, y, cls) {
        const el = document.elementFromPoint(x, y);

        if (el.className.includes(cls)) {
            return true;
        }

        if (el.parentNode.className.includes(cls)) {
            return true;
        }

        return false;
    }

    updateModule(id, values) {
        const modules = [ ...this.props.degree.modules ];

        for (let idx = 0; idx < modules.length; ++idx) {
            const m = modules[idx]

            if (String(m.id) !== String(id)) { continue; }
            modules[idx] = { ...m, ...values };
        }

        this.setDegree({ modules });
        return modules;
    }

    toggleCollapsedModule(mod) {
        const collapsedModules = { ...this.state.collapsedModules };
        collapsedModules[mod.id] = !collapsedModules[mod.id];

        this.setState({ collapsedModules });
    }

    isModuleCollapsed(mod) {
        return this.state.collapsedModules[mod.id];
    }

    renderModule(m) {
        if (m.isAddButton) {
            if (this.state.isDraggingModule) {
                return (
                    <button key='addModule' className='add-module -remove'>
                        <i translate='no' className='material-icons notranslate'>delete</i>
                        Arraste aqui para remover módulo
                    </button>
                );
            }

            return (
                <button key='addModule' onClick={ () => {
                    const modules = [ ...this.props.degree.modules ];

                    modules.push({
                        id: getId(),
                        title:'Módulo ' + getBestModuleNumber(modules),
                        content: [
                            { isAddButton: true }
                        ]
                    });

                    const s = { ...this.props.degree, modules };

                    updateMemory({ degree: s })
                    this.saveDraft(s);

                } } className='add-module'>
                    <i translate='no' className='material-icons notranslate'>add_box</i>
                    Criar Módulo
                </button>
            );
        }

        return (
            <div className='module -drag' key={ m.id }>
                <header>
                    <i
                        onClick={ (ev) => {
                            this.toggleCollapsedModule(m);
                        }}

                        translate='no' className='material-icons notranslate -expander'>{
                            this.isModuleCollapsed(m) ? 'expand_more' : 'expand_less'
                        }</i>
                    <h4
                        onInput={ (ev) => {

                        }}
                        onFocus={ (ev) => {

                        }}
                        onBlur={ (ev) => {
                            const val = getInnerValueByEvent(ev);
                            const modules = this.updateModule(m.id, { title: val })
                            this.saveDraft({ modules });
                        }}

                        contentEditable={ true }
                        spellCheck={ false }
                        dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(m.title) }} />
                            { !this.props.degree.no_dripping &&
                                <div style={ { marginBottom: 0, marginRight: 10 } } className='field-wrapper -switch'>
                                    <label>
                                        <span>sempre disponível &nbsp;</span>
                                    <Switch
                                        min={ true }
                                        id={ `alwaysAvailable_${m.id}` }
                                        isOn={ m.alwaysAvailable || false }
                                        handleToggle={() => {
                                            const alwaysAvailable = !m.alwaysAvailable;
                                            const modules = this.updateModule(m.id, { alwaysAvailable })
                                            this.saveDraft({ modules });
                                        }} />
                                    </label>
                                </div> }

                    <i translate='no' className='material-icons notranslate -dragger'>drag_indicator</i>
                </header>
                { !this.isModuleCollapsed(m) &&
                <ReactSortable
                    className='content-list'
                    group='content'
                    easing='cubic-bezier(1, 0, 0, 1)'
                    animation={200}
                    dragoverBubble={true}
                    delayOnTouchStart={true}
                    delay={2}
                    list={m.content}
                    draggable='.-drag'
                    ghostClass='-ghost'
                    handle='.-dragger'
                    onStart={ (ev) => {
                        this.setState({ isDraggingClass: true });
                    }}

                    onEnd={ (ev) => {
                        this.setState({ isDraggingClass: false });

                        const x = ev.originalEvent.clientX;
                        const y = ev.originalEvent.clientY;

                        const isRemove = this.isPointIn(x, y, 'add-content');

                        if (isRemove && window.confirm('Tem certeza?')) {
                            const id = String(ev.item.dataset.id);
                            const modules = [ ...this.props.degree.modules ];

                            for (const mod of modules) {
                                if (!mod.content) { continue; }

                                let idx = mod.content.length;
                                while (idx--) {
                                    const content = mod.content[idx];

                                    if (String(content.id) === id) {
                                        mod.content.splice(idx, 1);
                                    }
                                }
                            }

                            const s = { ...this.props.degree, modules };

                            updateMemory({ degree: s });
                            this.saveDraft(s);

                            return;
                        }

                        this.saveDraft();
                    }}
                    setList={(list) => {
                        const modules = [...this.props.degree.modules];

                        modules.forEach((c, idx) => {
                            if (c.id === m.id) {
                                modules[idx] = { ...c };
                                modules[idx].content = list
                            }
                        });

                        this.setDegree({ modules });
                    }} >
                    {m.content
                        .filter((c) => !/new_/.test(c.id))
                        .map((c) => this.renderContent(m, c) ) }
                </ReactSortable> }
            </div>);
    }

    async publishDegree() {
        this.setState({ isLoading: true });

        await this.saveDraft();
        this.setState({ isLoading: true });

        try {
            const u = `degree/${this.props.degree.link}/publish`;
            const res = await ajaxAdapter({ isSchoolRequest: true })
                .post(u, {
                    id: this.props.degree.id
                });

            updateMemory({
                school: res.school,
                degree: res.degree
            });

            this.setState({ isLoading: false });
        } catch(ex) {
            console.error(ex);
            this.setState({ isLoading: false, error: ex.message });
        }
    }

    async deleteDegree() {
        try {
            const res = await ajaxAdapter({ isSchoolRequest: true })
                .post('degree/delete', { id: this.props.degree.id })

            res.school.deletedDegreeId = this.props.degree.id;

            updateMemory({
                school: res.school,
                degree: null,
                content: null
            })

            if (this._isMounted) {
                this.props.history.replace(url('cursos'));
             }

            return res.degree;
        } catch(ex) {
            console.error(ex);
            this.setState({ error: ex.message });
        }
    }

    async unpublishDegree() {
        try {
            const u = `degree/${this.props.degree.link}/publish`;
            const res = await ajaxAdapter({ isSchoolRequest: true })
                .post(u, { id: this.props.degree.id, unpublish: true })

            updateMemory({ school: res.school, degree: { ...res.degree } });

            return res.degree;
        } catch(ex) {
            console.error(ex);
            this.setState({ error: ex.message });
        }
    }

    async clearDraft() {
        try {
            const u = `degree/${this.props.degree.link}/clear-draft`;
            const res = await ajaxAdapter({ isSchoolRequest: true })
                .post(u, { id: this.props.degree.id })

            updateMemory({ school: res.school, degree: { ...res.degree } });

            return res.degree;
        } catch(ex) {
            console.error(ex);
            this.setState({ error: ex.message });
        }

    }

    async saveDraft(s = this.props.degree) {
        this.isSavingDraft = true;

        const { categoryId } = this.props.match.params;
        const draft = {
            ...this.props.degree,
            ...s,
            categoryId
        };

        delete draft.draft;
        delete draft._base;

        const hadId = Boolean(this.props.degree.id || s.id);

        try {
            delete draft.currentValue;

            const { link } = this.props.match.params;
            if (!this.props.degree.id && link) { return; }

            const res = await saveDegree(draft, this.props.degree);

            this.isSavingDraft = false;
            let degree = { ...this.props.degree, id: res.degree.id, link: res.degree.link };

            if (hadId) {
                degree.draft = draft;
                degree.isDraft = true;
            }

            updateMemory({ school: res.school, degree });

            if (this._isMounted) {
                if (!hadId) {
                    window.history.replaceState(null, res.school.name, url('c/' + res.degree.link));
                }

                this.setState({ isLoading: false });
             }

            return { ...this.props.degree, ...res.degree };
        } catch(ex) {
            this.isSavingDraft = false;

            console.error(ex);
            this.setState({ error: ex.message });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getWorkloadText() {
        const { isEditingWorkload } = this.state;
        const { workload_hours, workload_hours_pretty } = this.props.degree;

        if (isEditingWorkload) {
            return workload_hours_pretty || '';
        }

        if (!workload_hours_pretty && workload_hours) {
            return formatInputHours(String(workload_hours))
        }

        return workload_hours_pretty || 'definir carga';
    }

    getLandingPageText() {
        const { landing_page_url } = this.props.degree;
        const { isEditingLandingPage } = this.state;

        if (isEditingLandingPage) {
            return landing_page_url || '';
        }

        return landing_page_url || 'própria da isei';
    }

    renderRemoveIfNecessary(field) {
        return null;
    }

    setDegree(values = {}) {
        let v = { ...this.props.degree };

        if (v.isDraft) {
            v.draft = { ...v.draft, ...values };
        } else {
            v = { ...this.props.degree, ...values };
        }

        updateMemory({ degree: v });
    }

    setDrippingConfig(obj) {
        const dripping_config = {
            ...this.props.degree.dripping_config,
            ...obj
        };

        this.setDegree({ dripping_config });
        this.saveDraft({ dripping_config });

        this.setState({
            isDropDownDrippingWhatOpen: false,
            isDropDownDrippingWhenOpen: false
        });
    }

    render() {
        const { link } = this.props.match.params;
        this._isMounted = true;

        if ((link && !this.props.degree.id)) {
            return <LoadingMask fullScreen={ true } show={ true  } />
        }

        const ratio = (29/9);
        const height = getHeight(document.querySelector('.view-degree > header')?.offsetWidth,ratio);

        const dripping_config = this.props.degree.dripping_config || {
            what: 'modules',
            when: 'week'
        };

        const schoolId = this.props.degree.school_id;
        if (schoolId && schoolId !== this.props.school.id) {
            this.props.history.replace(url(`c/${this.props.degree.link}?student=1`));
        }

        return (
            <div className='view-degree -admin'>
                { this.state.error && <div className='alert -error'>{ this.state.error}</div> }
                { this.state.success && <div className='alert -success'>{ this.state.success}</div> }

                <header style={ { height } }>
                    { this.props.degree.img_wide_url && <div className='-background'>
                        <div className='overlay' />
                        <img alt={ this.props.degree.title } src={ this.props.degree.img_wide_url } />
                    </div>}
                    <h2
                        style={ { minWidth: 250, textAlign: 'center' } }
                        onFocus={ (ev) => {
                            const val = ev.nativeEvent.target.innerHTML.trim();
                            if (val === 'Insira aqui o nome do curso') {
                                this.setDegree({ title: '<br>' })
                            }
                        }}
                        onBlur={ (ev) => {
                            const val = getInnerValueByEvent(ev);

                            if (!val) {
                                this.setDegree({ title: 'Insira aqui o nome do curso' });
                                return;
                            }

                            this.setDegree({ title: val });
                            this.saveDraft({ title: val });
                        }}

                        spellCheck={ false }
                        dangerouslySetInnerHTML={ {
                            __html: DOMPurify.sanitize(this.props.degree.title)
                        } }
                        contentEditable={ true } />


                <Dropzone accept='image/jpeg, image/png' onDrop={ (files) => {
                    const reader = new global.FileReader();

                    reader.onloadend = () => {
                        this.refs.cropImageModal.show({
                            src: reader.result,
                            fileName: files[0].name,
                            blobField: 'imgWideUrl',
                            imageField: 'img_wide_url',
                            format: 'wide'
                        });
                    }

                    reader.readAsDataURL(files[0]);

                    if (!this.props.degree.id) { this.saveDraft(); }
                }}>
                    {({ getRootProps, getInputProps }) => (
                        <React.Fragment>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            <button className='btn -send-file'>
                                <i translate='no' className='material-icons notranslate'>cloud_upload</i>
                                <span>Enviar Capa do Curso</span>
                            </button>
                            { this.renderRemoveIfNecessary('img_wide_url') }
                        </div>
                        </React.Fragment>
                    )}
                </Dropzone>
                </header>

                <main>
                    <div className='modules'>
                        <ReactSortable
                            animation={200}
                            easing='cubic-bezier(1, 0, 0, 1)'
                            dragoverBubble={true}
                            delayOnTouchStart={true}
                            delay={2}
                            group='module'
                            ghostClass='-ghost'
                            handle='.-dragger'
                            draggable='.-drag'
                            list={ this.props.degree.modules }

                            onStart={ (ev) => {
                                this.setState({ isDraggingModule: true });
                            }}

                            onEnd={ (ev) => {
                                this.setState({ isDraggingModule: false });

                                const x = ev.originalEvent.clientX;
                                const y = ev.originalEvent.clientY;

                                const isRemove = this.isPointIn(x, y, 'add-module');

                                if (isRemove && window.confirm('Tem certeza?')) {
                                    const modules = [ ...this.props.degree.modules ];

                                    const s = {
                                        ...this.props.degree,
                                        modules: modules.filter((m) =>
                                            String(m.id) !== String(ev.item.dataset.id))
                                    }

                                    this.setDegree(s);
                                    this.saveDraft(s);
                                    return;
                                }

                                this.saveDraft();
                            }}
                            setList={ (list) => {
                                this.setDegree({ modules: list });
                            } } >
                                { this.props.degree.modules.map(this.renderModule.bind(this)) }
                        </ReactSortable>
                    </div>

                    <div className='side -right'>
                        <section className='-description'>
                            <h4
                                spellCheck={ false }
                                onBlur={ (ev) => {
                                    const val = getInnerValueByEvent(ev);

                                    this.setDegree({ description_title: val });
                                    this.saveDraft({ description_title: val });
                                }}

                                dangerouslySetInnerHTML={ {
                                    __html: DOMPurify.sanitize(this.props.degree.description_title)
                                } }
                                contentEditable={ true } />

                            <IseiQuill
                                onFocus={ (ev) => {
                                    if (this.props.degree.description === '<p>Insira aqui a descrição para o seu curso</p>') {
                                        this.setDegree({ description: '<p></p>' });
                                    }

                                }}
                                onBlur={ (ev) => {
                                    if (this.props.degree.description.trim().replace(/\s/g, '') === '<p><br></p>') {
                                        this.setDegree({ description: '<p>Insira aqui a descrição para o seu curso</p>' });
                                        this.saveDraft({ description: '<p>Insira aqui a descrição para o seu curso</p>' });
                                        this.isSavingDraft = false;
                                        return;
                                    }

                                    this.saveDraft();
                                }}
                                value={ this.props.degree.description }
                                onChange={ (val) => {
                                    this.setDegree({ description: val });
                                }} />

                                <br />
                                <div className='magic-input'>
                                    <p>Carga horária: <span
                                        style={ {
                                            minWidth: 200,
                                            cursor: this.state.isEditingWorkload ? 'text' : 'pointer'
                                        } }
                                        onFocus={(ev) => {
                                            this.setState({
                                                isEditingWorkload: true,
                                            });

                                            this.setDegree({
                                                workload_hours_pretty: '',
                                                last_workload_hours_pretty: this.props.degree.workload_hours_pretty
                                            })
                                        }}
                                        onBlur={(ev) => {
                                            const d = getInnerValueByEvent(ev);

                                            if (!d) {
                                                this.setState({
                                                    isEditingWorkload: false,
                                                });
                                                this.setDegree({
                                                    workload_hours_pretty: this.props.degree.last_workload_hours_pretty || ''
                                                });
                                                return;
                                            }

                                            const s = formatInputHours(d);

                                            this.setState({ isEditingWorkload: false });
                                            this.setDegree({
                                                workload_hours_pretty: s,
                                                workload_hours: getMinutesFromInputHours(s)
                                            })

                                            this.saveDraft({
                                                workload_hours_pretty: s,
                                                workload_hours: getMinutesFromInputHours(s)
                                            });
                                        }}

                                        contentEditable={ true }
                                        spellCheck={ false }
                                        dangerouslySetInnerHTML={ {
                                            __html: DOMPurify.sanitize(this.getWorkloadText())
                                        }}
                                        className='-editable -dotted' /></p>
                                </div>

                                { this.props.degree.is_private &&

                                <div className='magic-input'>
                                    <p>Landing page: <span
                                        style={ {
                                            minWidth: 200,
                                            cursor: this.state.isEditingLandingPage ? 'text' : 'pointer'
                                        } }
                                        onFocus={(ev) => {
                                            this.setState({
                                                isEditingLandingPage: true,
                                            });

                                            this.setDegree({
                                                landing_page_url: '',
                                                last_landing_page_url: this.state.landing_page_url
                                            })
                                        }}
                                        onBlur={(ev) => {
                                            const d = getInnerValueByEvent(ev);

                                            if (!d) {
                                                this.setState({
                                                    isEditingLandingPage: false
                                                });
                                                return;
                                            }

                                            const link = prettify.removeDiacritics(d).replace(/\s/g, '-').toLowerCase();

                                            this.setState({
                                                isEditingLandingPage: false,
                                            });

                                            this.setDegree({
                                                landing_page_url: link
                                            })
                                            this.saveDraft({
                                                landing_page_url: link
                                            });
                                        }}

                                        contentEditable={ true }
                                        spellCheck={ false }
                                        dangerouslySetInnerHTML={ {
                                            __html: DOMPurify.sanitize(this.getLandingPageText()) }}
                                        className='-editable -dotted' /></p>
                                </div> }

                            <div className='-actions'>
                                <div key='' className='field-wrapper -switch'>
                                    <label>
                                    <Switch
                                        id='is_private'
                                        isOn={ !this.props.degree.is_private }
                                        handleToggle={() => {
                                            const isPrivate = !this.props.degree.is_private;
                                            this.setDegree({ is_private: isPrivate });
                                            this.saveDraft({ is_private: isPrivate });
                                        }} />

                                        <span>Curso público</span>
                                    </label>
                                </div>

                                <div key='is_free' className='field-wrapper -switch'>
                                    <label>
                                        <Switch
                                            id='is_visible'
                                            isOn={ !this.props.degree.is_visible }
                                            handleToggle={() => {
                                                const isVisible = !this.props.degree.is_visible;

                                                this.setDegree({ is_visible: isVisible });
                                                this.saveDraft({ is_visible: isVisible });
                                            }} />

                                        <span>Não listado</span>
                                    </label>
                                </div>

                                { this.props.school.has_affiliation &&
                                <div key='hide_to_affiliates' className='field-wrapper -switch'>
                                    <label>
                                        <Switch
                                            id='hide_to_affiliates'
                                            isOn={ this.props.degree.hide_to_affiliates }
                                            handleToggle={() => {
                                                const hide_to_affiliates = !this.props.degree.hide_to_affiliates;

                                                this.setDegree({ hide_to_affiliates });
                                                this.saveDraft({ hide_to_affiliates });
                                            }} />

                                        <span>Não exibir para filiais</span>
                                    </label>
                                </div> }

                                <div key='has_comments' className='field-wrapper -switch'>
                                    <label>
                                        <Switch
                                            id='has_comments'
                                            isOn={ this.props.degree.has_comments }
                                            handleToggle={() => {
                                                const hasComments = !this.props.degree.has_comments;

                                                this.setDegree({ has_comments: hasComments });
                                                this.saveDraft({ has_comments: hasComments });
                                            }} />

                                        <span>Permitir comentários</span>
                                    </label>
                                </div>

                                <div key='no_dripping' className='field-wrapper -switch'>
                                    <label>
                                        <Switch
                                            id='no_dripping'
                                            isOn={ this.props.degree.no_dripping }
                                            handleToggle={() => {
                                                const noDripping = !this.props.degree.no_dripping;

                                                if (!this.props.degree.dripping_config) {
                                                    this.setDrippingConfig(dripping_config);
                                                }

                                                this.setDegree({ no_dripping: noDripping });
                                                this.saveDraft({ no_dripping: noDripping });
                                            }} />

                                        <span>Liberar todas aulas de uma vez</span>
                                    </label>
                                </div>

                                { !this.props.degree.no_dripping &&
                                <div className='dripping-opts magic-input'>
                                    <p>
                                        Liberar <span onClick={ () => {
                                            this.setState({
                                                isDropDownDrippingWhatOpen:
                                                    !this.state.isDropDownDrippingWhatOpen
                                            });
                                        } } className='-editable -dotted'>
                                            { dripping_config.what === 'modules' ? 'módulos' : 'aulas' }
                                            <ul className={
                                                this.state.isDropDownDrippingWhatOpen ?
                                                    '-dropdown -open' :
                                                    '-dropdown'
                                                }>
                                                <li onClick={ () => {
                                                    this.setDrippingConfig({ what: 'modules' });
                                                } } >módulos</li>
                                                <li onClick={ () => {
                                                    this.setDrippingConfig({ what: 'school_gateways' });
                                                } } >aulas</li>
                                            </ul>
                                        </span> a cada <span onClick={ () => {
                                            this.setState({
                                                isDropDownDrippingWhenOpen:
                                                    !this.state.isDropDownDrippingWhenOpen
                                            });
                                        } } className='-editable -dotted'>
                                            { dripping_config.when === 'day' ?
                                                'dia' : dripping_config.when === 'week' ?
                                                    'semana' : 'mês' }

                                            <ul className={
                                                this.state.isDropDownDrippingWhenOpen ?
                                                    '-dropdown -open' :
                                                    '-dropdown'
                                                }>
                                                <li onClick={ () => {
                                                    this.setDrippingConfig({ when: 'day' });
                                                } } >dia</li>

                                                <li onClick={ () => {
                                                    this.setDrippingConfig({ when: 'week' });
                                                } } >semana</li>
                                                <li onClick={ () => {
                                                    this.setDrippingConfig({ when: 'month' });
                                                } } >mês</li>
                                            </ul>
                                        </span> quando iniciado.
                                    </p>
                                </div> }
                            </div>
                        </section>

                        <section className='-notifications'>
                            { !this.props.degree.id && <div className='-draft'>Criando novo curso...</div> }
                            { this.props.degree.isDraft && this.props.degree.utc_published_on && <div style={ { cursor: 'pointer' } } onClick={ (ev) => {
                                ev.preventDefault();
                                if (global.window.confirm('Tem certeza que deseja descartar as alterações?')) {
                                    this.clearDraft();
                                }

                            } } className='-draft'>Editando rascunho (clique para descartar)</div> }
                            { !this.props.degree.isDraft && this.props.degree.utc_published_on && <div className='-published'>
                                Publicado em { moment.utc(this.props.degree.utc_published_on).format('LL' )}
                            </div> }

                            { !this.props.degree.isDraft && !this.props.degree.utc_published_on && this.props.degree.utc_created_on && <div className='-draft'>
                                Criado em { moment.utc(this.props.degree.utc_created_on).format('LL' )}
                            </div> }
                        </section>

                        <section className='-buttons'>
                            { this.props.degree.id && (this.props.degree.isDraft || !this.props.degree.utc_published_on) &&
                            <button onClick={ debounce(this.publishDegree.bind(this), 100) } type='submit' className='btn -primary'>
                                <i translate='no' className='material-icons notranslate'>thumb_up</i>
                                <span>Publicar { this.props.degree.utc_published_on ? 'alterações' : 'curso' }</span>
                            </button> }
                        </section>

                        <section className='-links'>
                            { !this.props.degree.utc_published_on && this.props.degree.id &&
                                <a onClick={ (ev) => {
                                    ev.preventDefault();

                                if (global.window.confirm(`Tem certeza que deseja apagar esse curso?
                                \nTODOS os dados relacionados serão apagados para sempre!`)) {
                                    this.deleteDegree();
                                }

                            } } href='/'>Apagar curso e todos os dados relacionados</a> }
                            { this.props.degree.utc_published_on &&
                                <a onClick={ (ev) => {
                                    ev.preventDefault();

                                if (global.window.confirm('Tem certeza?')) {
                                    this.unpublishDegree();
                                }
                            } } href='/'>Despublicar curso</a> }
                        </section>
                    </div>
                </main>

                <CropImageModal
                    onSubmit={async (croppedImage) => {
                        const newState = { ...this.props.degree };
                        newState[croppedImage.imageField] = croppedImage.fileUrl;

                        this.setDegree(newState)

                        try {
                            croppedImage.blob.isImage = true;

                            const res = await ajaxAdapter({ isSchoolRequest: true }).postFormData('degree/img', {
                                id: this.props.degree.id,
                                field: croppedImage.imageField,
                                file: croppedImage.blob
                            })

                            updateMemory({ school: res.school })
                        } catch(ex) {
                            global.error(ex);
                        }
                    }}
                    ref='cropImageModal' />

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    async goToAddClass(mod) {
        let { link } = this.props.degree;

        if (!link) {
            link = (await this.saveDraft()).link;
        }

        if (this.isSavingDraft) {
            this.setState({ isLoading: true });

            return setTimeout(() => {
                this.goToAddClass(mod);
            }, 250);
        }

        this.setState({ isLoading: false });
        this.props.history.push(url(`c/${link}/${mod.id}/nova-aula`));
    }

    renderContent(mod, content) {
        content = {
            ...content,
            ...content.draft,
            isDraft: JSON.stringify(content.draft) !== '{}' ||
                (this.props.degree.utc_published_on && !content.utc_published_on)
        };

        if (content.isAddButton) {
            if (this.state.isDraggingClass) {
                return (<div key='addContent' className='add-content -remove'>
                    <i translate='no' className='material-icons notranslate'>delete</i>
                    <span >Arraste aqui para remover essa aula</span>
                </div>);
            }
            return (<div key='addContent' onClick={ () => {
                this.goToAddClass(mod);
            } } className='add-content'>
                <i translate='no' className='material-icons notranslate'>add_box</i>
                <span>Criar Aula</span>
            </div>);
        }

        return (
            <div key={ content.id } className='-content -drag'>
                <Link to={ url(`c/${this.props.degree.link}/${content.link}`) }>{ content.title }</Link>
                { content.files && content.files.length > 1 && <i translate='no' className='material-icons notranslate -draft'>attachment</i> }
                { content.main_media_url && <i translate='no' className='material-icons notranslate -tv'>slideshow</i> }
                { content.main_media_duration && <span className='-duration'>
                    { (content.main_media_duration / 60).toFixed(2).replace('.', ':') }
                </span> }
                { !content.isDraft && content.utc_published_on &&
                    <i translate='no' className='material-icons notranslate -publish'>check_circle</i>}

                { (!content.utc_published_on || content.isDraft) && <i translate='no' className='material-icons notranslate -draft'>text_snippet</i> }

                <i translate='no' className='material-icons notranslate -dragger'>drag_indicator</i>
            </div>
        );
    }
}

export default MemoryComponent(AddDegree, 'school', 'degree');
