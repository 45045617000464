import React from 'react';
import BaseView from '../BaseView';

import ajaxAdapter from '../../ajaxAdapter';
import { prettify, url } from '../../utils';

import { MemoryComponent, updateMemory } from '../../memory';
import LoadingMask from '../../components/LoadingMask';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DOMPurify from 'dompurify';

function getHeight(length, ratio) {
    var height = ((length) / (Math.sqrt((Math.pow(ratio, 2) + 1))));
    return Math.round(height);
}

function formatInputHours (d) {
    d = d.replace('meia', '30 minutos');

    let hr = 0;

    if (/hr|hora|hrs|horas|h/.test(d)) {
        const hparts = d.split(/hr|hora|hrs|horas|h/);

        hr =  Number(hparts[0]);
    }

    const mparts = d.split(/\D+/).filter((n) =>
        n && !isNaN(Number(n))).map((n) => Number(n));

    let min;

    if (mparts.length === 2) {
        min = mparts[1];

        if (!hr) {
            hr = mparts[0];
        }
    } else if (!hr) {
        min = mparts[0];
    }

    if (min >= 60) {
        const hours = min / 60;
        const rhours = Math.floor(hours);
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.round(minutes);

        hr += rhours;
        min = rminutes;
    }

    if (hr && min) {
        return `${hr} hora${hr > 1 ? 's' :'' } e ${min} minuto${min > 1 ? 's' :''}`;
    }

    if (!hr && min && !/minutos|min|m/.test(d)) {
        hr = min;
        min = null;
    }

    if (min) {
        return `${min} minuto${min > 1 ? 's' :''}`;
    }

    if (hr) {
        return `${hr} hora${hr > 1 ? 's' :'' }`;
    }

    return '';
}


class AddDegree extends BaseView {
    state = {
        error: null,
        success: null,
        isLoading: false
    };

    async componentDidMount() {
        super.componentDidMount();

        const { link } = this.props.match.params;

        if (this.props.degree.id && this.props.degree.link === link) {
            updateMemory({ degree: this.props.degree })
            return;
        }

        try {
            this.setState({ isLoading: true })
            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).get('degree/student/' + link);

            if (!res.degree) {
                this.props.history.replace(url('cursos'));
                return;
            }

            updateMemory({ degree: res.degree })
            this.setState({ isLoading: false });
        } catch(ex) {
global.error(ex)
            this.setState({ error: ex.message, isLoading: false })

            this.props.history.replace(url('cursos'));

        }
    }

    renderModule(m) {
        if (m.isAddButton) {
            return null;
        }

        return (
            <div className='module' key={ m.id }>
                <header>
                    <h4 dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(m.title) }} />
                </header>
                <div className='content-list'>
                    {m.content.map((c) => this.renderContent(c) ) }
                </div>
            </div>);
    }

    getWorkloadText() {
        const { workload_hours, workload_hours_pretty, isEditingWorkload } = this.state;

        if (isEditingWorkload) {
            return workload_hours_pretty || '';
        }

        if (!workload_hours_pretty && workload_hours) {
            return formatInputHours(String(workload_hours))
        }

        return workload_hours_pretty;
    }

    hasAccess() {

    }

    hasTitle() {
        const c = this.props.degree;

        return c.description_title !== 'Descrição do curso';
    }

    hasDescription() {
        const c = this.props.degree;

        return c.description !== '<p>Insira aqui a descrição para o seu curso</p>';
    }

    render() {
        const { link } = this.props.match.params;
        const { degree } = this.props;

        if ((link && !degree.id) || (link !== degree.link) ) {
            return <LoadingMask fullScreen={ true } show={ true  } />
        }

        const ratio = (29/9);
        let height = getHeight(document.querySelector('.view-degree > header')?.offsetWidth,ratio);

        if (isNaN(height)) { height = 0; }

        return (
            <div className='view-degree -admin'>
                { this.state.error && <div className='alert -error'>{ this.state.error}</div> }
                { this.state.success && <div className='alert -success'>{ this.state.success}</div> }

                <header style={ { height }}>
                    { degree.img_wide_url && <div className='-background'>
                        <div className='overlay' />
                        <img alt={ degree.title } src={ degree.img_wide_url } />
                    </div>}
                    <h2 style={ { borderBottom: 0 } }
                        dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(degree.title) } } />
                </header>

                <main>

                    { !this.hasAccessToDegree() && <div className='no-access-wrapper'>
                        <div>
                            <h2>Acesso não permitido :(</h2>
                            <div className='-message'>
                                <i translate='no' className='material-icons notranslate'>lock</i>
                                <p>
                                    Desculpe-nos, mas você não possuí acesso a esse curso.
                                    <br />
                                    Para acessar, por favor entre em contato com <strong>{ this.props.school.name }</strong>
                                </p>
                            </div>
                            { this.props.school.assistant_name ? this.props.school.assistant_name + ', ' : '' } <a href={ `mailto:${ this.props.school.email }`}>{ this.props.school.email }</a> - { prettify.phone(this.props.school.cellphone) }
                        </div>
                    </div>}

                    { this.hasAccessToDegree() &&
                    <div className='modules'>
                        { degree.modules.map(this.renderModule.bind(this)) }
                    </div> }

                    <div className='side -right'>
                        <section className='-description'>
                            { this.hasDescription() && <h4 dangerouslySetInnerHTML={ {
                                __html: DOMPurify.sanitize(degree.description_title) } } /> }
                            { this.hasDescription() && <div dangerouslySetInnerHTML={ {
                                __html: DOMPurify.sanitize(degree.description) } } /> }

                            { !this.hasDescription() && <div>
                                <strong>{ degree.title }</strong>
                                <p>Curso de: { this.props.school.name }</p>

                                </div>}

                            { degree.workload_hours && this.getWorkloadText() &&
                                <React.Fragment>
                                    <br />
                                    <div className='magic-input'>
                                        <p>Carga horária: {this.getWorkloadText()}</p>
                                    </div>
                                </React.Fragment> }

                            { this.hasAccessToDegree() &&
                                <div className='progress-wrapper'>
                                    <div className="progress-bar-wrapper">
                                        <div className="progress-bar">
                                            <span className="progress-bar-fill" style={ { width: `${this.getProgress()}%` } }></span>
                                        </div>
                                    </div>

                                    <div className='label'>{ Number(this.getProgress()).toFixed(0) }%</div>
                            </div> }

                        </section>

                        <section className='-notifications'>
                        </section>

                        { this.hasAccessToDegree() &&
                        <section className='-buttons'>
                            { this.isDegreeInitialized() && !this.isDegreeDone() &&
                                <button onClick={ () => {
                                    this.goToNextClass();
                                } } type='submit' className='btn -primary'>
                                    <i translate='no' className='material-icons notranslate'>play_circle_filled</i>
                                    <span>Continuar de onde parei</span>
                                </button>  }

                            { !this.isDegreeInitialized() && !this.isDegreeDone() &&
                            <button onClick={ () => {
                                this.goToNextClass();
                            } } type='submit' className='btn -primary'>
                                <i translate='no' className='material-icons notranslate'>thumb_up</i>
                                <span>Iniciar curso</span>
                            </button> }

                            { this.isDegreeDone() && <React.Fragment>
                                <div className='degree-done'>
                                    Curso finalizado <i translate='no' className='material-icons notranslate'>emoji_events</i>
                                </div>

                                { this.props.degree.certificate && <div onClick={ () => {
                                    window.open(this.props.degree.certificate.pdf);
                                } } style={ { cursor: 'pointer', marginTop: '.5rem' } } className='degree-certificate'>
                                    Ver certificado <i translate='no' className='material-icons notranslate'>picture_in_picture</i>
                                </div> }
                            </React.Fragment> }
                        </section> }

                        <section className='-links'>
                        </section>
                    </div>
                </main>
            </div>
        );
    }

    getFirstContent() {
        for (const mod of this.props.degree.modules) {
            if (!mod.content) { continue; }

            for (const content of mod.content) {
                if (content.link) {
                    return content;
                }
            }
        }

        return null;
    }

    _urlFromContent(content) {
        return url(`c/${this.props.degree.link}/${content.link}`);
    }

    findNextContent(firstContent) {
        let hasFoundContent = false;

        for (const mod of this.props.degree.modules) {
            if (!mod.content) { continue; }

            for (const content of mod.content) {
                hasFoundContent = hasFoundContent || content.id === firstContent.id;

                if (hasFoundContent && content.link) {
                    return content;
                }
            }
        }

        return null;
    }

    goToNextClass() {
        const cfg = this.props.acl.degrees[this.props.degree.id]?.metadata || {};
        const contentCfg = (cfg || {}).content || {};

        for (const mod of this.props.degree.modules) {
            if (!mod.content) { continue; }

            for (const content of mod.content) {
                if (!content.link) { continue; }

                if (!contentCfg[content.id]) {
                    this.props.history.push(this._urlFromContent(content));
                    return true;
                }

                if (!contentCfg[content.id].isComplete) {
                    this.props.history.push(this._urlFromContent(content));
                    return true;
                }
            }
        }

        return false;
    }

    renderContent(content) {
        if (content.isAddButton) {
            return null;
        }

        return (
            <div key={ content.id } className='-content -drag'>
                <Link to={ this._urlFromContent(content) }>{ content.title }</Link>

                { !content.dripped &&
                    <React.Fragment>
                        { content.files && content.files.length > 1 && <i translate='no' className='material-icons notranslate -draft'>attachment</i> }
                        { content.main_media_url && <i translate='no' className='material-icons notranslate -tv'>slideshow</i> }
                        { content.main_media_duration && <span className='-duration'>
                            { (content.main_media_duration / 60).toFixed(2).replace('.', ':') }
                        </span> }
                        <i className={
                            this.isComplete(content.id) ? 'material-icons notranslate -done' : 'material-icons notranslate -not-done'
                        }>check_circle</i>
                    </React.Fragment> }

                { content.dripped &&
                    <React.Fragment>
                        <span className='-duration'>
                            { moment.utc(content.availableWhen).fromNow() }
                        </span>
                        <i translate='no' className='material-icons notranslate'>schedule</i>
                    </React.Fragment> }
            </div>
        );
    }

    isComplete(contentId = this.props.content.id) {
        const degrees = { ...this.props.acl.degrees }
        const cfg = degrees[this.props.degree.id] || { metadata: { } };
        const contentCfg = (cfg.metadata?.content || {})[contentId] || {};

        return contentCfg.isComplete;
    }

    isSchoolAdmin() {
        return this.props.school.role && this.props.school.role.includes('admin');
    }

    hasAccessToDegree() {
        if (this.isSchoolAdmin()) {
            return true;
        }

        const { degree } = this.props;

        const degreeId = degree.id;
        const { school_gateways } = this.props.acl;

        return (school_gateways.hasPublicAccess && !degree.is_private) ||
                school_gateways.hasAllAccess ||
                school_gateways.allDegrees?.includes(degreeId);

    }

    isDegreeInitialized() {
        return Boolean(this.props.acl.degrees[this.props.degree.id]);
    }

    isDegreeBookmark() {
        return this.props.bookmarks.find((b) => b.degree_id === this.props.degree.id && !b.content_id);
    }

    isDegreeDone() {
        return this.getProgress() === '100.00';
    }

    getProgress() {
        const degrees = { ...this.props.acl.degrees }
        const cfg = degrees[this.props.degree.id] || { metadata: { } };

        return (cfg && cfg.progress) || '0.00';
    }
}

export default MemoryComponent(AddDegree, 'school', 'degree', 'acl');
