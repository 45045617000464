// by https://github.com/rossturner/HTML5-ImageUploader
// http://tiny.cc/fjl58y
class ImageUtils {
    constructor(config) {
        this.config = config;
    }

    scaleImage(img, type, callback) {
        let canvas = global.document.createElement('canvas');
        let ctx = canvas.getContext('2d');

        let width = canvas.width = img.width;
        let height = canvas.height = img.height;

        ctx.save();

        const opts = {};

        switch (opts.orientation) {
            case 3:
                ctx.translate(width, height);
                ctx.rotate(Math.PI);
                break;
            case 6:
                ctx.rotate(0.5 * Math.PI);
                ctx.translate(0, -height);
                break;

            case 8:
                ctx.rotate(-0.5 * Math.PI);
                ctx.translate(-width, 0);
                break;

            default: break;
        }

        ctx.drawImage(img, 0, 0);
        ctx.restore();

        while (canvas.width >= (2 * this.config.maxWidth)) {
            canvas = this.getHalfScaleCanvas(canvas);
        }

        if (canvas.width > this.config.maxWidth) {
            canvas = this.scaleCanvasWithAlgorithm(canvas);
        }

        function onBlob(blob) {
            const res = { blob, width: canvas.width, height: canvas.height }; 
            canvas.remove();

            callback(res);
        }

        canvas.toBlob(onBlob, type, this.config.quality);
    }

    scaleCanvasWithAlgorithm(canvas) {
        let scaledCanvas = global.document.createElement('canvas');

        let scale = this.config.maxWidth / canvas.width;

        scaledCanvas.width = canvas.width * scale;
        scaledCanvas.height = canvas.height * scale;

        let srcImgData = canvas.getContext('2d')
            .getImageData(0, 0, canvas.width, canvas.height);
        let destImgData = scaledCanvas.getContext('2d')
            .createImageData(scaledCanvas.width, scaledCanvas.height);

        this.applyBilinearInterpolation(srcImgData, destImgData, scale);

        scaledCanvas.getContext('2d').putImageData(destImgData, 0, 0);

        canvas.remove();
        return scaledCanvas;
    }

    getHalfScaleCanvas(canvas) {
        let halfCanvas = global.document.createElement('canvas');

        halfCanvas.width = canvas.width / 2;
        halfCanvas.height = canvas.height / 2;

        halfCanvas.getContext('2d')
            .drawImage(canvas, 0, 0, halfCanvas.width, halfCanvas.height);

        canvas.remove();

        return halfCanvas;
    }

    applyBilinearInterpolation(srcCanvasData, destCanvasData, scale) {
        function wrap(f00, f10, f01, f11) {
            return { f00, f10, f01, f11 };
        }

        function inner(wrapped, x, y) {
            const { f00, f10, f01, f11 } = wrapped;
            let un_x = 1.0 - x;
            let un_y = 1.0 - y;
            return (f00 * un_x * un_y +
                f10 * x * un_y + f01 * un_x * y + f11 * x * y);
        }

        let i; let j;
        let iyv; let iy0; let iy1; let ixv; let ix0; let ix1;
        let idxD; let idxS00; let idxS10; let idxS01; let idxS11;
        let dx; let dy;
        let r; let g; let b; let a;
        for (i = 0; i < destCanvasData.height; ++i) {
            iyv = i / scale;
            iy0 = Math.floor(iyv);
            // Math.ceil can go over bounds
            iy1 = (Math.ceil(iyv) > (srcCanvasData.height - 1) ?
                (srcCanvasData.height - 1) : Math.ceil(iyv));

            for (j = 0; j < destCanvasData.width; ++j) {
                ixv = j / scale;
                ix0 = Math.floor(ixv);
                // Math.ceil can go over bounds
                ix1 = (Math.ceil(ixv) > (srcCanvasData.width - 1) ?
                    (srcCanvasData.width - 1) : Math.ceil(ixv));
                idxD = (j + destCanvasData.width * i) * 4;
                // matrix to vector indices
                idxS00 = (ix0 + srcCanvasData.width * iy0) * 4;
                idxS10 = (ix1 + srcCanvasData.width * iy0) * 4;
                idxS01 = (ix0 + srcCanvasData.width * iy1) * 4;
                idxS11 = (ix1 + srcCanvasData.width * iy1) * 4;
                // overall coordinates to unit square
                dx = ixv - ix0;
                dy = iyv - iy0;
                // I let the r, g, b, a on purpose for debugging
                r = inner(wrap(srcCanvasData.data[idxS00],
                    srcCanvasData.data[idxS10], srcCanvasData.data[idxS01],
                    srcCanvasData.data[idxS11]), dx, dy);
                destCanvasData.data[idxD] = r;

                g = inner(wrap(srcCanvasData.data[idxS00 + 1],
                    srcCanvasData.data[idxS10 + 1],
                    srcCanvasData.data[idxS01 + 1],
                    srcCanvasData.data[idxS11 + 1]), dx, dy);
                destCanvasData.data[idxD + 1] = g;

                b = inner(wrap(srcCanvasData.data[idxS00 + 2],
                    srcCanvasData.data[idxS10 + 2],
                    srcCanvasData.data[idxS01 + 2],
                    srcCanvasData.data[idxS11 + 2]), dx, dy);
                destCanvasData.data[idxD + 2] = b;

                a = inner(wrap(srcCanvasData.data[idxS00 + 3],
                    srcCanvasData.data[idxS10 + 3],
                    srcCanvasData.data[idxS01 + 3],
                    srcCanvasData.data[idxS11 + 3]), dx, dy);
                destCanvasData.data[idxD + 3] = a;
            }
        }
    }
}

export default ImageUtils;
