import React from 'react';
import { Link } from 'react-router-dom';

import BaseView from '../BaseView';
import { MemoryComponent, updateMemory } from '../../memory';
import moment from 'moment';
import PaginationComponent from '../../components/Pagination';
import { debounce, url } from '../../utils';
import ajaxAdapter from '../../ajaxAdapter';

const originDict = {
    manual: 'Adicionado manualmente',
    api: 'Cadastro API',
    'public-class': 'Conteúdo público',
    'school-admin': 'Admin da escola',
    'school-register': 'Dono da escola',
    importer: 'Importador de alunos',
    bravapay: 'BravaPay',
    hotmart: 'Hotmart',
    octapay: 'OctaPay',
    eduzz: 'Eduzz'
};

function getOriginText(origin) {
    return originDict[origin] || origin;
}

class AdminStudentList extends BaseView {
    state = {
        page: 1,
        students: { total: 0, page: 1, list: [] },
    };

    constructor(props) {
        super(props);

        this.onSearchStudentDebounce = debounce(this.onSearchStudent.bind(this), 200);
    }

    onSearchStudent() {
        this.loadStudents(1, this.state.search);
    }

    renderStudent(s) {
        return (
            <tr key={ s.user_id }>
                <td style={ { textAlign: 'center' } }>{ s.user_id }</td>
                <td>{ s.name }</td>
                <td>{ s.email }</td>
                <td>
                    { s.school_gateways && s.school_gateways.map((s) => s.title).join(', ') }
                </td>
                <td>{ getOriginText(s.origin) }</td>
                <td style={ { textAlign: 'center' } } >
                    { moment(s.utc_created_on).format('DD/MM/YYYY') }
                </td>
                <td style={ { textAlign: 'right' } }>
                    <Link to={ url(`aluno/${s.link}`) }>dados do aluno</Link>
                </td>
            </tr>
        );
    }

    _paginationUrl(page) {
        return url(`alunos/p/${page}`)
    }

    render() {
        return (
            <div className='view-student-list base-view'>
                <h2>Meus alunos</h2>

                { this.hadStudents && <form style={ {
                    marginTop: '.5rem', maxWidth: 500
                } } className='search'>
                    <div className='field-wrapper'>
                        <div className='field'>
                            <div className='icon'>
                                <i translate='no' className='material-icons notranslate'>search</i>
                            </div>
                            <div className='input'>
                                <input
                                    value={ this.state.search || '' }
                                    autoFocus={ true }
                                    maxLength={ 30 }
                                    onChange={ (ev) => {
                                        this.setState({
                                            search: ev.nativeEvent.target.value
                                        });
                                        this.onSearchStudentDebounce();
                                    } }
                                    placeholder='Pesquise por um aluno...'
                                    type='search' />
                            </div>
                        </div>
                    </div>
                </form> }

                { this.props.students.total === 0 && <React.Fragment>
                    { this.state.search &&
                        <p>Nenhum aluno encontrado.</p> }
                    { !this.state.search &&
                        <p>Você ainda não tem nenhum aluno matriculado em sua escola.</p> }
                </React.Fragment>}
                { this.props.students.total > 0 && <React.Fragment>
                { this.hasSearch &&
                    <section className='searchbar'>
                        <input className='-qs' type='text' placeholder='Busque por nome, e-mail, telefone, turma...' />
                    </section> }

                { this.props.students.list.length > 0 &&
                <div className='grid-wrapper' style={ { marginTop: '1rem' } }>
                    <table className='schols-table'>
                        <thead>
                            <tr>
                                <th style={ { width: 50 } } className='-id'>#</th>
                                <th className='-name'>Nome</th>
                                <th className='-email'>E-mail</th>
                                <th className='-school_gateways'>Turmas</th>
                                <th style={ { width: 180 } } className='-created'>Origem</th>
                                <th style={ { textAlign: 'center', width: 120 } } className='-created'>Membro desde</th>
                                <th style={ { width: 120 } } className='-actions'>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.students.list.map(this.renderStudent.bind(this))}
                        </tbody>
                    </table>
                </div> }

                <PaginationComponent
                    onChange={ (p) => {
                        this.loadStudents(p);

                        return this._paginationUrl(p);
                    }}
                    titleFn={ (listTotal, total) => {
                        if (listTotal && listTotal === total) {
                            return `Mostrando ${total} aluno${total > 1 ? 's' : ''} matriculado${total > 1 ? 's' : ''} `;
                        }

                        return `Mostrando ${listTotal} aluno${listTotal > 1 ? 's' : ''} de ${total} matriculado${total > 1 ? 's' : ''}`;
                    }}
                    getUrl={ this._paginationUrl.bind(this) }
                    collection={ this.props.students } />

                </React.Fragment> }
            </div>
        );
    }

    async componentDidMount() {
        await this.loadStudents();
    }

    async loadStudents(page, search = this.state.search) {
        try {
            page = page || this.props.match.params.page || this.state.page;

            const students = (await ajaxAdapter({
                isSchoolRequest: true
            }).get(`students?page=${page}&search=${search}`)).students;

            if (students.total > 0) {
                this.hadStudents = true;
            }

            updateMemory({ students })
        } catch(ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(AdminStudentList, 'students');
