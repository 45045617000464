import React from 'react';
import { url } from '../utils';

const Switch = ({ min, id, isOn, handleToggle }) => {
    return (
        <div className={ min ? 'input-switch -min' : 'input-switch' }>
            <input
                checked={isOn || false}
                onChange={handleToggle}
                id={`field_switch_${id} `}
                type="checkbox" />

            <label className={ isOn ? '-active' : '' } htmlFor={`field_switch_${id} `}>
                <span />
            </label>
        </div>
    );
};

class IseiForm extends React.Component {
    state = {
        errorByField: {},
        errors: [],
        focus: null,
        values: {}
    }

    _getValidationError() {
        const ebf = { };
        const errors = [];

        const addErrorForFields = (fields) => {
            for (const key in fields) {
                const f = fields[key];

                if (f.getValidationError && this.refs[key]) {
                    const err = f.getValidationError(
                        this.state.values[key], this.state.values, this
                    );

                    if (err) {
                        errors.push(err);
                        ebf[key] = err;
                    }
                }
            }
        }

        if (this.props.sections) {
            for (const section of this.props.sections) {
                addErrorForFields(section.fields);
            }
        } else {
            addErrorForFields(this.props.fields);
        }

        if (errors.length === 0) { return null; }

        return { errorByField: ebf, errors };
    }

    isValid() {
        return Boolean(!this._getValidationError());
    }

    setInvalidState() {
        const validationError = this._getValidationError();

        for (const key in validationError.errorByField) {
            validationError.focus = key;
            this.refs[key].focus();
            break;
        }

        this.setState(validationError);
    }

    onSubmit(ev) {
        ev.preventDefault();

        if (!this.isValid()) {
            return this.setInvalidState();
        }

        this.setState({ errorByField: {}, errors: [] });
        this.props.onSubmit(this.state.values);
    }

    setValue(key, value) {
        const values = { ...this.state.values };

        values[key] = value;
        this.setState({ values });
    }

    renderField(key, field) {
        if (field.render) {
            return field.render(this, key, field);
        }

        const ebf = this.state.errorByField;
        const gatewayList =  [ 'field' ];

        if (this.state.focus === key) {
            gatewayList.push('-active');
        }

        if (ebf[key]) {
            gatewayList.push('-invalid');
        }

        const getValue = field.getPrettyValue || function(v) { return v; };

        if (field.type === 'switch') {
            return (<div key={ key } className='field-wrapper -switch'>
                <label>
                    <Switch
                        id={key}
                        onColor='red'
                        isOn={this.state.values[key]}
                        handleToggle={() => {
                            const value = getValue(
                                !this.state.values[key],
                                this.state.values,
                                this
                            );

                            this.setValue(key, value)
                            field.onChange && field.onChange(
                                value,
                                this.state.values,
                                this
                            );
                        }} />

                    <span>
                        {field.label}&nbsp;
                        { field.linkAtEnd && <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={ url(field.linkAtEnd.link) }>{ field.linkAtEnd.label }</a> }
                    </span>
                </label>
            </div>);
        }

        return (<div key={ key } onClick={ () => { this.refs[key].focus(); }} className='field-wrapper'>
            { field.label && <label>
                {field.label}
            </label> }
            <div className={ gatewayList.join(' ') }>
            <div
                onClick={ (ev) => {
                    field.onButtonClick &&
                    field.onButtonClick(this.state.values[key], this.refs[key])
                } }
                className={ field.onButtonClick ? 'icon -clickable' : 'icon' }>
                <i translate='no' className='material-icons notranslate'>{ field.icon }</i>
            </div>
            <div className='input'>
                <input
                    ref={ key }
                    onFocus={ (ev) => {
                        if (field.selectOnFocus) {
                            ev.target.select();
                        }

                        this.setState({ focus: key })
                    } }
                    onBlur={ (ev) => {
                        this.setState({ focus: null })

                        const value = getValue(
                            ev.nativeEvent.target.value,
                            this.state.values,
                            this
                        );

                        this.setValue(key, value)
                        field.onChange && field.onChange(
                            value,
                            this.state.values,
                            this
                        );
                    } }
                    autoComplete={ field.autoComplete }
                    value={ this.state.values[key] || '' }
                    maxLength={ field.maxLength || 100 }
                    onChange={ (ev) => {
                        const value = ev.nativeEvent.target.value;

                        this.setValue(key, value)
                        field.onChange && field.onChange(
                            value,
                            this.state.values,
                            this
                        );
                    } }
                    type={ field.type }
                    placeholder={ field.placeholder } />
            </div>

            { ebf[key] && <div className='input-error'>{ ebf[key] }</div>}
        </div>
    </div>);

    }

    render() {
        const { submitButton } = this.props;

        return (
            <form onSubmit={ this.onSubmit.bind(this)}>
                { this.props.sections && (this.props.error || this.props.success) &&
                <React.Fragment>
                    <div className='section' style={ { margin: 0 }} >
                        <div className='-description'></div>

                        <div className='-fields' style={ { boxShadow: 'none', padding: 0 }}>
                            {this.props.error && <div className='alert -error'>{this.props.error} </div>}
                            {this.props.success && <div className='alert -success'>{this.props.success} </div>}
                        </div>
                    </div>
                </React.Fragment> }

                { !this.props.sections &&
                    <React.Fragment>
                        {this.props.error && <div className='alert -error'>{this.props.error} </div>}
                        {this.props.success && <div className='alert -success'>{this.props.success} </div>}
                </React.Fragment> }

                { this.props.sections &&
                    this.props.sections.map((s, idx) => <div key={ idx } className='section'>
                        <div className='-description'>
                            <h2>{ s.title }</h2>
                            <p>{ s.description }</p>
                        </div>

                        <div className='-fields'>
                            { Object.entries(s.fields).map(([ key, field ]) => this.renderField(key, field)) }
                        </div>
                    </div>) }
                { !this.props.sections && Object.entries(this.props.fields).map(([ key, field ]) => this.renderField(key, field)) }

                <div className={ this.props.buttonsRight ? '-buttons -right' : '-buttons' }>
                    { this.props.hasCancelButton &&
                        <button type='button' className='btn -secondary'>
                            Cancelar
                        </button> }
                        { submitButton &&
                    <button type='submit' className='btn -primary'>
                        { submitButton.icon &&
                            <i translate='no' className='material-icons notranslate'>{ submitButton.icon }</i> }
                        { submitButton.label }
                        </button> }
                </div>
            </form>

        );
    }

    componentDidMount() {
        this.setState({ values: { ...(this.props.values || {}) } })
    }
}

export default IseiForm;
export { Switch };
