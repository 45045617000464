import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';

function IseiQuill({ value, onChange, onFocus, onBlur, theme = 'bubble'}) {
    let ref;

    useEffect(() => {
        ref.editingArea.setAttribute('spellcheck', false);
    });

    return (
        <ReactQuill
            modules={
                {
                    toolbar: [
                        [ 'bold', 'italic', 'underline', 'strike' ],
                        [ 'link' ],
                        [ 'clean'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }]
                    ]
                }
            }
            bounds='.react-quill'
            className='react-quill'
            onFocus={onFocus}
            onBlur={onBlur}
            theme={ theme }
            value={ value || '' }
            ref={ (r) => { ref = r; } }
            onChange={ onChange } />
    );
}

export default IseiQuill;
