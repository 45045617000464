import React from 'react';
import BaseView from '../BaseView';

class AdminCommunity extends BaseView {
    render() {
        return (
            <div className='view-gateway-list'>
                <h2>Meus certificados</h2>
                <p>Você ainda não possuí nenhum certificado.</p>
            </div>
        );
    }
}

export default AdminCommunity;