import React from 'react';
import { updateMemory, MemoryComponent } from '../../memory';
import { getSchoolUrl, url, pad } from '../../utils';
import BaseView from '../BaseView';
import LoadingMask from '../../components/LoadingMask';
import ForceUpdateDataModal from '../../components/ForceUpdateDataModal'
import ajaxAdapter from '../../ajaxAdapter';

class DecideAboutTemplateModal extends React.Component {
    state = { isModalOpen: this.props.isModalOpen, link: '' };

    render() {
        if (!this.state.isModalOpen) { return null; }

        // only one for time
        const school = this.props.templateSchoolsInApproval[0];
        if (!school) { return null; }

        return (
            <div className={ `modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}` }>
                <div className='-content'>
                    <div className='-body'>
                        <p className='-description'>
                            A seguinte escola deseja te adicionar como afiliado: <br />
                            <a target='_blank' rel="noopener noreferrer" href={ getSchoolUrl(school) }>{ school.name }</a>
                        </p>

                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}

                        <div className='-footer'>
                            <button type='button' onClick={ async(ev) => {
                                await this.props.approveAffiliation(school)
                                this.hide();
                            }} className='btn -primary'>
                                Afiliar-se a escola
                            </button>
                            <button type='button' onClick={ async() => {
                                await this.props.declineAffiliation(school)
                                this.hide();
                            } } className='btn -error'>Negar afiliação</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit(values) {
        this.props.onSubmit(values);
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            name: '',
            email: '',
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class AdminHome extends BaseView {
    state = { isLoading: false };

    randomMessages = [
        () => <React.Fragment>
            Siga nos no intagram <strong
                style={ { cursor: 'pointer'} }
                onClick={ () => {
                    window.open('https://instagram.com/iSei.app')
                }}>@iSei.app</strong>
            </React.Fragment>,

        () => <span>Curta cada momento</span>,
        () => <span>Já bebeu água hoje? :)</span>,
        () => <span>Você está em boas mãos! ;-)</span>,
        () => <span>Eu poderia estar roubando, mas estou aqui te pedindo: indica o iSei pra um amigo?</span>,
        () => <React.Fragment>
            Siga nos no facebook <strong
                style={ { cursor: 'pointer'} }
                onClick={ () => {
                    window.open('https://www.facebook.com/iSeiApp')
                }}>@iSeiApp</strong>
            </React.Fragment>,


    ];

    renderRandomBannerMessage() {
        const { randomMessages } = this;
        const randomIndex = Math.floor(Math.random() * randomMessages.length);

        return randomMessages[randomIndex]();
    }

    render() {
        return (
            <div className='view-admin-home base-view'>
                <header>
                    <h1>Olá, seja bem-vindo a { this.props.school.name }</h1>
                    <div className='-buttons'>
                        <button onClick={ () => {
                            this.props.history.push(url('bank'));
                        } } type='button' className='-money btn'>
                            R$ { this.props.bank.balance } em saldo
                        </button>

                        <button onClick={ () => {
                            this.props.history.push(url('pontos'))
                        } } type='button' className='-points btn'>
                            { this.getPoints() } pontos
                        </button>

                    </div>

                    <div className='banner'>
                        { this.renderRandomBannerMessage() }
                    </div>
                </header>
                <main>
                    <section className='overview'>
                        <div className={
                            `-plan -${this.props.resource.plan_id}`
                        } onClick={ () => { this.props.history.push(url('planos')); }}>
                            <header>
                                <h3>Plano: <span>{ this.props.resource.plan_name }</span></h3>
                            </header>
                            <main>
                                <div className='-students'>
                                    <i translate='no' className='material-icons notranslate'>people</i>
                                    <span>{ this.props.resource.free_students } alunos grátis</span>
                                </div>

                                <div className='change-plan-wrapper'>
                                <button className='btn -change-plan'>
                                    Mudar plano
                                </button>
                                </div>
                            </main>
                        </div>
                        <div onClick={() => { } } className='-resource-usage'>
                            <header>
                                <h3>Uso de sua escola</h3>
                            </header>
                            <main>
                                <div className='-students'>
                                    <i translate='no' className='material-icons notranslate'>people</i>
                                    <span>{ this.props.students.total } alunos</span>
                                </div>

                                <div className='-school_gateways'>
                                    <i translate='no' className='material-icons notranslate'>groups</i>
                                    <span>{ this.props.gatewayList.length } turmas</span>
                                </div>

                                <div className='-school_gateways'>
                                    <i translate='no' className='material-icons notranslate'>view_modules</i>
                                    <span>{ this.getTotalDegrees()  } cursos</span>
                                </div>
                            </main>
                        </div>
                    </section>

                    <section className='message'>
                        <p>Tem alguma dúvida? Aqui estão os nossos recursos mais populares para ajudar você.</p>
                    </section>
                    <section className='support-channels'>
                        <div style={ { cursor: 'pointer' } } onClick={ () => {
                            window.open('/remote-learning/c/bcd285bf');
                        } } className='-learning'>
                            <div className='-icon'>
                                <img alt='Learning Center' src={ require('../../assets/icons/icon-learning.png')} />
                            </div>
                            <div className='-description'>
                                <h4>Learning Center</h4>
                                <p>Aprenda a administrar e gerenciar o seu EAD com nossas aulas.</p>
                            </div>
                        </div>

                        <div style={ { cursor: 'pointer' } } onClick={ () => {
                            window.open('https://ajuda.suporteaousuario.com.br/isei/');
                        } } className='-support'>
                            <div className='-icon'>
                                <img alt='Ajuda e suporte' src={ require('../../assets/icons/icon-support.png')} />
                            </div>
                            <div className='-description'>
                                <h4>Ajuda e suporte</h4>
                                <p>Pesquise em nossa central de ajuda ou entre em contato com a equipe de suporte.</p>
                            </div>
                        </div>
                    </section>
                </main>

                <DecideAboutTemplateModal
                    approveAffiliation={ this.setAffiliation.bind(this, 'approved') }
                    declineAffiliation={ this.setAffiliation.bind(this, 'declined') }
                    isModalOpen={ this.props.templateSchoolsInApproval.length }
                    templateSchoolsInApproval={ this.props.templateSchoolsInApproval }
                    ref='decideAboutTemplateModal' />

                <ForceUpdateDataModal
                    school={ this.props.school }
                    user={ this.props.user }
                    onSubmit={ this.updateSchoolAndUserData.bind(this) }
                    ref='forceUpdateDataModal'  />

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    async updateSchoolAndUserData(data) {
        try {
            const { name, email, cellphone, userCellphone } = data;
            const school = { ...this.props.school, name, email, cellphone };
            const user = { ...this.props.user, cellphone: userCellphone };


            await ajaxAdapter({ isSchoolRequest: true })
                .post('update', { name, email, cellphone } );

            await ajaxAdapter()
                .post('user/update', { cellphone: userCellphone });

            updateMemory({ school, user });

            this.setState({ isLoading: true });
        } catch (ex) {
            global.error(ex);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    async setAffiliation(column, school) {
        try {
            this.setState({ isLoading: true });

            await ajaxAdapter({
                isSchoolRequest: true
            }).post('update-affiliation', {
                column,
                school_id: school.id
            });

            const templateSchoolsInApproval = [
                ...this.props.templateSchoolsInApproval
            ].filter((s) => s.id !== school.id);
            const templateSchools = [ ...this.props.templateSchools ];

            if (column === 'approved') { templateSchools.push(school); }
            updateMemory({ templateSchoolsInApproval, templateSchools });
        } catch (ex) {
            global.error(ex)
        } finally {
            this.setState({ isLoading: false });
        }
    }

    getTotalDegrees() {
        if (!this.props.school.categories) {
            return 0;
        }

        let total = 0;
        for (const cat of this.props.school.categories) {
            if (!cat.degrees) { continue; }

            total += (cat.degrees.length - 1);
        }

        return total;
    }

    getPoints() {
        const points = this.props.bank.points;

        return points && pad(points, 3, 0);
    }

    componentDidMount() {
        global.isInHome = true;

        const smallChatElement = document.querySelector('#Smallchat');

        if (smallChatElement) {
            smallChatElement.style.display = 'block';
        }

        if (this.props.bank.balance < 0) {
            this.props.history.push(url('low-balance'));
        }

        if(!this.props.school.name || !this.props.school.email ||
            !this.props.school.cellphone || !this.props.user.cellphone) {
            this.refs.forceUpdateDataModal.show();
        }
    }

    componentWillUnmount() {
        global.isInHome = false;

        const smallChatElement = document.querySelector('#Smallchat');

        if (smallChatElement) { smallChatElement.style.display = 'none'; }
    }
}

export default MemoryComponent(
    AdminHome, 'gatewayList', 'students', 'school', 'user',
    'bank', 'resource', 'templateSchoolsInApproval', 'templateSchools');
