import React from 'react';
import { LoadingCircles,Logo } from './svgs';
import { updateMemory } from './memory';
import * as Sentry from "@sentry/react";

import ajaxAdapter from './ajaxAdapter';

function addTokenToUrl(url) {
    url += url.includes('?') ? '&' : '?';
    url += `auth=${localStorage.getItem('jwtToken')}`;

    return url;
}

function getAcademyAppName(school, user) {
    const role = user.role === 'academy-admin' ? user.role : [].concat(
        school.role
    )[0];

    return {
        'academy-admin': 'mib',
        'admin': 'skinner',
        'academy': 'skinner',
        'student': 'hero',
        'teacher': 'xavier'
    }[role];
}

const ctx = React.createContext({ user: {}, school: {} });

function LongLoading ({ isLoading }) {
    if (window.isFromSchoolLoad) {
        return (
            <div className={ `context-loading ${isLoading ? '-loading' : '-hide'}` }>
                <div className='logo -loading' style={ { textAlign: 'center' } }>
                    <LoadingCircles />
                </div>
            </div>);
    }

    return (
        <div className={ `context-loading ${isLoading ? '-loading' : '-hide'}` }>
            <div className='logo -loading'>
                <Logo />
            </div>
        </div>
    );
}

let _contextInstance;

class IseiContext extends React.Component {
    state = { loaded: false, user: {}, school: {} }

    render() {
        const { user, school, loaded } = this.state;

        const hasUser = Boolean(user.id);
        const hasSchool = Boolean(school.id);

        const contextName = hasSchool ? 'school' : (hasUser ? 'user' : 'none');
        const contextRole = (school.role && school.role.length &&
            localStorage.getItem('contextOverride')) || (hasSchool ? (
            school.role && school.role.length > 0 ? school.role[0] : 'student'
            ) : 'student');

        localStorage.contextRole = contextRole;

        if (loaded && school && school.is_academy) {
            if (!user || !getAcademyAppName(school, user)) {
                window.location.href = '/login';
            } else {
                let url = '';
                if (global.baseUiUrl.includes('localhost')) {
                    url = 'http://localhost:4000'
                }

                url += `${global.baseUiPathname}/${getAcademyAppName(school, user)}/`;
                // if is not localhost, the token will already be defined
                if (global.baseUiUrl.includes('localhost')) {
                    url = addTokenToUrl(url);
                }

                window.location.href = url;
            }
        }

        const schoolId = school.id;
        const userId = user.id;
        const userRole = user.role;
        const isSchoolAdmin = hasSchool && school.role?.length > 0 && school.role[0] === 'admin';
        const isSchoolPrivate = hasSchool && school.is_private;
        const isSchoolBlocked = hasSchool && school.is_blocked;

        if (hasUser) {
            Sentry.setUser({
                id: user.id,
                email: user.email,
                username: user.name,
                extra: { school_id: school.id },
            });
        } else {
            Sentry.setUser();
        }

        return <ctx.Provider value={ {
            hasUser, hasSchool, contextRole, contextName, userId,
            schoolId, loaded, userRole, isSchoolAdmin, isSchoolPrivate, isSchoolBlocked,
            schoolRole: school.role && school.role[0]
        } }><LongLoading isLoading={ !this.state.loaded ||
            (school && school.is_academy) } />
            { (!school || !school.is_academy) &&
            <div className={ `context-body -${ this.state.loaded ? 'loaded' : 'hide' }` }>
                { this.props.children }
            </div> }
        </ctx.Provider>
    }

    async componentDidMount() {
        _contextInstance = this;
        IseiContext.load();
    }

    static type = ctx;
    static async load(domain, pathname) {
        try {
            const { location } = global.window;

            domain = domain || location.host;
            pathname = pathname || location.pathname;

            const res = await ajaxAdapter().get('/context', { domain, pathname });

            const newMemory = { user: res.user, school: res.school };
            const contextOverrideSchoolId = localStorage.getItem('contextOverrideSchoolId');

            if (res.school && String(contextOverrideSchoolId) !== String(res.school.id)) {
                localStorage.removeItem('contextOverrideSchoolId')
                localStorage.removeItem('contextOverride')
            }

            if (res.students) { newMemory.students = res.students; }
            if (res.schools) { newMemory.schools = res.schools; }
            if (res.bank) { newMemory.bank = res.bank; }
            if (res.resource) { newMemory.resource = res.resource; }
            if (res.gatewayList) { newMemory.gatewayList = res.gatewayList; }
            if (res.acl) { newMemory.acl = res.acl; }
            if (res.bookmarks) { newMemory.bookmarks = res.bookmarks; }
            if (res.certificateList) { newMemory.certificateList = res.certificateList; }
            if (res.templateSchools) {
                newMemory.templateSchools = res.templateSchools
                    .filter((s) => s.utc_approved_on);

                newMemory.templateSchoolsInApproval = res.templateSchools
                    .filter((s) => !s.utc_approved_on);
            }

            updateMemory(newMemory)
            _contextInstance.setState({
                loaded: true,
                user: res.user || {},
                school: res.school || {}
            })
        } catch(ex) {
            global.error(ex)
            window.location.reload(true);
        }
    }

    static update(obj) { _contextInstance.setState({ ...obj }); }
    static setLoading() { _contextInstance.setState({ loaded: false }); }
    static setLoaded() { _contextInstance.setState({ loaded: true }); }
}

export default IseiContext;
