import React from 'react';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent } from '../../memory';
import { capitalize } from '../../utils';
import IseiForm from '../../components/Form';
import BaseView from '../BaseView';

const siginUserFields = {
    name: {
        type: 'text',
        icon: 'person',
        placeholder: 'O nome completo',
        autoComplete: 'name',
        getPrettyValue: capitalize,

        getValidationError(str) {
            if (!str || str.length < 4) {
                return 'Por favor, preencha o nome';
            }

            return false;
        }
    },
    email: {
        type: 'text',
        icon: 'alternate_email',
        placeholder: 'E-mail para acessar a plataforma',
        autoComplete: 'email',
        selectOnFocus: true,

        getPrettyValue(value) {
            return (value &&
                value.toLowerCase &&
                value.toLowerCase().replace().replace(/\s/g, '')
            ) || value;
        },

        getValidationError(value) {
            if (!value || !/.+@.+\.[A-Za-z]+$/.test(value)) {
                return 'Preencha o e-mail corretamente';
            }

            return null;
        }
    }
};

class AddUserModal extends React.Component {
    state = {
        isModalOpen: false,
        name: '',
        email: ''
    };

    render() {
        if (!this.state.isModalOpen) { return null; }

        return (
            <div className={`modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}`}>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}

                        <p className='-description'>Preencha abaixo os dados de seu novo administrador:</p>

                        <IseiForm
                            ref='form'
                            onSubmit={this._submit.bind(this)}
                            values={this.state.values}
                            fields={siginUserFields} />

                        <div className='-footer'>
                            <button type='button' onClick={(ev) => {
                                this.refs.form.onSubmit(ev)
                            }} className='btn -primary'>
                                Criar Administrador
                            </button>
                            <button type='button' onClick={() => {
                                this.setState({ isModalOpen: false });
                            }} className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit(values) {
        this.props.onSubmit(values);
        this.hide();
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            name: '',
            email: '',
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class OtherSchools extends BaseView {
    state = { isLoading: true, users: [] };

    render() {
        return (
            <div className='view-school-list'>
                <h2>Usuários</h2>
                <p className='-description'>
                    Aqui você pode adicionar ou remover usuários em sua escola
                </p>

                <div style={ {
                    marginTop: '1rem'
                } } className='student-list-wrapper'>
                    <header>
                        <button onClick={ () => {
                            this.refs.addUserModal.show();

                        } } className='btn -primary'>
                            Criar Administrador
                        </button>
                    </header>
                    { this.state.users.length > 0 &&
                    <div className='grid-wrapper' style={ { marginTop: '1rem' } }>
                        <table className='students-table'>
                            <thead>
                                <tr>
                                    <th className='-name'>Nome</th>
                                    <th className='-email'>E-mail</th>
                                    <th className='-actions'>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users.map((s) =>
                                    <tr>
                                        <td className='-name'>{s.name}</td>
                                        <td className='-email'>{s.email}</td>
                                        <td className='-actions' style={ { textAlign: 'right' } }>
                                            { this.props.user.id !== s.user_id &&
                                            <button onClick={ () => {
                                                if (global.window.confirm('Tem certeza?')) {
                                                    this.removeUser(s);
                                                }
                                            } } style={ {
                                                fontSize: '.9rem',
                                                padding: '.4rem'
                                            } } className='btn  -error'>
                                                Remover usuário
                                            </button> }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div> }
                </div>

                <AddUserModal
                    onSubmit={this.onAddUser.bind(this)}
                    ref='addUserModal' />
            </div>
        );
    }

    async removeUser(user) {
        try {
            await ajaxAdapter({ isSchoolRequest: true }).post('remove-user', {
                user_id: user.user_id
            });

            const page = this.props.match.params.page || this.state.page;
            this.loadUsers(page);
        } catch (ex) {
            global.error(ex);
        }
    }

    async onAddUser(values) {
        try {
            await ajaxAdapter({ isSchoolRequest: true }).post('add-user', values);

            const page = this.props.match.params.page || this.state.page;
            this.loadUsers(page);
        } catch (ex) {
            global.error(ex);
        }
    }

    async componentDidMount() {
        super.componentDidMount();

        await this.loadUsers();
    }

    async loadUsers(page) {
        try {
            page = page || this.props.match.params.page || this.state.page;

            const { users } = (await ajaxAdapter({
                isSchoolRequest: true
            }).get(`users?page=` + page));

            this.setState({ users });
        } catch (ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(OtherSchools, 'user');
