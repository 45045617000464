import React from 'react';
import { updateMemory, MemoryComponent } from '../../memory';
import { url } from '../../utils';
import BaseView from '../BaseView';
import ajaxAdapter from '../../ajaxAdapter';
import LoadingMask from '../../components/LoadingMask';

class AdminHome extends BaseView {
    state = { };

    async swapPoints(pointsToRemove) {
        try {
            this.setState({ isLoading: true });
            const { bank } = await ajaxAdapter({
                isSchoolRequest: true
            }).post('swap-points', { pointsToRemove });

            updateMemory({ bank });
            this.setState({ isLoading: false, swapped: true })
        } catch (ex) {
            this.setState({ isLoading: false});

global.error(ex);
        }
    }

    renderSwapped() {
        return <div className='checkout-wrapper base-view'>
            <div className='paid-wrapper'>
                <header>
                    <i translate='no' className='material-icons notranslate'>check_circle</i>
                    <h2>Pontos trocados com sucesso!</h2>
                </header>
                <main>
                    <p>
                        <strong>Seus pontos foram trocados por saldo com sucesso</strong><br />
                        Os seus créditos já estão disponíveis.
                    </p>
                    <button onClick={ () => {
                        this.props.history.push(url('/'));
                    } } className='btn -primary'>
                        Voltar para home
                    </button>
                </main>
            </div>
        </div>;
    }

    render() {
        if (this.state.swapped) { return this.renderSwapped(); }
        return (
            <div className='view-admin-bank base-view'>
                <h2>Painel de Conquistas @iSei</h2>
                <p>Você tem um total de <strong>{ this.props.bank.points }</strong> pontos.</p>

                <div className='plans-wrapper'>
                    <div className='-plan -ondemand'>
                        <header>
                            <h3>First time</h3>
                        </header>
                        <main>
                            <div className='highlight'>
                                <h4>100 pontos</h4>
                                <span>R$ 1,00 em saldo imediato</span>
                            </div>
                            <div className='features'>
                                <ul>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Saldo nunca expira</li>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Domínio personalizado</li>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Carga imediata</li>
                                </ul>
                            </div>
                        </main>
                        { this.props.bank.points >= 100 &&
                        <footer>
                            <button type='button' onClick={ () => {
                                this.swapPoints(100);
                            }}>trocar pontos</button> 
                        </footer> }
                    </div>

                    <div className='-plan -middle'>
                        <header>
                            <h3>Experiente</h3>
                        </header>
                        <main>
                            <div className='highlight'>
                                <h4>500 pontos</h4>
                                <span>R$ 10,00 em saldo imediato</span>
                            </div>
                            <div className='features'>
                                <ul>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Saldo nunca expira</li>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Domínio personalizado</li>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Carga imediata</li>
                                </ul>
                            </div>
                        </main>
                        { this.props.bank.points >= 500 &&
                        <footer>
                            <button type='button' onClick={ () => {
                                this.swapPoints(500);
                            }}>trocar pontos</button> 
                        </footer> }
                    </div>

                    <div className='-plan -super'>
                        <header>
                            <h3>Profissional</h3>
                        </header>
                        <main>
                            <div className='highlight'>
                                <h4>1.000 pontos</h4>
                                <span>R$ 25,00 em saldo imediato</span>
                            </div>
                            <div className='features'>
                                <ul>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Saldo nunca expira</li>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Domínio personalizado</li>
                                    <li><i translate='no' className='material-icons notranslate'>check_circle</i> Carga imediata</li>
                                </ul>
                            </div>
                        </main>
                        { this.props.bank.points >= 1000 &&
                        <footer>
                            <button type='button' onClick={ () => {
                                this.swapPoints(1000);
                            }}>trocar pontos</button> 
                        </footer> }
                    </div>
                </div>

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }
}

export default MemoryComponent(AdminHome, 'gatewayList', 'students', 'school', 'bank', 'resource');
