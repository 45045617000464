import React from 'react';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent, updateMemory } from '../../memory';
import { url } from '../../utils';
import BaseView from '../BaseView';
import LoadingMask from '../../components/LoadingMask';

class SchoolTemplatesPlans extends BaseView {
    state = { isLoading: true };

    _findDegree(id) {
        const templateCategories = this.props
            .templateSchools
            .reduce((curr, s) => curr.concat(s.categories), []);

        const categories = this.props.school.categories
            .concat(templateCategories);

        for (const cat of categories) {
            if (!cat.degrees) { continue; }

            for (const degree of cat.degrees) {
                if (degree.id === id) { return degree; }
            }
        }

        throw Error(`Cannot find degree ${id}`);
    }

    degreeExists(id) {
        try {
            this._findDegree(id);
        } catch (ex) {
            return false;
        }

        return true;
    }

    getSelectedDegreesText(plan) {
        const { all_degrees, degrees } = plan;

        if (all_degrees) {
            return 'Todos os cursos';
        }

        if (!degrees) { return '(nenhum curso)'; }

        if (degrees.length <= 3) {
            const degreeTitles = degrees
                .filter(this.degreeExists.bind(this))
                .map((id) => {
                    const degree = this._findDegree(id);

                    return degree.title;
                });

            const last = degreeTitles.pop();

            if (degreeTitles.length) {
                return `${degreeTitles.join(', ')} e ${last}`;
            } else {
                return last || '(nenhum curso)';
            }
        }

        return `${degrees.length} cursos`
    }

    renderPlan(plan) {

        const goToPlan = () => {
            this.props.history.push(url(`filiais-planos/${plan.id}`));
        }

        return (
            <div key={ plan.id } className='-gateway' onClick={ goToPlan }>
                <h4>{ plan.title }</h4>

                <p>
                    <i translate='no' className='material-icons notranslate'>
                        lock
                    </i>
                    { this.getSelectedDegreesText(plan) }
                </p>

            </div>
        );
    }

    render() {
        const plans = this.props.schoolTemplatesPlans;

        const createPlan = () => {
            this.props.history.push(url('filiais-planos/criar'));
        } 

        return (
            <div className='view-gateway-list'>
                <h2>Meus planos para afiliados</h2>

                <p className='-description'>
                    Crie planos para liberar os cursos para os seus afiliados!
                    <br />
                </p>

                <div className='gateway-list-wrapper'>
                    <div onClick={ createPlan } className='-add'>
                        <i translate='no' className='material-icons notranslate'>
                            add_box
                        </i>
                        <p>Criar Plano</p>
                    </div>

                    { plans.map(this.renderPlan.bind(this))}
                </div>

                <LoadingMask fullScreen={ true } show={
                    this.state.isLoading && plans.length === 0
                } />
            </div>
        );
    }

    async componentDidMount() {
        super.componentDidMount();

        try {
            const { schoolTemplatesPlans } = await ajaxAdapter({
                isSchoolRequest: true
            }).get('school-templates-plans');

            updateMemory({ schoolTemplatesPlans });

            this.setState({ isLoading: false });
        } catch(ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(SchoolTemplatesPlans,
                                'school',
                                'schoolTemplatesPlans',
                                'templateSchools');
