import React, { Component } from 'react';
import ForceUpdateDataForm from './ForceUpdateDataForm';
import { prettify, capitalize } from '../utils';

const forceUpdateDataFormSections = [
    {
        title: 'Dados da escola',
        fields: {
            name: {
                type: 'text',
                icon: 'school',
                placeholder: 'Nome da escola',
                autoComplete: 'name',
                getPrettyValue: capitalize,

                getValidationError(str) {
                    if (!str || str.length < 4) {
                        return 'Por favor, preencha o nome';
                    }

                    return false;
                }
            },
            email: {
                type: 'text',
                icon: 'alternate_email',
                placeholder: 'E-mail para acessar a plataforma',
                autoComplete: 'email',
                selectOnFocus: true,

                getPrettyValue(value) {
                    return (value &&
                        value.toLowerCase &&
                        value.toLowerCase().replace().replace(/\s/g, '')
                    ) || value;
                },

                getValidationError(value) {
                    if (!value || !/.+@.+\.[A-Za-z]+$/.test(value)) {
                        return 'Preencha o e-mail corretamente';
                    }

                    return null;
                }
            },

            cellphone: {
                type: 'text',
                icon: 'contact_phone',
                placeholder: 'Telefone de contado da escola',
                maxLength: 20,
                selectOnFocus: true,
                autoComplete: 'tel',

                getPrettyValue(val) {
                    return prettify.phone(val);
                },

                getValidationError(value) {
                    if (!value || !String(value).length > 10) {
                        return 'Preencha o telefone corretamente';
                    }

                    return null;
                }
            },
        }
    },
    {
        title: 'Seus dados pessoais',
        fields: {
            userCellphone: {
                type: 'text',
                icon: 'phone_iphone',
                placeholder: 'Telefone de contado pessoal',
                maxLength: 20,
                selectOnFocus: true,
                autoComplete: 'tel',

                getPrettyValue(val) {
                    return prettify.phone(val);
                },

                getValidationError(value) {
                    if (!value || !String(value).length > 10) {
                        return 'Preencha o telefone corretamente';
                    }

                    return null;
                }
            },
        }
    }
];

class ForceUpdateDataModal extends Component {
    state = {
        isModalOpen: false,
        values: {
            name: this.props.school.name,
            email: this.props.school.email,
            cellphone: this.props.school.cellphone,
            userCellphone: this.props.user.cellphone
        }
    };

    render() {
        if (!this.state.isModalOpen) { return null; }

        return (
            <div className={`modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}`}>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}

                        <ForceUpdateDataForm
                            className='-force-update'
                            ref='form'
                            onSubmit={this._submit.bind(this)}
                            values={this.state.values}
                            sections={forceUpdateDataFormSections} />

                        <div className='-footer'>
                            <button type='button' onClick={(ev) => {
                                this.refs.form.onSubmit(ev)
                            }} className='btn -primary'>
                               Atulalizar dados
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit(values) {
        this.props.onSubmit(values);
        this.hide();
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            name: this.props.school.name,
            email: this.props.school.email,
            cellphone: this.props.school.cellphone,
            userCellphone: this.props.user.cellphone,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

export default ForceUpdateDataModal;
