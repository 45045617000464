import React from 'react';
import { MemoryComponent } from '../../memory';
import { url } from '../../utils';
import BaseView from '../BaseView';

import { PriceBasic, PriceMax, PriceTurbo } from '../../components/PriceItems';

class AdminHome extends BaseView {
    state = { };

    render() {
        return (
            <div className='view-admin-bank base-view'>
                <h2>Adicionar saldo</h2>
                <p>Selecione um plano abaixo para adicionar saldo em sua conta:</p>

                <div className='plans-wrapper'>
                    <PriceBasic onClick={ () => { this.props.history.push(url('add-saldo/basic')) } } />
                    <PriceTurbo onClick={ () => { this.props.history.push(url('add-saldo/turbo'))  } } />
                    <PriceMax onClick={ () => { this.props.history.push(url('add-saldo/max'))  } } />
                </div>
            </div>
        );
    }
}

export default MemoryComponent(AdminHome, 'gatewayList', 'students', 'school', 'bank', 'resource');
