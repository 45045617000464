import React from 'react';
import Dropzone from 'react-dropzone'
import BaseView from '../BaseView';

import { MemoryComponent, updateMemory } from '../../memory';
import ajaxAdapter from '../../ajaxAdapter';
import CropImageModal from '../../components/CropImageModal';
import { Logo, LogoSquare } from '../../svgs';

const themes = [
    { selector: 'default', label: 'Sensei' },
    { selector: 'lucky', label: 'Lucky' },
    { selector: 'creative', label: 'Creative' },

    { selector: 'pastel', label: 'Pastel' },
    { selector: 'baby', label: 'Baby' },
    { selector: 'happy', label: 'Happy' },
];

class ViewDesign extends BaseView {
    buildOnImageDrop(field, fieldName, format = 'square') {
        return (files) => {
            const reader = new global.FileReader();

            reader.onloadend = () => {
                this.refs.cropImageModal.show({
                    src: reader.result,
                    fileName: files[0].name,
                    blobField: field,
                    imageField: fieldName,
                    format
                });
            }

            reader.readAsDataURL(files[0]);
        }
    }

    renderRemoveIfNecessary(field) {
        const val = this.props.school[field];
        if (!val) { return null; }

        return (
            <a href='#remove' onClick={ async(ev) =>{
                ev.preventDefault();
                ev.stopPropagation();

                if (window.confirm('Tem certeza?')) {
                    const school = { ...this.props.school };

                    school[field] = null;
                    updateMemory({ school })

                    try {
                        await ajaxAdapter({
                            isSchoolRequest: true
                        }).delete('logo', { field });
                    } catch(ex) {
                        global.error(ex);
                    }

                }
            } } className='-remove'>remover</a>
        )
    }

    render() {
        const {
            logo_wide_url,
            logo_dark_wide_url,
            logo_square_url,
            logo_dark_square_url,
            name
        } = this.props.school;

        return (
            <React.Fragment>
                <form className='view-design'>
                    <div className='section'>
                        <div className='-description'>
                            <h2>Cores do seu tema</h2>
                            <p>
                                Defina a cor do seu tema para personalizar a sua escola para seus alunos.
                            </p>
                        </div>

                        <div className='-fields'>
                            <div className='theme-selector-wrapper -small'>
                                { themes.map(({ selector, label }) => <div
                                    onClick={ async() => {
                                        const school = { ...this.props.school };

                                        school.ui_theme = selector;
                                        updateMemory({ school })

                                        try {
                                            await ajaxAdapter({
                                                isSchoolRequest: true
                                            }).post('theme', {
                                                theme: selector
                                            });
                                        } catch(ex) {
                                            global.error(ex);
                                        }
                                    }}

                                    key={ selector }
                                    className={ `theme -${selector}`}>
                                    <div className={ `theme-selection ${this.props.school.ui_theme === selector ? '-active' : ''}`}>
                                            <label>{ label }</label>
                                            <div className='colors'>
                                                <div className='-primary'></div>
                                                <div className='-secondary'></div>
                                                <div className='-success'></div>
                                                <div className='-highlight'></div>
                                                <div className='-error'></div>
                                            </div>
                                            { this.props.school.ui_theme === selector &&
                                            <div className='active-text'>
                                                <i translate='no' className='material-icons notranslate'>check</i> Selecionado
                                            </div> }

                                            { this.props.school.ui_theme !== selector &&
                                            <div className='inactive-text'>
                                                Selecionar
                                            </div> }
                                        </div>
                                </div> )}
                            </div>
                        </div>
                    </div>

                    <div className='section'>
                        <div className='-description'>
                            <h2>Marca de sua escola</h2>
                            <p>
                                Envie sua própria marca no seu EAD personalizado.
                            </p>
                        </div>
                        <div className='-fields'>
                            <div className='img-uploader-wrapper'>
                                <div className='-col'>
                                    <Dropzone accept='image/jpeg, image/png' onDrop={ this.buildOnImageDrop('logoWide', 'logo_wide_url', 'wide')}>
                                        {({ getRootProps, getInputProps }) => (
                                            <React.Fragment>
                                            <div {...getRootProps()} className='-default'>
                                                <input {...getInputProps()} />

                                                <div className='-logo-wrapper -wide'>
                                                    <div className='content logo'>
                                                        { logo_wide_url ? <img alt={ name } title={ name } src={ logo_wide_url } /> : <Logo /> }
                                                    </div>
                                                </div>
                                                <h5>Logo Principal</h5>
                                                <p>Proporção: 29 por 9</p>
                                                <button type='button' className='btn -primary'>Alterar logo</button>
                                                { this.renderRemoveIfNecessary('logo_wide_url') }
                                            </div>
                                            </React.Fragment>
                                        )}
                                    </Dropzone>

                                    <Dropzone accept='image/jpeg, image/png' onDrop={ this.buildOnImageDrop('logoSquare', 'logo_square_url', 'square')}>
                                        {({ getRootProps, getInputProps }) => (
                                            <React.Fragment>
                                            <div {...getRootProps()} className='-default'>
                                                <input {...getInputProps()} />
                                                <div className='-logo-wrapper -square'>
                                                    <div className='content logo'>
                                                        { logo_square_url ? <img alt={ name } title={ name } src={ logo_square_url } /> : <LogoSquare /> }
                                                    </div>
                                                </div>
                                                <h5>Logo Secundário</h5>
                                                <p>Proporção: 1 por 1</p>
                                                <button type='button' className='btn -primary'>Alterar logo</button>
                                                { this.renderRemoveIfNecessary('logo_square_url') }
                                            </div>
                                            </React.Fragment>
                                        )}
                                    </Dropzone>
                                </div>


                                <div className='-col'>
                                    <Dropzone accept='image/jpeg, image/png' onDrop={ this.buildOnImageDrop('logoDarkWide', 'logo_dark_wide_url', 'wide')}>
                                        {({ getRootProps, getInputProps }) => (
                                            <React.Fragment>
                                                <div {...getRootProps()} className='-dark'>
                                                    <input {...getInputProps()} />

                                                    <div className='-logo-wrapper -wide'>
                                                        <div className='content logo'>
                                                            { logo_dark_wide_url ? <img alt={ name } title={ name } src={ logo_dark_wide_url } /> : <Logo /> }
                                                        </div>
                                                    </div>

                                                    <h5>Logo Principal</h5>
                                                    <p>Modo escuro</p>
                                                    <button type='button' className='btn -primary'>Alterar logo</button>

                                                { this.renderRemoveIfNecessary('logo_dark_wide_url') }
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </Dropzone>

                                    <Dropzone accept='image/jpeg, image/png' onDrop={ this.buildOnImageDrop('logoDarkSquare', 'logo_dark_square_url', 'square')}>
                                        {({ getRootProps, getInputProps }) => (
                                            <React.Fragment>
                                            <div {...getRootProps()} className='-dark'>
                                                <input {...getInputProps()} />

                                                <div className='-logo-wrapper -square'>
                                                    <div className='content logo'>
                                                        { logo_dark_square_url ? <img alt={ name } title={ name } src={ logo_dark_square_url } /> : <LogoSquare /> }
                                                    </div>
                                                </div>
                                                <h5>Logo Secundário</h5>
                                                <p>Modo escuro</p>
                                                <button type='button' className='btn -primary'>Alterar logo</button>
                                                { this.renderRemoveIfNecessary('logo_dark_square_url') }
                                            </div>
                                            </React.Fragment>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <CropImageModal
                    onSubmit={async (croppedImage) => {
                        const school = { ...this.props.school };

                        school[croppedImage.imageField] = croppedImage.fileUrl;
                        updateMemory({ school })

                        try {
                            croppedImage.blob.isImage = true;

                            await ajaxAdapter({ isSchoolRequest: true }).postFormData('logo', {
                                field: croppedImage.imageField,
                                file: croppedImage.blob
                            })
                        } catch(ex) {
                            global.error(ex);
                        }
                    }}
                    ref='cropImageModal' />
            </React.Fragment>
        );
    }
}

export default MemoryComponent(ViewDesign, 'school', 'user');
