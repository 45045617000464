import React from 'react';
import BaseView from '../BaseView';

class AdminSupport extends BaseView {
    render() {
        return (
            <div className='view-gateway-list'>
                <h2>Suporte - Admin</h2>
            </div>
        );
    }
}

export default AdminSupport;