import React, { Component } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

const cropByFormat = {
    square: {
        unit: '%',
        width: 100,
        aspect: 1 / 1
    },

    wide: {
        unit: '%',
        width: 100,
        aspect: 29 / 9
    },
    signature: {
        unit: 'px',
        width: 48,
        height: 150
    }
};

class CropImageModal extends Component {
    state = {
        isModalOpen: false,
        errorByField: {},
        src: null,
        crop: {
            unit: '%',
            width: 400,
            aspect: 16 / 9
        },
    };

    // If you setState the crop in here you should return false.
    onImageLoaded(image) {
        this.imageRef = image;
    };

    onCropChange(crop) {
        this.setState({ crop });
    };

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        var originWidth = crop.width * scaleX;
        var originHeight = crop.height * scaleY;
        // maximum width/height
        var maxWidth = 1920, maxHeight = 1280 / this.state.crop.aspect;
        var targetWidth = originWidth,
            targetHeight = originHeight;
        if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
                targetWidth = maxWidth;
                targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            } else {
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            }
        }
        // set canvas size
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            targetWidth,
            targetHeight
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                blob => {
                    if (!blob) {
                        global.error("Canvas is empty");
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    const fileUrl = window.URL.createObjectURL(blob);

                    resolve({ fileUrl, blob });
                },
                "image/png",
                1
            );
        });
    }

    render() {
        const { crop, src } = this.state;

        if (!this.state.isModalOpen) { return null; }

        return (
            <div className={ `modal -cropper ${this.state.isModalOpen ? '-open' : ''}` }>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}

                        <div className='react-crop-wrapper'>
                            <ReactCrop
                                locked={this.props.locked}
                                src={src}
                                crop={crop}
                                style={ { maxWidth: '500px', maxHeight: '500px' }}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded.bind(this)}
                                onChange={this.onCropChange.bind(this)}
                            />
                        </div>

                        <div className='-footer'>
                            <button type='button' onClick={this._submit.bind(this)} className='btn -primary'>Salvar imagem</button>
                            <button type='button' onClick={ () => {
                                this.setState({ isModalOpen: false });
                            } } className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit() {
        try {

            const croppedImage = await this.getCroppedImg(
                this.imageRef,
                this.state.crop,
                this.state.fileName
            );
            croppedImage.fileName = this.state.fileName;
            croppedImage.blobField = this.state.blobField;
            croppedImage.imageField = this.state.imageField;

            this.props.onSubmit(croppedImage)
            this.hide();
        } catch (ex) {
            console.error(ex);
            this.setState({ error: ex.message, justUpdated: false });
        }

    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        state.crop = cropByFormat[state.format];
        this.setState({ isModalOpen: true, ...state }); }

    hide() { this.setState({ isModalOpen: false }); }
}

export default CropImageModal;
