import React from 'react';

import { MemoryComponent } from '../../memory';
import { Logo } from '../../svgs';
import { prettify } from '../../utils';

import BaseView from '../BaseView';

class StudentSchoolContact extends BaseView {
    render() {
        return (
            <div className='view-student-school-contact'>
                <h2>Contato</h2>
                <p className='-description'>
                    Por favor, entre em contato com a sua escola:
                </p>
                <div className='school-wrapper'>
                    <div key={ this.props.school.id }
                        onClick={() => {}}
                        className='school-template -school'
                        style={ { cursor: 'default' } }>

                        <div className='-logo-wrapper -wide'>
                            <div className='content logo'>
                                { this.props.user.dark_mode &&
                                    this.props.school.logo_dark_wide_url &&
                                <img src={ this.props.school.logo_dark_wide_url }
                                    alt={ this.props.school.name }
                                    title={ this.props.school.name } /> }

                                { this.props.user.dark_mode &&
                                    !this.props.school.logo_dark_wide_url && <Logo /> }

                                { !this.props.user.dark_mode && this.props.school.logo_wide_url &&
                                <img src={ this.props.school.logo_wide_url }
                                    alt={ this.props.school.name }
                                    title={ this.props.school.name } /> }

                                { !this.props.user.dark_mode &&
                                    !this.props.school.logo_wide_url && <Logo /> }
                            </div>
                        </div>

                        <h4>{ this.props.school.name }</h4>

                        { this.props.school.cellphone &&
                        <p>
                            <i translate='no' className='material-icons notranslate'>phone</i>
                            { prettify.phone(this.props.school.cellphone) }
                        </p> }

                        { this.props.school.email &&
                            <p>
                                <i translate='no' className='material-icons notranslate'>alternate_email</i>
                                { this.props.school.email }
                            </p> }
                    </div>
                </div>
            </div>
        );
    }
}

export default MemoryComponent(StudentSchoolContact, 'user', 'school');
