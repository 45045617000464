import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .init({
        fallbackLng: 'pt-BR',
        interpolation: {
            // react already does escaping
            escapeValue: false
        },
        resources: {
            'pt-BR': { translation: require('./i18n/pt-BR') }
        }
    });

export default i18n;
