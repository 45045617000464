import React, { useContext } from 'react';
import './styles/main.scss';
import { Logo, Sensei } from './svgs';
import { url, pad } from './utils';
import 'moment/locale/pt';

import { Link, Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import IseiContext from './Context';
import Sidebar from './components/Sidebar';

import ViewUserLogin from './views/user/UserLogin';
import ViewUserActivate from './views/user/UserActivate';
import ViewUserWelcome from './views/user/UserWelcome';
import ViewUserDefineNewPassword from './views/user/UserDefineNewPassword';
import ViewUserRecoverPassword from './views/user/UserRecoverPassword';
import ViewUserSignIn from './views/user/UserSignIn';
import ViewUserProfile from './views/user/UserProfile';

import routes from './routes';
import { MemoryComponent } from './memory';

import moment from 'moment';

import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

let isPixelLoaded = false;
let isAnalyticsLoaded = false;

moment.locale('pt-BR');
const history = createBrowserHistory();

history.listen(() => {
    if (isAnalyticsLoaded) {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    if (isPixelLoaded) {
        ReactPixel.pageView();
    }
});

const navbarMenus = {
    loggedIn: [
        { icon: 'emoji_events', feature: 'has_rank', link: '/rank' },
        { icon: 'notifications', feature: 'has_notifications', link: '/notificacoes' },
        { icon: 'chat', feature: 'has_support', onlyAdmin: true, link: '/suporte' },
        {
            icon: 'person',
            cls: '-avatar',
            link: 'minha-conta',
            submenu: [
                { label: 'Minha conta', icon: 'person', link: 'minha-conta' },
                { label: 'Sair', icon: 'exit_to_app', onClick(ev) {
                    ev.preventDefault();

                    localStorage.removeItem('jwtToken');
                    localStorage.removeItem('user');
                    localStorage.removeItem('schools');

                    window.location.href = url('');
                } },
            ]
        },
    ],
    none: [
        { icon: 'person', cls: '-avatar', link: 'minha-conta' }
    ]
};

function loggedInHomePageMultipleComponents(components) {
    return function (props) {
        const context = useContext(IseiContext.type);
        const isLoggedIn = context.hasUser;

        if (!isLoggedIn) {
            if (!context.hasSchool && components.iseiPublic) {
                const Component = components.iseiPublic;
                return <Component { ...props } />;

            }

            if (components.public) {
                const Component = components.public;
                return <Component { ...props } />;
            }

            props.history.replace(url('login?redirect=' + global.window.location.pathname));
            return null;
        }

        const urlParams = new URLSearchParams(window.location.search);

        if (!context.hasSchool && components.isei) {
            const Component = components.isei;
            return <Component { ...props } />;
        }

        const Component = components[urlParams.get('student') ?
            'student' :
            context.contextRole];

        if (!Component) {
            props.history.replace(url('login'));
            return null;
        }

        return <Component { ...props } />;
    }
}

function loggedInHomePage(Component, auth) {
    return function (props) {
        const context = useContext(IseiContext.type);
        const isLoggedIn = context.hasUser;

        if (isLoggedIn) {
            if (context.contextRole === 'admin' && auth === 'student') {
                return <Component {...props} />
            }

            if (context.contextRole === auth) {
                return <Component {...props} />
            }

            if (`${context.contextName}-${context.contextRole}` === auth) {
                return <Component {...props} />
            }

            props.history.replace(url('/'));
            return null;
        }

        props.history.replace(url('login?redirect=' + global.window.location.pathname));
        return null;
    }
}

class Header extends React.Component {
    getMoneyDollars() {
        const { balance } = this.props.bank;

        return balance.split('.')[0];
    }

    getMoneyCents() {
        const { balance } = this.props.bank;
        return balance.split('.')[1];
    }

    getPoints() {
        const { context, user } = this.props;
        const points = context.contextRole === 'admin' ? this.props.bank.points : user.karma;

        if (!points) {
            return '000';
        }

        return points && pad(points, 3, 0);
    }

    render() {
        const { context, menus, school, user } = this.props;
        const { dark_mode } = user;
        const { logo_wide_url, logo_dark_wide_url } = school;
        const name = school.name || 'iSei - a sua escola online';

        if (school.google_analytics_code) {
            ReactGA.initialize(school.google_analytics_code);
            ReactGA.pageview(window.location.pathname + window.location.search);

            isAnalyticsLoaded = true;
        }

        if (school.facebook_pixel) {
            let advancedMatching = null;

            if (user.id && user.email) {
                const nameParts = user.name.split(/\s/g);

                advancedMatching = {
                    em: user.email,
                    fn: nameParts[0]
                };

                if (nameParts.length > 1) {
                    advancedMatching.ln = nameParts[nameParts.length - 1];
                }
            }

            const options = { autoConfig: true, debug: false };

            ReactPixel.init(school.facebook_pixel, advancedMatching, options);
            ReactPixel.pageView();
            isPixelLoaded = true;
        }

        return (
            <header style={ { padding: '.5rem 2rem .5rem 2rem' } } className='main-header'>
                <div onClick={ () => { history.push(url('/')) } } className='logo'>
                    { dark_mode && logo_dark_wide_url && <img alt={ name } title={ name } src={ logo_dark_wide_url } /> }
                    { dark_mode && !logo_dark_wide_url && <Logo /> }

                    { !dark_mode && logo_wide_url && <img alt={ name } title={ name } src={ logo_wide_url } /> }
                    { !dark_mode && !logo_wide_url && <Logo /> }
                </div>
                <div className='scoring'>
                    <div onClick={ () => {
                        history.push(url('pontos'));
                    } } className='-points -wrapper'>
                        <div className='-icon'>
                            <i translate='no' className='material-icons notranslate'>emoji_events</i>
                        </div>
                        <div className='-value'>
                            <div className='-money-value'>
                                <span className='-dollars'>{
                                    this.getPoints()
                                }</span>
                            </div>
                            <span className='-currency'>pontos</span>
                        </div>
                    </div>

                { context.contextRole === 'admin' &&
                    <div onClick={ () => {
                        history.push(url('bank'));
                    } } className='-money -wrapper'>
                        <div className='-icon'>
                            <i
                                style={ { width: 20 } }
                                translate='no' className='material-icons notranslate'>attach_money</i>
                        </div>
                        <div className='-value'>
                            <div className='-money-value'>
                                <span className='-dollars'>{ this.getMoneyDollars() }</span>
                                <span className='-cents'>,{ this.getMoneyCents() }</span>
                            </div>
                            <span className='-currency'>reais</span>
                        </div>
                    </div> }
                </div>
                <ul className='-menu'>
                    {menus.filter((m) => {
                        if (m.feature && !school[m.feature]) { return false; }

                        return true;
                    }).map((m, idx) => <li key={idx}>
                        <Link to={url(m.link)}>
                            { m.cls === '-avatar' &&
                                <React.Fragment>
                                    { user.profile_img_url &&
                                        <img src={ user.profile_img_url } alt={ user.name } className='-avatar' /> }

                                    { !user.profile_img_url &&
                                        <div className='sensei'><Sensei /></div> }
                                </React.Fragment> }

                            { m.cls !== '-avatar' &&
                                <i className={`material-icons notranslate ${m.cls || ''}`}>{m.icon}</i> }
                        </Link>
                        {m.submenu && <ul className='-dropdown'>
                            {m.submenu.map((s, idx) => <li key={idx}>
                                <Link onClick={s.onClick} to={url(s.link)}>
                                    <i className={`material-icons notranslate ${s.cls || ''}`}>{s.icon}</i>
                                    {s.label}
                                </Link>
                            </li>)}
                        </ul>}
                    </li>)}
                </ul>
            </header>
        );
    }
}

class Footer extends React.Component {
    render() {
        return (
        <footer>
            <ul>
                <li className='copyright'>
                    { this.props.school.name || 'iSei' } &copy; { new Date().getFullYear() } 
                </li>
                <li>
                    <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                </li>
            </ul>
        </footer>);
    }
}

const HeaderWithMemory = MemoryComponent(Header, 'school', 'user', 'bank')
const FooterWithMemory = MemoryComponent(Footer, 'school');

class BodyComponent extends React.Component {
    render() {
        return (<div
            className={ this.props.sidebar === 'open' ? 'body' : 'body sidebar-small'}>{
                this.props.children }</div>)
    }
}

const Body = MemoryComponent(BodyComponent, 'sidebar');

class IseiAppContainer extends React.Component {
    static contextType = IseiContext.type;

    state = { menuState: 'open' };

    render() {
        const isTermos = /termos-e-condi|politica-de/.test(history.location.pathname);
        const isContact = history.location.pathname.endsWith('/contato');

        if (!this.context.hasSchool && !isTermos) {
            return <ViewUserLogin { ...this.props } />
        }

        const isSchoolAdmin = this.context.contextRole === 'admin';
        const menus = navbarMenus[this.context.hasUser ? 'loggedIn' : 'none'].filter((m) => {
            if (m.onlyStudent && isSchoolAdmin) { return false }
            if (m.onlyAdmin && !isSchoolAdmin) { return false }

            return true;
        });

        if (!isContact && this.context.isSchoolBlocked) {
            this.props.history.replace(url('contato'));
            return null;
        }

        if (this.context.isSchoolPrivate && !this.context.schoolRole && !isTermos) {
            this.props.history.replace(url('login?redirect=' + global.window.location.pathname));
            return null;
        }

        const currentPath = history.location.pathname;
        const currentRoute = routes.find((r) => r.path && currentPath.includes(r.path));

        const isWithoutHeader = (
            currentPath.includes(url('c/')) &&
            currentPath.replace(url('c/'), '').split('/').length >= 2
        );

        if (!global.window.Smallchat && isSchoolAdmin && window.innerWidth > 600) {
            global.window.Smallchat = {
                config: {
                    "slackTeamId": "T017SGUM5DX",
                    "scChannelId": "-MO2U5heXklLu3OUmEla",
                    "slackChannelId": "G01GTHBT8KB",
                    "uid": "-MO2U2lTbE5H1snKeCju",
                    "planId": null,
                    "accountCreated": 1607449984226
                }, appearance: {
                    "brand_color": "#2a72ee",
                    "contact_dismissible": false,
                    "contact_enabled": false,
                    "contact_field_label": "Email",
                    "contact_immediately": false,
                    "contact_prompt": "Adicione o seu nome e e-mail",
                    "contact_reply": "Thanks {{name}}! You'll get a response here or we'll contact you at {{contact}}.",
                    "custom_css": "",
                    "hide_logo": false,
                    "hide_team_icon": false,
                    "launcher_pos": "right",
                    "launcher_prompt": "Suporte online",
                    "launcher_type": "tab",
                    "messenger_blank": "Envie uma mensagem e responderemos o mais breve possível",
                    "messenger_entry": "Digite aqui a sua dúvida...",
                    "messenger_prompt": "Como podemos ajudar? :)",
                    "name_field_label": "Name",
                    "offline_greeting": "Estamos offline agora, mas nos envie uma mensagem que responderemos o mais breve possível.",
                    "text_color": "#FFFFFF"
                },
            };

            const styles = document.createElement('link');

            styles.rel = 'stylesheet';
            styles.href = 'https://static.small.chat/messenger.css';

            document.head.appendChild(styles);

            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://static.small.chat/messenger.js';

            script.onload = () => {
                setTimeout(() => {
                    if (!global.isInHome) {
                        document.querySelector('#Smallchat').style.display = 'none';
                    }
                }, 100);
            };

            document.body.appendChild(script);
        }

        return (<div id='app'>
            <Sidebar history={ history } />
            <Body>
                { !isWithoutHeader && <HeaderWithMemory
                    context={ this.context }
                    currentRoute={ currentRoute }
                    menus={ menus } /> }

                <main className={ !isWithoutHeader ? '-padding' : ''}>
                    <section className='-body'>
                        <Switch>
                            { routes.map((r, idx) => this.renderRoute(r, idx)) }
                        </Switch>
                    </section>

                    <FooterWithMemory />
                </main>
            </Body>
        </div>);
    }

    renderRoute(route, idx) {
        const path = `${global.baseUiPathname}/${route.path}`;

        if (route.auth) {
            return (
                <Route
                    key={ idx }
                    path={ path }
                    component={ loggedInHomePage(route.component, route.auth) } />
            );
        }

        if (route.components) {
            return (
                <Route
                    key={ idx }
                    path={ path }
                    component={ loggedInHomePageMultipleComponents(route.components) } />
            );
        }

        return <Route key={ idx } path={ path } component={ route.component } />;
    }
}

class App extends React.Component {
    static contextType = IseiContext.type;

    state = { menuState: 'open' };

    render() {
        if (!this.context.loaded) { return null; }

        return (<Router history={history}>
            <Switch>
                <Route path={ `${global.baseUiPathname}/login/:token` } component={ ViewUserLogin } />
                <Route path={ `${global.baseUiPathname}/login` } component={ ViewUserLogin } />
                <Route path={ `${global.baseUiPathname}/p/:link` } component={ ViewUserProfile } />
                <Route path={ `${global.baseUiPathname}/activate/:token` } component={ ViewUserActivate } />
                <Route path={ `${global.baseUiPathname}/welcome/:token` } component={ ViewUserWelcome } />
                <Route path={ `${global.baseUiPathname}/recover/:token` } component={ ViewUserDefineNewPassword } />
                <Route path={ `${global.baseUiPathname}/cadastro` } component={ ViewUserSignIn } />
                <Route path={ `${global.baseUiPathname}/recuperar-senha` } component={ ViewUserRecoverPassword } />
                <Route component={ IseiAppContainer } />
            </Switch>
        </Router>);
    }
}

export default App;
