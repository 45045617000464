import React from 'react';
import { Link } from "react-router-dom";
import BaseView from '../BaseView';

import { Logo } from '../../svgs';
import { url } from '../../utils';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';
import IseiContext from '../../Context';
import { MemoryComponent, updateMemory } from '../../memory';

function addTokenToUrl(url) {
    url += url.includes('?') ? '&' : '?';
    url += `auth=${localStorage.getItem('jwtToken')}`;

    return url;
}

function getAcademyAppName(school, user) {
    const role = user.role === 'academy-admin' ? user.role : [].concat(
        school.role
    )[0];

    return {
        'academy-admin': 'mib',
        'admin': 'skinner',
        'academy': 'skinner',
        'student': 'hero',
        'teacher': 'xavier'
    }[role];
}

class ViewSignIn extends BaseView {
    state = { timer: 5 };

    render() {
        const { school, user } = this.props;
        const { dark_mode } = user;
        const { logo_wide_url, logo_dark_wide_url } = school;
        const name = school.name || 'isei';

        if (!this.state.loaded) {
            return (
                <div className='app custom-page -signin'>
                    <LoadingMask fullScreen={ true } show={ true } />
                </div>);
        }

        const isAdmin = school && school.role && school.role.includes('admin');

        return (
            <div className='app custom-page -signin'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            { dark_mode && logo_dark_wide_url && <img src={ logo_dark_wide_url } alt={ name } title={ name } /> }
                            { dark_mode && !logo_dark_wide_url && <Logo /> }

                            { !dark_mode && logo_wide_url && <img src={ logo_wide_url } alt={ name } title={ name } /> }
                            { !dark_mode && !logo_wide_url && <Logo /> }
                        </div>
                    </header>
                    <main>
                        <h2 style={ { margin: 0 } }>Pronto, { user.name.split(' ')[0] } !</h2>
                        {
                            isAdmin &&
                        <p className='-secondary'>Sua área de membros está pronta! (eba!)<br />Agora é só esperar {this.state.timer} segundo{ this.state.timer > 1 ? 's' : '' } para ser redirecionado ou <a
                            onClick={ (ev) => {
                                ev.preventDefault();
                                this.goToMySchool();
                            }}
                        href={ url('/') }>clicar aqui</a></p> }

                        {
                            !isAdmin &&
                        <p className='-secondary'>Sua conta está pronta! (eba!)<br /> Agora é só esperar {this.state.timer} segundo{ this.state.timer > 1 ? 's' : '' } para ser redirecionado ou <a
                            onClick={ (ev) => {
                                ev.preventDefault();
                                this.goToMySchool();
                            }}
                        href={ url('/') }>clicar aqui</a></p> }
                    </main>
                </div>

                <footer>
                    <ul>
                        <li className='copyright'>
                            iSei &copy; 2020
                        </li>
                        <li>
                            <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                        </li>
                    </ul>
                </footer>
                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }

    async componentDidMount() {
        super.componentDidMount();

        global.isActivating = true;

        try {
            const { token } = this.props.match.params;
            const res = await ajaxAdapter({
                isSchoolRequest: Boolean(this.props.school.id)
            }).get('activate/' + token);

            const { user, school } = res;
            updateMemory({ user, school });

            localStorage.setItem('lastActivateSchoolUrl', '/escola/' + school.id);

            this.setState({ loaded: true });
            this.interval = setInterval(() => {
                const timer = --this.state.timer;
                this.setState({ timer });

                if (timer === 0) {
                    clearInterval(this.interval);
                    this.goToMySchool();
                }
            }, 1000);
        } catch(ex) {
            console.error(ex);
            await this.goToMySchool();
        }
    }

    async goToMySchool() {
        if (this.hasGoneToMySchool) { return; }
        const { school, user } = this.props;

        if (school.is_academy) {
            let url = '';

            if (global.baseUiUrl.includes('localhost')) {
                url = 'http://localhost:4000'
            }

            url += `${global.baseUiPathname}/${getAcademyAppName(school, user)}/`;
            url = addTokenToUrl(url);

            window.location.href = url;
            return;
        }

        this.hasGoneToMySchool = true;
        await IseiContext.load(
            null, localStorage.getItem('lastActivateSchoolUrl') || url('/')
        );
        this.props.history.replace(localStorage.getItem('lastActivateSchoolUrl') || url('/'));
    }
}

export default MemoryComponent(ViewSignIn, 'school', 'user');
