import React from 'react';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent, updateMemory } from '../../memory';
import { url } from '../../utils';
import BaseView from '../BaseView';
import LoadingMask from '../../components/LoadingMask';

class GatewayList extends BaseView {
    state = { isLoading: true, gatewayList: [ ] };

    _findDegree(id) {
        const templateCategories = this.props
            .templateSchools
            .reduce((curr, s) => curr.concat(s.categories), []);

        const categories = this.props.school.categories
            .concat(templateCategories);

        for (const cat of categories) {
            if (!cat.degrees) { continue; }

            for (const degree of cat.degrees) {
                if (degree.id === id) { return degree; }
            }
        }

        throw Error(`Cannot find degree ${id}`);
    }

    degreeExists(id) {
        try {
            this._findDegree(id);
        } catch (ex) {
            return false;
        }

        return true;
    }

    getSelectedDegreesText(gateway) {
        const { all_degrees, is_public, degrees } = gateway;

        if (all_degrees) {
            return 'Todos os cursos';
        }

        if (is_public) {
            return 'Todos os cursos públicos';
        }

        if (!degrees) { return '(nenhum curso)'; }

        if (degrees.length <= 3) {
            const degreeTitles = degrees
                .filter(this.degreeExists.bind(this))
                .map((id) => {
                    const degree = this._findDegree(id);

                    return degree.title;
                });

            const last = degreeTitles.pop();

            if (degreeTitles.length) {
                return `${degreeTitles.join(', ')} e ${last}`;
            } else {
                return last || '(nenhum curso)';
            }
        }

        return `${degrees.length} cursos`
    }

    renderGateway(gateway) {
        const totalIntegrations = gateway.integrations.length - 1;
        const lastIntegration = gateway.integrations[gateway.integrations.length - 1];

        return (
            <div key={ gateway.id } className='-gateway' onClick={ () => {
                this.props.history.push(url(`turma/${gateway.id}`));
            }}>
                <h4>{ gateway.title }</h4>

                { totalIntegrations === 1 &&
                    <p><i translate='no' className='material-icons notranslate'>integration_instructions</i> { lastIntegration.title }</p> }

                { totalIntegrations > 1 &&
                    <p><i translate='no' className='material-icons notranslate'>integration_instructions</i> { totalIntegrations } integrações</p> }
                { gateway.is_public && <p><i translate='no' className='material-icons notranslate'>lock_open</i> Todos os cursos públicos</p> }
                { !gateway.is_public && <p><i translate='no' className='material-icons notranslate'>lock</i> { this.getSelectedDegreesText(gateway) }</p> }

                { gateway.totalStudents > 0 &&
                <p><i translate='no' className='material-icons notranslate'>people</i> { gateway.totalStudents } aluno{ gateway.totalStudents > 1 ? 's' : '' } matriculado{ gateway.totalStudents > 1 ? 's' :'' }</p> }
            </div>
        );
    }

    render() {
        const gatewayList = this.props.gatewayList
            .filter((c) => !this.props.school.is_private || !c.is_public);
        return (
            <div className='view-gateway-list'>
                <h2>Minhas turmas</h2>

                <p className='-description'>
                    Crie planos para liberar os cursos para os seus alunos. Você já começa com duas turmas por padrão, uma para todos os seus cursos público e outra para todos os cursos privados.<br />
                    Você pode adicionar quantas integrações quiser para cada turma.
                </p>

                <div className='gateway-list-wrapper'>
                    <div onClick={ () => {
                        this.props.history.push(url('turma/criar'));
                    } } className='-add'>
                        <i translate='no' className='material-icons notranslate'>add_box</i>
                        <p>Criar Turma</p>
                    </div>

                    { gatewayList.map(this.renderGateway.bind(this))}
                </div>

                <LoadingMask fullScreen={ true } show={
                    this.state.isLoading && this.props.gatewayList.length === 0
                } />
            </div>
        );
    }

    async componentDidMount() {
        super.componentDidMount();

        try {
            const { gatewayList } = await ajaxAdapter({ isSchoolRequest: true }).get('gateway-list');

            updateMemory({ gatewayList });

            this.setState({ isLoading: false });
        } catch(ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(GatewayList, 'school', 'gatewayList', 'templateSchools');
