import { connect } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import config from './config';
import { setDocumentTitle } from './utils';

function isDraft(current, draft) {
    if (!current.utc_published_on) { return true; }
    if (!draft || JSON.stringify(draft) === '{}') {
        if (current.modules) {
            for (const mod of current.modules) {
                if (!mod.content) { continue; }

                for (const content of mod.content) {
                    if (content.isDraft) { return true; }
                }
            }
        }

        return false;
    }

    for (const key in current) {
        if (key === 'id' || key === 'draft') { continue; }

        if (draft[key] !== undefined &&
            JSON.stringify(current[key]) !== JSON.stringify(draft[key])) {
            return true;
        }

        if (key === 'modules') {
            const modules = [].concat(current.modules || []).concat(draft.modules || [])
            for (const mod of modules) {
                if (!mod.content) { continue; }

                for (const content of mod.content) {
                    if (content.id && (content.isDraft || isDraft(content, content.draft))) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
}

function decorateWithDraftIfPossible(value, baseValue) {
    if (!value || JSON.stringify(value) === '{}') { return baseValue; }

    if (localStorage.getItem('contextRole') === 'student' &&
        localStorage.getItem('contextOverride') !== 'student') {
        return { ...value, ...(value.currentValue || {}), isDraft: false };
    }

    value = {
        ...value,
        ...(value.draft || {}),
        isDraft: value.isDraft || isDraft(value, value.draft),
        currentValue: { ...(value.currentValue || value) }
    };

    if (value.draft?.modules) {
        value.modules = [ ...value.draft.modules ];
    }

    if (value.draft?.external_links) {
        value.external_links = [ ...value.draft.external_links ];
    }

    if (value.draft?.files) {
        value.files = [ ...value.draft.files ];
    }

    return value;
}

const store = createStore(combineReducers({
    sidebar(state, action) {
        if (!state) {
            state = localStorage.getItem('sidebarState') || 'open';

            const element = document.querySelector('main footer');

            if (!element) {
                setTimeout(() => {
                    const el = document.querySelector('main footer');

                    if (el) {
                        el.className = state === 'small' ? '-visible' : '';
                    }
                }, 500)
            } else {
                element.className = state === 'small' ? '-visible' : '';
            }
        }

        if (action.type === 'update/sidebar') {
            localStorage.setItem('sidebarState', action.value);

            const element = document.querySelector('main footer');

            if (!element) {
                setTimeout(() => {
                    const el = document.querySelector('main footer');

                    if (el) {
                        el.className = action.value === 'small' ? '-visible' : '';
                    }
                }, 500)
            } else {
                element.className = action.value === 'small' ? '-visible' : '';
            }

            return action.value;
        }

        return state;
    },

    school(state = {}, action) {
        if (action.type === 'update/school') {
            const school = action.value || {};
            const hasSchool = school && school.id;

            school.categories = school.categories || [];

            if (hasSchool && !school.role && state.role) {
                school.role = state.role;
            }

            const withoutCat = state.categories && state.categories.find((c) => c.withoutCat)

            if (withoutCat) {
                let { degrees } = withoutCat;

                degrees = degrees.filter((c) => {
                    return c.id !== school.deletedDegreeId &&
                        !helpersByMemory.school.tryFindDegree(school, c.link);
                })

                if (degrees.length && !school.categories.find((c) => c.withoutCat)) {
                    school.categories.push(withoutCat);
                }
            }

            setDocumentTitle((hasSchool && school.name) || 'iSei - a sua escola online');

            if (hasSchool && !school.namespace && state.namespace) {
                school.namespace = state.namespace;
            }

            if (hasSchool && school.namespace && (school.namespace.namespace || school.namespace.domain)) {
                const { domain, namespace } = school.namespace;
                const useDomain = domain && domain === global.window.location.host;

                global.baseUiPathname = useDomain ? '' : `/${namespace}`;
                global.baseUiUrl = useDomain ? window.location.protocol + `//${domain}`
                    : `${config.baseAppUrl}/${namespace}`;
            } else {
                global.baseUiPathname = hasSchool ? `/escola/${school.id}` : '';
                global.baseUiUrl = `${config.baseAppUrl}${global.baseUiPathname}`;
            }

            global.baseApiPath = hasSchool && `/school/${school.id}`;
            global.defaultBaseSchoolUrl = hasSchool && `${config.baseAppUrl}/escola/${school.id}`;

            if (global.baseUiPathname === '/') {
                global.baseUiPathname = '';
            }

            if (hasSchool && school.ui_theme) {
                let theme = school.ui_theme;

                if (global.user.dark_mode) {
                    theme = `dark-${theme}`;
                }

                document.querySelector('html').className = `theme -${theme}`;
            } else {
                document.querySelector('html').className = `theme -default`;
            }

            const { logo_square_url, logo_dark_square_url } = school;
            const isDarkMode = window.matchMedia &&
                window.matchMedia('(prefers-color-scheme: dark)').matches;

            let faviconHref;
            if (logo_square_url || logo_dark_square_url) {
                faviconHref = (isDarkMode && logo_dark_square_url) || logo_square_url;
            } else {
                faviconHref = 'https://deqlry6h35w7w.cloudfront.net/s/31/46140f78-4c52-4527-9ff4-f814041cad4d-favicon.png';
            }

            const link = document.querySelector("link[rel*='icon']") ||
                document.createElement('link');

            if (faviconHref !== link.href) {
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = faviconHref;

                document.getElementsByTagName('head')[0].appendChild(link);
            }

            global.school = school;
            return school;
        }

        return state;
    },

    user(state = {}, action) {
        if (action.type === 'update/user') {
            const user = action.value;
            const school = global.school;

            global.user = user;

            if (school && school.ui_theme) {
                let theme = school.ui_theme;

                if (global.user.dark_mode) {
                    theme = `dark-${theme}`;
                }

                document.querySelector('html').className = `theme -${theme}`;
            }

            return user;
        }

        return state;
    },

    degree(state, action) {
        const baseDegree =  { modules: [ ], draft: { }, _base: {} };

        state =  state || baseDegree;

        if (action.type === 'update/degree') {
            state = decorateWithDraftIfPossible({ ...action.value }, baseDegree);
        }

        return state;
    },

    content(state, action) {
        const baseContent = {
            media: { },
            draft: { },
            files: [ ],
            external_links: [ ]
        };

        state = state || baseContent;

        if (action.type === 'update/content') {
            state = decorateWithDraftIfPossible({ ...action.value }, baseContent);
        }

        return state;
    },
    bank(state = { balance: '0.00', points: 0 }, action) {
        if (action.type === 'update/bank') { return action.value; }
        return state;
    },

    resource(state = { }, action) {
        if (action.type === 'update/resource') { return action.value; }
        return state;
    },

    gatewayList(state = [], action) {
        if (action.type === 'update/gatewayList') { return action.value; }
        return state;
    },

    certificateList(state = [], action) {
        if (action.type === 'update/certificateList') { return action.value; }
        return state;
    },

    acl(state = { degrees: {}, school_gateways: {}, allDegreesAccess: [] }, action) {
        if (action.type === 'update/acl') { return action.value; }
        return state;
    },
    bookmarks(state = [], action) {
        if (action.type === 'update/bookmarks') { return action.value; }
        return state;
    },
    schools(state = { total: 0, list: [], page: 1 }, action) {
        if (action.type === 'update/schools') { return action.value; }
        return state;
    },
    students(state = { total: 0, list: [], page: 1 }, action) {
        if (action.type === 'update/students') { return action.value; }
        return state;
    },

    templateSchools(state = [], action) {
        if (action.type === 'update/templateSchools') { return action.value; }
        return state;
    },

    templateSchoolsInApproval(state = [], action) {
        if (action.type === 'update/templateSchoolsInApproval') {
            return action.value;
        }

        return state;
    },

    schoolTemplatesPlans(state = [], action) {
        if (action.type === 'update/schoolTemplatesPlans') {
            return action.value;
        }

        return state;
    },

    'html_politica-de-privacidade': (state = '', action) => {
        if (action.type === 'update/html_politica-de-privacidade') {
            return action.value;
        }

        return state;
    },

    'html_termos-e-condicoes': (state = '', action) => {
        if (action.type === 'update/html_termos-e-condicoes') {
            return action.value;
        }

        return state;
    }
}));

const helpersByMemory = {
    school: {
        tryFindDegree(school, link) {
            for (const cat of school.categories) {
                if (!cat.degrees) { continue; }

                for (const degree of cat.degrees) {
                    if (degree.link === link) { return degree; }
                }
            }

            return null;
        }
    },
    degree: {
        tryFindContent(degree, link) {
            for (const mod of degree.modules) {
                if (!mod.content) { continue; }

                for (const content of mod.content) {
                    const cnt = decorateWithDraftIfPossible(content, content)

                    if (cnt.link === link) {
                        return cnt;
                    }
                }
            }

            return null;
        }

    }
};

function updateMemory(obj) {
    for (const key in obj) {
        store.dispatch({ type: `update/${key}`, value: obj[key] })
    }
}

function MemoryComponent(Component, ...args) {
    return connect((state) => {
        const props = {};

        for (const arg of args) {
            props[arg] = state[arg];

            const helpers = helpersByMemory[arg];
            if (helpers) {
                for (const fnName in helpers) {
                    props[fnName] = helpers[fnName].bind(null, state[arg]);
                }
            }
        }

        return props;
    }, () => ({ updateMemory }))(Component);
}


function getMemory(key) {
    return store.getState()[key];
}

window.addEventListener('resize', function() {
    if (window.innerWidth < 900) {
        updateMemory({ sidebar: 'small' });
    } else {
        updateMemory({ sidebar: 'open' });
    }
});

if (!localStorage.getItem('sidebarState')) {
    if (window.innerWidth < 900) {
        updateMemory({ sidebar: 'small' });
    } else {
        updateMemory({ sidebar: 'open' });
    }
}

export { store, MemoryComponent, updateMemory, getMemory };
