import React from 'react';
import { Link } from "react-router-dom";
import BaseView from '../BaseView';

import { Logo } from '../../svgs';

import {
    validations,
    url,
    getQueryString,
    nextTick,
    isAcademyUrl
} from '../../utils';

import IseiForm from '../../components/Form';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent, updateMemory } from '../../memory';
import IseiContext from '../../Context';

function addTokenToUrl(url) {
    url += url.includes('?') ? '&' : '?';
    url += `auth=${localStorage.getItem('jwtToken')}`;

    return url;
}

function getAcademyAppName(school, user) {
    const role = user.role === 'academy-admin' ? user.role : [].concat(
        school.role
    )[0];

    return {
        'academy-admin': 'mib',
        'admin': 'skinner',
        'academy': 'skinner',
        'student': 'hero',
        'teacher': 'xavier'
    }[role];
}

const loginFields = {
    email: {
        type: 'text',
        icon: 'person',
        placeholder: 'Preencha seu e-mail para acessar',
        autoComplete: 'email',
        selectOnFocus: true,

        getPrettyValue(value) {
            return (value &&
                value.toLowerCase &&
                value.toLowerCase().replace().replace(/\s/g, '')
            ) || value;
        },

        getValidationError: validations.email
    },

    password: {
        type: 'password',
        icon: 'locker',
        placeholder: 'Preencha aqui sua senha',
        autoComplete: 'current-password',
        selectOnFocus: true,
        getValidationError: validations.password
    }
};

class Login extends BaseView {
    state = { isLoading: true };

    async resendRegisterEmail() {
        try {
            const postData = {
                email: this.state.email,
                password: this.state.password,
                schoolId: this.props.school.id
            };
            await ajaxAdapter({
                showLoading: true
            }).post(url('user/resend-signup'), postData);
        } catch (ex) {
            console.error(ex);
            this.setState({ success: null, error: ex.message, justUpdated: false });
        }
    }

    async onSubmit(values) {
        try {
            this.setState({
                isLoading: true,
                success: null,
                mustConfirmEmail: false,
                error: null,
                errorByField: {},
                ...values
            })

            const postData = {
                ...values,
                degreeLink: global.localStorage.getItem('degreeLink'),
                schoolId: this.props.school.id
            };

            const res = await ajaxAdapter().post('/user/login', postData);
            updateMemory({ user: res.user, school: res.school })

            nextTick(() => {
                const redirect = getQueryString('redirect');

                let url = redirect && redirect !== '/' ?
                    redirect :
                    global.baseUiUrl;

                if (isAcademyUrl(url)) {
                    url = addTokenToUrl(url);
                } else if (res.school && res.school.is_academy) {
                    if (global.baseUiUrl.includes('localhost')) {
                        url = 'http://localhost:4000'
                    }

                    url += `${global.baseUiPathname}/${getAcademyAppName(res.school, res.user)}/`;
                    url = addTokenToUrl(url);
                }

                window.location.href = url;
            });
        } catch (ex) {
            if (/Must confirm email/.test(ex.message)) {
                this.setState({
                    isLoading: false,
                    error: 'Por favor, confirme o seu e-mail para acessar',
                    mustConfirmEmail: true
                });
                return;
            }
            console.error(ex);
            this.setState({ isLoading: false, success: null, error: ex.message, justUpdated: false });
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        const { token } = this.props.match.params;

        if (!token) {
            this.setState({ isLoading: false });
            return;
        }

        try {
            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).post('/user/login-by-token', { token });
            updateMemory({ user: res.user, school: res.school })

            nextTick(async () => {
                await IseiContext.load();
                const redirect = getQueryString('redirect');

                this.props.history.replace(
                    redirect && redirect !== '/' ?  redirect : url('/')
                );

                this.setState({ isLoading: false });
            });
        } catch (ex) {
            this.setState({ isLoading: false });
            global.error(ex, false);
        }
    }

    render() {
        const { school, user } = this.props;
        const { dark_mode } = user;
        const { logo_wide_url, logo_dark_wide_url } = school;
        const name = school.name || 'isei';

        return (
            <div className='app custom-page -login'>
                <div className='box -size-2'>
                    <header>
                        <div className='logo'>
                            { dark_mode && logo_dark_wide_url && <img
                                src={ logo_dark_wide_url }
                                alt={ name }
                                title={ name } /> }

                            { dark_mode && !logo_dark_wide_url && <Logo /> }

                            { !dark_mode && logo_wide_url && <img
                                src={ logo_wide_url }
                                alt={ name }
                                title={ name } /> }

                            { !dark_mode && !logo_wide_url && <Logo /> }
                        </div>
                    </header>
                    <main>
                        <p className='-secondary'>Olá!</p>
                        <p className='-secondary'>Preencha seus dados abaixo para entrar:</p>
                            { this.state.success && <div className='alert -success'>{this.state.success}</div>}
                            { !this.state.mustConfirmEmail && this.state.error &&
                                <div className='alert -error'>{this.state.error}</div>}

                            {this.state.mustConfirmEmail && <React.Fragment>
                                <div style={ { marginBottom: 0 } } className='alert -error'>{this.state.error}</div>

                                <small style={ {
                                    margin: '.5rem',
                                    marginBottom: '1rem',
                                    display: 'block'
                                } }>
                                    Caso ainda não tenha recebido o e-mail de confirmação, <a href='/' onClick={(ev) => {
                                        ev.preventDefault();

                                        this.resendRegisterEmail();
                                        this.setState({
                                            error: null,
                                            mustConfirmEmail: false,
                                            success: 'E-mail enviado com sucesso!'
                                        });

                                    }}>clique aqui</a> para enviar novamente.</small>
                                </React.Fragment>}
                        <IseiForm
                            onSubmit={ this.onSubmit.bind(this) }
                            fields={ loginFields }
                            submitButton={ { label: `Acessar ${name}` } } /><br />

                        <p>Esqueceu a sua senha? <Link to={ url('recuperar-senha') }>clique aqui</Link></p>
                        { !this.props.school.is_private &&
                        <p>Ainda não tem cadastro? <Link to={ url('cadastro') }>clique aqui</Link></p> }
                    </main>

                    <footer>
                        <ul>
                            <li className='copyright'>
                                { this.props.school.name || 'iSei' } &copy; { new Date().getFullYear() }
                            </li>
                            <li>
                                <Link to={ url('termos-e-condicoes') }>Termos e Condições</Link>
                            </li>
                        </ul>
                    </footer>
                </div>

                <LoadingMask fullScreen={ true } show={ this.state.isLoading } />
            </div>
        );
    }
}

export default MemoryComponent(Login, 'school', 'user');
