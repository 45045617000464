import React from 'react';
import Dropzone from 'react-dropzone'

import { url, getInnerValueByEvent } from '../../utils';
import { updateMemory, MemoryComponent } from '../../memory';
import ajaxAdapter from '../../ajaxAdapter';
import { prettify, capitalize, copyToClipboard } from '../../utils';
import IseiForm from '../../components/Form';
import BaseView from '../BaseView';
import LoadingMask from '../../components/LoadingMask';
import PaginationComponent from '../../components/Pagination';
import config from '../../config';
import { Link } from 'react-router-dom';
import SelectDegreesModal from '../../components/SelectDegreesModal';

import DOMPurify from 'dompurify';

function getId() { return String(new Date().getTime()); }

const integrationConfig = {
    bravapay: {
        logo: require('../../assets/integrations/bravapay.png'),
        title: 'BravaPay'
    },

    hotmart: {
        logo: require('../../assets/integrations/hotmart.png'),
        title: 'Hotmart'
    },

    eduzz: {
        logo: require('../../assets/integrations/eduzz.png'),
        title: 'Eduzz'
    },

    octapay: {
        logo: require('../../assets/integrations/octapay.png'),
        title: 'OctaPay'
    },

    mercadopago: {
        logo: require('../../assets/integrations/mercadopago.png'),
        title: 'MercadoPago'
    },

    fornece: {
        logo: require('../../assets/integrations/fornece.png'),
        title: 'fornece.club'
    }
};


const siginStudentFields = {
    name: {
        type: 'text',
        icon: 'person',
        placeholder: 'O nome completo',
        autoComplete: 'name',
        getPrettyValue: capitalize,

        getValidationError(str) {
            if (!str || str.length < 4) {
                return 'Por favor, preencha o nome';
            }

            return false;
        }
    },
    email: {
        type: 'text',
        icon: 'alternate_email',
        placeholder: 'E-mail para acessar a plataforma',
        autoComplete: 'email',
        selectOnFocus: true,

        getPrettyValue(value) {
            return (value &&
                value.toLowerCase &&
                value.toLowerCase().replace().replace(/\s/g, '')
            ) || value;
        },

        getValidationError(value) {
            if (!value || !/.+@.+\.[A-Za-z]+$/.test(value)) {
                return 'Preencha o e-mail corretamente';
            }

            return null;
        }
    },

    cellphone: {
        type: 'text',
        icon: 'phone_iphone',
        placeholder: 'exemplo: (19) 9999.99999',
        maxLength: 20,
        selectOnFocus: true,
        autoComplete: 'tel',

        getPrettyValue(val) {
            return prettify.phone(val);
        }
    }
};

const rgx = {
    csvDelimiter: /(,|;)/g,
    isValidEmail: /\S+@\S+\.\w+$/
};

function getStudentsFromCsv(csv) {
    const lines = csv.split(/\r?\n/g);
    if (!lines.length) { return []; }

    const students = [];
    for (const line of lines) {
        const lineColumns = line.split(rgx.csvDelimiter);

        for (let value of lineColumns) {
            value = value.trim().toLowerCase().replace(/[^\w.@]/g, '');

            if (rgx.isValidEmail.test(value)) {
                students.push(value);
            }
        }
    }

    return students;
}

class ImportStudentModal extends React.Component {
    state = { isModalOpen: false, students: [ ] };

    render() {
        if (!this.state.isModalOpen) { return null; }

        return (
            <div className={`modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}`}>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}

                        <p className='-description'>
                            Você está prestes a importar {
                                this.state.students.length
                            } alunos para a sua escola.<br />
                            <br />
                            Confira abaixo uma preview dos alunos:
                        </p>

                        { this.state.students.slice(0, 3).map((email) => (
                            <p key={ email } className='-secondary'>{ email }</p>
                        )) }

                        <p>...</p>

                        <p style={ { marginTop: '1rem' } }>
                            <strong>Confirma essa ação?</strong>
                        </p>

                        <div className='-footer'>
                            <button type='button' onClick={async(ev) => {
                                await this.props.onSubmit(this.state.students, this.state.filename);
                                this.hide();
                            }} className='btn -primary'>
                                Importar { this.state.students.length } alunos.
                            </button>
                            <button type='button' onClick={() => {
                                this.setState({ isModalOpen: false });
                            }} className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            name: '',
            email: '',
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class AddStudentModal extends React.Component {
    state = {
        isModalOpen: false,
        cellphone: '',
        name: '',
        email: ''
    };

    render() {
        if (!this.state.isModalOpen) { return null; }

        return (
            <div className={`modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}`}>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}

                        <p className='-description'>Preencha abaixo os dados de seu aluno:</p>

                        <IseiForm
                            ref='form'
                            onSubmit={this._submit.bind(this)}
                            values={this.state.values}
                            fields={siginStudentFields} />

                        <div className='-footer'>
                            <button type='button' onClick={(ev) => {
                                this.refs.form.onSubmit(ev)
                            }} className='btn -primary'>
                                Adicionar aluno
                            </button>
                            <button type='button' onClick={() => {
                                this.setState({ isModalOpen: false });
                            }} className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit(values) {
        this.props.onSubmit(values);
        this.hide();
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            name: '',
            email: '',
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class AddIntegrationModal extends React.Component {
    state = {
        isModalOpen: false,
        integrationType: null
    };

    render() {
        if (!this.state.isModalOpen) { return null; }
        const itgConfig = integrationConfig[this.state.integrationType];

        return (
            <div className={`modal -cropper ${this.state.isModalOpen ? '-open' : ''}`}>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}

                        <div className='field-wrapper'>
                            <label>Selecione o gateway:</label>
                            <div className='integrations-list'>
                                {Object.entries(integrationConfig).map(([key, cfg]) =>
                                    <div onClick={() => {
                                        this.setState({
                                            integrationType: key,
                                            title: integrationConfig[key].title,
                                            logo: integrationConfig[key].logo
                                        });
                                    }} className={
                                        this.state.integrationType === key ?
                                            `-integration -${key} -active` :
                                            `-integration -${key}`
                                    }>
                                        <img src={cfg.logo} alt={cfg.title} title={cfg.title} />
                                        <h4>{cfg.title}</h4>
                                    </div>
                                )}
                            </div>
                        </div>

                        {this.state.integrationType && this.state.integrationType === 'fornece' &&
                            <React.Fragment>
                                <div className='field-wrapper' onClick={() => { this.refs[`code`].focus(); }} >
                                    <div className={this.state.focus === 'code' ? 'field -active' : 'field'} >
                                        <div className='icon'>
                                            <i translate='no' className='material-icons notranslate'>lock</i>
                                        </div>
                                        <div className='input'>
                                            <input
                                                ref={`code`}
                                                onFocus={(ev) => {
                                                    ev.target.select();
                                                    this.setState({ focus: 'key' })
                                                }}
                                                value={this.state.code || ''}
                                                maxLength={200}
                                                onChange={(ev) => {
                                                    this.setState({
                                                        code: ev.nativeEvent.target.value,
                                                        ids: [ 'fornece' ]
                                                    });
                                                }}

                                                onBlur={(ev) => {
                                                    this.setState({
                                                        focus: null,
                                                        ids: [ 'fornece' ],
                                                        code: ev.nativeEvent.target.value.replace(/\s/g, '')
                                                    });
                                                }}
                                                placeholder='ID do produto' type='text' />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment> }

                        {this.state.integrationType && this.state.integrationType === 'mercadopago' &&
                            <React.Fragment>
                                <p className='service-description' style={{
                                    marginTop: '1rem', textAlign: 'left', marginBottom: '1rem'
                                }}>
                                    Para integrar configure a url: <a
                                        href={`${config.baseApiUrl}/webhook/${this.props.school.id}/${this.props.gatewayId}`}
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            copyToClipboard(`${config.baseApiUrl}/webhook/${this.props.school.id}/${this.props.gatewayId}`);
                                    }}>{config.baseApiUrl}/webhook/{this.props.school.id}/{this.props.gatewayId}</a> no {itgConfig.title}
                                    <br />Em:&nbsp;<a
                                            rel="noopener noreferrer"
                                            href='https://www.mercadopago.com.br/developers/panel/notifications' target='_blank'>
                                            https://www.mercadopago.com.br/developers/panel/notifications
                                        </a> e adicione o seu Access Token abaixo:
                                </p>

                                <div className='field-wrapper' onClick={() => { this.refs[`code`].focus(); }} >
                                    <div className={this.state.focus === 'code' ? 'field -active' : 'field'} >
                                        <div className='icon'>
                                            <i translate='no' className='material-icons notranslate'>lock</i>
                                        </div>
                                        <div className='input'>
                                            <input
                                                ref={`code`}
                                                onFocus={(ev) => {
                                                    ev.target.select();
                                                    this.setState({ focus: 'key' })
                                                }}
                                                value={this.state.code || ''}
                                                maxLength={200}
                                                onChange={(ev) => {
                                                    this.setState({ code: ev.nativeEvent.target.value });
                                                }}

                                                onBlur={(ev) => {
                                                    this.setState({
                                                        focus: null,
                                                        code: ev.nativeEvent.target.value.replace(/\s/g, '')
                                                    });
                                                }}
                                                placeholder='Access Token' type='text' />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment> }

                        {this.state.integrationType && ![ 'mercadopago', 'fornece', ].includes(this.state.integrationType) &&
                            <React.Fragment>
                                <p className='service-description' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                                    Para integrar configure a url: <a href={`${config.baseApiUrl}/webhook`} onClick={(ev) => {
                                        ev.preventDefault();
                                        copyToClipboard(`${config.baseApiUrl}/webhook`);
                                    }}>{config.baseApiUrl}/webhook</a> no {itgConfig.title} e adicione os seus dados abaixo:
                                </p>

                                <div className='field-wrapper' onClick={() => { this.refs[`code`].focus(); }} >
                                    <div className={this.state.focus === 'code' ? 'field -active' : 'field'} >
                                        <div className='icon'>
                                            <i translate='no' className='material-icons notranslate'>lock</i>
                                        </div>
                                        <div className='input'>
                                            <input
                                                ref={`code`}
                                                onFocus={(ev) => {
                                                    ev.target.select();
                                                    this.setState({ focus: 'key' })
                                                }}
                                                value={this.state.code || ''}
                                                maxLength={200}
                                                onChange={(ev) => {
                                                    this.setState({ code: ev.nativeEvent.target.value });
                                                }}

                                                onBlur={(ev) => {
                                                    this.setState({
                                                        focus: null,
                                                        code: ev.nativeEvent.target.value.replace(/\s/g, '')
                                                    });
                                                }}
                                                placeholder='O seu código/token de integração' type='text' />
                                        </div>
                                    </div>
                                </div>

                                <div className='field-wrapper' onClick={() => { this.refs[`ids`].focus(); }} >
                                    <div className={this.state.focus === 'ids' ? 'field -active' : 'field'} >
                                        <div className='icon'>
                                            <i translate='no' className='material-icons notranslate'>dashboard</i>
                                        </div>
                                        <div className='input'>
                                            <input
                                                ref={`ids`}
                                                onFocus={(ev) => {
                                                    ev.target.select();
                                                    this.setState({ focus: 'key' })
                                                }}
                                                value={this.state.ids || ''}
                                                maxLength={200}
                                                onChange={(ev) => {
                                                    this.setState({ ids: ev.nativeEvent.target.value });
                                                }}

                                                onBlur={() => {
                                                    let ids = [];

                                                    if (this.state.ids && this.state.ids.split) {
                                                        ids = this.state.ids.split(/[,\s.]/g).filter((s) => s && String(s).trim());
                                                    } else if (this.state.ids) {
                                                        ids = [].concat(this.state.ids);
                                                    }

                                                    this.setState({ focus: null, ids });
                                                }}
                                                placeholder='IDs dos seus produtos' type='text' />
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>}

                        <div className='-footer'>
                            {this.state.integrationType &&
                                <button type='button' onClick={this._submit.bind(this)} className='btn -primary'>Salvar</button>}
                            <button type='button' onClick={() => {
                                this.setState({ isModalOpen: false });
                            }} className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit() {
        try {
            this.props.onSubmit({ ...this.state });
            this.hide();
        } catch (ex) {
            console.error(ex);
            this.setState({ error: ex.message, justUpdated: false });
        }

    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            integrationType: null,
            id: null,
            code: '',
            ids: '',
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class AdminGateway extends BaseView {
    state = {
        isLoading: false,
        page: 1,
        integrationsPage: 1,
        integrationMaxRowsPerPage: 50,

        gateway: { id: null, title: '', integrations: [] },
        students: { total: 0, page: 1, list: [] },
        pendingImports: []
    };

    _findDegree(id) {
        const templateCategories = this.props
            .templateSchools
            .reduce((curr, s) => curr.concat(s.categories), []);

        const categories = this.props.school.categories
            .concat(templateCategories);

        for (const cat of categories) {
            if (!cat.degrees) { continue; }

            for (const degree of cat.degrees) {
                if (degree.id === id) { return degree; }
            }
        }

        throw Error(`Cannot find degree ${id}`);
    }

    degreeExists(id) {
        try {
            this._findDegree(id);
        } catch (ex) {
            return false;
        }

        return true;
    }

    getSelectedDegreesText() {
        const { all_degrees, is_public, degrees } = this.state.gateway;

        if (all_degrees) {
            return 'Todos os cursos';
        }

        if (is_public) {
            return 'Todos os cursos públicos';
        }

        if (!degrees) { return '(nenhum curso)'; }

        if (degrees.length <= 3) {
            const degreeTitles = degrees
                .filter(this.degreeExists.bind(this))
                .map((id) => {
                const degree = this._findDegree(id);

                return degree.title;
            });

            const last = degreeTitles.pop();

            if (degreeTitles.length) {
                return `${degreeTitles.join(', ')} e ${last}`;
            } else {
                return last || '(nenhum curso)';
            }
        }

        return `${degrees.length} cursos`
    }

    _paginationUrl(page) {
        return url(`turma/${this.state.gateway.id}/p/${page}`)
    }

    renderIntegrations(integrations) {
        integrations = integrations.filter((i) => !i.isAddButton);

        return (
            <div className='grid-wrapper'>
                <table className='integrations-table'>
                    <thead>
                        <tr>
                            <th className='-name'>Nome</th>
                            <th className='-codes'>Código</th>
                            <th className='-ids'>Ids</th>
                            <th className='-actions'>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        { integrations.map((i) =>
                                    <tr key={ i.id }>
                                        <td className='-name'>{i.title}</td>
                                        <td className='-codes'>{i.code}</td>
                                        <td className='-ids'>
                                            {i.ids && [].concat(i.ids).map((id, i, arr) => arr.length - 1 !== i ?
                                                `${id}, ` : id)}
                                        </td>
                                        <td className='-actions'>
                                            <button style={{
                                                fontSize: '.8rem',
                                                padding: '.4rem',
                                                marginTop: '.4rem',
                                            }}onClick={ (ev) => {
                                                ev.preventDefault();

                                                this.refs.addIntegrationModal.show(i);
                                            } } className='btn -secondary'>
                                                <i style={ { margin: 0, fontSize: '1.2rem' } } translate='no' className='material-icons notranslate'>edit</i>
                                            </button>
                                            <button style={{
                                                fontSize: '.8rem',
                                                padding: '.4rem',
                                                marginTop: '.4rem',
                                                marginLeft: '.4rem'
                                            }} className='btn -secondary' onClick={(ev) => {
                                                ev.preventDefault();

                                                if (global.window.confirm('Tem certeza?')) {
                                                    this.removeIntegration(i);
                                                }
                                            }}>
                                                <i style={ { margin: 0, fontSize: '1.2rem' } } translate='no' className='material-icons notranslate'>delete</i>
                                            </button>
                                        </td>
                                    </tr>
                                ) }
                    </tbody>
                </table>
            </div>
        )
    }

    renderStudents() {
        const gatewayTitle = (this.state.gateway && this.state.gateway.title) || '';

        return (
            <div className='grid-wrapper'>
                <table className='students-table'>
                    <thead>
                        <tr>
                            <th className='-name'>Nome</th>
                            <th className='-email'>E-mail</th>
                            <th className='-actions'>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.students.list.map((s) =>
                            <tr key={ s.user_id }>
                                <td className='-name'>{s.name}</td>
                                <td className='-email'>{s.email}</td>
                                <td className='-actions' style={ { textAlign: 'right' } }>
                                    <Link to={ url(`aluno/${s.link}`) }>dados do aluno</Link>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <PaginationComponent
                    onChange={(p) => {
                        this.loadGateway(p)
                        return this._paginationUrl(p);
                    }}
                    titleFn={(listTotal, total) => {
                        if (listTotal && listTotal === total) {
                            return `Mostrando ${total} aluno${total > 1 ? 's' : ''} matriculado${total > 1 ? 's' : ''} em ${gatewayTitle}`;
                        }

                        return `Mostrando ${listTotal} aluno${listTotal > 1 ? 's' : ''} de ${total} matriculado${total > 1 ? 's' : ''} em ${gatewayTitle}`;
                    }}
                    getUrl={this._paginationUrl.bind(this)}
                    collection={this.state.students} />
            </div>
        );
    }

    async removeGateway() {
        const gatewayId = this.state.gateway.id;

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`gateway/${gatewayId}/delete`);

            this.props.history.push(url('turmas'));
        } catch (ex) {
            global.error(ex);
        }
    }

    async removeIntegration(integration) {
        const id = integration.id;

        const {
            integrationsPage,
            integrationMaxRowsPerPage,
        } = this.state;

        try {
            const integrations = [...this.state.gateway.integrations]
                .filter((m) => Number(m.id) !== Number(id));

            const newTotalIntegrationPages = Math
                .ceil((integrations.length - 1) / integrationMaxRowsPerPage);

            if (integrationsPage > newTotalIntegrationPages) {
                this.setState({
                    integrationsPage: Math.max(1, integrationsPage - 1)
                });
            }

            this.setGateway({ integrations })
        } catch (ex) {
            global.error(ex);
        }
    }

    async onImportStudent(students, filename) {
        try {
            const totalStudents = students.length;

            await ajaxAdapter({
                isSchoolRequest: true
            }).postFormData(`students/import?total=${totalStudents}` +
            `&filename=${filename}&school_gateway_id=${this.state.gateway.id}`, {
                students: {
                    blob: new Blob(
                        [ JSON.stringify(students) ],
                        { type: 'application/json' }
                    ),
                    name: `${filename}.json`
                }
            });

            alert('Importação iniciada, aguarde...');
            await this.loadGateway();
        } catch (ex) {
            global.error(ex);
        }
    }

    render() {

        const {
            integrationsPage,
            integrationMaxRowsPerPage,
            gateway
        } = this.state;

        const integrations = gateway.integrations.slice(
            (integrationsPage - 1) * integrationMaxRowsPerPage,
            (integrationsPage * integrationMaxRowsPerPage)
        );

        const totalIntegrationPages = Math.ceil(
            (gateway.integrations.length) /
            integrationMaxRowsPerPage);

        const integrationPages = [];
        for (let i = 1; i <= totalIntegrationPages; ++i) {
            integrationPages.push(i);
        }

        return (
            <div className='view-class base-view'>
                <h2
                    onInput={() => {

                    }}
                    onFocus={() => {

                    }}
                    onBlur={(ev) => {
                        const val = getInnerValueByEvent(ev);
                        this.setGateway({ title: val })
                    }}

                    contentEditable={true}
                    spellCheck={false}
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize((this.state.gateway && this.state.gateway.title) || '')
                    }} />

                <p className='-description'>
                    <span onClick={() => {
                        if (this.state.gateway.is_public) { return; }

                        this.refs.selectDegreesModal.show({
                            degrees: this.state.gateway.degrees,
                            is_public: this.state.gateway.is_public,
                            all_degrees: this.state.gateway.all_degrees
                        });
                    }} className={this.state.gateway.is_public ? '' : '-editable'}>{
                            this.getSelectedDegreesText()
                        }</span> {(this.state.gateway.degrees && this.state.gateway.degrees.length > 1) ||
                            this.state.gateway.all_degrees ? 'estão disponíveis' : 'está disponível'} para a {this.state.gateway.title}.
                </p>

                <div className='integration-list-wrapper'>
                    <h3>Mover alunos</h3>
                    <div className='-fields'>
                        <div style={ {
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        } }>

                        <div style={ { width: 200 } } className='input'>
                            <select onChange={ (ev) => {
                                this.setState({
                                    migrateStudentsFrequency: ev.nativeEvent.target.value
                                });
                            } } value={ this.state.migrateStudentsFrequency }>
                                <option value='-1'></option>
                                <option value='all'>Todos os alunos</option>
                                <option value='year'>Cadastrados a mais de um ano</option>
                                <option value='6month'>Cadastrados a mais 6 meses</option>
                                <option value='month'>Cadastrados a mais 1 mes</option>
                            </select>
                        </div>
                        <div style={ { marginLeft: 10, marginRight: 10, width: 200 } } className='input'>
                            <select onChange={ (ev) => {
                                this.setState({
                                    migrateStudentsTo: ev.nativeEvent.target.value
                                });
                            } } value={ this.state.migrateStudentsTo }>
                                <option value='-1'></option>
                                { this.props.gatewayList.map((g) => <option
                                    key={ g.id }
                                    value={ g.id }>{ g.title }</option>)}
                            </select>
                        </div>

                            <button
                                onClick={ async () => {
                                    const {
                                        migrateStudentsFrequency,
                                        migrateStudentsTo
                                    } = this.state;

                                    if (!migrateStudentsFrequency || migrateStudentsFrequency === '-1') {
                                        return alert('Por favor, selecione uma frequencia');
                                    }

                                    if (!migrateStudentsTo || migrateStudentsTo === '-1') {
                                        return alert('Por favor, selecione uma turma');
                                    }

                                    if (!window.confirm('Tem certeza que deseja migrar os alunos?')) {
                                        return;
                                    }

                                    try {
                                        const gatewayId = this.state.gateway.id;
                                        const res = await ajaxAdapter({
                                            isSchoolRequest: true
                                        }).post(`gateway/${gatewayId}/migrate`, {
                                            migrateStudentsFrequency,
                                            migrateStudentsTo
                                        });

                                        alert('Alunos movidos!');

                                        const page = this.props.match.params.page || this.state.page;
                                        this.loadGateway(page, gatewayId);
                                    } catch (ex) {
                                        global.error(ex);
                                    }

                                } }
                                class='btn btn-primary'>Mover alunos</button>
                        </div>
                    </div>
                </div>

                <div className='integration-list-wrapper'>
                    <h3>Integrações</h3>

                    <header>
                        <button onClick={() => {
                            this.refs.addIntegrationModal.show();
                        }} className='btn -primary'>
                            Integrar
                        </button>
                    </header>

                    {
                        integrations.length === 0 &&
                        <p>Não há nenhuma integração para essa turma</p>
                    }

                    { integrations.length > 0 && this.renderIntegrations(integrations) }

                    {
                        totalIntegrationPages > 1 &&
                        <div className='pagination-integrations'>
                            <p>
                                Páginas: &nbsp;
                                {
                                    integrationPages.map((page) => page === integrationsPage ?
                                    <span>{ page }&nbsp;</span> :
                                    <React.Fragment>
                                        <a href='/' onClick={ (ev) => {
                                            ev.preventDefault();
                                            this.setState({ integrationsPage: page });
                                        } }>
                                            { page }
                                        </a>&nbsp;
                                    </React.Fragment>)
                                }
                            </p>
                        </div>
                    }
                </div>

                <div className='student-list-wrapper'>
                    <h3>Alunos matriculados</h3>

                    <header>
                        <button onClick={() => {
                            this.refs.addStudentModal.show();
                        }} className='btn -primary'>
                            Inscrever aluno
                        </button>

                        <Dropzone accept={ [ 'text/csv', 'application/json' ] } onDrop={ (files) => {
                            if (!files.length) { return; }

                            const reader = new global.FileReader();

                            reader.onloadend = () => {
                                const students = getStudentsFromCsv(
                                    reader.result
                                );

                                if (students.length > 10000) {
                                    alert('Máximo de 10000 alunos por importação');
                                    return;
                                }

                                if (!students.length) {
                                    alert(`Nenhum e-mail encontrado no arquivo ${files[0].name}`);
                                    return;
                                }

                                this.refs.importStudentModal.show({ students, filename: files[0].name });
                            };

                            reader.readAsBinaryString(files[0]);
                        } }>
                            {({ getRootProps, getInputProps }) => (
                                <button { ...getRootProps() } className='btn -primary'>
                                    <input { ...getInputProps() } />
                                    Importar alunos
                                </button>
                            )}
                        </Dropzone>
                    </header>

                    { this.state.pendingImports.length > 0 &&
                    <div className='pending-imports'>
                        <strong>Importação em andamento para os arquivos:</strong>
                        <br />
                        { this.state.pendingImports.map((p) => <div>
                            - { p.filename }, &nbsp; &nbsp;{ p.total_students } alunos
                        </div>)}
                    </div> }

                    {this.state.students.total === 0 &&
                        <div className='student-list'>
                            <p>Nenhum aluno cadastrado para essa turma</p>
                        </div>}

                    {this.state.students.total > 0 && this.renderStudents()}
                </div>

                { this.state.gateway.id && !this.state.gateway.is_public &&
                    this.state.students.total === 0 &&
                    <div className='link-remove'>
                        <button style={{
                            fontSize: '.8rem',
                            padding: '.4rem'
                        }} className='btn -remove' onClick={(ev) => {
                            ev.preventDefault();

                            if (global.window.confirm('Tem certeza?')) {
                                this.removeGateway();
                            }
                        }}>Apagar turma</button>
                    </div>}

                <AddStudentModal
                    onSubmit={this.onAddStudent.bind(this)}
                    ref='addStudentModal' />

                <ImportStudentModal
                    onSubmit={this.onImportStudent.bind(this)}
                    ref='importStudentModal' />

                <SelectDegreesModal
                    templateSchools={this.props.templateSchools}
                    school={this.props.school}
                    className={this.state.gateway.title}
                    onSubmit={(values) => {
                        const all_degrees = values.all_degrees;
                        const degrees = all_degrees ? [] : values.degrees;

                        this.setGateway({ degrees, all_degrees })
                    }}
                    ref='selectDegreesModal' />

                <AddIntegrationModal
                    school={ this.props.school }
                    gatewayId={ this.state.gateway.id }
                    onSubmit={(values) => {
                        const integrations = [...this.state.gateway.integrations];

                        delete values.logo;
                        delete values.isModalOpen;
                        delete values.focus;

                        if (values.id) {
                            for (let idx = 0; idx < integrations.length; ++idx) {
                                if (integrations[idx].id === values.id) {
                                    integrations[idx] = {
                                        ...integrations[idx],
                                        ...values
                                    };
                                }
                            }

                        } else {
                            values.id = getId();

                            integrations.push(values);
                        }

                        this.setGateway({ integrations });
                    }}
                    ref='addIntegrationModal' />

                <LoadingMask fullScreen={true} show={this.state.isLoading} />
            </div>
        );
    }

    isPointIn(x, y, cls) {
        const el = document.elementFromPoint(x, y);

        if (!el) { return false; }

        if (el.className && el.className.includes && el.className.includes(cls)) {
            return true;
        }

        if (
            el.parentNode &&
            el.parentNode.className &&
            el.parentNode.className.includes &&
            el.parentNode.className.includes(cls)) { return true; }

        return false;
    }



    setGateway(values, doSave = true) {
        const cls = { ...this.state.gateway, ...values };

        this.setState({ gateway: cls });
        if (doSave) { this.saveGateway(cls); }
    }

    async onAddStudent(student) {
        try {
            const gatewayId = this.state.gateway.id;
            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).post(`gateway/${gatewayId}/add-student`, student);

            if (res.bank) {
                updateMemory({ bank: res.bank });
            }

            const page = this.props.match.params.page || this.state.page;
            this.loadGateway(page, gatewayId);
        } catch (ex) {
            global.error(ex);
        }
    }

    async saveGateway(cls) {
        const gatewayId = this.state.gateway.id;

        try {
            await ajaxAdapter({
                isSchoolRequest: true
            }).post(`gateway/${gatewayId}`, cls);
        } catch (ex) {
            global.error(ex);
        }
    }

    async loadGateway(page, gatewayId = this.props.match.params.gatewayId) {
        try {
            page = page || this.props.match.params.page || this.state.page;
            let res = {
                gateway: this.props.gatewayList
                    .find((c) => c.id === Number(gatewayId))
            };

            this.setState(res);

            res = { ...res, ...(await ajaxAdapter({
                isSchoolRequest: true
            }).get(`gateway/${gatewayId}?page=` + page)) };

            res.isLoading = false;

            this.setState(res);
        } catch (ex) {
            global.error(ex);
        }
    }

    async createGateway() {
        const res = await ajaxAdapter({
            isSchoolRequest: true
        }).post('gateway/create');

        window.history.replaceState(null, this.props.school.name, url('turma/' + res.gateway.id));

        const gatewayList = [...this.props.gatewayList];
        gatewayList.push(res.gateway);

        updateMemory({ gatewayList });

        res.isLoading = false;
        this.setState(res);
    }

    async componentDidMount() {
        super.componentDidMount();

        try {
            const { gatewayId } = this.props.match.params;

            if (!gatewayId) {
                return await this.createGateway();
            }

            await this.loadGateway();
        } catch (ex) {
            global.error(ex);
        }
    }
}

export default MemoryComponent(AdminGateway, 'school', 'gatewayList', 'templateSchools');
