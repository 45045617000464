import axios from 'axios';
import i18n from './i18n';
import config from './config';

import ImageUtils from './ImageUtils';

function redirectUrl() {
    const pathParts = global.window.location.pathname.split('/');
    const basepath = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}`;

    return `${basepath}/login`;
}

async function getBlob(file) {
    let reader = new global.FileReader();
    let img = global.document.createElement('img');

    return new Promise((resolve) => {
        reader.onload = (e) => {
            img.src = e.target.result;

            img.onload = () => {
                let iu = new ImageUtils({ maxWidth: 1280, quality: 7 });

                iu.scaleImage(img, file.type, (data) => {
                    img.remove();
                    resolve(data.blob);
                });
            };
        };

        reader.readAsDataURL(file);
    });
}

class AjaxAdapter {
    constructor(cfg = {}) {
        this.cfg = cfg;
        this.isSchoolRequest = cfg.isSchoolRequest;
    }

    async postFormData(url, body) {
        const formData = new global.FormData();

        for (const key in body) {
            const value = body[key];

            if (value && value.isSignature) {
                formData.append(`${key}Type`, 'image/png');
                formData.append(`${key}Blob`, value.blob, value.name);
                continue;
            }

            if (value instanceof Blob && value.isImage) {
                const blob = await getBlob(value);

                formData.append(`${key}Type`, value.type);
                formData.append(`${key}Blob`, blob, value.name);

                const sizeInMb = (value.size / 1024 / 1024)

                if (sizeInMb > 3) {
                    alert(`Imagem: ${value.name} excede o tamanho máximo de 3mb!`);
                    return;
                }

                continue;
            }

            if (value && value.blob) {
                formData.append(key, value.blob, value.name);
                continue;
            }

            formData.append(key, value);
        }

        return this.doRequest(url, 'POST', formData, {
            'Content-Type': 'multipart/form-data'
        });
    }

    async doRequest(url, method, body, headers) {
        headers = headers || {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };

        const jwtToken = localStorage.getItem('jwtToken');
        if (jwtToken) {
            headers['Authorization'] = `Bearer ${jwtToken}`;
        }

        if (/logout/.test(url)) {
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('user');
        }

        url = url.replace(config.baseSchoolUrl, config.baseApiSchoolUrl);
        url = (url.slice(0, 4) !== 'http' && url[0] !== '/' ? '/' : '') + url;

        if (global.baseApiPath && this.isSchoolRequest) {
            url = `${global.baseApiPath}${url}`;
        }

        return new Promise((resolve, reject) => {
            const options = {
                method: method,
                url: url.slice(0, 4) !== 'http' ? config.baseApiUrl + url : url,
                headers
            };

            if (/get/i.test(method)) {
                options.params = body;
            } else {
                options.data = body;
            }

            axios(options).then(function(res) {
                if (res && res.data && res.data.token) {
                    localStorage.removeItem('contextOverride');
                    localStorage.setItem('jwtToken', res.data.token);
                    localStorage.setItem('user', JSON.stringify(res.data.user));
                    localStorage.setItem('schools', JSON.stringify(res.data.schools));
                }

                resolve(res && res.data);
            }).catch(function(axiosResponse) {
                const res = axiosResponse.response || {};

                if (res.status === 401 || res.status === 403) {
                    const r = res.data;

                    if (r.redirectUrl) {
                        window.location.href = r.redirectUrl;
                        return;
                    }

                    if (/degree/i.test(r.message)) {
                        return reject(new Error(reject(i18n.t(r.message))));
                    }

                    localStorage.removeItem('jwtToken')
                    localStorage.removeItem('user');

                    return window.location.href = redirectUrl();
                }

                if (res.status === 205) {
                    return resolve(res.data);
                }

                if (res.data) {
                    const r = res.data;
                    let msg = r.message || r.msg;

                    if (msg && msg.slice(-1) === '.') {
                        msg = msg.slice(0, -1);
                    }

                    if (msg) {
                        res.message = i18n.t(msg);
                    }
                }

                if (!res.message) {
                    res.message = [
                        'Desculpe, mas aconteceu um erro inesperado.',
                        "Por favor, tente novamente mais tarde."
                    ].join('\n');
                }

                reject(res);
            });
        });
    }

    async get(url, postData) {
        return await this.doRequest(url, 'GET', postData);
    }

    async post(url, postData) {
        return await this.doRequest(url, 'POST', postData);
    }

    async delete(url, postData) {
        return await this.doRequest(url, 'DELETE', postData);
    }
}

export default function (cfg = {}) {
    return new AjaxAdapter(cfg);
};
