import React from 'react';
import Dropzone from 'react-dropzone'

import IseiForm from '../../components/Form';
import config from '../../config';
import LoadingMask from '../../components/LoadingMask';
import ajaxAdapter from '../../ajaxAdapter';
import { MemoryComponent, updateMemory } from '../../memory';
import { url, copyToClipboard, pick, capitalize } from '../../utils';
import CropImageModal from '../../components/CropImageModal';
import BaseView from '../BaseView';

import { isValid, prettify, nextTick } from '../../utils';

const cardTypeImages = {
    visa: require('../../assets/credit-cards/visa.png'),
    mastercard: require('../../assets/credit-cards/mastercard.png'),
    jcb: require('../../assets/credit-cards/jcb.png'),
    discover: require('../../assets/credit-cards/discover.png'),
    diners_club: require('../../assets/credit-cards/diners_club.png'),
    amex: require('../../assets/credit-cards/amex.png')
}

function getPrettyValueOfLink(value, values) {
    const base = `${config.baseAppUrl}/`;

    if (/\/escola\//.test(value)) {
        return `${base}escola/${global.school.id}`;
    }

    if (values.use_own_domain || value.indexOf(base) === 0) {
        if (values.use_own_domain && value.indexOf('http') !== 0) {
            value = `${window.location.protocol}//${value}`;
        }

        const link = prettify.removeDiacritics(value).replace(/\s/g, '-').toLowerCase();
        const domain = link.split('/')[2];
        const path = link.split('/')[3];

        if (values.use_own_domain) {
            return `${window.location.protocol}//${domain}`;
        }

        return `${window.location.protocol}//${domain}/${path}`;
    }

    return `${base}${value.replace(base, '')}`;
}

class PaymentMethods extends React.Component {
    render() {
        return <div className='payment-methods-wrapper'>
            <main style={ { flexDirection: 'column' }}>
                { this.props.paymentMethods.map(this.renderPaymentMethod.bind(this))}
            </main>
        </div>;
    }

    onRemoveCard(paymentMethod) {
        this.props.onRemoveCard(paymentMethod);
    }

    renderPaymentMethod(paymentMethod) {
        return (<div key={ paymentMethod.id } className='payment-method'>
            <div className='-brand'>
                <img
                    src={ cardTypeImages[paymentMethod.card_type]}
                    alt={ capitalize(paymentMethod.card_type) } />
            </div>

            <div className='-number'>
                <p>XXXX-XXXX-XXXX-{paymentMethod.last_4_digits}</p>
            </div>
            <button onClick={ async () => {
                this.setState({ isLoading: true })
                if (!window.confirm('Tem certeza?')) { return; }

                await this.onRemoveCard(paymentMethod);
            } } type='button' className='btn -secondary'>
                Remover cartão
            </button>
        </div>);
    }
}

const configSections = [
    {
        title: 'Configurações gerais',
        description: 'Altere dados básicas de seu Ensino a Distância',
        fields: {
            link: {
                type: 'text',
                icon: 'content_copy',
                placeholder: 'exemplo: isei.com.br',
                label: 'Link de sua escola',
                maxLength: 40,
                getPrettyValue(value, values) {
                    return getPrettyValueOfLink(value, values);
                },

                getValidationError(str, values) {
                    if (!values.use_own_domain || isValid.url(str)) {
                        return false;
                    }

                    return 'Por favor, preencha um dominio válido';
                },

                onButtonClick(value, field) {
                    field.focus();
                    field.select();
                    document.execCommand('copy');
                }
            },

            name: {
                type: 'text',
                icon: 'text_format',
                placeholder: 'Escola iSei',
                label: 'Nome de sua área de membros',
                autoComplete: 'organization',
                getValidationError(str) {
                    if (str.length < 4) {
                        return 'Por favor, preencha o nome de sua área de membros';
                    }

                    return false;
                }
            },

            email: {
                type: 'text',
                icon: 'alternate_email',
                label: 'E-mail da escola',
                placeholder: 'exemplo: carol@isei.com.br',
                autoComplete: 'email',
                selectOnFocus: true,
                getValidationError(str) {
                    if (isValid.email(str)) { return false; }

                    return 'Por favor, preencha um e-mail válido';
                }
            },

            cellphone: {
                type: 'text',
                icon: 'phone_iphone',
                label: 'Número do WhatsApp',
                placeholder: 'exemplo: (19) 9999.99999',
                maxLength: 20,
                selectOnFocus: true,
                autoComplete: 'tel',

                getValidationError(number) {
                    if (String(number).length > 10) { return false; }

                    return 'Por favor, preencha o seu WhatsApp corretamente';
                },

                getPrettyValue(val) {
                    return prettify.phone(val);
                }
            }
        }
    },

    {
        title: 'Tracking',
        description: 'Configure aqui o seu Google Analytics e Facebook Pixel',
        fields: {
            google_analytics_code: {
                type: 'text',
                icon: 'code',
                label: 'Google Analytics',
                placeholder: 'UA-000000-01',
                selectOnFocus: true
            },

            facebook_pixel: {
                type: 'text',
                icon: 'code',
                label: 'Facebook Pixel',
                placeholder: 'O seu pixel id',
                selectOnFocus: true
            }
        }
    },
    {
        title: 'Recursos premium',
        description: 'Controle completo de sua escola no isei',
        fields: {
            use_own_domain: {
                type: 'switch',
                label: 'Usar dominio próprio'
            },
            is_private: {
                type: 'switch',
                label: 'Escola privada'
            },
            has_certificate: {
                type: 'switch',
                label: 'Cursos com certificado'
            },
            has_multiple_users: {
                type: 'switch',
                label: 'Multiusuário'
            },
            has_affiliation: {
                type: 'switch',
                label: 'Sou matriz (tenho afiliados/filiais)'
            },
            has_api: {
                type: 'switch',
                label: 'Recursos avançados de API'
            }
        }
    },
    {

        title: 'Comunicação / E-mails',
        description: 'Aqui você personaliza o nome e avatar das comunicações automáticas que serão feitas com seus alunos',
        fields: {
            assistant_name: {
                type: 'custom',
                render() { return null; },

            },

            assistant_avatar_url: { type: 'custom' },
        }
    },
];

class ConfigDomainModal extends React.Component {
    state = {
        isModalOpen: false,
        name: '',
        email: ''
    };

    render() {
        if (!this.state.isModalOpen) { return null; }

        return (
            <div className={ `modal -select-degree -cropper ${this.state.isModalOpen ? '-open' : ''}` }>
                <div className='-content'>
                    <div className='-body'>
                        {this.state.error && <div className='alert -error'>{this.state.error}</div>}

                        <div className='field-wrapper'>
                            <label>Para configurar o seu domínio, adicione abaixo a sua URL:</label>
                            <div className={ this.state.focus === 'main_media_url' ? 'field -active' : 'field' } >
                                <div className='icon'>
                                    <i translate='no' className='material-icons notranslate'>link</i>
                                </div>
                                <div className='input'>
                                    <input
                                        onFocus={ (ev) => {
                                            ev.target.select();
                                            this.setState({ focus: 'main_media_url' })
                                        } }
                                        value={ this.state.link || '' }
                                        autoFocus={ true }
                                        maxLength={ 200 }
                                        onChange={ (ev) => {
                                            const d = ev.nativeEvent.target.value.trim();

                                            if (!d) {
                                                this.setState({ link: '' });
                                                return;
                                            }

                                            const link = prettify.removeDiacritics(d).replace(/\s/g, '-');
                                            const hasLink = isValid.url(link);

                                            this.setState({ link: link, hasLink });
                                        } }

                                        onBlur={() => {
                                            this.setState({ focus: null });
                                        }}
                                        placeholder='O domínio de sua escola, exemplo: ead.isei.app' type='text' />
                                </div>
                            </div>
                        </div>

                        <div className='how-to'>
                            <p>
                                E adicione um registro <a
                                target='_blank'
                                rel="noopener noreferrer"
                                href='https://support.google.com/a/answer/112037?hl=pt-BR'>CNAME</a> em seu <a
                                    target='_blank'
                                    rel="noopener noreferrer"
                                    href='https://www.youtube.com/results?search_query=como+adicionar+um+registro+cname+dns'>
                                    gerenciador de DNS</a> para a mesma URL e o seguinte valor:
                            </p>

                            <div onClick={ (ev) => {
                                const { currentTarget } = ev;

                                if (document.selection) { // IE
                                    const range = document.body.createTextRange();

                                    range.moveToElementText(currentTarget);
                                    range.select();
                                } else if (window.getSelection) {
                                    const range = document.createRange();

                                    range.selectNode(currentTarget);
                                    window.getSelection().removeAllRanges();
                                    window.getSelection().addRange(range);
                                }
                                copyToClipboard('');
                            } } ref='cname' className='-cname'>
                                cname.isei.app
                            </div>
                        </div>

                        <div className='-footer'>
                            { this.state.hasLink &&
                            <button type='button' onClick={ (ev) => {
                                this.setState({ isModalOpen: false });
                                this.props.onSubmit({ link: this.state.link })
                            }} className='btn -primary'>
                                Salvar meu domínio
                        </button> }
                            <button type='button' onClick={ () => {
                                this.setState({ isModalOpen: false });
                                this.props.onSubmit({ link: null })
                            } } className='btn -secondary'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async _submit(values) {
        this.props.onSubmit(values);
        this.hide();
    }

    toggle = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }

    show(state = {}) {
        this.setState({
            isModalOpen: true,
            name: '',
            email: '',
            id: null,
            ...state
        });
    }

    hide() { this.setState({ isModalOpen: false }); }
}

class Config extends BaseView {
    state = { error: null, success: null, isInRequest: false, paymentMethods: [] };

    _getLink() {
        const { namespaces } = this.props.school;
        const namespace = namespaces[0];

        if (!namespace) {
            return global.baseUiUrl;
        }

        if (namespace.domain) {
            return `${window.location.protocol}//${namespace.domain}`;
        }

        return `${config.baseAppUrl}/${namespace.namespace}`;
    }

    renderAvatarField(form) {
        const { values } = form.state;

        const assistantAvatarUrl = values.assistant_avatar_url || 'https://isei.app/sensei.png';
        const assistantName = values.assistant_name || 'Sensei';

        return (
            <div key='formImg' className='img-uploader-wrapper'>
                <Dropzone accept='image/jpeg, image/png' onDrop={ (files) => {
                    const reader = new global.FileReader();

                    reader.onloadend = () => {
                        this.refs.cropImageModal.show({
                            src: reader.result,
                            fileName: files[0].name,
                            blobField: 'assistantAvatarUrl',
                            imageField: 'assistant_avatar_url',
                            format: 'square'
                        });
                    }

                    reader.readAsDataURL(files[0]);
                }}>
                    {({ getRootProps, getInputProps }) => (
                        <React.Fragment>
                        <div {...getRootProps()} className='-default'>
                            <input {...getInputProps()} />

                            <div className='-logo-wrapper -square'>
                                <div className='content logo'>
                                    <img alt={ assistantName } title={ assistantName } src={ assistantAvatarUrl } />
                                </div>
                            </div>
                            <button type='button' className='btn -primary'>Alterar avatar</button>
                            { this.renderRemoveIfNecessary('assistant_avatar_url') }
                        </div>
                        </React.Fragment>
                    )}
                </Dropzone>

                <div className='-preview'>
                    { form.renderField('assistant_name', {
                        type: 'text',
                        icon: 'person',
                        placeholder: 'exemplo: Sensei',
                        label: 'Nome enviado na assinatura dos e-mails',
                        maxLength: 40,
                        selectOnFocus: true,
                        getPrettyValue(val) {
                            return capitalize(val);
                        },

                        getValidationError(str) {
                            if (str.length < 4) {
                                return 'Por favor, preencha o nome de sua assistente';
                            }

                            return false;
                        }
                    }) }
                    <h5>Pré-visualização</h5>

                    <div className='-email-footer'>
                        <div className='-avatar'>
                            <img src={ assistantAvatarUrl } alt={ assistantName } />
                        </div>
                        <div className='-text'>
                            <p>
                                { assistantName }, { values.name }<br />
                                { values.email && <React.Fragment>
                                        <a href='/' onClick={ (ev) => { ev.preventDefault(); }}>{ values.email }</a> - { prettify.phone(values.cellphone) }
                                        <br />
                                    </React.Fragment> }
                                <a href='/' onClick={ (ev) => { ev.preventDefault(); }}>{ values.link }</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>);

    }

    renderRemoveIfNecessary(field) {
        const val = this.props.school[field];
        if (!val) { return null; }

        return (
            <a href='#remove' onClick={ async(ev) =>{
                ev.preventDefault();
                ev.stopPropagation();

                if (window.confirm('Tem certeza?')) {
                    const school = { ...this.props.school };

                    school[field] = null;
                    this.refs.form.setValue(field, null);
                    updateMemory({ school })

                    try {
                        await ajaxAdapter({
                            isSchoolRequest: true
                        }).delete('logo', { field });
                    } catch(ex) {
                        global.error(ex);
                    }

                }
            } } className='-remove'>remover</a>
        )
    }

    render() {
        const cfgSections = [ ...configSections ];
        const values = { ...this.props.school };

        values.cellphone = prettify.phone(values.cellphone);
        values.link = this._getLink();
        values.assistant_name = values.assistant_name || 'Sensei';

        cfgSections[3].fields.assistant_avatar_url.render =
            this.renderAvatarField.bind(this);

        cfgSections[2].fields.use_own_domain.onChange = (value, values, instance) => {
            values = { ...values };
            values.use_own_domain = value;

            if (value) {
                values.link = '';
            } else {
                values.link = global.defaultBaseSchoolUrl;
            }

            instance.setState({ values });

            if (!values.link && values.use_own_domain) {
                const balance = Number(this.props.bank.balance);

                if (balance <= 0 && Number(this.props.resource.price_ssl) > 0) {
                    this.props.history.push(url('offer/dominio'));
                    return;
                }

                this.refs.configDomainModal.show();
                return;
            }

            instance.refs.link.focus()
            nextTick(() => instance.refs.link.select());
        };

        if (this.state.paymentMethods.length) {
            cfgSections.push({
                title: 'Métodos de pagamento',
                description: 'Aqui você gerencia os seus métodos de pagamento',
                fields: {
                    payment_methods: {
                        type: 'custom',
                        render: () => {
                            return <PaymentMethods
                                key='payMethod'
                                onRemoveCard={ this.onRemoveCard.bind(this) }
                                paymentMethods={ this.state.paymentMethods } />;
                        },
                    }
                }
            });
        }

        return (<div className='view-config'>
            <IseiForm
                ref='form'
                success={ this.state.success }
                error={ this.state.error }
                onSubmit={ this.onSubmit.bind(this) }
                sections={ cfgSections }
                values={ values }
                buttonsRight={ true }
                submitButton={ { label: 'Salvar' } } />

                <LoadingMask
                    show={ this.state.isInRequest || this.state.isLoading }
                    fullScreen={ true } />

                <CropImageModal
                    onSubmit={async (croppedImage) => {
                        const school = { ...this.props.school };

                        school[croppedImage.imageField] = croppedImage.fileUrl;
                        this.refs.form.setValue(croppedImage.imageField, croppedImage.fileUrl);

                        updateMemory({ school })

                        try {
                            croppedImage.blob.isImage = true;

                            await ajaxAdapter({ isSchoolRequest: true }).postFormData('logo', {
                                field: croppedImage.imageField,
                                file: croppedImage.blob
                            })
                        } catch(ex) {
                            global.error(ex);
                        }
                    }}
                    ref='cropImageModal' />

            <ConfigDomainModal
                onSubmit={ ({ link }) => {
                    const values = { ...this.refs.form.state.values }
                    if (!link) {
                        link = `escola/${this.props.school.id}`;
                        values.use_own_domain = false;
                    }

                    link = getPrettyValueOfLink(link || '', values);
                    values.link = link;

                    this.refs.form.setState({ values });

                    if (values.use_own_domain) { this.onSubmit(values); }
                }}
                ref='configDomainModal' />
        </div>);
    }

    async onSubmit(values) {
        if (this.state.isInRequest) { return; }

        try {
            this.setState({ isInRequest: true, error: null, success: null });

            const res = await ajaxAdapter({
                isSchoolRequest: true
            }).post('update', pick(values, 'link', 'name',
                'email', 'cellphone', 'use_own_domain',
                'is_private',
                'has_certificate', 'assistant_name', 'has_video_upload',
                'google_analytics_code', 'facebook_pixel',
                'has_api', 'has_affiliation',
                'has_multiple_users', 'has_support'));

            updateMemory({ school: res.school })

            this.setState({
                isInRequest: false,
                success: 'Dados atualizados com sucesso'
            });

        } catch(ex) {
            console.error(ex);

            this.setState({ isInRequest: false, error: ex.message });
        } finally {
            const el = document.querySelector('#app');

            if (el) {
                el.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
        }
    }

    async componentDidMount() {
        await this.loadPaymentMethods();
    }

    async loadPaymentMethods() {
        try {
            const { paymentMethods } = await ajaxAdapter({
                isSchoolRequest: true
            }).get('payment-methods')

            this.setState({
                isLoading: false,
                paymentMethods: paymentMethods,
                isShowingWidget: paymentMethods.length === 0
            })
        } catch (ex) {
            global.error(ex)
        }
    }

    async onRemoveCard(paymentMethod) {
        try {
            this.setState({ success: null, error: null, isLoading: true })
            const { paymentMethods } = await ajaxAdapter({
                isSchoolRequest: true
            }).post('remove-payment-method', {
                id: paymentMethod.dbId,
                paymentMethodStripeId: paymentMethod.id
            });

            this.setState({
                isLoading: false,
                paymentMethods: paymentMethods,
                isShowingWidget: paymentMethods.length === 0,
                success: 'Cartão removido com sucesso'
            })
        } catch (ex) {
            this.setState({
                isLoading: false,
                error: ex.message
            })
            console.error(ex);
        } finally {
            const el = document.querySelector('#app');

            if (el) {
                el.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
        }

    }
}

export default MemoryComponent(Config, 'school', 'bank', 'resource');
