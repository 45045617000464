import React from 'react';
import BaseView from '../BaseView';
import { url } from '../../utils';
import { updateMemory, MemoryComponent } from '../../memory';
import ajaxAdapter from '../../ajaxAdapter';

class StudentBookmarks extends BaseView {
    render() {
        return (
            <div className='view-degrees -student'>
                <div className='category-degrees '>
                    <header>
                        <h2>Meus favoritos</h2>
                    </header>
                    { this.props.bookmarks.length === 0 &&
                        <p>Você ainda não possuí nenhum favorito.</p> }
                    <div className='degrees-list-wrapper'>
                        <div className='degrees-list'>
                            {this.props.bookmarks.map(this.renderBookmark.bind(this))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderBookmark(bookmark) {
        return <div onClick={ () => {
            const { degree_link, content_link } = bookmark;

            let link = `/c/${degree_link}`;
            if (content_link) {
                link += `/${content_link}`;
            }

            this.props.history.push(url(link));
        } } className='degree' id={ bookmark.id }>
            <h4>{ bookmark.title }</h4>
                { bookmark.img_wide_url && <div className='-background'>
                    <div className='overlay' />
                    <img loading='lazy' alt={ bookmark.title } src={ bookmark.img_wide_url } />
                </div>}
        </div>;
    }

    async loadBookmarks() {
        try {
            const { bookmarks } = await ajaxAdapter({
                isSchoolRequest: true
            }).get('bookmarks/student');

            updateMemory({ bookmarks });
            this.setState({ isLoading: false });
        } catch(ex) {
            global.error(ex);
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.loadBookmarks();
    }
}

export default MemoryComponent(StudentBookmarks, 'bookmarks');
