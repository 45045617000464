import React from 'react';
import Dropzone from 'react-dropzone'
import { MemoryComponent, updateMemory } from '../../memory';
import ajaxAdapter from '../../ajaxAdapter';
import IseiForm from '../../components/Form';
import { Sensei } from '../../svgs';
import BaseView from '../BaseView';
import { validations, prettify, capitalize } from '../../utils';
import CropImageModal from '../../components/CropImageModal';

const configSections = [
    {
        title: 'O seu perfil',
        description: 'Configure aqui o seu perfil, do seu jeito',
        fields: {
            name: {
                type: 'custom',
                render() { return null; },

                getValidationError(str) {
                    if (!str || str.length < 4) {
                        return 'Por favor, preencha o seu nome';
                    }

                    return false;
                }
            },

            cellphone: {
                type: 'custom',
                render() { return null; },
                getValidationError(number) {
                    if (String(number).length > 10) { return false; }

                    return 'Por favor, preencha o seu WhatsApp corretamente';
                }
            },
            cpf: {
                type: 'custom',
                render() { return null; },
                getValidationError(str) {
                    return (String(str).length < 10) ? 'Por favor, preencha o seu CPF corretamente' : false;
                }
            },
            profile_img_url: { type: 'custom' },
            dark_mode: { type: 'custom', render() { return null; } },
            is_private: { type: 'custom', render() { return null; } },
            password: {
                type: 'custom',
                render() { return null; },
                getValidationError: validations.password
            }
        }
    }
];

class ViewMyAccount extends BaseView {
    state = { };

    async onSubmit(values) {
        try {
            updateMemory({ user: { ...this.props.user, ...values } })
            this.setState({ isInRequest: true, error: null, success: null });

            await ajaxAdapter().post('profile/update', {
                schoolId: global.school?.id,
                name: values.name,
                cpf: values.cpf.replace(/\D/g, ''),
                cellphone: values.cellphone.replace(/\D/g, ''),
                password: values.password
            });

            this.setState({
                isInRequest: false,
                success: 'Dados atualizados com sucesso'
            });

        } catch(ex) {
            console.error(ex);

            this.setState({ isInRequest: false, error: ex.message });
        } finally {
            const el = document.querySelector('#app');

            if (el) {
                el.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
        }
    }

    renderAvatarField(form) {
        const { values } = form.state;

        return (
            <div key='formImg' className='img-uploader-wrapper'>
                <Dropzone accept='image/jpeg, image/png' onDrop={ (files) => {
                    const reader = new global.FileReader();

                    reader.onloadend = () => {
                        this.refs.cropImageModal.show({
                            src: reader.result,
                            fileName: files[0].name,
                            blobField: 'assistantAvatarUrl',
                            imageField: 'profile_img_url',
                            format: 'square'
                        });
                    }

                    reader.readAsDataURL(files[0]);
                }}>
                    {({ getRootProps, getInputProps }) => (
                        <React.Fragment>
                            <div {...getRootProps()} className='-default' style={ {
                                background: 'transparent'
                            } }>
                            <input {...getInputProps()} />

                            <div className='-logo-wrapper -square'>
                                <div className='content logo'>
                                    { !values.profile_img_url &&
                                        <div className='sensei'><Sensei /></div> }
                                    { values.profile_img_url &&
                                        <img alt={ values.name } title={ values.name } src={ values.profile_img_url } /> }
                                </div>
                            </div>
                            <button type='button' className='btn -primary'>Alterar avatar</button>
                            { this.renderRemoveIfNecessary('profile_img_url') }
                        </div>
                        </React.Fragment>
                    )}
                </Dropzone>

                <div className='-preview'>
                    { form.renderField('name', {
                        type: 'text',
                        icon: 'person',
                        autoComplete: 'name',
                        getPrettyValue: capitalize

                    }) }

                    { form.renderField('cellphone', {
                        type: 'text',
                        icon: 'phone_iphone',
                        placeholder: 'exemplo: (19) 9999.99999',
                        maxLength: 20,
                        selectOnFocus: true,
                        autoComplete: 'tel',

                        getPrettyValue(val) {
                            return prettify.phone(val);
                        }
                    }) }

                    { form.renderField('cpf', {
                        type: 'text',
                        icon: 'contacts',
                        placeholder: 'O seu documento CPF',
                        autoComplete: 'name',
                        getPrettyValue(val) {
                            return prettify.cpf(val);
                        }
                    }) }

                    { this.state.isChangingPassword && <React.Fragment>
                        { form.renderField('password', {
                            type: 'password',
                            autoFocus: true,
                            icon: 'locker',
                            placeholder: 'Preencha aqui a sua nova senha',
                            autoComplete: 'new-password',
                            selectOnFocus: true
                        }) }
                    </React.Fragment> }

                    { form.renderField('change_password', {
                        type: 'switch',
                        label: 'Alterar senha',
                        onChange: async(val, values) => {
                            this.setState({ isChangingPassword: val });
                        }
                    }) }

                    { form.renderField('dark_mode', {
                        type: 'switch',
                        label: 'Modo escuro',
                        onChange: async(val, values) => {
                            updateMemory({ user: { ...values, dark_mode: val } });

                            try {
                                await ajaxAdapter({ }).post('user/dark-mode', {
                                    dark_mode: val
                                });
                            } catch(ex) {
                                global.error(ex)
                            }
                        }
                    }) }
                </div>
            </div>);

    }

    renderRemoveIfNecessary(field) {
        const val = this.props.user[field];
        if (!val) { return null; }

        return (
            <a href='#remove' onClick={ async(ev) =>{
                ev.preventDefault();
                ev.stopPropagation();

                if (window.confirm('Tem certeza?')) {
                    const user = { ...this.props.user };

                    user[field] = null;
                    this.refs.form.setValue(field, null);
                    updateMemory({ user })

                    try {
                        await ajaxAdapter().delete('profile/img', { field });
                    } catch(ex) {
                        global.error(ex);
                    }

                }
            } } className='-remove'>remover</a>
        )
    }

    render() {
        const values = { ...this.props.user };

        values.cellphone = prettify.phone(values.cellphone);
        values.cpf = prettify.cpf(values.cpf);

        configSections[0].fields.profile_img_url.render =
            this.renderAvatarField.bind(this);

        return (<div className='view-my-account'>
                <IseiForm
                    success={ this.state.success }
                    error={ this.state.error }
                    onSubmit={ this.onSubmit.bind(this) }
                    sections={ configSections }
                    values={ values }
                    buttonsRight={ true }
                    ref='form'
                    submitButton={ { label: 'Salvar' } } />

                <CropImageModal
                    onSubmit={async (croppedImage) => {
                        const user = { ...this.props.user };

                        user[croppedImage.imageField] = croppedImage.fileUrl;
                        this.refs.form.setValue(croppedImage.imageField, croppedImage.fileUrl);

                        updateMemory({ user })

                        try {
                            croppedImage.blob.isImage = true;

                            await ajaxAdapter().postFormData('profile/img', {
                                field: croppedImage.imageField,
                                file: croppedImage.blob
                            })
                        } catch(ex) {
                            global.error(ex);
                        }
                    }}
                    ref='cropImageModal' />
            </div>
        );
    }
}

export default MemoryComponent(ViewMyAccount, 'user');
