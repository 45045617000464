module.exports = {
    "Can't find school": 'Escola não encontrada',
    'E-mail not found': 'E-mail não cadastrado.',
    'Domain or namespace in use': 'URL já está em uso, por favor, escolha outro',
    "Invalid password": 'E-mail ou senha inválida',
    "Can't find login": 'E-mail não encontrado',
    'bravapay': 'BravaPay',
    'hotmart': 'Hotmart',

    'Invalid name': 'Nome inválido',
    'Invalid email': 'E-mail inválido',
    'Invalid whatsapp': 'WhatsApp inválido',

    'Password too short': 'Senha muito curta',

    'Are you sure?': 'Tem certeza?',

    'name': 'Nome',
    'email': 'E-mail',
    'cpf': 'CPF',
    'whatsapp': 'WhatsApp',
    'city': 'Cidade',
    'state': 'Estado',
    'address': 'Endereço',

    'other_phone': 'Telefone',
    'found_at': 'Onde nos encontrou',
    'marital_status': 'Estado Civil',
    'born_date': 'Data de Nascimento',

    'married': 'Casada(o)',
    'single': 'Solteira(o)',
    'divorced': 'Divorciada(o)',

    'notes': 'Anotações',

    'male': 'Masculino',
    'female': 'Feminino',
    'other': 'Outro',

    'gender': 'Sexo',
    'country': 'País',

    "Can't find e-mail!": 'E-mail não encontrado',
    "Can't find token": 'Token inválido, tente novamente mais tarde.',

    "Your card was declined": 'O seu cartão não foi aprovado, nenhum valor foi cobrado.',
    "Your card number is incorrect": 'Número do cartão inválido, nenhum valor foi cobrado.',
    "Your card has insufficient funds": "O seu cartão não tem saldo suficiente, nenhum valor foi cobrado."
};
