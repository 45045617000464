import React from 'react';
import { updateMemory, MemoryComponent } from '../../memory';
import { url } from '../../utils';
import BaseView from '../BaseView';
import ajaxAdapter from '../../ajaxAdapter';

class ClassList extends BaseView {
    state = { isLoading: true, gatewayList: [ ] };

    render() {
        return (
            <div className='view-gateway-list'>
                <h2>Meus certificados</h2>

                <p className='-description'>
                    Crie aqui modelos de certificados e os atribua para os seus cursos.
                </p>

                <div className='gateway-list-wrapper'>
                    <div onClick={ () => {
                        this.props.history.push(url('certificado/criar'));
                    } } className='-add'>
                        <i translate='no' className='material-icons notranslate'>add_box</i>
                        <p>Criar Modelo</p>
                    </div>

                    { this.props.certificateList.map(this.renderCertificate.bind(this))}
                </div>
            </div>
        );
    }

    async componentDidMount() {
        super.componentDidMount();

        try {
            const { certificateList } = await ajaxAdapter({ isSchoolRequest: true }).get('certificate-list');

            updateMemory({ certificateList });
            this.setState({ isLoading: false });
        } catch(ex) {
            global.error(ex);
        }
    }
    renderCertificate(certificate) {
        return (
            <div key={ certificate.id } className='-gateway' onClick={ () => {
                this.props.history.push(url(`certificado/${certificate.id}`));
            }}>
                <h4>{ certificate.title }</h4>
            </div>
        );
    }
}

export default MemoryComponent(ClassList, 'school', 'certificateList');
